import React, { Component } from 'react';
import styled from 'styled-components';
import Card from '@datashop/card';
import Question from '../Questions/Question';
import { Redirect } from 'react-router-dom';
import { Loader } from 'packages/loader';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const ContentWrapper = styled.div`
   display: flex;
   overflow: auto;
`;

const QuestionWrapper = styled.div`
   width: 100%;
   padding: 8px 16px 16px;
`;

const QuestionCard = styled(Card)`
   display: flex;
   flex-direction: column;
   flex: 1;
   background-color: #fff;
   margin: 12px 0;
   padding: 8px;
`;

class PUIForm extends Component {
   state = {
      surveyStarted: false,
      redirectToSuccess: false,
      redirectToMain: false,
      surveyCompleted: false,
      questions: [],
      activeQuestion: {},
      activeQuestionNumber: 0,
      totalQuestions: null,
      questionsAnswered: 0,
      answers: {},
      isLoading: true,
      buttonLoading: false,
      responseId: this.props.responseId,
   };

   componentWillMount() {
      this.formatQuestions(this.props.data);
   }

   componentDidUpdate(prevProps) {
      if (prevProps.data.length !== this.props.data.length) {
         this.formatQuestions(this.props.data);
      }
   }

   formatQuestions = data => {
      const incomingAnswers = {};
      const newQuestions = data.map(item => {
         if (item.userResponse) {
            incomingAnswers[item.id] = item.userResponse;
         }
         return {
            ...item,
            selectedAnswerId: item.userResponse ? item.userResponse : null,
            answers: [
               ...item.answer.choiceList.map(ele => ({
                  ...ele,
                  label: ele.value,
               })),
            ],
         };
      });
      this.setState(
         { questions: newQuestions, answers: incomingAnswers },
         () => {
            this.checkCanSubmit();
         }
      );
   };

   checkCanSubmit = () => {
      if (
         !Object.values(this.state.answers).some(item => !item.length) &&
         Object.keys(this.state.answers).length === this.state.questions.length
      ) {
         this.props.getPuiPayload({ answerMap: this.state.answers });
      } else this.props.disableNext();
   };

   changeAnswer = (ind, questionId, answer) => {
      const newQuestionObject = this.state.questions.find(
         element => element.id === questionId
      );
      newQuestionObject.selectedAnswerId = answer;
      const newQuestionList = this.state.questions.map(item => {
         if (item.id === questionId) {
            item.selectedAnswerId = answer;
            return item;
         }
         return item;
      });
      let newAnswers = this.state.answers;
      newAnswers[questionId] = answer;
      this.props.answerQuestion(ind, questionId, answer, 'puiAssessment');
      this.setState(
         {
            answers: newAnswers,
            questions: newQuestionList,
            questionsAnswered: this.state.questionsAnswered + 1,
         },
         () => {
            this.checkCanSubmit();
         }
      );
   };

   onMultipleChoiceChangeHandler = (ind, questionId, answerId) => {
      this.changeAnswer(ind, questionId, answerId);
   };

   onTextChangeHandler = (ind, text, questionId) => {
      this.changeAnswer(ind, questionId, text);
   };

   onClickRadioHandler = (ind, event, selection) => {
      const { questionId, answerId } = selection;
      this.changeAnswer(ind, questionId, answerId);
   };

   submitForm = () => {
      this.props.getPuiPayload(this.state.answers);
   };

   render() {
      const { totalQuestions, questionsAnswered, answers } = this.state;
      if (this.state.redirectToMain) {
         return (
            <Redirect
               to={{
                  pathname: '/',
               }}
            />
         );
      }
      return (
         <Wrapper>
            {this.props.data.length === 0 ? (
               <Loader />
            ) : (
               <ContentWrapper>
                  <QuestionWrapper>
                     <QuestionCard shadow='light'>
                        {this.state.questions.map((item, ind) => (
                           <Question
                              key={ind}
                              question={item}
                              totalQuestions={totalQuestions}
                              questionsAnswered={questionsAnswered}
                              fromPui
                              answerMap={answers}
                              onClickCheckboxHandler={
                                 this.onClickCheckboxHandler
                              }
                              onClickRadioHandler={(...pro) =>
                                 this.onClickRadioHandler(ind, ...pro)
                              }
                              onTextChangeHandler={(...pro) =>
                                 this.onTextChangeHandler(ind, ...pro)
                              }
                              onMultipleChoiceChangeHandler={(...pro) =>
                                 this.onMultipleChoiceChangeHandler(ind, ...pro)
                              }
                           />
                        ))}
                     </QuestionCard>
                  </QuestionWrapper>
               </ContentWrapper>
            )}
         </Wrapper>
      );
   }
}

export default PUIForm;
