import { _getSectionList } from 'helpers/helpArticleHelper';
import {
   REQUEST_SECTION_LIST,
   SECTION_LIST_SUCCESS,
   SECTION_LIST_FAILURE,
} from './constants';

/**
 * Invoked before requesting data from sections API
 * @return {Object} Action
 */
function requestSectionList() {
   return {
      type: REQUEST_SECTION_LIST,
   };
}

/**
 * Invoked after successfully retriving data from sections API
 * @param {Array} sections List of sections
 * @return {Object} Action
 */
function getSectionListSuccess(sections) {
   return {
      type: SECTION_LIST_SUCCESS,
      sections,
   };
}

/**
 * Invoked if sections API returns error
 * @param {Object} error
 * @return {Object} Action
 */
function getSectionListFailure(error) {
   return {
      type: SECTION_LIST_FAILURE,
      error,
   };
}

/**
 * Action creator to get section list from API
 * @return {Function}
 */
function getSectionList() {
   return dispatch => {
      dispatch(requestSectionList());
      _getSectionList()
         .then(({ sections }) => {
            dispatch(getSectionListSuccess(sections));
         })
         .catch(({ error }) => {
            dispatch(getSectionListFailure(error));
         });
   };
}

export { getSectionList };
