import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Modal, motion } from '@krishnaxv/react-surface';
import StyledButton from '@datashop/button';
import SendNumberAssessment from './SendNumberAssessment';
import SendBulkAssessment from './SendBulkAssessment';
import SelectProviderDropdown from 'components/Assessments/SelectProviderDropdown';
import { Mixpanel } from 'helpers/mixpanelHelper';

// Should be used to position modal on the surface
const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: '100vh',
   width: '100%',
   display: 'flex',
};

const CardWrapper = styled.div`
   padding: 16px;
   border-radius: 4px;
   background-color: #ffffff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const SelectProviderWrapper = styled.div`
   width: 100%;
`;

const BodyWrapper = styled.div`
   padding: 16px;
`;

const ModalWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const Header = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 16px;
   margin-bottom: 4px;
   margin-top: 8px;
`;

const Button = styled(StyledButton)`
   font-weight: 300;
`;

const ModalContainer = styled.div`
   > div {
      max-width: 400px;
      display: flex;
      left: 0;
      background: none;
      justify-content: center;
      margin: 0 auto;
      left: 0;
      right: 0;
   }
`;

const HeaderContentWrapper = styled.div`
   display: flex;
   background-color: #ffffff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
   padding: 16px;
   border-bottom: 1px solid #ededed;
   align-items: center;
`;

const HeaderWrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const HeaderText = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const StepCounter = styled.p`
   color: #868686;
   line-height: 20px;
`;

const HeaderIcon = styled.i`
   font-size: 40px;
   color: #a6a6a6;
   margin-bottom: 16px;
`;

const FileWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const ContentWrapper = styled.div``;

const BackIcon = styled.i`
   font-size: 24px;
   cursor: pointer;
   margin-right: 8px;
`;

const SelectProviderText = styled.p`
   margin: 32px 0 16px;
   color: #868686;
   line-height: 20px;
`;

class SendAssessment extends Component {
   state = {
      goToSendAssessment: false,
      goToBulkUpload: false,
      assignedTo: undefined,
   };
   providerList = JSON.parse(localStorage.getItem('userList')) || [];

   redirectToSendAssessment = () => {
      this.setState({
         goToSendAssessment: false,
         goToBulkUpload: false,
      });
   };

   goToSendAssessment = () => {
      Mixpanel.track('Send Assessment started', {
         category: 'sendAssessment',
      });
      this.setState({
         goToSendAssessment: true,
         goToBulkUpload: false,
      });
   };

   goToBulkUpload = () => {
      this.setState({
         goToSendAssessment: false,
         goToBulkUpload: true,
      });
   };

   render() {
      const isProvider = localStorage.getItem('access') === 'PROVIDER';
      return (
         <Fragment>
            <ModalContainer>
               <Modal
                  motion={motion.slideInBottom}
                  style={modalStyle}
                  onClose={this.props.close}>
                  {onCloseModal => (
                     <ModalWrapper>
                        <HeaderContentWrapper>
                           <BackIcon
                              onClick={() => {
                                 if (
                                    this.state.goToSendAssessment ||
                                    this.state.goToBulkUpload
                                 ) {
                                    this.setState({
                                       goToSendAssessment: false,
                                       goToBulkUpload: false,
                                    });
                                 } else {
                                    onCloseModal();
                                 }
                              }}
                              className='material-icons'>
                              arrow_back
                           </BackIcon>
                           <HeaderWrapper>
                              <HeaderText>Send Assessment</HeaderText>
                              <StepCounter>COVID-19</StepCounter>
                           </HeaderWrapper>
                        </HeaderContentWrapper>
                        <BodyWrapper>
                           {!this.state.goToSendAssessment &&
                              !this.state.goToBulkUpload && (
                                 <ContentWrapper>
                                    <CardWrapper>
                                       <FileWrapper>
                                          <HeaderIcon className='material-icons'>
                                             message
                                          </HeaderIcon>
                                          <Header
                                             style={{
                                                textAlign: 'center',
                                                width: '296px',
                                             }}>
                                             Patient will recieve a text message
                                             with the assessment link.
                                          </Header>
                                          {!isProvider && (
                                             <Fragment>
                                                <SelectProviderText>
                                                   Please select a provider to
                                                   review the assessment
                                                   response
                                                </SelectProviderText>
                                                <SelectProviderWrapper>
                                                   <SelectProviderDropdown
                                                      owner={
                                                         this.state.assignedTo
                                                      }
                                                      providerList={
                                                         this.providerList
                                                      }
                                                      source='Send Assessment'
                                                      category='sendAssessment'
                                                      onChange={assignedTo => {
                                                         Mixpanel.track(
                                                            'Provider selected for assessment',
                                                            {
                                                               category:
                                                                  'sendAssessment',
                                                               Source:
                                                                  'Assessment',
                                                               'Selected Provider': assignedTo,
                                                            }
                                                         );
                                                         this.setState({
                                                            assignedTo,
                                                         });
                                                      }}
                                                   />
                                                </SelectProviderWrapper>
                                             </Fragment>
                                          )}
                                          <Button
                                             expanded
                                             disabled={
                                                !isProvider &&
                                                !this.state.assignedTo
                                             }
                                             style={{ margin: '24px 0' }}
                                             onClick={this.goToSendAssessment}>
                                             Send to a patient
                                          </Button>
                                       </FileWrapper>
                                       <Button
                                          appearance='primary'
                                          expanded
                                          disabled={
                                             !isProvider &&
                                             !this.state.assignedTo
                                          }
                                          onClick={this.goToBulkUpload}>
                                          Send to multiple patients
                                       </Button>
                                    </CardWrapper>
                                 </ContentWrapper>
                              )}
                           {this.state.goToSendAssessment && (
                              <CardWrapper>
                                 <SendNumberAssessment
                                    assignedTo={this.state.assignedTo}
                                    close={this.props.close}
                                    showSuccessToast={
                                       this.props.showSuccessToast
                                    }
                                 />
                              </CardWrapper>
                           )}
                           {this.state.goToBulkUpload && (
                              <CardWrapper>
                                 <SendBulkAssessment
                                    assignedTo={this.state.assignedTo}
                                    redirectToSendAssessment={
                                       this.redirectToSendAssessment
                                    }
                                    close={this.props.close}
                                    showSuccessToast={
                                       this.props.showSuccessToast
                                    }
                                 />
                              </CardWrapper>
                           )}
                        </BodyWrapper>
                     </ModalWrapper>
                  )}
               </Modal>
            </ModalContainer>
         </Fragment>
      );
   }
}

export default SendAssessment;
