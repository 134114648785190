import React, { useEffect, useState, useRef, Fragment } from 'react';
import styled from 'styled-components';
import StyledCard from '@datashop/card';

import LoadingState from '../TabLoadingState';

import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';

import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import Item from './WaitingItem';

import {
   BADGE_APPEARANCES,
   BADGE_ICONS,
   CALL_STATUS,
   CALL_STATUS_LABELS,
} from '../../../constants';
import { connect } from 'react-redux';

const Wrapper = styled.div`
   padding: 8px 12px;
   height: ${window.innerHeight - 185}px;
   overflow: auto;
`;

const DataWrapper = styled.span``;

const Card = styled(StyledCard)`
   padding: 12px 12px 0;
   background-color: #fff;
   margin-bottom: 8px;
`;

const returnBadgeDetails = isProvider => ({
   READY_FOR_DOCTOR: {
      appearance: isProvider
         ? BADGE_APPEARANCES.success
         : BADGE_APPEARANCES.nimbu,
      icon: isProvider ? BADGE_ICONS.done : BADGE_ICONS.timer,
      text: isProvider
         ? CALL_STATUS_LABELS.readyForProvider
         : CALL_STATUS_LABELS.awaitingProvider,
   },
   ON_CALL: {
      appearance: BADGE_APPEARANCES.warning,
      icon: BADGE_ICONS.timer,
      text: CALL_STATUS_LABELS.waiting,
   },
   IN_PROGRESS: {
      appearance: BADGE_APPEARANCES.primary,
      icon: BADGE_ICONS.timelapse,
      text: CALL_STATUS_LABELS.inProgress,
   },
   WAITING: {
      appearance: BADGE_APPEARANCES.warning,
      icon: BADGE_ICONS.timer,
      text: CALL_STATUS_LABELS.waiting,
   },
   POST_VISIT_QUEUE: {
      appearance: BADGE_APPEARANCES.warning,
      icon: BADGE_ICONS.timer,
      text: CALL_STATUS_LABELS.postVisitQueue,
   },
});

const WaitingRoom = props => {
   const [badgeDetails, setBadgeDetails] = useState({});
   const [loading, toggleLoading] = useState(true);
   const [data, setData] = useState([]);
   const [isLoadingMore, setLoadingMore] = useState(true);
   const [endReached, setEndReached] = useState(false);
   const [offset, setOffset] = useState(0);

   const targetElement = useRef(null);

   const fetchData = (shouldAppend = false) => {
      if (shouldAppend) setLoadingMore(true);
      axios
         .get(
            `/innote-survey/telehealth/video/user/schedule?status=IN_QUEUE&offset=${offset}`
         )
         .then(({ data: { schedule } }) => {
            const list = shouldAppend ? [...data, ...schedule] : schedule;
            setData(list);
            if (schedule.length >= 20) {
               setOffset(offset + 20);
            } else {
               setEndReached(true);
            }
         })
         .catch(() => {
            setEndReached(true);
         })
         .finally(() => {
            toggleLoading(false);
            setLoadingMore(false);
         });
   };

   const callBack = entries => {
      entries.forEach(item => {
         item.isIntersecting && fetchData(true);
      });
   };

   useEffect(() => {
      if (!isLoadingMore && !endReached) {
         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadingMore, endReached]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      Mixpanel.track('Waiting room clicked', {
         category: 'virtualVisit',
      });
      const isProvider = localStorage.getItem('access') === 'PROVIDER';
      setBadgeDetails(returnBadgeDetails(isProvider));
      fetchData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (
         props.socketData === 'PATIENT_WAITING' ||
         props.socketData === 'REFRESH_PATIENT_QUEUE' ||
         props.socketData === 'PROVIDER_ENTERED_EXAM_ROOM'
      )
         fetchData();
   }, [props.socketData]); // eslint-disable-line react-hooks/exhaustive-deps

   const onStatusChange = (index, label) => {
      const callStatus =
         label === CALL_STATUS.readyForProvider
            ? label
            : CALL_STATUS.readyForDoctor;

      setData([
         ...data.slice(0, index),
         {
            ...data[index],
            callStatus,
         },
         ...data.slice(index + 1),
      ]);
   };

   return (
      <Wrapper>
         {loading ? (
            <LoadingState />
         ) : data.length > 0 ? (
            <Fragment>
               <DataWrapper innerRef={targetElement}>
                  {data.map((item, index) => (
                     <Item
                        key={item.scheduleId}
                        userType={props.userType}
                        item={item}
                        joinRoom={props.joinRoom}
                        fetchData={fetchData}
                        badgeDetails={badgeDetails}
                        onStatusChange={label => onStatusChange(index, label)}
                     />
                  ))}
               </DataWrapper>
            </Fragment>
         ) : (
            <Card style={{ paddingBottom: 12 }} shadow='light'>
               No record to show
            </Card>
         )}
         <Fragment>
            {isLoadingMore ? (
               <CardPlaceholder smallList />
            ) : (
               endReached &&
               data.length > 20 && (
                  <Card shadow='light'>No more records to show</Card>
               )
            )}
         </Fragment>
      </Wrapper>
   );
};

const mapStateToProps = ({ frontDoorSocket }) => ({
   userType: frontDoorSocket.userInfo.accessRole,
});

export default connect(mapStateToProps)(WaitingRoom);
