import axios from 'helpers/axios';
import {
   GET_SETTINGS_START,
   GET_SETTINGS_FAILURE,
   GET_SETTINGS_SUCCESS,
   SET_SETTINGS_START,
   SET_SETTINGS_FAILURE,
   SET_SETTINGS_SUCCESS,
   GET_CONFIG_START,
   GET_CONFIG_FAILURE,
   GET_CONFIG_SUCCESS,
   ON_CLOSE,
   ON_ERROR,
   ON_MESSAGE,
   ON_OPEN,
   CHANGE_TAB,
   UNASSIGN_TAB_LOADING,
   GET_INBOX_LIST,
   IS_LOAD_MORE_UNASSIGNED_INBOX,
   MY_TAB_LOADING,
   IS_CHAT_OPEN,
   IS_LOAD_MORE_MY_INBOX,
   SET_SELECTED_CHAT,
   RETRIEVE_INBOX_MESSAGES,
   GET_MY_OFFLINE_MESSAGES,
   SEND_MESSAGE,
   ADD_ATTACHMENT,
   START_ATTACHMENT_UPLOAD,
   ADD_ATTACHMENT_IDENTIFIER,
   ATTACHMENT_UPLOAD_DONE,
   ASSIGN_TO_ME,
   AVAILABLE_FOR_ASSISTANCE_START,
   AVAILABLE_FOR_ASSISTANCE_FAILURE,
   AVAILABLE_FOR_ASSISTANCE_SUCCESS,
   GET_USER_INFO_START,
   GET_USER_INFO_FAILURE,
   GET_USER_INFO_SUCCESS,
   SET_SOCKET,
   ADDED_IN_GROUP,
   MESSAGE_READ,
   INBOX_VIEW_SET_DELETE,
   NEW_MESSAGE_RECEIVED,
   SET_USER_INFO,
   SET_SOUND_CARD,
   CHAT_LOADING,
   GET_INBOX,
   CLEAR_CHAT,
   RESET_MY_TAB,
   CHAT_HEADER_LOADING,
   REMOVE_MESSAGE_OBSERVER,
   RESET_HAS_NEW_MESSAGES,
} from './constants';

export const setSettings = reqBody => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: SET_SETTINGS_START,
            };
         })()
      );
      return axios
         .post('/innote-survey/user/settings', reqBody)
         .then(() => {
            dispatch(
               (() => {
                  return {
                     type: SET_SETTINGS_SUCCESS,
                     data: { ...reqBody },
                  };
               })()
            );
         })
         .catch(error => {
            dispatch(
               (() => {
                  return {
                     type: SET_SETTINGS_FAILURE,
                  };
               })()
            );
         });
   };
};

export const getSettings = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: GET_SETTINGS_START,
            };
         })()
      );
      return axios
         .get('/innote-survey/user/settings')
         .then(({ data }) => {
            dispatch(
               (() => {
                  return {
                     type: GET_SETTINGS_SUCCESS,
                     data,
                  };
               })()
            );
         })
         .catch(error => {
            dispatch(
               (() => {
                  return {
                     type: GET_SETTINGS_FAILURE,
                  };
               })()
            );
         });
   };
};

export const getConfig = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: GET_CONFIG_START,
            };
         })()
      );
      return axios
         .get('/innote-survey/user/configs')
         .then(({ data }) => {
            dispatch(
               (() => {
                  return {
                     type: GET_CONFIG_SUCCESS,
                     data,
                  };
               })()
            );
         })
         .catch(error => {
            dispatch(
               (() => {
                  return {
                     type: GET_CONFIG_FAILURE,
                  };
               })()
            );
         });
   };
};

export const getUserInfo = isSecondCall => {
   return dispatch => {
      dispatch(
         (isSecondCall => {
            return {
               type: GET_USER_INFO_START,
               isSecondCall,
            };
         })(isSecondCall)
      );
      return axios
         .get('/innote-survey/user/info')
         .then(({ data }) => {
            dispatch(
               (data => {
                  return {
                     type: GET_USER_INFO_SUCCESS,
                     data,
                  };
               })(data)
            );
         })
         .catch(() => {
            dispatch(
               (() => {
                  return {
                     type: GET_USER_INFO_FAILURE,
                  };
               })()
            );
         });
   };
};

export const setSoundCard = data => {
   return dispatch => {
      dispatch(
         (data => {
            return {
               type: SET_SOUND_CARD,
               data,
            };
         })(data)
      );
   };
};

export const onOpen = data => {
   return dispatch => {
      dispatch(
         (data => {
            return {
               type: ON_OPEN,
               data,
            };
         })(data)
      );
   };
};

export const removeMSGObserver = data => {
   return dispatch => {
      dispatch(
         (data => {
            return {
               type: REMOVE_MESSAGE_OBSERVER,
               data,
            };
         })(data)
      );
   };
};

export const onClose = data => {
   return dispatch => {
      dispatch(
         (data => {
            return {
               type: ON_CLOSE,
               data,
            };
         })(data)
      );
   };
};

export const onError = data => {
   return dispatch => {
      dispatch(
         (data => {
            return {
               type: ON_ERROR,
               data,
            };
         })(data)
      );
   };
};

export const onMessage = data => {
   const { event_type, ...rest } = data;
   let actionType = '';
   let actionData = '';
   switch (event_type) {
      case 'InboxViewset_retrieve_response': {
         actionType = GET_INBOX;
         actionData = rest;
         break;
      }
      case 'InboxViewset_list_response': {
         setUnassignTabLoading(false);
         setMyTabLoading(false);
         actionType = GET_INBOX_LIST;
         actionData = rest;
         break;
      }
      case 'RetrieveInboxMessages_list_response': {
         actionType = RETRIEVE_INBOX_MESSAGES;
         actionData = rest;
         break;
      }
      case 'GetMyOfflineInbox_list_response': {
         actionType = GET_MY_OFFLINE_MESSAGES;
         actionData = rest;
         break;
      }
      case 'SendMessage_create_response': {
         actionType = SEND_MESSAGE;
         actionData = rest;
         break;
      }
      case 'New_message_received': {
         actionType = NEW_MESSAGE_RECEIVED;
         actionData = rest;
         break;
      }
      case 'AssignChatToMeViewset_create_response': {
         actionType = ASSIGN_TO_ME;
         actionData = rest;
         break;
      }
      case 'Added_in_group': {
         actionType = ADDED_IN_GROUP;
         actionData = rest;
         break;
      }
      case 'MarkMessageAsRead_partial_update_response': {
         // actionType = MARK_MESSAGE_AS_READ_PARTIAL_UPDATE;
         // actionData = rest;
         break;
      }
      case 'Message_read': {
         actionType = MESSAGE_READ;
         actionData = rest;
         break;
      }
      case 'InboxViewset_delete': {
         actionType = INBOX_VIEW_SET_DELETE;
         actionData = rest;
         break;
      }
      case 'Bad_request': {
         break;
      }
      case 'Exception_500': {
         break;
      }
      default: {
         actionType = ON_MESSAGE;
         actionData = '';
         break;
      }
   }
   return dispatch => {
      dispatch(
         (actionData => {
            return {
               type: actionType,
               data: actionData,
               eventType: event_type,
            };
         })(actionData)
      );
   };
};

export const resetHasNewMessages = () => {
   return dispatch => {
      dispatch(
         (() => ({
            type: RESET_HAS_NEW_MESSAGES,
         }))()
      );
   };
};

export const addAttachment = file => {
   return dispatch => {
      dispatch(
         (file => ({
            type: ADD_ATTACHMENT,
            data: {
               message: {
                  type: 'ATTACHMENT',
                  file,
                  attachment: {
                     display_name: file.name,
                     size: file.size,
                  },
                  upload: true,
               },
            },
         }))(file)
      );
   };
};

export const startAttachmentUpload = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: START_ATTACHMENT_UPLOAD,
            };
         })()
      );
   };
};

export const addAttachmentIdentifier = ({ external_id, index }) => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: ADD_ATTACHMENT_IDENTIFIER,
               data: {
                  external_id,
                  index,
               },
            };
         })()
      );
   };
};

export const attachmentUploadDone = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: ATTACHMENT_UPLOAD_DONE,
            };
         })()
      );
   };
};

export const changeTab = id => {
   return dispatch => {
      dispatch(
         (id => {
            return {
               type: CHANGE_TAB,
               id,
            };
         })(id)
      );
   };
};

export const setUserInfo = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: SET_USER_INFO,
            };
         })()
      );
   };
};

export const changeChat = isChatOpen => {
   return dispatch => {
      dispatch(
         (isChatOpen => {
            return {
               type: IS_CHAT_OPEN,
               isChatOpen,
            };
         })(isChatOpen)
      );
   };
};

export const chatLoading = isChatLoading => {
   return dispatch => {
      dispatch(
         (isChatLoading => {
            return {
               type: CHAT_LOADING,
               isChatLoading,
            };
         })(isChatLoading)
      );
   };
};

export const chatHeaderLoading = isChatHeaderLoading => {
   return dispatch => {
      dispatch(
         (isChatHeaderLoading => {
            return {
               type: CHAT_HEADER_LOADING,
               isChatHeaderLoading,
            };
         })(isChatHeaderLoading)
      );
   };
};

export const setAvailableForAssistance = (options, isChange) => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: AVAILABLE_FOR_ASSISTANCE_START,
            };
         })()
      );
      return axios
         .patch('/innote-survey/user/availability', { ...options })
         .then(({ data }) => {
            dispatch(
               ((data, options, isChange) => {
                  return {
                     type: AVAILABLE_FOR_ASSISTANCE_SUCCESS,
                     data,
                     options,
                     isChange,
                  };
               })(data, options, isChange)
            );
         })
         .catch(() => {
            dispatch(
               (() => {
                  return {
                     type: AVAILABLE_FOR_ASSISTANCE_FAILURE,
                  };
               })()
            );
         });
   };
};

export const setUnassignTabLoading = isUnassignedTabLoading => {
   return dispatch => {
      dispatch(
         (isUnassignedTabLoading => {
            return {
               type: UNASSIGN_TAB_LOADING,
               isUnassignedTabLoading,
            };
         })(isUnassignedTabLoading)
      );
   };
};

export const setMyTabLoading = isMyTabLoading => {
   return dispatch => {
      dispatch(
         (isMyTabLoading => {
            return {
               type: MY_TAB_LOADING,
               isMyTabLoading,
            };
         })(isMyTabLoading)
      );
   };
};

export const setLoadMoreUnassignedInbox = isLoadMoreUnassignedInbox => {
   return dispatch => {
      dispatch(
         (isLoadMoreUnassignedInbox => {
            return {
               type: IS_LOAD_MORE_UNASSIGNED_INBOX,
               isLoadMoreUnassignedInbox,
            };
         })(isLoadMoreUnassignedInbox)
      );
   };
};

export const setLoadMoreMyInbox = isLoadMoreMyInbox => {
   return dispatch => {
      dispatch(
         (isLoadMoreMyInbox => {
            return {
               type: IS_LOAD_MORE_MY_INBOX,
               isLoadMoreMyInbox,
            };
         })(isLoadMoreMyInbox)
      );
   };
};

export const setSelectedChat = item => {
   return dispatch => {
      dispatch(
         (item => {
            return {
               type: SET_SELECTED_CHAT,
               item,
            };
         })(item)
      );
   };
};

export const setSocket = socket => {
   return dispatch => {
      dispatch(
         (socket => {
            return {
               type: SET_SOCKET,
               socket,
            };
         })(socket)
      );
   };
};

export const clearChat = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: CLEAR_CHAT,
            };
         })()
      );
   };
};

export const setResetMyTab = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: RESET_MY_TAB,
            };
         })()
      );
   };
};
