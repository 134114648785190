import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';
import StyledInput from '@datashop/input';

import { Loader } from './../../packages/loader/';
import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';

const Form = styled.form`
   display: flex;
   flex: 1;
   flex-direction: column;

   i {
      margin-top: 4px;
   }
`;

const GetStarted = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const Label = styled.p`
   margin: 24px 0 4px;
   color: #2f2f2f;
   font-weight: 600;
   line-height: 16px;
`;

const HelpText = styled.p`
   color: #868686;
   margin-top: 16px;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;

const Button = styled(StyledButton)`
   margin-top: 40px;
   height: 40px;
   font-weight: 300;
`;

const Input = styled(StyledInput)`
   height: 40px;
`;

export default class Login extends Component {
   state = {
      email: '',
      isLoading: false,
      emailError: '',
   };

   componentWillMount() {
      Mixpanel.track('Landing Page Visited', {
         category: 'login',
      });
   }

   onClick = event => {
      const {
         target: { value },
      } = event;
      const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.setState({
         email: value,
         emailError: !regEx.test(value.toLowerCase()),
      });
   };

   submitNpi = event => {
      event.preventDefault();
      this.setState({ isLoading: true });
      axios
         .post('/innote-survey/user/auth ', {
            email: this.state.email,
            loginType: 'email',
         })
         .then(({ data }) => {
            Mixpanel.track('Valid Email Entered', {
               category: 'login',
            });
            this.props.setDetails(data);
         })
         .catch(data => {
            if (data.response) {
               Mixpanel.track('Invalid Email Entered', {
                  category: 'login',
               });
               createToast({ message: data.response.data.error.message });
            } else {
               createToast({ message: 'Network error' });
            }
         })
         .finally(() => {
            this.setState({ isLoading: false });
         });
   };

   render() {
      return (
         <Fragment>
            <Form onSubmit={this.submitNpi}>
               <GetStarted>Get Started</GetStarted>
               <Label>Email</Label>
               <Input
                  icon='email'
                  error={this.state.email.length > 0 && this.state.emailError}
                  value={this.state.email}
                  onChange={this.onClick}
                  placeholder='Enter your email'
               />
               <HelpText>
                  We will send a verification code on your email to verify it’s
                  you.
               </HelpText>
               <Button
                  disabled={
                     !this.state.email ||
                     this.state.isLoading ||
                     this.state.emailError
                  }
                  appearance='primary'>
                  {this.state.isLoading ? <Loader stroke='#fff' /> : 'Next'}
               </Button>
            </Form>
         </Fragment>
      );
   }
}
