import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Heading from '@datashop/heading';
import { get } from 'lodash-es';
import {
   setAvailableForAssistance,
   getUserInfo,
} from 'store/frontDoorSocket/action';
import { Mixpanel } from 'helpers/mixpanelHelper';
import Radio from '@datashop/radio';
import Text from '@datashop/text';
import Button from '@datashop/button';
import Switch from '@datashop/switch';
import { getTime, format } from 'date-fns';
import * as S from './style';

const CardAvailable = ({
   userInfo,
   isProviderAvailableButtonEnable,
   isApiLoading,
   setAvailableForAssistance,
   getUserInfo,
   isOptionOpen,
   isAvailableForAssistanceEnable,
   isStaffAvailableButtonEnable,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const [currentTime, setCurrentTime] = useState(0);
   const [selectedTime, setSelectedTime] = useState(
      userInfo.walkInAvailableTill || 0
   );

   useEffect(() => {
      if (!isApiLoading) {
         getUserInfo(true);
      }
      if (isOptionOpen === false) {
         setIsOpen(false);
      }
   }, [getUserInfo, isApiLoading, isOptionOpen]);

   useEffect(() => {
      if (userInfo.walkInAvailableTill) {
         setSelectedTime(userInfo.walkInAvailableTill);
      }
   }, [userInfo]);

   const onChange = option => {
      setSelectedTime(option.value);
      Mixpanel.track(`Marked available ${option.label}`, {
         category: 'VFD',
      });
      if (!isAvailableForAssistanceEnable) {
         Mixpanel.track(`Marked available`, {
            category: 'VFD',
         });
      }
      setAvailableForAssistance(
         {
            walkInAvailableTill: Math.floor(option.value / 1000),
            isAvailableWalkIn: true,
         },
         true
      );
   };

   const getOptions = () => {
      return [
         {
            label: 'For 30 minutes',
            value: currentTime + 1800000,
            id: 'one',
            text: '',
         },
         {
            label: 'For 1 hour',
            value: currentTime + 3600000,
            id: 'two',
            text: '',
         },
         {
            label: 'For 2 hours',
            value: currentTime + 7200000,
            id: 'three',
            text: '',
         },
         {
            label: 'Until marked unavailable',
            value: currentTime + 31536000000,
            id: 'four',
            text: 'Until you turn it off',
         },
      ].map((option, index) => {
         return (
            <S.RadioWrapper key={index}>
               <Radio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  id={option.id}
                  name='selectTime'
                  checked={option.value === selectedTime}
                  onChange={() => onChange(option)}
                  disabled={isApiLoading}
               />
               <Text style={{ color: '#868686' }} fontSize='small'>
                  {option.text ||
                     `Until ${format(new Date(option.value), 'p')}`}
               </Text>
            </S.RadioWrapper>
         );
      });
   };

   return (
      <>
         {get(userInfo, 'accessRole', '') === 'PROVIDER' &&
            isProviderAvailableButtonEnable && (
               <S.CardWrapper shadow='light'>
                  <S.Column>
                     <S.Row isCenter={false}>
                        <S.IconWrapper name='directions_run' />
                        <S.SpaceBetween>
                           <Heading as='h5'>Accept Walk-ins</Heading>
                           <S.HeaderIcon
                              className='material-icons'
                              color='#2F2F2F'
                              onClick={() => {
                                 if (!isOpen) {
                                    setCurrentTime(getTime(new Date()));
                                 }
                                 setIsOpen(!isOpen);
                              }}>
                              {isOpen
                                 ? 'keyboard_arrow_up'
                                 : 'keyboard_arrow_down'}
                           </S.HeaderIcon>
                        </S.SpaceBetween>
                     </S.Row>
                     <div style={{ marginLeft: '24px' }}>
                        {!isOpen && (
                           <S.TextWrapperStaff>
                              You will see the patients who are active on
                              virtual front door
                           </S.TextWrapperStaff>
                        )}
                        <S.Row
                           isCenter={false}
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '4px',
                              marginBottom: '12px',
                           }}>
                           <S.Status
                              color={
                                 isAvailableForAssistanceEnable
                                    ? '#2EA843'
                                    : '#d5d5d5'
                              }
                           />
                           <Text fontWeight='semiBold'>
                              {isAvailableForAssistanceEnable
                                 ? `Active until ${
                                      getTime(new Date(selectedTime)) -
                                         getTime(new Date()) >
                                      7200000
                                         ? 'you turn it off'
                                         : format(new Date(selectedTime), 'p')
                                   }`
                                 : 'Inactive'}
                           </Text>
                        </S.Row>
                        {isAvailableForAssistanceEnable && (
                           <Button
                              onClick={() => {
                                 setAvailableForAssistance({
                                    isAvailableWalkIn: !isAvailableForAssistanceEnable,
                                 });
                                 Mixpanel.track(`Marked unavailable`, {
                                    category: 'VFD',
                                 });
                              }}>
                              Mark inactive
                           </Button>
                        )}
                     </div>
                     {isOpen && (
                        <S.MarginRadio
                           isAvailableForAssistanceEnable={
                              isAvailableForAssistanceEnable
                           }>
                           {getOptions()}
                        </S.MarginRadio>
                     )}
                  </S.Column>
               </S.CardWrapper>
            )}
         {get(userInfo, 'accessRole', '') === 'STAFF' &&
            isStaffAvailableButtonEnable && (
               <S.CardWrapper shadow='light'>
                  <S.Row isCenter={false}>
                     <S.IconWrapper name='headset_mic' />
                     <S.Column>
                        <Heading as='h5'>Mark available for assistance</Heading>
                        <S.TextWrapperStaff>
                           {isAvailableForAssistanceEnable
                              ? 'You will see the patients who are active on virtual front door'
                              : 'You won’t see the patients who are active on virtual front door'}
                        </S.TextWrapperStaff>
                     </S.Column>
                     <S.SwitchWrapper isBottom={false} isTop={true}>
                        <Switch
                           disabled={isApiLoading}
                           appearance='success'
                           onClick={() => {
                              Mixpanel.track(
                                 `Marked ${
                                    isAvailableForAssistanceEnable
                                       ? 'unavailable'
                                       : 'available'
                                 } chat assistance`,
                                 {
                                    category: 'VFD',
                                 }
                              );
                              setAvailableForAssistance({
                                 isAvailableChat: !isAvailableForAssistanceEnable,
                              });
                           }}
                           checked={isAvailableForAssistanceEnable}
                        />
                     </S.SwitchWrapper>
                  </S.Row>
               </S.CardWrapper>
            )}
      </>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      userInfo,
      isProviderAvailableButtonEnable,
      isApiLoading,
      isOptionOpen,
      isAvailableForAssistanceEnable,
      isStaffAvailableButtonEnable,
   },
}) => ({
   userInfo,
   isProviderAvailableButtonEnable,
   isApiLoading,
   isOptionOpen,
   isAvailableForAssistanceEnable,
   isStaffAvailableButtonEnable,
});

const mapDispatchToProps = {
   setAvailableForAssistance,
   getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardAvailable);
