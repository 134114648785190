import React from 'react';
import styled from 'styled-components';
import { LabelWrapper, Wrapper } from './PoorEmoji';

const SvgWrapper = styled.svg`
   &:hover {
      circle {
         fill: #ffc208;
      }
      ellipse {
         fill: #6b4a06;
      }
   }
`;

const AverageEmoji = ({ selected, showLabel }) => (
   <Wrapper>
      <SvgWrapper width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fill-rule='evenodd'>
            <circle
               fill={selected ? '#ffc208' : '#E5E5E5'}
               fill-rule='nonzero'
               cx='15.998'
               cy='15.998'
               r='15.998'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='10.3'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='21.7'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
            <rect
               fill={selected ? '#6b4a06' : '#595959'}
               x='6.4'
               y='20.8'
               width='19.2'
               height='2'
               rx='1'
            />
         </g>
      </SvgWrapper>
      {showLabel && <LabelWrapper isSelected={selected}>Average</LabelWrapper>}
   </Wrapper>
);

export default AverageEmoji;
