import { chain } from 'lodash';

/**
 * Convert object to query string
 * @param  {Object} queryObject Query object
 * @returns {string} Query string
 */
export const objectToQueryString = queryObject =>
   chain(queryObject)
      .reduce(
         (queryStringList, value, key) => [
            ...queryStringList,
            `${key}=${value}`,
         ],
         []
      )
      .join('&')
      .value();

export const asyncDelay = ms => new Promise(resolve => setTimeout(resolve, ms));
