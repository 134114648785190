import React, { useState } from 'react';
import { Modal } from '@krishnaxv/react-surface';
import {
   ModalContainer,
   WrapperParent,
   StyledHeading,
} from 'components/Chat/style';
import * as S from 'components/Messages/style';
import Icon from '@datashop/icon';
import Tab from 'components/Messages/Tab';
import RecentVisits from 'components/Messages/RecentVisits';
import MyPatients from 'components/Messages/MyPatients';
import InviteModal from 'components/Messages/InviteModal';
import { Mixpanel } from 'helpers/mixpanelHelper';

const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
};

const NewChatSelect = ({ advanceToggle, openChat, attributionEnabled }) => {
   const [isInvite, setIsInvite] = useState(false);
   const [patientInfo, setPatientInfo] = useState({});
   const [activeTabId, setActiveTabId] = useState(1);
   const tabList = [
      {
         name: 'Recent visits',
         id: 1,
      },
      {
         name: 'My patients',
         id: 2,
         disable: !attributionEnabled,
      },
   ];

   const inviteToggle = () => {
      setIsInvite(!isInvite);
   };

   const sendInvite = item => {
      setPatientInfo(item);
      inviteToggle();
   };

   return (
      <>
         <ModalContainer>
            <Modal style={modalStyle}>
               {onModalClose => (
                  <WrapperParent>
                     <S.HeadingWrapper>
                        <S.Row
                           isCenter={true}
                           style={{
                              justifyContent: 'space-between',
                              paddingBottom: '12px',
                           }}>
                           <S.Column>
                              <StyledHeading style={{ width: '250px' }}>
                                 Start new conversation
                              </StyledHeading>
                           </S.Column>
                           <Icon
                              name='close'
                              style={{ fontSize: 18, cursor: 'pointer' }}
                              onClick={() => {
                                 advanceToggle();
                                 onModalClose();
                              }}
                           />
                        </S.Row>
                        <Tab
                           activeTabId={activeTabId}
                           setActiveTab={id => {
                              setActiveTabId(id);
                              Mixpanel.track(
                                 `Chat with ${
                                    id === 1 ? 'visited' : 'my'
                                 } patients`,
                                 { category: 'chat' }
                              );
                           }}
                           list={tabList}
                        />
                     </S.HeadingWrapper>
                     <S.TabWrapper>
                        {activeTabId === 1 && (
                           <RecentVisits
                              openChat={openChat}
                              sendInvite={sendInvite}
                           />
                        )}
                        {activeTabId === 2 && (
                           <MyPatients
                              openChat={openChat}
                              sendInvite={sendInvite}
                           />
                        )}
                     </S.TabWrapper>
                  </WrapperParent>
               )}
            </Modal>
         </ModalContainer>
         {isInvite && (
            <InviteModal
               patientInfo={patientInfo}
               inviteToggle={inviteToggle}
               isInvite={isInvite}
            />
         )}
      </>
   );
};

export default NewChatSelect;
