import React, { Fragment } from 'react';
import { Loader } from '../../packages/loader';
import SearchResult from './SearchResult';
import * as S from './style';

/**
 * Help sections component
 * @param {Function} close To handle modal close
 * @param {Array} sectionsList List of sections
 * @param {boolean} isLoading Is loading
 * @param {Object} search Search result object
 * @param {Function} renderSearch Renders search input box component
 * @param {Function} showArticles To show list of articles in a section
 * @param {boolean} showSearchResult Show search result
 * @param {Function} openArticle To open a particular article
 */
const HelpSection = ({
   close,
   sections: { sectionsList: list, isLoading },
   search,
   renderSearch,
   showArticles,
   showSearchResult,
   openArticle,
}) => (
   <Fragment>
      {isLoading ? (
         <S.LoaderWrapper>
            <Loader />
         </S.LoaderWrapper>
      ) : (
         <S.Wrapper color={list.length > 0 ? '#f8f8f8' : '#fff'}>
            <S.SectionWrapper>
               <S.HeaderWrapper>
                  <S.HeaderContainer>
                     <S.Header>Look for articles</S.Header>
                     <S.Icon
                        name='close'
                        className='cursor-pointer'
                        size={24}
                        onClick={close}
                     />
                  </S.HeaderContainer>
               </S.HeaderWrapper>
               <S.ContentWrapper
                  style={{
                     maxHeight: `calc(100% - 60px)`,
                     height: `calc(100vh - 60px)`,
                  }}>
                  {renderSearch()}
                  {showSearchResult ? (
                     <SearchResult search={search} openArticle={openArticle} />
                  ) : (
                     <S.ListWrapper>
                        <S.ListHeaderWrapper>Sections</S.ListHeaderWrapper>
                        {list.map(item => (
                           <S.ListItem
                              key={item.id}
                              onClick={() => showArticles(item.id, item.name)}>
                              <S.Description>{item.name}</S.Description>
                              <S.Icon
                                 name='keyboard_arrow_right'
                                 className='icon-hover ml-4'
                                 size={16}
                                 appearance='subtle'
                              />
                           </S.ListItem>
                        ))}
                     </S.ListWrapper>
                  )}
               </S.ContentWrapper>
            </S.SectionWrapper>
         </S.Wrapper>
      )}
   </Fragment>
);

export default HelpSection;
