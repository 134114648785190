import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'whatwg-fetch';

import SentryService from 'services/sentry';

import configureStore from './store/configureStore';

import Routes from './routes';
// import registerServiceWorker from "./registerServiceWorker";

import './App.css';

// Define store
export const store = configureStore();

// For WalkMe
window.skeletonPwa = {
   container: {
      auth: {
         userData: {
            email: 'test@innovaccer.com',
         },
      },
   },
};

SentryService();

ReactDOM.render(
   <Provider store={store}>
      <Routes />
   </Provider>,
   document.getElementById('root')
);
// registerServiceWorker();
