import React from 'react';
import styled from 'styled-components';
import StyledCard from '@datashop/card';
import StyledInput from '@datashop/input';

const Card = styled(StyledCard)`
   padding: 16px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const CardHeader = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
   margin-bottom: 4px;
`;

const ParticipantNo = styled.p``;

const CrossIcon = styled.i`
   color: #151414;
   font-size: 16px;
   cursor: pointer;
`;

const CardSubText = styled.p`
   color: #868686;
   font-size: 14px;
   letter-spacing: 0;
   line-height: 20px;
   margin-bottom: 16px;
`;

const InviteModalParticipantCard = ({
   item: { name, phoneNo, email, id },
   index,
   onChangeHandler,
   validateEmail,
   deleteParticipant,
   showCrossIcon,
}) => (
   <Card>
      <CardHeader>
         <ParticipantNo>{`Participant ${index + 1}`}</ParticipantNo>
         {showCrossIcon && (
            <CrossIcon
               className='material-icons'
               onClick={() => deleteParticipant(id)}>
               close
            </CrossIcon>
         )}
      </CardHeader>
      <CardSubText>
         Please provide the name and phone number of the participant you want to
         invite for this appointment
      </CardSubText>
      <StyledInput
         style={{ marginBottom: '16px' }}
         icon='person'
         type='text'
         placeholder='Enter name'
         value={name}
         name='name'
         onChange={event => {
            onChangeHandler(event, id);
         }}
      />
      <StyledInput
         style={{ marginBottom: '16px' }}
         icon='phone'
         error={phoneNo && phoneNo.length !== 10}
         placeholder='Enter phone number'
         value={phoneNo}
         name='phoneNo'
         onChange={event => {
            typeof parseInt(event.target.value.slice(-1)) === 'number' &&
               event.target.value.slice(-1) !== ' ' &&
               !isNaN(event.target.value.slice(-1)) &&
               onChangeHandler(event, id);
         }}
      />
      <StyledInput
         required
         icon='email'
         type='email'
         placeholder='Enter email'
         value={email}
         name='email'
         onChange={event => {
            onChangeHandler(event, id);
         }}
         error={email && !validateEmail(email)}
      />
   </Card>
);

export default InviteModalParticipantCard;
