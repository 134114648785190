import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Modal from '@datashop/modal';
import returnCamelCasedName from 'helpers/nameTransformer';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createNotification from 'services/notificationService';
import { connect } from 'react-redux';
import createToast from 'helpers/toastHelper';
import JoinCallModal from 'components/VirtualVisit/JoinCallModal';
import { eventReceived } from 'store/socket/action';
import { changeTab } from 'store/virtualVisit/action';
import userTracker from './userTracker';
import { get } from 'lodash-es';

const messageList = {
   PATIENT_WAITING: {
      msg: 'is waiting for you to join the call.',
      native: 'is waiting for you to join.',
   },
   READY_FOR_PROVIDER: {
      msg: 'is ready for their consultation with you.',
      native: 'is ready for their consultation with you.',
   },
   POST_VISIT_QUEUE: {
      msg: 'is waiting for their post-visit consultation.',
      native: 'is waiting for their post-visit consultation.',
   },
   VIRTUAL_VISIT_REQUESTED: {
      msg: 'has requested for a virtual visit.',
      native: 'has requested for a virtual visit.',
   },
};

class Socket extends Component {
   _isMounted = true;
   state = {
      showJoinCallModal: false,
      eventDetails: {},
      sendToVideoVisitRoute: false,
   };

   sendToVideoVisit = () => {
      Mixpanel.track('Join Room Clicked', {
         category: 'notification',
      });
      this.props.changeTab(1);

      this.setState(
         {
            sendToVideoVisitRoute: true,
         },
         () => this.setState({ sendToVideoVisitRoute: false })
      );
   };

   sendToVirtualVisitRequest = () => {
      this.setState(
         {
            sendToVideoVisitRoute: true,
         },
         () => {
            this.setState({ sendToVideoVisitRoute: false });
            this.props.changeTab(2);
         }
      );
   };

   showJoinCallModal = eventDetails => {
      this.setState({ showJoinCallModal: true, eventDetails });
   };

   notifyUser = (firstName, lastName, messageItem, isSilent) => {
      createNotification({
         firstName,
         lastName,
         isSilent,
         msg: messageItem.native,
         openVideoVisit: this.sendToVideoVisit,
      });
      createToast({
         title: 'Virtual Visit',
         appearance: 'information',
         message: `${returnCamelCasedName(firstName, lastName)} ${
            messageItem.msg
         }`,
         time: 30,
         button: { text: 'View', function: this.sendToVideoVisit },
      });
   };

   notifyVirtualVisitRequest = (firstName, lastName, messageItem, isSilent) => {
      createNotification({
         firstName,
         lastName,
         isSilent,
         msg: messageItem.native,
         openVideoVisit: this.sendToVirtualVisitRequest,
      });
      createToast({
         title: 'Virtual Visit',
         appearance: 'information',
         message: `${returnCamelCasedName(firstName, lastName)} ${
            messageItem.msg
         }`,
         time: 30,
         button: { text: 'View', function: this.sendToVirtualVisitRequest },
      });
   };

   createSocketConnection = () => {
      const URL =
         process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_SOCKET_URL`];
      this.socket = new WebSocket(
         `${URL}?app=INNOTE_ASSISTANT&token=${
            localStorage.getItem('token') &&
            localStorage.getItem('token').split(' ')[1]
         }`
      );

      userTracker.socket = this.socket;

      this.socket.onopen = () => {
         const assessmentSocket = { ...this.socket };
         this.setState({ socket: assessmentSocket });
      };

      this.socket.onmessage = ({ data }) => {
         const details = JSON.parse(data);
         const {
            message: { event, eventDetails },
         } = details;

         this.props.eventReceived({ message: event, data: eventDetails });
         this.props.sendEvent(event);

         switch (event) {
            case 'PATIENT_JOINED_ROOM':
               const { visitId } = eventDetails;
               if (this.props.isLoading) this.props.fetchRoomInfo(visitId);
               break;
            case 'PATIENT_LEFT':
               const { message } = eventDetails;
               this.props.handlePatientError(message);
               break;
            case 'PATIENT_WAITING': {
               const {
                  patientDetails: { firstName, lastName },
               } = eventDetails;
               const isSilent = get(
                  this,
                  'props.userSettings.waitingQueue.enabled',
                  true
               );
               this.notifyUser(
                  firstName,
                  lastName,
                  messageList[event],
                  isSilent
               );
               break;
            }
            case 'READY_FOR_PROVIDER': {
               const {
                  patientDetails: { firstName, lastName },
               } = eventDetails;
               const isSilent = get(
                  this,
                  'props.userSettings.readyForProvider.enabled',
                  true
               );
               this.notifyUser(
                  firstName,
                  lastName,
                  messageList[event],
                  isSilent
               );
               break;
            }
            case 'POST_VISIT_QUEUE': {
               const {
                  patientDetails: { firstName, lastName },
               } = eventDetails;
               const isSilent = get(
                  this,
                  'props.userSettings.postVisitQueue.enabled',
                  true
               );
               this.notifyUser(
                  firstName,
                  lastName,
                  messageList[event],
                  isSilent
               );
               break;
            }
            case 'PROVIDER_ENTERED_EXAM_ROOM':
               this.showJoinCallModal(eventDetails);
               break;
            case 'UNMUTE_REQUEST':
               if (
                  navigator.userAgent.toLowerCase().indexOf('electron/') === -1
               ) {
                  createToast({
                     title: 'Request to unmute',
                     appearance: 'default',
                     message: `${returnCamelCasedName(
                        get(eventDetails, 'requester.firstName', ''),
                        get(eventDetails, 'requester.lastName', '')
                     )} has requested you to unmute your mic.`,
                     time: 30,
                     button: {
                        text: 'Unmute',
                        function: this.props.toggleAudio,
                     },
                  });
               }
               break;
            case 'MUTE':
               if (
                  navigator.userAgent.toLowerCase().indexOf('electron/') === -1
               ) {
                  this.props.toggleAudio();
                  createToast({
                     message: `${returnCamelCasedName(
                        get(eventDetails, 'requester.firstName', ''),
                        get(eventDetails, 'requester.lastName', '')
                     )} has muted your audio.`,
                     time: 5,
                     appearance: 'default',
                  });
               }
               break;
            case 'ASK_USER_FEEDBACK':
               this.props.askUserFeedBack();
               break;
            case 'VIRTUAL_VISIT_REQUESTED': {
               const {
                  patientDetails: { firstName, lastName },
               } = eventDetails;
               const isSilent = get(
                  this,
                  'props.userSettings.isSoundEnable',
                  true
               );
               this.notifyVirtualVisitRequest(
                  firstName,
                  lastName,
                  messageList[event],
                  isSilent
               );
               break;
            }
            default:
               break;
         }
      };

      this.socket.onclose = () => {
         if (this._isMounted && localStorage.getItem('token'))
            setTimeout(() => {
               this.createSocketConnection();
            }, 1000);
      };
   };

   componentDidMount() {
      this.props.onRef(this);
      this.createSocketConnection();
   }

   componentWillUnmount() {
      this.props.onRef(undefined);
      this._isMounted = false;
      this.socket.close();
   }

   doMute = ({ scheduleId, visitId, participantId, participantType }) => {
      this.socket.send(
         JSON.stringify({
            messageType: 'DIRECTIVE',
            message: {
               directive: 'PARTICIPANT_CONTROL',
               directiveDetails: {
                  scheduleId,
                  visitInstanceId: '',
                  visitId,
                  controlEvent: 'MUTE',
                  participantId,
                  participantType,
               },
            },
         })
      );
   };

   doUnMute = ({ scheduleId, visitId, participantId, participantType }) => {
      this.socket.send(
         JSON.stringify({
            messageType: 'DIRECTIVE',
            message: {
               directive: 'PARTICIPANT_CONTROL',
               directiveDetails: {
                  scheduleId,
                  visitInstanceId: '',
                  visitId,
                  controlEvent: 'UNMUTE_REQUEST',
                  participantId,
                  participantType,
               },
            },
         })
      );
   };

   render() {
      return this.props.isSkip ? null : (
         <Fragment>
            {this.state.sendToVideoVisitRoute && <Redirect to='/' />}
            {this.state.showJoinCallModal && (
               <Modal
                  hideScroll
                  backdrop
                  closeOnEscape
                  style={{ width: 320 }}
                  onClose={() =>
                     this.setState({
                        showJoinCallModal: false,
                        eventDetails: {},
                     })
                  }
                  showCloseButton>
                  <JoinCallModal
                     data={this.state.eventDetails}
                     joinCall={this.props.joinRoom}
                     close={() =>
                        this.setState({
                           showJoinCallModal: false,
                           eventDetails: {},
                        })
                     }
                  />
               </Modal>
            )}
         </Fragment>
      );
   }
}
const mapStateToProps = ({ frontDoorSocket: { userSettings } }) => ({
   userSettings,
});

const mapDispatchToProps = {
   eventReceived,
   changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
