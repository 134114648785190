import { checkMobile } from 'helpers/checkMobile';

let flag = false;
let isElectron = false;

if (navigator.userAgent.toLowerCase().indexOf(' electron/') > -1) {
   isElectron = true;
}

const createNotification = data => {
   if (!checkMobile()) {
      if (!('Notification' in window)) {
         alert('This browser does not support desktop notification');
      } else if (Notification.permission === 'granted') {
         flag = true;
      } else if (Notification.permission !== 'denied') {
         Notification.requestPermission(permission => {
            if (!('permission' in Notification)) {
               Notification.permission = permission;
            }
            if (permission === 'granted') {
               flag = true;
            }
         });
      }

      if (window.require === 'function') {
         flag = true;
      }
      if (flag) {
         notification(data);
      }
   }
};

const notification = ({
   firstName,
   lastName,
   msg,
   openVideoVisit,
   isSilent,
   isMsg = false,
   showAlways = false,
}) => {
   if (isElectron) {
      const remote = window.require('electron').remote;
      const mainWindow = remote.getCurrentWindow();
      if (showAlways || !mainWindow.isVisible()) {
         new Notification('InNote', {
            icon: `${process.env.PUBLIC_URL}/img/InNote.png`,
            body: isMsg ? msg : `${firstName} ${lastName} ${msg}`,
            silent: !isSilent,
         }).onclick = () => {
            openVideoVisit();
            const { ipcRenderer } = window.require('electron');
            ipcRenderer.send('bringToFocus');
         };
      }
   } else {
      new Notification('InNote', {
         icon: `${process.env.PUBLIC_URL}/img/InNote.png`,
         body: isMsg ? msg : `${firstName} ${lastName} ${msg}`,
         silent: !isSilent,
      }).onclick = () => {
         openVideoVisit();
      };
   }
   flag = false;
};

export default createNotification;
