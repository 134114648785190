import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash-es';
import Switch from '@datashop/switch';
import Heading from '@datashop/heading';
import { getSettings, setSettings } from 'store/frontDoorSocket/action';
import Text from '@datashop/text';
import * as S from './style';
import { Mixpanel } from 'helpers/mixpanelHelper';

const CardSoundNotification = ({
   userInfo,
   settingsLoading,
   userSettings,
   getSettings,
   setSettings,
}) => {
   const [isSettingsCalled, setIsSettingsCalled] = useState(false);

   useEffect(() => {
      if (isEmpty(userSettings) && !isSettingsCalled) {
         setIsSettingsCalled(true);
         getSettings();
      }
   }, [userSettings, getSettings, isSettingsCalled]);

   const handlerSoundClick = () => {
      const reqBody = {};
      if (userSettings.isSoundEnable) {
         reqBody['waitingQueue'] = { enabled: false };
         if (get(userInfo, 'accessRole', '') === 'PROVIDER') {
            reqBody['readyForProvider'] = { enabled: false };
         }
         if (get(userInfo, 'accessRole', '') === 'STAFF') {
            reqBody['postVisitQueue'] = { enabled: false };
         }
      } else {
         reqBody['waitingQueue'] = { enabled: true };
         if (get(userInfo, 'accessRole', '') === 'PROVIDER') {
            reqBody['readyForProvider'] = { enabled: true };
         }
         if (get(userInfo, 'accessRole', '') === 'STAFF') {
            reqBody['postVisitQueue'] = { enabled: true };
         }
      }
      Mixpanel.track(
         `Audio notification ${
            userSettings.isSoundEnable ? 'disabled' : 'enabled'
         }`,
         {
            category: 'audioNotification',
         }
      );
      setSettings(reqBody);
   };

   return (
      (get(userInfo, 'accessRole', '') === 'STAFF' ||
         get(userInfo, 'accessRole', '') === 'PROVIDER') && (
         <S.CardWrapper shadow='light' style={{ marginBottom: 24 }}>
            <S.Column>
               <S.Row isCenter={true}>
                  <S.IconWrapper name='notifications_active' />
                  <S.SpaceBetween>
                     <Heading as='h5'>Sound Notifications</Heading>
                     <Switch
                        disabled={settingsLoading}
                        appearance='success'
                        onClick={handlerSoundClick}
                        checked={userSettings.isSoundEnable}
                     />
                  </S.SpaceBetween>
               </S.Row>
               {userSettings.isSoundEnable && (
                  <>
                     <S.Row
                        isCenter={false}
                        style={{
                           marginTop: '24px',
                           paddingBottom: '12px',
                           borderBottom: '1px solid #E5E5E5',
                        }}>
                        <S.Column>
                           <Text fontWeight='bold'>Waiting queue</Text>
                           <S.TextWrapperStaff>
                              You will get a sound notification when a patient
                              joins the “Waiting queue”
                           </S.TextWrapperStaff>
                        </S.Column>
                        <S.SwitchWrapper isBottom={false} isTop={true}>
                           <Switch
                              disabled={settingsLoading}
                              appearance='success'
                              onClick={() => {
                                 setSettings({
                                    waitingQueue: {
                                       enabled: !(
                                          userSettings.waitingQueue &&
                                          userSettings.waitingQueue.enabled
                                       ),
                                    },
                                 });
                                 Mixpanel.track(
                                    `Audio notification Waiting Room ${
                                       !(
                                          userSettings.waitingQueue &&
                                          userSettings.waitingQueue.enabled
                                       )
                                          ? 'enabled'
                                          : 'disabled'
                                    }`,
                                    {
                                       category: 'audioNotification',
                                    }
                                 );
                              }}
                              checked={
                                 userSettings.waitingQueue &&
                                 userSettings.waitingQueue.enabled
                              }
                           />
                        </S.SwitchWrapper>
                     </S.Row>
                     {get(userInfo, 'accessRole', '') === 'STAFF' && (
                        <S.Row isCenter={false} style={{ marginTop: '12px' }}>
                           <S.Column>
                              <Text fontWeight='bold'>Post-visit queue</Text>
                              <S.TextWrapperStaff>
                                 You will get a sound notification when a
                                 patient joins the “Post-visit queue”
                              </S.TextWrapperStaff>
                           </S.Column>
                           <S.SwitchWrapper isBottom={false} isTop={true}>
                              <Switch
                                 disabled={settingsLoading}
                                 appearance='success'
                                 onClick={() => {
                                    setSettings({
                                       postVisitQueue: {
                                          enabled: !(
                                             userSettings.postVisitQueue &&
                                             userSettings.postVisitQueue.enabled
                                          ),
                                       },
                                    });
                                    Mixpanel.track(
                                       `Audio notification Post visit ${
                                          !(
                                             userSettings.postVisitQueue &&
                                             userSettings.postVisitQueue.enabled
                                          )
                                             ? 'enabled'
                                             : 'disabled'
                                       }`,
                                       {
                                          category: 'audioNotification',
                                       }
                                    );
                                 }}
                                 checked={
                                    userSettings.postVisitQueue &&
                                    userSettings.postVisitQueue.enabled
                                 }
                              />
                           </S.SwitchWrapper>
                        </S.Row>
                     )}
                     {get(userInfo, 'accessRole', '') === 'PROVIDER' && (
                        <S.Row isCenter={false} style={{ marginTop: '12px' }}>
                           <S.Column>
                              <Text fontWeight='bold'>Ready for provider</Text>
                              <S.TextWrapperStaff>
                                 You will get a sound notification when a
                                 patient joins the “Ready for provider”
                              </S.TextWrapperStaff>
                           </S.Column>
                           <S.SwitchWrapper isBottom={false} isTop={true}>
                              <Switch
                                 disabled={settingsLoading}
                                 appearance='success'
                                 onClick={() => {
                                    setSettings({
                                       readyForProvider: {
                                          enabled: !(
                                             userSettings.readyForProvider &&
                                             userSettings.readyForProvider
                                                .enabled
                                          ),
                                       },
                                    });
                                    Mixpanel.track(
                                       `Audio notification Ready for Provider ${
                                          !(
                                             userSettings.readyForProvider &&
                                             userSettings.readyForProvider
                                                .enabled
                                          )
                                             ? 'enabled'
                                             : 'disabled'
                                       }`,
                                       {
                                          category: 'audioNotification',
                                       }
                                    );
                                 }}
                                 checked={
                                    userSettings.readyForProvider &&
                                    userSettings.readyForProvider.enabled
                                 }
                              />
                           </S.SwitchWrapper>
                        </S.Row>
                     )}
                  </>
               )}
            </S.Column>
         </S.CardWrapper>
      )
   );
};

const mapStateToProps = ({
   frontDoorSocket: { userInfo, settingsLoading, userSettings },
}) => ({
   userInfo,
   settingsLoading,
   userSettings,
});

const mapDispatchToProps = { getSettings, setSettings };

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CardSoundNotification);
