import React from 'react';
import SuccessCard from 'components/EvaluatePatient/SuccessCard';
import styled from 'styled-components';

const Wrapper = styled.div`
   padding: 16px;
`;

const Results = ({ status, responseId, close, assessmentId }) => {
   return (
      <Wrapper>
         <SuccessCard
            assessmentId={assessmentId}
            close={close}
            responseId={responseId}
            state={status}
         />
      </Wrapper>
   );
};

export default Results;
