import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
   padding: 32px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
`;

const Icon = styled.i`
   font-size: 60px;
   color: #a6a6a6;
`;

const NewAssessment = styled.p`
   margin-top: 20px;
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const AssessmentSubtext = styled.p`
   margin-top: 4px;
   color: #868686;
   text-align: center;
   line-height: 20px;
`;

const EmptyList = ({ startAssessment, sendAssessment, searching }) => {
   return (
      <Wrapper>
         <Icon className='material-icons'>
            {searching ? 'search' : 'assignment_ind'}
         </Icon>
         <NewAssessment>
            {searching ? 'No results were found' : 'Take a new assessment'}
         </NewAssessment>
         <AssessmentSubtext>
            {searching
               ? 'We tried but could not find any results matching your search'
               : 'Assessments help you determine the medical condition and general health of patients'}
         </AssessmentSubtext>
      </Wrapper>
   );
};

export default EmptyList;
