import React from 'react';
import { camelCase } from 'lodash-es';

import TextMessage from './TextMessage';
import AttachmentMessage from './AttachmentMessage';

const CardMessage = ({
   index,
   messageDetails,
   isStatus,
   sendAttachment,
   onAttachmentUploadStart,
   addAttachmentIdentifier,
   onAttachmentUploadDone,
}) => {
   const { message } = messageDetails;

   if (camelCase(message.type) === 'attachment') {
      return (
         <AttachmentMessage
            index={index}
            messageDetails={messageDetails}
            onSuccess={sendAttachment}
            isStatus={isStatus}
            onAttachmentUploadDone={onAttachmentUploadDone}
            addAttachmentIdentifier={addAttachmentIdentifier}
            onAttachmentUploadStart={onAttachmentUploadStart}
         />
      );
   }

   return <TextMessage messageDetails={messageDetails} isStatus={isStatus} />;
};

export default CardMessage;
