import React, { Component } from 'react';
import styled from 'styled-components';
import Checkbox from '@datashop/checkbox';

const SurveyCheckbox = styled.div`
   margin-top: 4px;
   label {
      display: inline;
      font-weight: normal;
      font-size: 16px;
   }

   padding: 0 !important;

   & > div > div {
      margin-left: 8px;
      font-size: 16px;
   }
   & > div > label {
      ${({ providerFlow }) =>
         providerFlow
            ? 'color: #2F2F2F;font-size: 12px;font-weight: 600;line-height: 16px;'
            : 'line-height: 20px;font-weight: 600;font-size: 12px;'};
   }

   & > div {
      padding: ${({ providerFlow }) => (providerFlow ? '8px 0' : '12px 0')};
      border-bottom: ${({ providerFlow }) =>
         !providerFlow && '1px solid rgb(232, 228, 228)'};
   }

   & > div:last-child {
      border-bottom: none;
   }
`;

class MultiSelectQuestion extends Component {
   state = {
      selectedAnswers: this.props.answerMap
         ? this.props.answerMap[this.props.question.id] || []
         : [],
      disabled: false,
   };

   componentDidMount() {
      if (this.state.selectedAnswers.length > 0)
         this.props.question.answer.choiceList.forEach(item => {
            if (
               item.id === this.state.selectedAnswers[0] &&
               item.tag === 'NONE-OF-THE-ABOVE'
            )
               this.setState({ disabled: true });
         });
   }

   changeSelectedAnswers = (id, tag = '') => {
      let newSelectedAnswers = this.state.selectedAnswers.slice();
      const { selectedAnswers } = this.state;
      // De selecting Login
      if (selectedAnswers.includes(id)) {
         const index = selectedAnswers.indexOf(id);
         newSelectedAnswers.splice(index, 1);
         if (tag === 'NONE-OF-THE-ABOVE') {
            this.setState({ disabled: false });
         }
      } else {
         // Selecting logic
         if (tag === 'NONE-OF-THE-ABOVE') {
            newSelectedAnswers = [id];
            this.setState({ disabled: true });
         } else newSelectedAnswers.push(id);
      }
      this.props[
         this.props.onChange ? 'onChange' : 'onMultipleChoiceChangeHandler'
      ](this.props.question.id, newSelectedAnswers);
      this.setState({ selectedAnswers: newSelectedAnswers });
   };

   componentDidUpdate(prevProps, prevState) {
      if (this.props.question.id !== prevProps.question.id) {
         if (this.props.answerMap[this.props.question.id]) {
            const selectedAnswers = this.props.question.answer.choiceList.filter(
               item =>
                  this.props.answerMap[this.props.question.id].some(
                     id => item.id === id
                  )
            );
            this.setState({
               selectedAnswers: this.props.answerMap[this.props.question.id],
               disabled: selectedAnswers.some(
                  item => item.tag === 'NONE-OF-THE-ABOVE'
               ),
            });
         } else {
            this.setState({ disabled: false });
         }
      }
   }

   render() {
      const {
         question: {
            answer: { choiceList },
         },
         providerFlow,
      } = this.props;

      const answers = choiceList;
      return (
         <SurveyCheckbox providerFlow={providerFlow}>
            {Object.keys(answers).map(each => (
               <Checkbox
                  key={answers[each].id}
                  providerFlow={providerFlow}
                  name={answers[each].id}
                  checked={
                     this.state.selectedAnswers.indexOf(answers[each].id) > -1
                  }
                  label={answers[each].value}
                  onChange={() =>
                     this.changeSelectedAnswers(
                        answers[each].id,
                        answers[each].tag
                     )
                  }
                  id={answers[each].id}
                  disabled={
                     answers[each].tag !== 'NONE-OF-THE-ABOVE' &&
                     this.state.disabled
                  }
               />
            ))}
         </SurveyCheckbox>
      );
   }
}

export default MultiSelectQuestion;
