import React, { Component } from 'react';
import styled from 'styled-components';
import matchSorter from 'match-sorter';
import StyledCard from '@datashop/card';
import Button from '@datashop/button';
import { Modal, motion } from '@krishnaxv/react-surface';
import axios from 'helpers/axios';
import Toast from '@datashop/toast';

import UuidGenerator from 'utils/uuidGenerator';
import { Loader } from 'packages/loader';
import InviteModalParticipantCard from './InviteModalParticipantCard';
import InviteModalMemberList from './InviteModalMemberList';

const ModalWrapper = styled.div`
   > div {
      margin: 0 auto;
      right: 0;
   }
`;

const modalStyle = {
   backgroundColor: '#fff',
   height: '100%',
   width: '100%',
   display: 'flex',
};

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
   background-color: #f7f5f5;
   justify-content: space-between;
`;

const OverflowWrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const HeaderWrapper = styled.div`
   padding: 12px 16px;
   display: flex;
   flex-direction: column;
   background-color: #ffffff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const HeaderNameWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const HeaderText = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const ContentWrapper = styled.div`
   padding: 12px;
   overflow: auto;
   max-height: ${window.innerHeight - 130}px;
`;

const LoaderWrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   justify-content: center;
   align-items: center;
   padding: 32px;
`;

const CloseModalIcon = styled.i`
   font-size: 24px;
   color: #2f2f2f;
   cursor: pointer;
`;

const Card = styled(StyledCard)`
   padding: 16px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const CardSubText = styled.p`
   color: #868686;
   font-size: 14px;
   letter-spacing: 0;
   line-height: 20px;
   margin-bottom: 16px;
`;

const Form = styled.form`
   flex-grow: 1;
   background-color: white;
   border: 1px solid #d2d2d2;
   border-radius: 4px;
   margin-bottom: 16px;
`;

const TextFieldWrapper = styled.div`
   color: #868686;
   display: flex;
   padding: 8px;
   font-size: 16px;
   line-height: 20px;
   align-items: center;
`;

const TextField = styled.input`
   border: 0;
   outline: none;
   font-size: 16px;
   width: 100%;
`;

const SearchIcon = styled.i`
   font-size: 20px;
   margin-right: 8px;
`;

const AddParticipantButton = styled.div`
   display: flex;
   justify-content: flex-end;
   padding: 4px 0;
`;

const ButtonWrapper = styled.div`
   background-color: #fff;
   padding: 8px 16px;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
   position: sticky;
   bottom: 0;
   width: 100%;
`;

const Text = styled.div`
   color: #868686;
   font-family: 'Nunito Sans';
   font-size: 14px;
   letter-spacing: 0;
   line-height: 20px;
`;

class InviteModal extends Component {
   state = {
      isLoading: true,
      searchText: '',
      memberList: [],
      searchList: [],
      selectedMembers: [],
      newSelectedMembers: [],
      participantsAdded: 0,
      newParticipantsAdded: 0,
      addedParticipants: [],
      newAddedParticipants: [],
      canSendInvite: false,
      showToast: false,
      title: '',
      dataPresent: false,
      existingParticipants: 0,
   };

   componentWillMount() {
      this.getMembers();
   }

   componentDidMount() {
      this.canSendInvite();
   }

   processExternalVisitors = list => {
      let newList = [];
      list.forEach((item, index) => {
         newList.push({ ...item.userDetails, id: UuidGenerator() });
      });
      return newList;
   };

   processInternalVisitors = list => {
      let newList = [];
      list.forEach(item => {
         newList.push(item.userDetails.userId);
      });
      return newList;
   };

   getMembers = () => {
      axios
         .get(`/innote-survey/user/organization/_fetch?type=ALL`)
         .then(({ data: { userList } }) => {
            axios
               .get(
                  `/innote-survey/telehealth/video/schedule/${this.props.scheduleId}/visitors`
               )
               .then(
                  ({
                     data: { externalVisitors, internalVisitors, participants },
                  }) => {
                     let newUserList = userList.slice();
                     participants.forEach(id => {
                        let index = newUserList.findIndex(
                           item => item.userId === id
                        );
                        if (index > -1) {
                           newUserList.splice(index, 1);
                        }
                     });
                     this.setState({
                        addedParticipants: this.processExternalVisitors(
                           externalVisitors
                        ),
                        selectedMembers: this.processInternalVisitors(
                           internalVisitors
                        ),
                        memberList: newUserList,
                        participantsAdded: externalVisitors.length,
                        dataPresent: internalVisitors.length > 0,
                        existingParticipants: internalVisitors.length,
                     });
                  }
               )
               .catch(
                  ({
                     response: {
                        data: {
                           error: { message = 'Something went wrong' } = {},
                        },
                     },
                  }) => {
                     this.setState({ showToast: true, title: message });
                  }
               )
               .finally(() => {
                  this.setState({ isLoading: false });
               });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               this.setState({
                  showToast: true,
                  title: message,
                  isLoading: false,
               });
            }
         );
   };

   handleSearchChange = event => {
      const matchedMembers = matchSorter(
         this.state.memberList,
         event.target.value.trim(),
         {
            keys: ['name.firstName', 'name.lastName'],
         }
      );
      this.setState({
         searchText: event.target.value.trim(),
         searchList: matchedMembers,
      });
   };

   changeSelectedMembers = id => {
      if (this.state.dataPresent) {
         const modifySelectedMembers = this.state.selectedMembers.slice();
         const modifyNewSelectedMembers = this.state.newSelectedMembers.slice();
         const { selectedMembers, newSelectedMembers } = this.state;

         if (newSelectedMembers.includes(id)) {
            const index = selectedMembers.indexOf(id);
            const index2 = newSelectedMembers.indexOf(id);
            modifySelectedMembers.splice(index, 1);
            modifyNewSelectedMembers.splice(index2, 1);
         } else {
            if (!selectedMembers.includes(id)) {
               if (
                  this.state.selectedMembers.length +
                     this.state.participantsAdded <
                  7
               ) {
                  modifySelectedMembers.push(id);
                  modifyNewSelectedMembers.push(id);
               }
            }
         }
         this.setState(
            () => ({
               selectedMembers: modifySelectedMembers,
               newSelectedMembers: modifyNewSelectedMembers,
            }),
            this.canSendInvite
         );
      } else {
         const modifySelectedMembers = this.state.selectedMembers.slice();
         const { selectedMembers } = this.state;
         if (selectedMembers.includes(id)) {
            const index = selectedMembers.indexOf(id);
            modifySelectedMembers.splice(index, 1);
         } else {
            if (
               this.state.selectedMembers.length +
                  this.state.participantsAdded <
               7
            ) {
               modifySelectedMembers.push(id);
            }
         }
         this.setState(
            () => ({ selectedMembers: modifySelectedMembers }),
            this.canSendInvite
         );
      }
   };

   addParticipant = () => {
      if (
         this.state.selectedMembers.length + this.state.participantsAdded <
         7
      ) {
         const { participantsAdded, newParticipantsAdded } = this.state;
         const addInAddedParticipants = this.state.addedParticipants.slice();
         const addInNewAddedParticipants = this.state.newAddedParticipants.slice();
         const newParticipant = {
            id: UuidGenerator(),
            name: '',
            phoneNo: '',
            email: '',
         };
         addInAddedParticipants.push(newParticipant);
         addInNewAddedParticipants.push(newParticipant);
         this.setState(
            () => ({
               addedParticipants: addInAddedParticipants,
               participantsAdded: participantsAdded + 1,
               newParticipantsAdded: newParticipantsAdded + 1,
               newAddedParticipants: addInNewAddedParticipants,
            }),
            this.canSendInvite
         );
      }
   };

   onChangeHandler = (event, id) => {
      const { name, value } = event.target;
      let modifyAddedParticipants = this.state.addedParticipants.slice();
      let modifyNewAddedParticipants = this.state.newAddedParticipants.slice();
      modifyNewAddedParticipants.map(item => {
         if (item.id === id) {
            item[name] = value;
            modifyAddedParticipants.map(item => {
               if (item.id === id) {
                  item[name] = value;
                  return item;
               }
               return item;
            });
            return item;
         }
         return item;
      });
      this.setState(
         () => ({
            addedParticipants: modifyAddedParticipants,
            newAddedParticipants: modifyNewAddedParticipants,
         }),
         this.canSendInvite
      );
   };

   validateEmail = email => {
      if (email === '') {
         return true;
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
   };

   deleteParticipant = id => {
      const { participantsAdded } = this.state;
      let modifyAddedParticipants = this.state.addedParticipants.slice();
      let modifyNewAddedParticipants = this.state.newAddedParticipants.slice();
      let index = modifyNewAddedParticipants.findIndex(item => item.id === id);
      let index2 = modifyAddedParticipants.findIndex(item => item.id === id);
      if (index > -1 && index2 > -1) {
         let index2 = modifyAddedParticipants.findIndex(item => item.id === id);
         modifyAddedParticipants.splice(index2, 1);
         modifyNewAddedParticipants.splice(index, 1);
         this.setState(
            () => ({
               newAddedParticipants: modifyNewAddedParticipants,
               addedParticipants: modifyAddedParticipants,
               participantsAdded: participantsAdded - 1,
            }),
            this.canSendInvite
         );
      }
   };

   canSendInvite = () => {
      const {
         participantsAdded,
         addedParticipants,
         selectedMembers,
         existingParticipants,
         newAddedParticipants,
      } = this.state;
      if (
         newAddedParticipants.length === 0 &&
         existingParticipants === selectedMembers.length
      ) {
         this.setState({ canSendInvite: false });
         return;
      }
      let addedParticipantsVerified = false;
      let verifiedParticipants = 0;
      let allPhoneDifferent = false;
      let phoneNumberList = [];
      if (participantsAdded > 0) {
         addedParticipants.forEach(item => {
            if (
               item.name.length > 0 &&
               item.phoneNo.length === 10 &&
               this.validateEmail(item.email)
            ) {
               verifiedParticipants += 1;
            }
         });
      }
      if (verifiedParticipants === participantsAdded) {
         addedParticipantsVerified = true;
         addedParticipants.forEach(item => {
            phoneNumberList.push(item.phoneNo);
         });
      }
      if (
         participantsAdded > 0 &&
         addedParticipantsVerified &&
         new Set(phoneNumberList).size === phoneNumberList.length
      ) {
         allPhoneDifferent = true;
      } else if (
         addedParticipantsVerified &&
         new Set(phoneNumberList).size !== phoneNumberList.length
      ) {
         this.setState({
            canSendInvite: false,
            showToast: true,
            title: 'All phone numbers given are not unique',
         });
         return;
      }
      if (selectedMembers.length > 0 && addedParticipantsVerified) {
         this.setState({ canSendInvite: true });
      } else if (
         selectedMembers.length === 0 &&
         addedParticipantsVerified &&
         allPhoneDifferent &&
         participantsAdded > 0
      ) {
         this.setState({ canSendInvite: true });
      } else {
         this.setState({
            canSendInvite: false,
         });
      }
   };

   formatExternalUsers = list => {
      return list.map(item => {
         const newItem = { ...item };
         delete newItem.id;
         return newItem;
      });
   };

   sendInvite = () => {
      const externalUsers = this.formatExternalUsers(
         this.state.newAddedParticipants
      );
      const selectedInvitees = {
         internalUsers: this.state.dataPresent
            ? this.state.newSelectedMembers
            : this.state.selectedMembers,
         externalUsers,
      };
      this.props.sendInvitation(selectedInvitees);
   };

   render() {
      const {
         searchText,
         selectedMembers,
         searchList,
         memberList,
         participantsAdded,
         addedParticipants,
         newAddedParticipants,
         canSendInvite,
         isLoading,
         title,
         showToast,
      } = this.state;
      return (
         <ModalWrapper>
            <Modal motion={motion.slideInBottom} style={modalStyle}>
               {onModalClose => (
                  <Wrapper>
                     {showToast && (
                        <Toast
                           style={{ zIndex: '10' }}
                           title={title}
                           appearance='alert'
                           onDismissClick={() => {
                              this.setState({ showToast: false });
                           }}></Toast>
                     )}
                     <OverflowWrapper>
                        <HeaderWrapper>
                           <HeaderNameWrapper>
                              <HeaderText>Invite Participants</HeaderText>
                              <CloseModalIcon
                                 className='material-icons'
                                 onClick={this.props.onClose}>
                                 close
                              </CloseModalIcon>
                           </HeaderNameWrapper>
                           <Text>Maximum: 7</Text>
                        </HeaderWrapper>
                        {isLoading ? (
                           <LoaderWrapper>
                              <Loader />
                           </LoaderWrapper>
                        ) : (
                           <ContentWrapper>
                              <Card shadow='light'>
                                 <CardSubText>
                                    Select members from your team or add
                                    participants from outside.
                                 </CardSubText>
                                 <Form
                                    onSubmit={event => event.preventDefault()}>
                                    <TextFieldWrapper>
                                       <SearchIcon className='material-icons'>
                                          search
                                       </SearchIcon>
                                       <TextField
                                          type='text'
                                          placeholder='Search member'
                                          onChange={this.handleSearchChange}
                                          value={searchText}
                                       />
                                    </TextFieldWrapper>
                                 </Form>
                                 <InviteModalMemberList
                                    totalMembers={
                                       selectedMembers.length +
                                       participantsAdded
                                    }
                                    memberList={
                                       searchText ? searchList : memberList
                                    }
                                    selectedMembers={selectedMembers}
                                    changeSelectedMembers={
                                       this.changeSelectedMembers
                                    }
                                 />
                                 {searchText && searchList.length === 0 && (
                                    <CardSubText>
                                       No search results found.
                                    </CardSubText>
                                 )}
                              </Card>
                              {participantsAdded > 0 &&
                                 addedParticipants.map((item, index) => (
                                    <InviteModalParticipantCard
                                       key={index}
                                       item={item}
                                       index={index}
                                       showCrossIcon={
                                          newAddedParticipants.findIndex(
                                             el => el.id === item.id
                                          ) > -1
                                       }
                                       onChangeHandler={this.onChangeHandler}
                                       validateEmail={this.validateEmail}
                                       deleteParticipant={
                                          this.deleteParticipant
                                       }
                                    />
                                 ))}
                              <AddParticipantButton>
                                 <Button
                                    disabled={
                                       selectedMembers.length +
                                          participantsAdded ===
                                       7
                                    }
                                    appearance='secondary'
                                    dimension='large'
                                    onClick={this.addParticipant}>
                                    + Add other participant
                                 </Button>
                              </AddParticipantButton>
                           </ContentWrapper>
                        )}
                     </OverflowWrapper>
                     <ButtonWrapper>
                        <Button
                           appearance='primary'
                           expanded
                           dimension='large'
                           disabled={!canSendInvite || this.props.sendingInvite}
                           onClick={this.sendInvite}>
                           Send Invitation
                        </Button>
                     </ButtonWrapper>
                  </Wrapper>
               )}
            </Modal>
         </ModalWrapper>
      );
   }
}

export default InviteModal;
