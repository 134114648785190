import { TAB_RESET, TAB_SET } from './constants';

function tabReset() {
   return {
      type: TAB_RESET,
   };
}

function tabSet(tab) {
   return {
      type: TAB_SET,
      tab,
   };
}

function changeTab(tab) {
   return dispatch => {
      dispatch(tabReset());
      setTimeout(() => {
         dispatch(tabSet(tab));
      }, 0);
   };
}

export { changeTab };
