import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import Header from 'components/Header/Header';
import VideoPermissionChecker from 'components/TeleVisit/VideoPermissionChecker';
import { Loader } from 'packages/loader';
import Socket from 'helpers/socket';
import createToast, { clearToast } from 'helpers/toastHelper';
import returnCamelCasedName from 'helpers/nameTransformer';
import { checkAVPermission } from 'helpers/avPermissionHelper';
import Idle from 'packages/idleTimeout/idleTimeout';
import { loadWalkMe } from 'helpers/walkMe';
import { version } from '../../../package.json';
import { getConfig } from 'store/frontDoorSocket/action';
import { logSentry } from 'services/sentry';
import ChatSocket from 'components/ChatSocket';
import FeedBack from 'components/Feedback';
import { isEqual } from 'lodash-es';
import createNotification from 'services/notificationService';
import history from '../../history';

const Wrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
   max-height: 100%;
   ${({ goToVideoChecker }) =>
      !goToVideoChecker && 'background-color: #f4f4f4;'}
`;

const LoaderWrapper = styled.div`
   background: #00000045;
   position: absolute;
   height: 100%;
   z-index: 10;
   left: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
`;

class Main extends Component {
   walkMe = undefined;
   child = undefined;
   videoChild = undefined;
   state = {
      surveyStarted: false,
      isLoading: false,
      goToVideoChecker: false,
      socketData: null,
      visitId: null,
      incomingVideoVisit: false,
      showFeedbackComponent: false,
   };

   componentWillMount() {
      if (localStorage.getItem('videoObj')) {
         this.setState({ goToVideoChecker: true });
      }
   }

   componentDidUpdate(prevProps) {
      if (
         this.props.isWalkMeEnable !== prevProps.isWalkMeEnable &&
         this.props.isWalkMeEnable
      ) {
         loadWalkMe(this.props.isWalkMeEnable);
      }
      if (
         !isEqual(this.props.notificationDetails, prevProps.notificationDetails)
      ) {
         this.notifyUser();
      }
   }

   openChat = id => {
      history.push(`/messages/${id}`);
   };

   notifyUser = () => {
      const { notificationDetails } = this.props;
      createNotification({
         firstName: notificationDetails.firstName,
         lastName: notificationDetails.lastName,
         isSilent: true,
         isMsg: true,
         showAlways: true,
         msg: `You have new message`,
         openVideoVisit: () => this.openChat(notificationDetails.id),
      });
   };

   componentDidMount() {
      // Identify user
      const email = localStorage.getItem('email');
      if (email) {
         const accessRole = localStorage.getItem('access');
         Mixpanel.people.set({
            Type: accessRole,
            'App Version': version,
         });
         if (accessRole !== 'PATIENT') {
            // @TODO remove this call after debugging
            logSentry({
               data: {
                  Type: accessRole,
                  AppVersion: version,
               },
               identify: email,
            });
            Mixpanel.identify(`${email}`);
         }
      }
      // Load walkme
      loadWalkMe(this.props.isWalkMeEnable);
      // Clear toast on login
      clearToast();
      // Get auto logout config
      this.props.getConfig();
      axios
         .get('/innote-survey/user/organization/_fetch?type=PROVIDER')
         .then(({ data: { userList = [] } }) => {
            const newList = userList.map((item, index) => ({
               value: index + 1,
               userId: item.userId,
               label: returnCamelCasedName(
                  item.name.firstName,
                  item.name.lastName
               ),
            }));
            localStorage.setItem('userList', JSON.stringify(newList));
         });
   }

   sendEvent = event => {
      this.setState(
         {
            socketData: null,
         },
         () => {
            this.setState({ socketData: event });
         }
      );
   };

   getFunction = getAssessment => {
      this.setState({ getAssessment });
   };

   closeVideoPermissionChecker = () => {
      this.setState({ goToVideoChecker: false });
   };

   roomCreationFailed = title => {
      this.setState({ isLoading: false });
      createToast({ message: title });
   };

   enableButton = visitId => {
      axios.delete('/innote-survey/telehealth/video/rooms/_leave', {
         data: {
            visitId: visitId,
            participantId: localStorage.getItem('userId'),
         },
      });
   };

   handlePatientError = (visitId, isSetTimeOut = false) => {
      if (this.state.isLoading) {
         this.setState({ isLoading: false });
         this.enableButton(visitId);
         createToast({
            message: `${
               isSetTimeOut
                  ? "Can't join because of Technical issues. Please try again."
                  : 'Error connecting with the patient. Please wait for them to join.'
            }`,
         });
      }
   };

   createRoom = (scheduleId, roomInfo, visitId, role) => {
      this.setState({ isLoading: true });
      setTimeout(() => {
         this.handlePatientError(visitId, true);
      }, 15000);

      return axios
         .post(`/innote-survey/telehealth/video/rooms/users/_join`, {
            visitId,
         })
         .then(({ data: { roomInfo, token, patientInfo = {} } }) => {
            if (roomInfo) {
               const { id: roomId, name: roomName } = roomInfo;
               const { firstName = '', lastName = '' } = patientInfo;
               this.joinRoom({
                  roomId,
                  roomName,
                  role,
                  token,
                  visitId,
                  scheduleId,
                  firstName,
                  lastName,
               });
            }
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
               this.setState({ isLoading: false });
            }
         );
   };

   fetchRoomInfo = visitId => {
      return axios
         .get(`innote-survey/telehealth/video/rooms/token?visit_id=${visitId}`)
         .then(
            ({
               data: {
                  roomName,
                  roomId,
                  token,
                  scheduleId,
                  patientInfo: { firstName, lastName },
               },
            }) => {
               this.joinRoom({
                  roomId,
                  roomName,
                  token,
                  visitId,
                  scheduleId,
                  firstName,
                  lastName,
               });
            }
         )
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
               this.setState({ isLoading: false });
            }
         );
   };

   joinRoom = ({
      roomName,
      token,
      roomId,
      visitId,
      firstName,
      lastName,
      scheduleId,
      role,
   }) => {
      const isProvider = localStorage.getItem('access') === 'PROVIDER';
      this.setState({ isLoading: false });
      if (navigator.userAgent.toLowerCase().indexOf('electron/') > -1) {
         Mixpanel.track('Virtual Visit Joined', {
            category: 'groupCall',
            'App Type': 'electron',
         });
         const url = `${
            window.location.origin
         }/#/televisit?roomName=${roomName}&token=${token}&isProvider=${
            role === 'VISITOR' ? 'VISITOR' : isProvider ? 'PROVIDER' : 'STAFF'
         }&scheduleId=${scheduleId}&visitId=${visitId}&recipient=${returnCamelCasedName(
            firstName,
            lastName
         )}&roomId=${roomId}&userId=${localStorage.getItem('userId')}`;
         checkAVPermission(url);
      } else {
         Mixpanel.track('Virtual Visit Joined', {
            category: 'groupCall',
            'App Type': 'web',
         });
         const videoObj = {
            roomName,
            token,
            visitId,
            isProvider: role === 'VISITOR' ? 'VISITOR' : isProvider,
            lastName,
            firstName,
            scheduleId,
            roomId,
         };
         localStorage.setItem('videoObj', JSON.stringify(videoObj));
         this.setState({
            goToVideoChecker: true,
            roomName,
            token,
            scheduleId,
            visitId,
            isProvider,
            lastName,
            firstName,
            roomId,
         });
      }
   };

   doMute = data => {
      this.child.doMute(data);
   };

   doUnMute = data => {
      this.child.doUnMute(data);
   };

   toggleAudio = () => {
      this.videoChild.toggleAudio();
   };

   askUserFeedBack = () => {
      this.setState({
         showFeedbackComponent: true,
      });
   };

   render() {
      const {
         roomName,
         token,
         lastName,
         firstName,
         scheduleId,
         visitId,
         isProvider,
         isLoading,
         roomId,
         showFeedbackComponent,
      } = this.state;

      return (
         <Wrapper goToVideoChecker={this.state.goToVideoChecker}>
            {!this.state.goToVideoChecker && this.props.logoutTimeoutAllowed && (
               <Idle
                  timeout={this.props.logoutTimeout}
                  onChange={isIdle => {
                     if (isIdle) {
                        localStorage.clear();
                        localStorage.setItem('isOnboarded', true);
                        createToast({
                           message: 'Logged out due to inactivity.',
                        });
                     }
                  }}
                  render={isIdle => isIdle && <Redirect to='/signup' />}
               />
            )}
            <Socket
               onRef={ref => (this.child = ref)}
               isLoading={isLoading}
               handlePatientError={this.handlePatientError}
               fetchRoomInfo={this.fetchRoomInfo}
               sendEvent={this.sendEvent}
               joinRoom={this.createRoom}
               getAssessment={this.state.getAssessment}
               isSkip={this.state.goToVideoChecker}
               toggleAudio={this.toggleAudio}
               askUserFeedBack={this.askUserFeedBack}
            />
            {this.state.goToVideoChecker && (
               <VideoPermissionChecker
                  onRef={ref => (this.videoChild = ref)}
                  roomName={roomName}
                  token={token}
                  isProvider={isProvider}
                  visitId={visitId}
                  scheduleId={scheduleId}
                  lastName={lastName}
                  firstName={firstName}
                  roomId={roomId}
                  close={this.closeVideoPermissionChecker}
                  doMute={this.doMute}
                  doUnMute={this.doUnMute}
               />
            )}
            {!this.state.goToVideoChecker && (
               <>
                  <Header />
                  {this.state.isLoading && (
                     <LoaderWrapper>
                        <Loader stroke='#fff' />
                     </LoaderWrapper>
                  )}
                  {!this.props.isUserInfoLoading && (
                     <ChatSocket>
                        {React.Children.map(this.props.children, Component => {
                           if (React.isValidElement(Component)) {
                              return React.cloneElement(Component, {
                                 createRoom: this.createRoom,
                                 getFunction: this.getFunction,
                                 socketData: this.state.socketData,
                              });
                           }
                        })}
                     </ChatSocket>
                  )}
               </>
            )}
            {showFeedbackComponent && (
               <FeedBack
                  hideFeedBackComponent={() =>
                     this.setState({
                        showFeedbackComponent: false,
                     })
                  }
               />
            )}
         </Wrapper>
      );
   }
}

const mapDispatchToProps = { getConfig };

const mapStateToProps = ({ frontDoorSocket }) => {
   const {
      logoutTimeoutAllowed,
      logoutTimeout,
      isWalkMeEnable,
      notificationDetails,
      isUserInfoLoading,
   } = frontDoorSocket;
   return {
      logoutTimeoutAllowed,
      logoutTimeout,
      isWalkMeEnable,
      notificationDetails,
      isUserInfoLoading,
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
