import HTTPService, {
   interpolateURL,
   withQueryString,
} from 'services/httpService';
import { transformError } from './errorTransformer';

// API URL
const API = {
   articles:
      'https://innoteassistant.zendesk.com/api/v2/help_center/en-us/sections/:sectionId/articles',
   sections:
      'https://innoteassistant.zendesk.com/api/v2/help_center/en-us/sections',
   search:
      'https://innoteassistant.zendesk.com/api/v2/help_center/articles/search',
};

/**
 * Parse article list
 * @param {Object} articleList List of articles
 * @return {Object}
 */
const parseArticleList = articleList =>
   articleList.map(article => ({
      articleID: article.id,
      name: article.name,
      body: article.body,
      link: article.html_url,
      section_id: article.section_id,
   }));

/**
 * Get article list
 * @param {number} sectionId Section id
 * @return {Promise}
 */
const getArticleList = sectionId => {
   return HTTPService.get(interpolateURL(API.articles, { sectionId }))
      .then(({ data }) => ({
         ...data,
         articles: parseArticleList(data.articles),
      }))
      .catch(error => Promise.reject(transformError(error)));
};

/**
 * Get section list
 * @return {Promise}
 */
const getSectionList = () =>
   HTTPService.get(API.sections)
      .then(({ data }) => data)
      .catch(error => Promise.reject(transformError(error)));

/**
 * Search articles
 * @param {string} query Query
 * @return {Promise}
 */
const searchArticles = query =>
   HTTPService.get(withQueryString(API.search, { query }))
      .then(({ data }) => ({
         ...data,
         results: parseArticleList(data.results),
      }))
      .catch(error => Promise.reject(transformError(error)));

export {
   getArticleList as _getArticleList,
   getSectionList as _getSectionList,
   searchArticles as _searchArticles,
};
