import React, { Component } from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
   display: flex;
   padding: 8px 0;
   border-bottom: 1px solid rgb(232, 228, 228);
   flex: 1;
   cursor: pointer;
`;

const Item = styled.li`
   display: flex;
   flex-direction: column;
   cursor: default;
   background-color: #fff;

   &:last-child {
      ${TextWrapper} {
         border-bottom: none;
      }
   }
`;

const Name = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 20px;
   align-self: center;
`;

const CheckedIcon = styled.i`
   color: #0072e4;
   cursor: pointer;
   padding: 8px;
   font-size: 20px;
`;

const UncheckedIcon = styled.i`
   color: #bfc0c0;
   cursor: pointer;
   padding: 8px;
   font-size: 20px;
`;

class BooleanQuestion extends Component {
   render() {
      const {
         question,
         question: {
            id,
            answers = [
               { id: true, label: 'Yes' },
               { id: false, label: 'No' },
            ],
         },
         onClickRadioHandler,
      } = this.props;

      return (
         <Item>
            {Object.keys(answers).map(each => (
               <TextWrapper
                  key={answers[each].label}
                  onClick={e =>
                     onClickRadioHandler(e, {
                        questionId: id,
                        answerId: answers[each].id,
                     })
                  }>
                  {answers[each].id === question.selectedAnswerId ? (
                     <CheckedIcon className='material-icons'>
                        check_circle
                     </CheckedIcon>
                  ) : (
                     <UncheckedIcon className='material-icons'>
                        panorama_fish_eye
                     </UncheckedIcon>
                  )}
                  <Name>{answers[each].label}</Name>
               </TextWrapper>
            ))}
         </Item>
      );
   }
}

export default BooleanQuestion;
