import update from 'immutability-helper';
import {
   GET_SETTINGS_START,
   GET_SETTINGS_FAILURE,
   GET_SETTINGS_SUCCESS,
   SET_SETTINGS_START,
   SET_SETTINGS_FAILURE,
   SET_SETTINGS_SUCCESS,
   GET_CONFIG_START,
   GET_CONFIG_FAILURE,
   GET_CONFIG_SUCCESS,
   ON_CLOSE,
   ON_ERROR,
   ON_MESSAGE,
   ON_OPEN,
   CHANGE_TAB,
   UNASSIGN_TAB_LOADING,
   MY_TAB_LOADING,
   GET_INBOX_LIST,
   IS_LOAD_MORE_UNASSIGNED_INBOX,
   IS_LOAD_MORE_MY_INBOX,
   IS_CHAT_OPEN,
   SET_SELECTED_CHAT,
   RETRIEVE_INBOX_MESSAGES,
   GET_MY_OFFLINE_MESSAGES,
   SEND_MESSAGE,
   ASSIGN_TO_ME,
   AVAILABLE_FOR_ASSISTANCE_START,
   AVAILABLE_FOR_ASSISTANCE_FAILURE,
   AVAILABLE_FOR_ASSISTANCE_SUCCESS,
   GET_USER_INFO_START,
   GET_USER_INFO_SUCCESS,
   GET_USER_INFO_FAILURE,
   SET_SOCKET,
   ADDED_IN_GROUP,
   MESSAGE_READ,
   INBOX_VIEW_SET_DELETE,
   NEW_MESSAGE_RECEIVED,
   MARK_MESSAGE_AS_READ_PARTIAL_UPDATE,
   SET_USER_INFO,
   SET_SOUND_CARD,
   CHAT_LOADING,
   GET_INBOX,
   CLEAR_CHAT,
   RESET_MY_TAB,
   CHAT_HEADER_LOADING,
   REMOVE_MESSAGE_OBSERVER,
   ADD_ATTACHMENT,
   START_ATTACHMENT_UPLOAD,
   ADD_ATTACHMENT_IDENTIFIER,
   ATTACHMENT_UPLOAD_DONE,
   RESET_HAS_NEW_MESSAGES,
} from './constants';

import { get, cloneDeep, isEmpty, merge } from 'lodash-es';
import { setItem } from 'helpers/localStore';

const initialState = {
   activeTabId: 1,
   socketDisConnectCount: 0,
   tabList: [
      {
         count: 0,
         name: 'Unassigned',
         id: 1,
      },
      {
         name: 'My patients',
         id: 2,
      },
   ],
   isAvailableForAssistanceEnable: false,
   isSocketOpen: false,
   isSocketError: false,
   messageCount: 0,
   inboxList: [],
   onLineInbox: [],
   offLineInbox: [],
   isUnassignedTabLoading: false,
   isLoadMoreUnassignedInbox: false,
   endReachedUnassignedInbox: false,
   next: null,
   myInboxList: [],
   isMyTabLoading: true,
   isLoadMoreMyInbox: false,
   endReachedMyInbox: false,
   myInboxNext: null,
   isChatOpen: false,
   isProviderChatEnable: false,
   isChatLoading: true,
   isChatHeaderLoading: false,
   selectedInbox: {
      next: null,
      previous: null,
      firstName: '',
      lastName: '',
      phone: '',
      isOnline: true,
      messages: [],
      inboxId: '',
      dob: '',
      gender: '',
      email: '',
      isRead: false,
      userId: '',
   },
   isChatLoadingError: false,
   isApiLoading: false,
   isUserInfoLoading: true,
   userInfo: {},
   isUserInfoError: false,
   socket: null,
   settingsLoading: false,
   configLoading: false,
   logoutTimeoutAllowed: false,
   logoutTimeout: 0,
   isWalkMeEnable: false,
   navItemList: [
      {
         name: 'Virtual Visits',
         icon: 'videocam',
         link: '/',
         exact: true,
         visible: true,
      },
      {
         name: 'Assessments',
         icon: 'assignment',
         link: '/assessments',
         exact: true,
         visible: true,
      },
      {
         name: 'Front door',
         icon: 'meeting_room',
         link: '/frontdoor',
         exact: true,
         visible: false,
      },
      {
         name: 'Messages',
         icon: 'message',
         link: '/messages',
         exact: true,
         visible: false,
      },
      {
         name: 'Profile',
         icon: 'person',
         link: '/profile',
         exact: true,
         visible: true,
      },
   ],
   role: localStorage.getItem('access'),
   isOptionOpen: false,
   userSettings: {},
   notificationDetails: {},
   isAttachmentUploading: false,
   isAttachmentDownloading: false,
   hasNewMessages: false,
};

const isSoundEnable = userSettings => {
   const userRole = localStorage.getItem('access');
   if (userRole === 'PROVIDER') {
      return (
         userSettings.readyForProvider.enabled ||
         userSettings.waitingQueue.enabled
      );
   } else {
      return (
         userSettings.postVisitQueue.enabled ||
         userSettings.waitingQueue.enabled
      );
   }
};

const frontDoorSocket = (state = initialState, action) => {
   switch (action.type) {
      case SET_SOUND_CARD:
         return update(state, {
            $merge: {
               userSettings: {
                  ...state.userSettings,
                  isSoundEnable: action.data,
               },
            },
         });
      case SET_SETTINGS_START:
         return update(state, {
            settingsLoading: {
               $set: true,
            },
         });
      case SET_SETTINGS_SUCCESS: {
         const userSettings = merge(
            state.userSettings,
            get(action, 'data', {})
         );
         userSettings.isSoundEnable = isSoundEnable(userSettings);
         return update(state, {
            $merge: {
               settingsLoading: false,
               userSettings,
            },
         });
      }
      case SET_SETTINGS_FAILURE:
         return update(state, {
            settingsLoading: {
               $set: false,
            },
         });
      case GET_SETTINGS_START:
         return update(state, {
            settingsLoading: {
               $set: true,
            },
         });
      case GET_SETTINGS_SUCCESS: {
         const userSettings = get(action, 'data', {});
         userSettings.isSoundEnable = isSoundEnable(userSettings);
         return update(state, {
            $merge: {
               settingsLoading: false,
               userSettings,
            },
         });
      }
      case GET_SETTINGS_FAILURE:
         return update(state, {
            settingsLoading: {
               $set: false,
            },
         });
      case GET_CONFIG_START:
         return update(state, {
            configLoading: {
               $set: true,
            },
         });
      case GET_CONFIG_SUCCESS: {
         const navItemList = [...state.navItemList];
         const data = get(action, 'data.data', {});
         const findIndex = navItemList.findIndex(
            el => el.name === 'Front door'
         );
         if (findIndex !== -1) {
            navItemList[findIndex].visible = data.isFrontDoorEnable || false;
         }
         const findIndexProvider = navItemList.findIndex(
            el => el.name === 'Messages'
         );
         if (findIndexProvider !== -1) {
            navItemList[findIndexProvider].visible =
               data.isPatientProviderChatEnabled || false;
         }

         return update(state, {
            $merge: {
               attributionEnabled: get(data, 'attributionEnabled', false),
               configLoading: false,
               logoutTimeoutAllowed: get(data, 'logoutTimeoutAllowed', false),
               logoutTimeout: get(data, 'logoutTimeout', 0),
               isStaffAvailableButtonEnable: get(
                  data,
                  'isStaffAvailableButtonEnable',
                  false
               ),
               isProviderAvailableButtonEnable: get(
                  data,
                  'isProviderAvailableButtonEnable',
                  false
               ),
               isWalkMeEnable: get(data, 'isWalkMeEnable', false),
               navItemList: navItemList,
            },
         });
      }
      case GET_CONFIG_FAILURE:
         return update(state, {
            configLoading: {
               $set: false,
            },
         });
      case INBOX_VIEW_SET_DELETE: {
         const inboxList = cloneDeep(state.inboxList);
         const indexID = get(action, 'data.inbox_id', '');
         const findIndex = inboxList.findIndex(el => el.inbox_id === indexID);
         if (findIndex !== -1) {
            inboxList.splice(findIndex, 1);
         }
         const onLineInbox = inboxList.filter(el =>
            get(el, 'users[0].is_online', false)
         );
         const offLineInbox = inboxList.filter(
            el => !get(el, 'users[0].is_online', false)
         );

         return update(state, {
            $merge: {
               inboxList,
               onLineInbox,
               offLineInbox,
            },
         });
      }
      case GET_INBOX: {
         let result = get(action, 'data', {});
         if (isEmpty(result)) {
            return update(state, {});
         } else {
            const [updateResult] = result.users.filter(
               el => el.user_type === 'PATIENT'
            );
            return update(state, {
               $merge: {
                  isChatHeaderLoading: false,
                  selectedInbox: {
                     ...state.selectedInbox,
                     firstName: get(updateResult, 'first_name', ''),
                     lastName: get(updateResult, 'last_name', ''),
                     isOnline: get(updateResult, 'is_online', false),
                     phone: get(updateResult, 'phone', ''),
                     dob: get(updateResult, 'dob', ''),
                     gender: get(updateResult, 'gender', ''),
                     email: get(updateResult, 'email', ''),
                     messages: [],
                     inboxId: get(result, 'inbox_id', ''),
                     unreadMessageCount: get(result, 'unread_message_count', 0),
                     userId: get(updateResult, 'user_id', ''),
                  },
               },
            });
         }
      }
      case ADDED_IN_GROUP: {
         let result = get(action, 'data', {});
         if (isEmpty(result)) {
            return update(state, {});
         } else {
            if (localStorage.getItem('access') === 'PROVIDER') {
               const results = cloneDeep(state.myInboxList);
               results.unshift(result);
               const updateResult = results
                  .map(el => {
                     el.users = el.users.filter(
                        el => el.user_type === 'PATIENT'
                     );
                     return el;
                  })
                  .filter(el => el.users.length > 0);
               return update(state, {
                  $merge: {
                     myInboxList: updateResult,
                  },
               });
            } else {
               const results = cloneDeep(state.inboxList);
               results.unshift(result);
               const updateResult = results
                  .map(el => {
                     el.users = el.users.filter(
                        el => el.user_type === 'PATIENT'
                     );
                     return el;
                  })
                  .filter(el => el.users.length > 0);
               const onLineInbox = updateResult.filter(el =>
                  get(el, 'users[0].is_online', false)
               );
               const offLineInbox = updateResult.filter(
                  el => !get(el, 'users[0].is_online', false)
               );
               return update(state, {
                  $merge: {
                     inboxList: updateResult,
                     onLineInbox,
                     offLineInbox,
                  },
               });
            }
         }
      }
      case SET_SOCKET: {
         return update(state, {
            socket: {
               $set: action.socket,
            },
         });
      }
      case GET_USER_INFO_START:
         return update(state, {
            isUserInfoLoading: {
               $set: action.isSecondCall ? state.isUserInfoLoading : true,
            },
            isUserInfoError: {
               $set: false,
            },
         });

      case GET_USER_INFO_FAILURE:
         return update(state, {
            isUserInfoLoading: {
               $set: false,
            },
            isUserInfoError: {
               $set: true,
            },
         });
      case GET_USER_INFO_SUCCESS: {
         let role = get(action, 'data.accessRole', '');
         if (role) {
            role = role === 'PRACTICE_MANAGER' ? 'STAFF' : role;
            localStorage.setItem('access', role);
         }
         setItem(
            'organization',
            get(action, 'data.organization.organization_name', '')
         );
         setItem('practices', get(action, 'data.practices', ''));
         return update(state, {
            $merge: {
               isUserInfoLoading: false,
               isAvailableForAssistanceEnable:
                  localStorage.getItem('access') === 'STAFF'
                     ? get(
                          action,
                          'data.isAvailableChat',
                          state.isAvailableForAssistanceEnable
                       )
                     : get(
                          action,
                          'data.isAvailableWalkIn',
                          state.isAvailableForAssistanceEnable
                       ),
               userInfo: {
                  ...action.data,
                  accessRole: role,
               },
            },
         });
      }
      case SET_USER_INFO: {
         return update(state, {
            $merge: {
               userInfo: {},
            },
         });
      }
      case SET_SELECTED_CHAT:
      case ASSIGN_TO_ME: {
         const merge = {};
         let removed = [];
         if (action.type === ASSIGN_TO_ME) {
            const inboxList = cloneDeep(state.inboxList);
            const myInboxList = cloneDeep(state.myInboxList);
            const findIndex = inboxList.findIndex(
               el => el.inbox_id === action.data.inbox_id
            );
            if (findIndex !== -1) {
               removed = inboxList.splice(findIndex, 1);
            }
            const onLineInbox = inboxList.filter(el =>
               get(el, 'users[0].is_online', false)
            );
            const offLineInbox = inboxList.filter(
               el => !get(el, 'users[0].is_online', false)
            );
            merge.onLineInbox = onLineInbox;
            merge.offLineInbox = offLineInbox;
            merge.inboxList = inboxList;
            if (!isEmpty(myInboxList)) {
               merge.myInboxList = [removed[0], ...myInboxList];
            }
         }
         const item =
            action.type === ASSIGN_TO_ME ? removed[0] : get(action, 'item', {});
         let {
            first_name: firstName,
            last_name: lastName,
            is_online: isOnline,
            phone,
            dob,
            gender,
            email,
            user_id: userId,
         } = get(item, 'users[0]', {});
         firstName = firstName || '';
         lastName = lastName || '';
         phone = phone || '';
         return update(state, {
            $merge: {
               ...merge,
               isChatOpen: true,
               selectedInbox: {
                  ...state.selectedInbox,
                  firstName,
                  lastName,
                  isOnline,
                  phone,
                  dob,
                  gender,
                  email,
                  userId,
                  messages: [],
                  inboxId: get(item, 'inbox_id', ''),
                  unreadMessageCount: get(item, 'unread_message_count', 0),
               },
            },
         });
      }
      case NEW_MESSAGE_RECEIVED: {
         const merge = {};
         const notificationDetails = {};
         const inboxList = cloneDeep(state.inboxList);
         const myInboxList = cloneDeep(state.myInboxList);
         const data = get(action, 'data', {});
         const selectedInbox = get(state, 'selectedInbox.inboxId', '');
         if (
            state.isChatOpen &&
            selectedInbox &&
            selectedInbox === data.inbox_id
         ) {
            merge.selectedInbox = {
               ...state.selectedInbox,
               messages: [
                  ...state.selectedInbox.messages,
                  { ...action.data, observe: true },
               ],
            };
         } else {
            let findIndex = inboxList.findIndex(
               el => el.inbox_id === data.inbox_id
            );
            if (findIndex !== -1) {
               const old = get(state, `inboxList[${findIndex}]`, {});
               inboxList.splice(findIndex, 1, {
                  ...old,
                  last_message: get(data, 'message', {}),
                  unread_message_count: old.unread_message_count + 1,
                  updated_at: get(data, 'sent_at', ''),
               });
               inboxList.unshift(inboxList.splice(findIndex, 1)[0]);
               const onLineInbox = inboxList.filter(el =>
                  get(el, 'users[0].is_online', false)
               );
               const offLineInbox = inboxList.filter(
                  el => !get(el, 'users[0].is_online', false)
               );
               merge.inboxList = inboxList;
               merge.onLineInbox = onLineInbox;
               merge.offLineInbox = offLineInbox;
            } else {
               findIndex = myInboxList.findIndex(
                  el => el.inbox_id === data.inbox_id
               );
               if (findIndex !== -1) {
                  const old = get(state, `myInboxList[${findIndex}]`, {});
                  notificationDetails.firstName = get(
                     old,
                     'users[0].first_name',
                     ''
                  );
                  notificationDetails.lastName = get(
                     old,
                     'users[0].last_name',
                     ''
                  );
                  notificationDetails.id = get(old, 'inbox_id');
                  notificationDetails.msg = get(data, 'message', {});
                  myInboxList.splice(findIndex, 1, {
                     ...old,
                     last_message: get(data, 'message', {}),
                     unread_message_count: old.unread_message_count + 1,
                     updated_at: get(data, 'sent_at', ''),
                  });
                  myInboxList.unshift(myInboxList.splice(findIndex, 1)[0]);
                  merge.myInboxList = myInboxList;
               }
            }
         }
         return update(state, {
            $merge: {
               ...merge,
               notificationDetails,
            },
         });
      }
      case SEND_MESSAGE: {
         if (action.data.message.type === 'ATTACHMENT') {
            let updateIndex = -1;
            state.selectedInbox.messages.forEach((message, index) => {
               if (message.external_id === action.data.external_id) {
                  updateIndex = index;
               }
            });
            return update(state, {
               selectedInbox: {
                  messages: {
                     [updateIndex]: {
                        $set: {
                           ...action.data,
                           observe: false,
                           status: 'Sent',
                        },
                     },
                  },
               },
            });
         }

         return update(state, {
            selectedInbox: {
               messages: {
                  $push: [
                     {
                        ...action.data,
                        observe: false,
                        status: 'Sent',
                     },
                  ],
               },
            },
         });
      }
      case ADD_ATTACHMENT: {
         return update(state, {
            isAttachmentUploading: {
               $set: true,
            },
            selectedInbox: {
               messages: {
                  $push: [
                     {
                        ...action.data,
                        observe: false,
                        status: 'Sent',
                     },
                  ],
               },
            },
         });
      }
      case START_ATTACHMENT_UPLOAD: {
         return update(state, {
            isAttachmentUploading: {
               $set: true,
            },
         });
      }
      case ADD_ATTACHMENT_IDENTIFIER: {
         return update(state, {
            selectedInbox: {
               messages: {
                  [action.data.index]: {
                     external_id: {
                        $set: action.data.external_id,
                     },
                  },
               },
            },
         });
      }
      case ATTACHMENT_UPLOAD_DONE: {
         return update(state, {
            isAttachmentUploading: {
               $set: false,
            },
         });
      }
      case MARK_MESSAGE_AS_READ_PARTIAL_UPDATE: {
         const myInboxList = get(state, 'myInboxList', []);
         const data = get(action, 'data', {});

         const findIndex = myInboxList.findIndex(
            el => el.inbox_id === data.inbox_id
         );
         if (findIndex !== -1) {
            const old = get(state, `myInboxList[${findIndex}]`, {});
            myInboxList.splice(findIndex, 1, {
               ...old,
               unread_message_count: old.unread_message_count - 1,
            });
         }
         return update(state, {
            $merge: {
               myInboxList,
            },
         });
      }
      case REMOVE_MESSAGE_OBSERVER: {
         const selectedInbox = get(state, 'selectedInbox', {});
         const messages = get(state, 'selectedInbox.messages', []);
         const data = get(action, 'data', {});
         if (
            state.isChatOpen &&
            selectedInbox &&
            selectedInbox.inboxId === data.inbox_id
         ) {
            const msgIndex = messages.findIndex(
               el => el.message_id === data.message_id
            );
            if (msgIndex !== -1) {
               messages.splice(msgIndex, 1, {
                  ...messages[msgIndex],
                  observe: false,
               });
            }
         }
         return update(state, {
            $merge: {
               selectedInbox: {
                  ...state.selectedInbox,
                  messages,
               },
            },
         });
      }
      case MESSAGE_READ: {
         const messages = get(state, 'selectedInbox.messages', []);
         let updatedMsg = cloneDeep(messages);
         const data = get(action, 'data', {});
         const selectedInbox = get(state, 'selectedInbox.inboxId', '');
         const findIndex = messages.findIndex(
            el => el.message_id === data.message_parent_id
         );

         if (
            state.isChatOpen &&
            selectedInbox === data.inbox_id &&
            findIndex !== -1
         ) {
            for (let i = 0; i <= findIndex; i++) {
               const msgDetails = messages[i].sender;
               if (
                  !(msgDetails && msgDetails.toUpperCase().includes('PATIENT'))
               ) {
                  updatedMsg.splice(i, 1, { ...messages[i], status: 'Read' });
               }
            }
            return update(state, {
               $merge: {
                  selectedInbox: {
                     ...state.selectedInbox,
                     isRead: true,
                     messages: updatedMsg,
                  },
               },
            });
         } else {
            return update(state, {});
         }
      }
      case GET_MY_OFFLINE_MESSAGES: {
         let result = get(action, 'data.results', []);
         const { inboxList, myInboxList } = state;
         const updateResult = result
            .map(el => {
               el.users = el.users.filter(el => el.user_type === 'PATIENT');
               return el;
            })
            .filter(el => el.users.length > 0);
         let isInboxUpdated = false;
         let isMyInboxUpdated = false;
         let updatedValues = {};
         updateResult.forEach(el => {
            const index = inboxList.findIndex(
               el1 => el1.inbox_id === el.inbox_id
            );
            if (index !== -1) {
               inboxList.splice(index, 1, el);
               isInboxUpdated = true;
            } else {
               const myIndex = myInboxList.findIndex(
                  el1 => el1.inbox_id === el.inbox_id
               );
               if (myIndex !== -1) {
                  myInboxList.splice(index, 1, el);
                  isMyInboxUpdated = true;
               }
            }
         });
         if (isInboxUpdated) {
            const onLineInbox = inboxList.filter(el =>
               get(el, 'users[0].is_online', false)
            );
            const offLineInbox = inboxList.filter(
               el => !get(el, 'users[0].is_online', false)
            );
            updatedValues = {
               inboxList: inboxList,
               onLineInbox,
               offLineInbox,
            };
         }
         if (isMyInboxUpdated) {
            updatedValues = {
               ...updatedValues,
               myInboxList: myInboxList,
            };
         }
         return update(state, {
            $merge: {
               ...updatedValues,
            },
         });
      }
      case RETRIEVE_INBOX_MESSAGES: {
         const unreadMessageCount = get(
            state,
            'selectedInbox.unreadMessageCount',
            0
         );
         const messages = get(action, 'data.results', []);
         if (unreadMessageCount > 0) {
            if (state.isSocketOpen && state.socket) {
               const msg = messages.filter(el =>
                  el.sender.toUpperCase().includes('PATIENT')
               );
               const details = msg.length > 0 ? msg[0] : {};
               if (!isEmpty(details)) {
                  state.socket.send(
                     JSON.stringify({
                        kwargs: {
                           message_id: details.message_id,
                           inbox_id: details.inbox_id,
                        },
                        message_id: details.message_id,
                        event_type: 'MarkMessageAsRead_partial_update',
                     })
                  );
               }
            }
         }
         return update(state, {
            $merge: {
               isChatLoading: false,
               selectedInbox: {
                  ...state.selectedInbox,
                  next: get(action, 'data.next', null),
                  previous: get(action, 'data.previous', null),
                  messages: messages.reverse(),
               },
            },
         });
      }
      case ON_OPEN:
         return update(state, {
            $merge: {
               isSocketOpen: true,
               isSocketError: false,
            },
         });
      case ON_CLOSE:
         return update(state, {
            $merge: {
               socketDisConnectCount: state.socketDisConnectCount + 1,
               isSocketOpen: false,
               socket: null,
            },
         });
      case ON_ERROR:
         return update(state, {
            $merge: {
               isSocketOpen: false,
               isSocketError: true,
               socket: null,
            },
         });
      case ON_MESSAGE:
         return update(state, {
            messageCount: {
               $set: state.messageCount + 1,
            },
         });
      case IS_CHAT_OPEN:
         return update(state, {
            isChatOpen: {
               $set: action.isChatOpen,
            },
         });
      case CHAT_LOADING:
         return update(state, {
            isChatLoading: {
               $set: action.isChatLoading,
            },
         });
      case RESET_MY_TAB: {
         return update(state, {
            $merge: {
               myInboxList: [],
               myInboxNext: null,
               isMyTabLoading: true,
               isLoadMoreMyInbox: false,
               endReachedMyInbox: false,
            },
         });
      }
      case CHAT_HEADER_LOADING:
         return update(state, {
            isChatHeaderLoading: {
               $set: action.isChatHeaderLoading,
            },
         });
      case GET_INBOX_LIST: {
         let result = get(action, 'data.results', []);
         let updateResult = result
            .map(el => {
               el.users = el.users.filter(el => el.user_type === 'PATIENT');
               return el;
            })
            .filter(el => el.users.length > 0);
         const updateResultBot = updateResult.filter(el => el.type === 'BOT');
         const updateResultGroup = updateResult.filter(el =>
            localStorage.getItem('access') === 'PROVIDER'
               ? el.type === 'ONE_TO_ONE'
               : el.type === 'GROUP'
         );
         if (!isEmpty(updateResultBot)) {
            const onLineInbox = updateResultBot.filter(el =>
               get(el, 'users[0].is_online', false)
            );
            const offLineInbox = updateResultBot.filter(
               el => !get(el, 'users[0].is_online', false)
            );

            return update(state, {
               $merge: {
                  inboxList: state.inboxList.concat(updateResult),
                  next: get(action, 'data.next', null),
                  isUnassignedTabLoading: false,
                  onLineInbox: state.onLineInbox.concat(onLineInbox),
                  offLineInbox: state.offLineInbox.concat(offLineInbox),
                  endReachedUnassignedInbox:
                     get(action, 'data.next', null) === null,
                  isLoadMoreUnassignedInbox: false,
               },
            });
         }
         if (!isEmpty(updateResultGroup)) {
            const hasNewMessages =
               !action.data.previous &&
               updateResultGroup[0].unread_message_count > 0;
            return update(state, {
               $merge: {
                  myInboxList: action.data.previous
                     ? state.myInboxList.concat(updateResultGroup)
                     : [...updateResultGroup],
                  myInboxNext: get(action, 'data.next', null),
                  isMyTabLoading: false,
                  isLoadMoreMyInbox: false,
                  endReachedMyInbox: get(action, 'data.next', null) === null,
                  hasNewMessages,
               },
            });
         }
         return update(state, {
            $merge: {
               isMyTabLoading: false,
            },
         });
      }
      case RESET_HAS_NEW_MESSAGES:
         return update(state, {
            hasNewMessages: {
               $set: false,
            },
         });
      case CHANGE_TAB:
         return update(state, {
            activeTabId: {
               $set: action.id,
            },
         });
      case AVAILABLE_FOR_ASSISTANCE_START:
         return update(state, {
            isApiLoading: {
               $set: true,
            },
            isOptionOpen: {
               $set: true,
            },
         });
      case AVAILABLE_FOR_ASSISTANCE_FAILURE:
         return update(state, {
            isApiLoading: {
               $set: false,
            },
            isOptionOpen: {
               $set: true,
            },
         });
      case AVAILABLE_FOR_ASSISTANCE_SUCCESS: {
         const isAvailableForAssistanceEnable = action.isChange
            ? true
            : !state.isAvailableForAssistanceEnable;

         return update(state, {
            $merge: {
               isApiLoading: false,
               isAvailableForAssistanceEnable,
               isOptionOpen: false,
            },
         });
      }
      case UNASSIGN_TAB_LOADING:
         return update(state, {
            isUnassignedTabLoading: {
               $set: action.isUnassignedTabLoading,
            },
         });
      case MY_TAB_LOADING:
         return update(state, {
            isMyTabLoading: {
               $set: action.isMyTabLoading,
            },
         });
      case IS_LOAD_MORE_UNASSIGNED_INBOX:
         return update(state, {
            isLoadMoreUnassignedInbox: {
               $set: action.isLoadMoreUnassignedInbox,
            },
         });
      case IS_LOAD_MORE_MY_INBOX:
         return update(state, {
            isLoadMoreMyInbox: {
               $set: action.isLoadMoreMyInbox,
            },
         });
      case CLEAR_CHAT: {
         const selectedInbox = get(state, 'selectedInbox', '');
         const myInboxList = cloneDeep(state.myInboxList);
         const findIndex = myInboxList.findIndex(
            el => el.inbox_id === selectedInbox.inboxId
         );
         if (findIndex !== -1) {
            const old = get(state, `myInboxList[${findIndex}]`, {});
            const findLastSuccessfulMessage = (messages, checkIndex) => {
               const message = messages[checkIndex] || {};
               if (
                  (message.message || {}).type === 'ATTACHMENT' &&
                  (message.message || {}).upload
               ) {
                  return findLastSuccessfulMessage(messages, checkIndex - 1);
               }

               return messages[checkIndex];
            };
            const msg = findLastSuccessfulMessage(
               selectedInbox.messages,
               selectedInbox.messages.length - 1
            );
            myInboxList.splice(findIndex, 1, {
               ...old,
               last_message: get(msg, 'message', {}),
               unread_message_count: 0,
               updated_at: get(msg, 'sent_at', ''),
            });
            myInboxList.unshift(myInboxList.splice(findIndex, 1)[0]);
         }
         return update(state, {
            $merge: {
               isChatOpen: false,
               myInboxList,
               selectedInbox: {
                  next: null,
                  previous: null,
                  firstName: '',
                  lastName: '',
                  phone: '',
                  isOnline: true,
                  messages: [],
                  inboxId: '',
                  dob: '',
                  gender: '',
                  email: '',
                  isRead: false,
               },
            },
         });
      }
      default:
         return state;
   }
};

export default frontDoorSocket;
