import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import CardWrapper from 'components/FrontDoor/Card';

const Wrapper = styled.div``;

const MyInboxCards = ({
   chats,
   isLoadMoreMyInbox,
   endReachedMyInbox,
   getMyInbox,
   openChat,
}) => {
   const targetElement = useRef(null);

   useEffect(() => {
      if (!isLoadMoreMyInbox && !endReachedMyInbox) {
         const callBack = entries => {
            entries.forEach(item => {
               item.isIntersecting && getMyInbox(true);
            });
         };

         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadMoreMyInbox, endReachedMyInbox]); // eslint-disable-line react-hooks/exhaustive-deps
   return (
      <Wrapper innerRef={targetElement}>
         {chats.map((item, index) => (
            <CardWrapper
               item={item}
               key={index}
               isCenter={true}
               isAvatar={true}
               isMenu={false}
               isTop={true}
               isCreated={true}
               openChat={openChat}
            />
         ))}
      </Wrapper>
   );
};

export default MyInboxCards;
