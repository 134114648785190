import React from 'react';
import styled from 'styled-components';
import Card from '@datashop/card';
import Icon from '@datashop/icon';
import Heading from '@datashop/heading';
import Switch from '@datashop/switch';
import Text from '@datashop/text';
import Avatar from '@datashop/avatar';
import { getLetterIconColor } from 'helpers/badgeColor';
import Button from '@datashop/button';
import MoreOptionDropdown from 'components/VirtualVisit/MoreOptionDropdown';
import returnCamelCasedName from 'helpers/nameTransformer';
import { get, isEmpty } from 'lodash-es';
import formatPhoneNumber from 'helpers/formatPhone';
import { returnCreatedOn } from 'helpers/returnCreatedTime';

const Wrapper = styled(Card)`
   width: calc(100% - 12px);
   margin-bottom: 12px;
   background-color: #ffffff;
   padding: 12px;
   ${({ err }) => err && `margin-top: 12px;`}
   :last-child {
      margin-bottom: ${({ err }) => (err ? '0' : '12px')};
   }
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

const Column = styled.div`
   ${({ hasAssignToMe }) => hasAssignToMe && 'max-width: calc(100% - 150px)'};
   display: flex;
   flex-direction: column;
`;

const IconWrapper = styled(Icon)`
   color: #868686;
   font-size: 16px;
   margin-right: 8px;
`;

const AvatarWrapper = styled(Avatar)``;

const SwitchWrapper = styled.div`
   display: flex;
   flex: auto;
   justify-content: flex-end;
   ${({ isBottom = false }) => isBottom && 'align-self: flex-end;'}
   ${({ isTop }) => isTop && 'height: 45px;'}
`;

const TextWrapper = styled(Text)`
   color: #868686;
   margin-top: 4px;
`;

const Count = styled.div`
   background-color: #dcecf9;
   border-radius: 50%;
   width: 24px;
   height: 24px;
   margin-left: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const AvailableDot = styled.div`
   background-color: ${({ isAvailable }) =>
      isAvailable ? '#2ea843;' : '#868686'};
   border: 2px solid #ffffff;
   border-radius: 100%;
   width: 12px;
   height: 12px;
   margin-left: -12px;
`;

const SpaceDot = styled.div`
   color: #868686;
   font-family: NunitoSans;
   font-size: 24px;
   line-height: 16px;
   margin: -2px 8px 0 8px;
`;

const Available = styled.div`
   display: flex;
   align-items: flex-end;
   margin-right: 8px;
`;

const LastMessageAttachment = styled.span`
   display: flex;
   align-items: center;
`;

const AttachmentIcon = styled.i`
   font-size: 12px;
   margin-right: 4px;
`;

const TextEllipsis = styled.span`
   display: inline-block;
   max-width: 288;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
`;

const getItem = item => {
   if (isEmpty(item)) {
      return {};
   }

   const getLastMessage = (lastMessageItem = {}) => {
      if (lastMessageItem.type === 'ATTACHMENT') {
         return (
            <LastMessageAttachment>
               <AttachmentIcon className='material-icons'>
                  attach_file
               </AttachmentIcon>
               <TextEllipsis>
                  {get(lastMessageItem, 'attachment.display_name', '')}
               </TextEllipsis>
            </LastMessageAttachment>
         );
      }

      return lastMessageItem.body || '';
   };

   let {
      first_name: firstName,
      last_name: lastName,
      phone,
      is_online: isOnline,
   } = item.users[0];
   firstName = firstName || '';
   lastName = lastName || '';
   phone = phone || '';
   return {
      inboxId: item.inbox_id,
      unreadMessageCount: item.unread_message_count,
      firstName,
      lastName,
      isOnline,
      phone,
      joinedAt: item.updated_at,
      lastMessage: getLastMessage(item.last_message),
   };
};

const CardWrapper = ({
   isCenter = false,
   isAssistanceCard = false,
   isAvatar = false,
   isAvailableForAssistanceEnable,
   setAvailableForAssistance,
   isBottom = false,
   isCreated = false,
   assignToMe,
   err,
   isMenu,
   isTop,
   item,
   openChat,
   isApiLoading,
}) => {
   const data = getItem(item);
   const openChatHandler = () => {
      if (openChat) {
         openChat(item);
      }
   };
   if (err) {
      return (
         <Wrapper shadow='light' err={err}>
            {err}
         </Wrapper>
      );
   }
   return (
      <>
         <Wrapper
            shadow='light'
            onClick={openChatHandler}
            style={{ cursor: 'pointer' }}>
            <Row isCenter={isCenter}>
               {isAssistanceCard && <IconWrapper name='headset_mic' />}
               {isAvatar && (
                  <Available>
                     <AvatarWrapper
                        text={[data.firstName, data.lastName]
                           .map((el, i) => (i <= 1 && el ? el[0] : ''))
                           .join('')
                           .trimRight()
                           .toUpperCase()}
                        backgroundColor={getLetterIconColor(
                           data.firstName,
                           data.lastName
                        )}
                     />
                     <AvailableDot isAvailable={data.isOnline} />
                  </Available>
               )}
               <Column
                  hasAssignToMe={
                     assignToMe && typeof assignToMe === 'function'
                  }>
                  {isAssistanceCard ? (
                     <>
                        <Heading as='h5'>Mark available for assistance</Heading>
                        <TextWrapper>
                           {isAvailableForAssistanceEnable
                              ? 'You will see the patients who are active on virtual front door'
                              : 'You won’t see the patients who are active on virtual front door'}
                        </TextWrapper>
                     </>
                  ) : (
                     <>
                        <Row isCenter={isCenter}>
                           {openChat ? (
                              <Text
                                 fontSize='large'
                                 fontWeight='bold'
                                 title={returnCamelCasedName(
                                    data.firstName,
                                    data.lastName
                                 )}
                                 ellipsis={true}
                                 style={{ maxWidth: '250px' }}>
                                 {returnCamelCasedName(
                                    data.firstName,
                                    data.lastName
                                 )}
                              </Text>
                           ) : (
                              <Text
                                 fontSize='large'
                                 fontWeight='bold'
                                 title={returnCamelCasedName(
                                    data.firstName,
                                    data.lastName
                                 )}
                                 ellipsis={true}
                                 style={{
                                    maxWidth: assignToMe ? '200px' : '250px',
                                 }}>
                                 {returnCamelCasedName(
                                    data.firstName,
                                    data.lastName
                                 )}
                              </Text>
                           )}
                           {data.unreadMessageCount > 0 && (
                              <Count>{data.unreadMessageCount}</Count>
                           )}
                        </Row>
                        <Row>
                           {data.phone && (
                              <TextWrapper>
                                 {formatPhoneNumber(data.phone)}
                              </TextWrapper>
                           )}
                           {isCreated && data.joinedAt && (
                              <>
                                 {data.phone && <SpaceDot>.</SpaceDot>}
                                 <TextWrapper>
                                    {returnCreatedOn(data.joinedAt)}
                                 </TextWrapper>
                              </>
                           )}
                        </Row>
                        {data.lastMessage && (
                           <Row style={{ marginTop: '4px' }}>
                              <TextWrapper
                                 style={{ maxWidth: '300px' }}
                                 ellipsis>
                                 {data.lastMessage}
                              </TextWrapper>
                           </Row>
                        )}
                     </>
                  )}
               </Column>
               <SwitchWrapper isBottom={isBottom} isTop={isTop}>
                  {isAssistanceCard && (
                     <Switch
                        disabled={isApiLoading}
                        appearance='success'
                        onClick={setAvailableForAssistance}
                        checked={isAvailableForAssistanceEnable}
                     />
                  )}
                  {assignToMe && (
                     <Button
                        appearance='primary'
                        onClick={() => assignToMe(data.inboxId)}>
                        Assign to me
                     </Button>
                  )}
                  {isMenu && (
                     <div>
                        <MoreOptionDropdown />
                     </div>
                  )}
               </SwitchWrapper>
            </Row>
         </Wrapper>
      </>
   );
};

export default CardWrapper;
