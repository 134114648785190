import React from 'react';
import styled from 'styled-components';
import Checkbox from '@datashop/checkbox';

import { getLetterIconColor } from 'helpers/badgeColor';

const MemberWrapper = styled.div`
   overflow: auto;
   max-height: ${(window.innerHeight - 116) / 3}px;
`;

const CheckBoxWrapper = styled.div`
   display: flex;
   align-items: center;
   padding: 8px 4px;
`;

const NameWrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const MemberName = styled.div`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 20px;
`;

const MemberDesignation = styled.div`
   color: #919191;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;

const MemberLogo = styled.div`
   border-radius: 50%;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 30px;
   width: 30px;
   font-size: 12px;
   color: white;
   margin-right: 8px;
   background-color: ${({ color }) => color};
`;

const InviteModalMemberList = ({
   totalMembers,
   memberList,
   selectedMembers,
   changeSelectedMembers,
}) => (
   <MemberWrapper>
      {memberList.map(item => (
         <Checkbox
            disabled={
               totalMembers === 7 && selectedMembers.indexOf(item.userId) < 0
            }
            key={item.userId}
            checked={selectedMembers.indexOf(item.userId) > -1}
            label={
               <CheckBoxWrapper>
                  <MemberLogo
                     color={getLetterIconColor(
                        item.name.firstName,
                        item.name.lastName
                     )}>
                     {`${item.name.firstName[0].toUpperCase()}${item.name.lastName[0].toUpperCase()}`}
                  </MemberLogo>
                  <NameWrapper>
                     <MemberName>{`${item.name.firstName} ${item.name.lastName}`}</MemberName>
                     <MemberDesignation>{item.email}</MemberDesignation>
                  </NameWrapper>
               </CheckBoxWrapper>
            }
            onChange={() => changeSelectedMembers(item.userId)}
            id={item.userId}
         />
      ))}
   </MemberWrapper>
);

export default InviteModalMemberList;
