import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import Button from '@datashop/button';
import { format } from 'date-fns';
import { isMobile } from 'utils/checkMobile';
import { Formik } from 'formik';
import Text from '@datashop/text';
import Icon from '@datashop/icon';
import timezoneList from 'helpers/timeZones';
import { object as yupObject, string as yupString } from 'yup';
import FormComponent from './FormComponent';

const ButtonWrapper = styled.div`
   display: flex;
   padding: 12px 16px;
   background-color: white;
   bottom: 0;
   position: sticky;
   box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const ScheduleForm = ({
   fromChat = false,
   isRequestSchedule = false,
   item: {
      scheduledOn,
      meta,
      scheduleId = undefined,
      owner = '',
      callType,
      isTestCall = false,
      reasonForVisit = '',
      patientInfo: {
         firstName = '',
         lastName = '',
         unFormattedNo = '',
         dob = '',
         email = '',
         gender = '',
      } = {},
   } = {},
   onSubmitCallback,
   onModalClose,
}) => {
   const defaultTimeZone = () => {
      const userTimezone = localStorage.getItem('timezone') || '';
      const timezone = timezoneList.findIndex(
         ({ abbr }) => abbr === userTimezone
      );
      if (timezone > -1) {
         localStorage.setItem('timezone', timezoneList[timezone].abbr);
         return timezoneList[timezone].abbr;
      }
      return '';
   };

   const initialValues = {
      lastName: lastName || '',
      firstName: firstName || '',
      phoneNumber: unFormattedNo || '',
      gender: gender || '',
      appointmentDate: isMobile() ? format(new Date(), 'MM/dd/yyyy') : '',
      email,
      time: '',
      timezone: defaultTimeZone(),
      timesuffix: '',
      dob: dob ? format(new Date(dob), 'MM/dd/yyyy') : '',
      provider: undefined,
      practiceId: undefined,
      scheduleId,
      callType: callType || 'VIDEO',
      isTestCall,
      reasonForVisit: reasonForVisit || '',
   };

   const formValidationSchema = yupObject().shape({
      lastName: yupString().trim().required(),
      firstName: yupString().trim().required(),
      phoneNumber: yupString()
         .matches(/^\d{10}$/)
         .required(),
      appointmentDate: yupString()
         .matches(/^\d{2}\/\d{2}\/\d{4}$/)
         .required(),
      dob: yupString()
         .matches(/^\d{2}\/\d{2}\/\d{4}$/)
         .required(),
      email: yupString().email(),
      time: yupString()
         .test(
            'isValidTime',
            'Time is invalid',
            value =>
               value && value.includes(':') && value.split(':')[1].length === 2
         )
         .required(),
      timesuffix: yupString().required(),
      timezone: yupString().required(),
      provider:
         localStorage.getItem('access') !== 'PROVIDER' &&
         yupString().required(),
      practiceId: yupString().required(),
      callType: yupString().required(),
      gender: yupString().required(),
      reasonForVisit: yupString().notRequired(),
   });

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={formValidationSchema}
         onSubmit={(values, formikActions) => {
            onSubmitCallback(values).finally(() => {
               if (!fromChat && onModalClose) {
                  onModalClose();
               }
               formikActions.setSubmitting(false);
            });
         }}>
         {formikProps => {
            return (
               <>
                  <FormComponent
                     isRequestSchedule={isRequestSchedule}
                     formikProps={formikProps}
                     firstName={firstName}
                     lastName={lastName}
                     email={email}
                     unFormattedNo={unFormattedNo}
                     dob={dob}
                     gender={gender}
                     meta={meta}
                     scheduledOn={scheduledOn}
                     reasonForVisit={reasonForVisit}
                     owner={owner}
                  />
                  <ButtonWrapper>
                     <Button
                        type='button'
                        appearance='primary'
                        onClick={event => formikProps.handleSubmit(event)}
                        style={{ flexGrow: 1 }}
                        disabled={
                           formikProps.isSubmitting ||
                           !isEmpty(formikProps.errors)
                        }>
                        <Icon name='videocam' />
                        <Text style={{ marginLeft: 8 }}>Schedule</Text>
                     </Button>
                  </ButtonWrapper>
               </>
            );
         }}
      </Formik>
   );
};

export default ScheduleForm;
