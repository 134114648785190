import React from 'react';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import styled from 'styled-components';

const Wrapper = styled.div`
   ${({ marginRight }) => marginRight && 'margin-right: 12px;'}
   > *:not(:first-child) {
      margin-top: 8px;
   }
`;

const LoadingState = ({ marginRight, count = 5 }) => {
   return (
      <Wrapper marginRight={marginRight}>
         {[...Array(parseInt(count)).keys()].map(key => (
            <CardPlaceholder key={key} smallList />
         ))}
      </Wrapper>
   );
};

export default LoadingState;
