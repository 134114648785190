import React, { useEffect, useRef, Fragment, useState } from 'react';
import styled from 'styled-components';
import { format, isToday } from 'date-fns';
import Badge from '@datashop/badge';

import returnCallDuration from 'helpers/returnCallDuration';
import MoreOptionDropdown from './MoreOptionDropdown';
import returnCamelCasedName from 'helpers/nameTransformer';
import { Mixpanel } from 'helpers/mixpanelHelper';
import Text from '@datashop/text';

const DataWrapper = styled.div`
   display: flex;
`;

const ContentWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
   max-width: 100%;
`;

const PatientNameWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
`;

const StatusIcon = styled.i`
   margin: 1px 4px 0 0;
   font-size: 20px;
   color: ${({ color }) => color};
`;

const PatientName = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
`;

const TextWrapper = styled(Text)`
   display: flex;
   align-items: center;
   justify-content: center;
   ${({ isMargin = true }) => isMargin && 'margin-left: 8px'}
   border-radius: 3px;
   padding: 0 3px;
   height: 20px;
   text-transform: uppercase;
   ${({ color }) => `background-color: ${color}`}
`;

const OptionWrapper = styled.div`
   display: flex;
`;

const NotesIcon = styled.i`
   color: #f08618;
   font-size: 12px;
   margin-right: 4px;
`;

const NotesText = styled.p`
   color: #743208;
   font-weight: 600;
   line-height: 19px;
`;

const DetailsWrapper = styled.div`
   display: flex;
   flex: 1;
   margin-top: 4px;
   justify-content: space-between;
   align-items: center;
`;

const CallDetails = styled.div`
   color: #868686;
   display: flex;
   align-items: center;
   max-width: 100%;
`;

const StatusIconSpace = styled.div`
   margin: 3px 4px 0 0;
   width: 20px;
`;

const Icon = styled.i`
   margin: -2px 4px 0 0;
   font-size: 16px;
`;

const StatusText = styled.p`
   display: flex;
   font-weight: 600;
   line-height: 20px;
`;

const EllipsisWrapper = styled.div`
   display: flex;
   min-width: 0;
   font-weight: 600;
   line-height: 20px;
   cursor: pointer;
`;

const EllipsisSection = styled.span`
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width: ${({ wi }) => wi}px;
`;

const EllipsisEnd = styled.span`
   flex-shrink: 0;
`;

const Dot = styled.div`
   color: #868686;
   font-family: NunitoSans;
   font-size: 12px;
   line-height: 14px;
   text-align: left;
   margin: 0 8px;
`;

const iconStatus = {
   COMPLETED: { icon: 'done', color: '#2EA843' },
   MISSED: { icon: 'call_missed', color: '#D93737' },
   WAITING: { icon: 'call_missed', color: '#D93737' },
   NO_SHOW: { icon: 'not_interested', color: '#D93737' },
   INCOMPLETE: { icon: 'error_outline', color: '#F07D00' },
};

const trackScheduleChange = status => {
   Mixpanel.track(`Virtual visit ${status}`, {
      category: 'virtualVisit',
   });
};

function Item({ item, toggleModal, markAsTest, markInComplete }) {
   const targetName = useRef(null);
   const [nameWidth, setNameWidth] = useState(100);

   const isProvider = localStorage.getItem('access') === 'PROVIDER';

   useEffect(() => {
      if (targetName && targetName.current) {
         setNameWidth(targetName.current.offsetLeft);
      }
   }, [targetName]);

   return (
      <DataWrapper>
         <ContentWrapper>
            <PatientNameWrapper>
               <Row>
                  <StatusIcon
                     color={iconStatus[item.callStatus || 'COMPLETED'].color}
                     className='material-icons'>
                     {iconStatus[item.callStatus || 'COMPLETED'].icon}
                  </StatusIcon>
                  <PatientName>
                     {returnCamelCasedName(
                        item.patientInfo.firstName,
                        item.patientInfo.lastName
                     )}
                  </PatientName>

                  {item.isTestCall && (
                     <TextWrapper color='#FFF5C7'>TEST</TextWrapper>
                  )}
               </Row>
               <OptionWrapper>
                  {item.note && item.note.noteAdded && (
                     <Badge subtle appearance='tawak'>
                        <NotesIcon className='material-icons-outlined'>
                           assignment
                        </NotesIcon>
                        <NotesText>Notes</NotesText>
                     </Badge>
                  )}
                  <MoreOptionDropdown
                     reschedule={() => {
                        toggleModal(
                           {
                              ...item,
                              patientInfo: {
                                 ...item.patientInfo,
                                 unFormattedNo: item.patientInfo.phoneNo,
                              },
                           },
                           false
                        );
                        trackScheduleChange('rescheduled', item);
                     }}
                     markTest={() => {
                        markAsTest(item);
                     }}
                     markInComplete={() => {
                        markInComplete(item);
                     }}
                     item={item}
                  />
               </OptionWrapper>
            </PatientNameWrapper>
            <DetailsWrapper>
               <CallDetails>
                  <StatusIconSpace />
                  <Icon className='material-icons'>
                     {item.type === 'AUDIO' ? 'call' : 'videocam'}
                  </Icon>
                  {item.callDuration > 0 && (
                     <>
                        <div>
                           <StatusText style={{ width: 'max-content' }}>
                              {returnCallDuration(item.callDuration)}
                           </StatusText>
                        </div>
                        <Dot>•</Dot>
                     </>
                  )}
                  <div>
                     <StatusText style={{ width: 'max-content' }}>
                        {isToday(new Date(item.createdOn))
                           ? 'Today'
                           : format(new Date(item.createdOn), 'MM/dd/yyyy')}
                     </StatusText>
                  </div>
                  {!isProvider && item.ownerInfo.firstName && (
                     <Fragment>
                        <Dot>•</Dot>
                        <Icon className='material-icons'>person_outline</Icon>
                        <EllipsisWrapper
                           innerRef={targetName}
                           title={`${item.ownerInfo.firstName[0].toUpperCase()}${item.ownerInfo.firstName
                              .toLowerCase()
                              .slice(
                                 1
                              )} ${item.ownerInfo.lastName[0].toUpperCase()}${item.ownerInfo.lastName
                              .toLowerCase()
                              .slice(1)}`}>
                           <EllipsisSection wi={nameWidth}>
                              {`${item.ownerInfo.firstName[0].toUpperCase()}${item.ownerInfo.firstName
                                 .toLowerCase()
                                 .slice(
                                    1
                                 )} ${item.ownerInfo.lastName[0].toUpperCase()}${item.ownerInfo.lastName
                                 .toLowerCase()
                                 .slice(1)}`}
                           </EllipsisSection>
                           <EllipsisEnd />
                        </EllipsisWrapper>
                     </Fragment>
                  )}
               </CallDetails>
            </DetailsWrapper>
         </ContentWrapper>
      </DataWrapper>
   );
}

export default Item;
