import { useCallback, useEffect, useReducer } from 'react';
import axios from 'helpers/axios';
import { debounce } from 'lodash';
import update from 'immutability-helper';

const initialOffset = 0;
const pageSize = 20;

const initialState = {
   loading: true,
   error: false,
   data: [],
   search: '',
   offset: initialOffset,
   loadingMore: false,
   endReached: false,
   isSearchDirty: false,
   isFirstLoad: true,
};

const reducer = (state, action) => {
   switch (action.type) {
      case 'FETCH':
         return update(state, {
            loading: {
               $set: true,
            },
         });

      case 'ERROR':
         return update(state, {
            loading: {
               $set: false,
            },
            error: {
               $set: true,
            },
            loadingMore: {
               $set: false,
            },
         });

      case 'FETCH_MORE_DATA':
         return update(state, {
            loadingMore: {
               $set: true,
            },
         });

      case 'FETCH_SUCCESS':
         return update(state, {
            loading: {
               $set: false,
            },
            error: {
               $set: false,
            },
            loadingMore: {
               $set: false,
            },
            isFirstLoad: {
               $set: false,
            },
            endReached: {
               $set: action.payload.data.length < pageSize,
            },
            data: {
               $set: action.meta.shouldAppend
                  ? [...state.data, ...action.payload.data]
                  : action.payload.data,
            },
         });

      case 'FETCH_DONE':
         return update(state, {
            loading: {
               $set: false,
            },
            loadingMore: {
               $set: false,
            },
         });

      case 'FETCH_MORE':
         return update(state, {
            loadingMore: {
               $set: true,
            },
         });

      case 'DONE_FIRST_LOAD':
         return update(state, {
            isFirstLoad: {
               $set: false,
            },
         });

      case 'CHANGE_SEARCH':
         return update(state, {
            offset: {
               $set: initialOffset,
            },
            endReached: {
               $set: false,
            },
            isSearchDirty: {
               $set: true,
            },
            search: {
               $set: action.payload.search,
            },
         });

      case 'INCREASE_OFFSET':
         return update(state, {
            offset: {
               $set: state.offset + pageSize,
            },
         });

      case 'END_REACHED':
         return update(state, {
            endReached: {
               $set: true,
            },
         });

      default:
         return state;
   }
};

const useInfiniteScroll = ({ apiUrl = '', searchParamKey = 'search-term' }) => {
   const [state, dispatch] = useReducer(reducer, initialState);
   const { search, offset, endReached, isSearchDirty, isFirstLoad } = state;

   const getData = useCallback(
      (shouldAppend = true) => {
         axios
            .get(
               `${apiUrl}?${
                  search ? `${searchParamKey}=${search}&` : ''
               }size=${pageSize}&offset=${offset}`
            )
            .then(({ data: { results } }) => {
               dispatch({
                  type: 'FETCH_SUCCESS',
                  payload: {
                     data: results,
                  },
                  meta: {
                     shouldAppend,
                  },
               });
            })
            .catch(() => {
               dispatch({ type: 'ERROR' });
            })
            .finally(() => {
               dispatch({ type: 'FETCH_DONE' });
            });
      },
      [apiUrl, searchParamKey, search, offset, dispatch]
   );

   useEffect(() => {
      dispatch({ type: 'FETCH' });

      getData(false);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (offset > 0) {
         dispatch({ type: 'FETCH_MORE' });

         getData();
      }
   }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

   const increaseOffset = () => {
      if (!endReached) {
         dispatch({
            type: 'INCREASE_OFFSET',
         });
      }
   };

   const debounceSearch = debounce(() => {
      if (isSearchDirty) {
         getData(false, true);
      }
   }, 300);

   useEffect(() => {
      if (!isFirstLoad) {
         dispatch({ type: 'FETCH' });
         debounceSearch();
      }

      return () => {
         debounceSearch.cancel();
      };
   }, [search, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleSearchChange = e => {
      const value = e.target.value;
      dispatch({
         type: 'CHANGE_SEARCH',
         payload: {
            search: value,
         },
      });
   };

   return { state, handleSearchChange, increaseOffset };
};

export default useInfiniteScroll;
