import React, { Component } from 'react';
import styled from 'styled-components';
import PatientInsuranceDetails from './PatientInsurance';
import ConsentForm from './ConsentForm';
import { Modal, motion } from '@krishnaxv/react-surface';
import TermsOfService from './TermsOfService';
import DisclosureStatement from './DisclosureStatement';
import PrivacyNotice from './PrivacyNotice';
import { Mixpanel } from 'helpers/mixpanelHelper';
import { Loader } from 'packages/loader';
import VisitStatus from './VisitStatus';
import axios from 'helpers/axios';
import VisitorComponent from './VisitorComponent';
import uuidv4 from 'utils/uuidGenerator';
import Payments from './payments';
import { setItem, getItem } from 'helpers/localStore';
import Verify from 'components/VerifyOtp';

const modalStyle = {
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
   backgroundColor: '#fff',
};

const Wrapper = styled.div`
   > div {
      right: 0;
      margin: 0 auto;
   }
`;

const ModalWrapper = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
`;

const ContentWrapper = styled.div`
   overflow: auto;
   flex: 1;
   background-color: #f4f4f4;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const ModalHeaderWrapper = styled.div`
   display: flex;
   padding: 48px 12px 12px;
   align-items: flex-start;
   background-color: #fff;
`;

const ModalHeader = styled.div`
   color: #000000;
   font-size: 22px;
   font-weight: 500;
   line-height: 28px;
`;

const BackButton = styled.div`
   color: #000000;
   font-size: 24px;
   margin: 2px 16px 0 0;
   cursor: pointer;
`;

const LoaderWrapper = styled.div`
   margin: auto !important;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   height: 20px;
   position: fixed;
`;

const Components = [
   {
      header: 'Consent, Use and Disclosure Statement',
      component: DisclosureStatement,
   },
   { header: 'Privacy Practices Notice', component: PrivacyNotice },
   { header: 'Terms of Service', component: TermsOfService },
];

class PatientInsuranceContainer extends Component {
   state = {
      sendToPatientInsurance: false,
      scheduleId: null,
      organization: '',
      isLoading: true,
      message: '',
      messageValidate: '',
      error: false,
      openContentNumber: null,
      type: '',
      title: '',
      visitorName: '',
      firstName: '',
      lastName: '',
      getVisitorInfo: false,
      subCode: null,
      patientId: '',
      showVerifyScreen: false,
   };

   componentWillMount() {
      const params = new URLSearchParams(this.props.location.search);
      const userId = params.get('userId');
      const participantId = params.get('participantId');
      const scheduleId = params.get('scheduleId');
      const type = params.get('participantType');
      const organization = params.get('organization');
      const firstName = params.get('firstName');
      const lastName = params.get('lastName');
      const visitId = params.get('visitId');
      const patientId = params.get('patientId');

      this.setState({
         userId,
         participantId,
         scheduleId,
         organization,
         type,
         firstName,
         lastName,
         visitId,
         patientId,
      });
   }

   checkValidate = () => {
      axios
         .get('/innote-survey/telehealth/video/schedule/_validate', {
            params: {
               visit_id: this.state.visitId,
               participant_type: this.state.type,
               name: this.state.visitorName,
               schedule_id: this.state.scheduleId,
               session_id: this.state.sessionId
                  ? this.state.sessionId.split('_')[0]
                  : '',
               participant_id: this.state.participantId,
            },
         })
         .then(
            ({
               data: {
                  socketToken,
                  paymentInfo,
                  collectPayment,
                  message,
                  visitorDetails,
               },
            }) => {
               this.setState({
                  socketToken,
                  isLoading: false,
                  paymentInfo,
                  collectPayment,
                  messageValidate: message,
                  visitorName: (visitorDetails && visitorDetails.name) || '',
               });
               const consentGiven = localStorage.getItem('consentGiven');
               // If participant is not a visitor
               if (this.state.type !== 'VISITOR') {
                  // If consent form has been acceptepted for the same scheduleId send to next screen
                  if (consentGiven && consentGiven === this.state.scheduleId)
                     this.setState({ sendToPatientInsurance: true });
               } else {
                  // Otherwise check is visitor has refreshed page and had a previous session
                  const sessionId = getItem('visitorId');
                  if (sessionId) {
                     this.setState({
                        sendToPatientInsurance: true,
                        sessionId,
                     });
                  }
               }
            }
         )
         .catch(
            ({
               response: {
                  data: {
                     error: {
                        message = 'There seems to be a problem connecting to the server try again later.',
                        title = 'Something went wrong!',
                        subCode = 0,
                     } = {},
                     statusCode,
                  },
               },
            }) => {
               this.setState({
                  isLoading: false,
                  error: true,
                  message,
                  title,
                  statusCode,
                  subCode,
               });
            }
         );
   };

   componentDidMount() {
      if (this.state.participantId) {
         this.checkValidate();
      } else {
         this.setState({ getVisitorInfo: true, isLoading: false });
      }
   }

   closePaymentFlow = () => {
      this.setState({ showPaymentFlow: false });
   };

   sendToPatientInsurance = bypassPayment => {
      const { paymentInfo, collectPayment } = this.state;
      Mixpanel.track(
         `${
            this.state.type === 'VISITOR' ? 'Visitor' : 'Patient'
         } - consent provided`,
         { category: 'login' }
      );
      if (!bypassPayment && !paymentInfo && collectPayment) {
         this.setState({ showPaymentFlow: true, showVerifyScreen: true });
      } else {
         this.setState({ sendToPatientInsurance: true });
      }
   };

   seeContent = number => {
      this.setState({
         openContent: true,
         openContentNumber: number,
         openContentComponent: Components[number].component,
      });
   };

   closeModal = () => {
      this.setState({ openContent: false });
   };

   saveVisitorInfo = name => {
      // cameCase the name
      const newName = name
         .split(' ')
         .map(item => `${item[0].toUpperCase()}${item.slice(1).toLowerCase()}`);
      // Create a session id
      let sessionId = `${uuidv4()}_${newName.join('+')}`;
      if (getItem('visitorId')) {
         sessionId = getItem('visitorId');
      } else {
         setItem('visitorId', sessionId);
      }
      Mixpanel.track('Visitor - name entered', { category: 'login' });
      this.setState(
         {
            visitorName: name,
            getVisitorInfo: false,
            sessionId,
            isLoading: true,
         },
         this.checkValidate
      );
   };

   resetDetails = () => {
      this.setState({ showPaymentFlow: false, showVerifyScreen: false });
   };

   render() {
      const {
         title,
         message,
         subCode,
         sendToPatientInsurance,
         socketToken,
         userId,
         participantId,
         scheduleId,
         isLoading,
         openContent,
         openContentNumber,
         organization,
         getVisitorInfo,
         showPaymentFlow,
         showVerifyScreen,
         type,
         firstName,
         lastName,
         visitId,
         sessionId,
         visitorName,
         error,
         patientId,
         messageValidate,
      } = this.state;
      if (sendToPatientInsurance) {
         return (
            <PatientInsuranceDetails
               visitId={visitId}
               type={type}
               messageValidate={messageValidate}
               sessionId={sessionId}
               visitorName={visitorName}
               socketToken={socketToken}
               scheduleId={scheduleId}
               participantId={type === 'patient' ? patientId : participantId}
            />
         );
      }
      if (showPaymentFlow) {
         return showVerifyScreen ? (
            <Verify
               email={''}
               phone={participantId}
               patientId={patientId}
               resetDetails={() =>
                  this.setState({
                     showPaymentFlow: false,
                     showVerifyScreen: false,
                  })
               }
               onSuccess={() =>
                  this.setState({
                     showVerifyScreen: false,
                  })
               }
               isPatient={type === 'PATIENT'}
            />
         ) : (
            <Payments
               onDismiss={this.closePaymentFlow}
               onComplete={() => this.sendToPatientInsurance(true)}
               scheduleId={scheduleId}
               visitId={visitId}
               userDetails={{
                  userId,
                  firstName,
                  lastName,
                  participantId: type === 'patient' ? patientId : participantId,
               }}
            />
         );
      }
      if (getVisitorInfo)
         return (
            <VisitorComponent
               firstName={firstName}
               lastName={lastName}
               saveVisitorInfo={this.saveVisitorInfo}
            />
         );
      return (
         <Wrapper>
            {isLoading ? (
               <LoaderWrapper>
                  <Loader />
               </LoaderWrapper>
            ) : error ? (
               <VisitStatus title={title} message={message} status={subCode} />
            ) : openContent ? (
               <Modal
                  motion={motion.slideInBottom}
                  style={modalStyle}
                  onClose={this.closeModal}>
                  {onCloseModal => (
                     <ModalWrapper>
                        <ModalHeaderWrapper>
                           <BackButton
                              className='material-icons'
                              onClick={this.closeModal}>
                              arrow_back
                           </BackButton>
                           <ModalHeader>
                              {Components[openContentNumber].header}
                           </ModalHeader>
                        </ModalHeaderWrapper>
                        <ContentWrapper>
                           <this.state.openContentComponent />
                        </ContentWrapper>
                     </ModalWrapper>
                  )}
               </Modal>
            ) : (
               <ConsentForm
                  organization={organization}
                  acceptAndContinue={() => this.sendToPatientInsurance(false)}
                  seeContent={this.seeContent}
               />
            )}
         </Wrapper>
      );
   }
}

export default PatientInsuranceContainer;
