import Axios from 'axios';
import history from './../history';
import { Mixpanel } from './mixpanelHelper';
import createToast from './toastHelper';

const CancelToken = Axios.CancelToken;
const isCancel = Axios.isCancel;

const patientOutreachUrl = window.location.href.includes('covid')
   ? 'https://dif7wpsznb.execute-api.us-east-2.amazonaws.com/prod'
   : 'https://17y6kj10e4.execute-api.us-east-2.amazonaws.com/preprod';

const URL = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_API_URL`];
const INNOTEURL =
   process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_INNOTE_URL`];
const INNOTE_ATTACHMENT_URL =
   process.env[
      `REACT_APP_${process.env.REACT_APP_BUILD}_INNOTE_ATTACHMENT_URL`
   ];

const axios = Axios.create({
   baseURL: URL,
   headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'X-TIMEZONE-OFFSET': new Date().getTimezoneOffset(),
   },
});

const axiosInNote = Axios.create({
   baseURL: INNOTEURL,
   headers: {
      Authorization: `${localStorage.getItem('token')}`,
      userType: 'provider',
   },
});

const axiosAttachment = Axios.create({
   baseURL: INNOTE_ATTACHMENT_URL,
   headers: {
      Authorization: `${localStorage.getItem('token')}`,
      userType: 'provider',
   },
});

const addInterceptors = instance => {
   instance.interceptors.response.use(null, error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Check if error has a response object
      if (error.response) {
         // Show error toast
         const {
            response: {
               data: { error: { message = 'Something went wrong' } = {} },
            },
         } = error;
         // Check for login API
         if (error.response.config.url.includes('user/auth/_validate')) {
            // Login API returns 401 on wrong attempt
            if (error.response.status === 401) {
               Mixpanel.track('Incorrect OTP', {
                  category: 'login',
                  'Error Api': error.response.config.url,
               });
            }
         } else if (
            !error.response.config.url.includes('user/track') &&
            error.response.status === 401
         ) {
            // Check if a API gives unauthorized status
            Mixpanel.track('Unauthorized Access', {
               category: 'login',
               Url: error.response.config.url,
            });
            createToast({ message });
            localStorage.clear();
            localStorage.setItem('isOnboarded', true);
            history.push('/signup');
         } else if (error.response.status === 404) {
            // Check if a API route was not found
            Mixpanel.track('Api Not Found', {
               category: 'browser',
               Url: error.response.config.url,
            });
         }
      }
      return Promise.reject(error);
   });
};

export const addAuthorizationHeader = (instance, token) => {
   instance.defaults.headers['Authorization'] = token;
};

export const addTokenToAllInstances = token => {
   addAuthorizationHeader(axios, token);
   addAuthorizationHeader(axiosInNote, token);
   addAuthorizationHeader(axiosAttachment, token);
};

addInterceptors(axios);
addInterceptors(axiosInNote);
addInterceptors(axiosAttachment);

export default axios;
export {
   CancelToken,
   isCancel,
   patientOutreachUrl,
   axiosInNote,
   axiosAttachment,
};
