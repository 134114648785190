import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as S from 'components/Messages/style';
import Input from '@datashop/input';
import MyInBox from 'components/Messages/MyInBox';
import NewChatSelect from 'components/Messages/NewChatSelect';
import { changeChat } from 'store/frontDoorSocket/action';
import { Mixpanel } from 'helpers/mixpanelHelper';

let timeout = undefined;
const Messages = ({
   isChatOpen,
   getMyInbox,
   changeChat,
   attributionEnabled,
}) => {
   const [search, setSearch] = useState('');
   const [isAdvance, setIsAdvance] = useState(false);
   const [selectedInbox, setSelectedInbox] = useState('');

   useEffect(() => {
      Mixpanel.track('Messages tab clicked', {
         category: 'chat',
      });
   }, []);

   const debounce = e => {
      setSearch(e.target.value);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
         Mixpanel.track('Search', { category: 'chat' });
      }, 500);
   };

   const openChat = item => {
      changeChat(true);
      setSelectedInbox(item.inbox_id);
   };

   const advanceToggle = () => {
      setIsAdvance(!isAdvance);
   };

   if (isChatOpen && selectedInbox) {
      return (
         <Redirect
            to={{
               pathname: `/messages/${selectedInbox}`,
            }}
         />
      );
   }

   return (
      <S.Wrapper>
         <S.BodyWrapper>
            <S.SearchWrapper>
               {!isAdvance && (
                  <>
                     <S.InputWrapper isAdvance={isAdvance}>
                        <Input
                           icon='search'
                           value={search}
                           name='search'
                           placeholder='Search for chats by patient name'
                           onChange={debounce}
                        />
                     </S.InputWrapper>
                     <S.ButtonWrapper
                        dimension='regular'
                        onClick={() => {
                           advanceToggle();
                           Mixpanel.track('New chat added', {
                              category: 'chat',
                           });
                        }}>
                        <i className='material-icons'>add</i>
                     </S.ButtonWrapper>
                  </>
               )}
            </S.SearchWrapper>
            <S.MyPatientWrapper>
               {isAdvance ? (
                  <NewChatSelect
                     attributionEnabled={attributionEnabled}
                     advanceToggle={advanceToggle}
                     openChat={openChat}
                  />
               ) : (
                  <MyInBox
                     search={search}
                     getMyInbox={getMyInbox}
                     advanceClick={advanceToggle}
                     openChat={openChat}
                  />
               )}
            </S.MyPatientWrapper>
         </S.BodyWrapper>
      </S.Wrapper>
   );
};

const mapStateToProps = ({
   frontDoorSocket: { isChatOpen, attributionEnabled },
}) => ({
   isChatOpen,
   attributionEnabled,
});

const mapDispatchToProps = { changeChat };

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
