import React from 'react';
import Toast from '@datashop/toast';
import styled from 'styled-components';

const Wrapper = styled.div`
   div {
      z-index: 10000000;
   }
`;

const AxiosErrorToast = ({
   title,
   message,
   appearance,
   close,
   button: { text, function: onClick },
}) => {
   return (
      <Wrapper>
         <Toast
            title={title}
            message={message}
            appearance={appearance}
            onDismissClick={close}>
            {text && (
               <Toast.Button
                  onClick={() => {
                     onClick();
                     close();
                  }}>
                  {text}
               </Toast.Button>
            )}
         </Toast>
      </Wrapper>
   );
};

export default AxiosErrorToast;
