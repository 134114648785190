import React from 'react';
import * as S from './style';

const MessageStatus = ({ status, ticks = {} }) => {
   if (status) {
      return (
         <>
            <S.SpaceDot />
            <S.TimeWrapper>{status}</S.TimeWrapper>
         </>
      );
   }

   if (ticks.blue && ticks.grey) {
      return (
         <>
            <S.SpaceDot />
            <S.TimeWrapper>Read</S.TimeWrapper>
         </>
      );
   }

   return (
      <>
         <S.SpaceDot />
         <S.TimeWrapper>Sent</S.TimeWrapper>
      </>
   );
};

export default MessageStatus;
