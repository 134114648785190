import React, { FC } from 'react';
import styled from 'styled-components';
import Modal from '@datashop/modal';
import Button from '@datashop/button';

const Dialog = styled.div`
   padding: 16px;
   background: #fff;
   border-radius: 8px;
   text-align: center;
`;

const Smiley = styled.i`
   display: inline-block;
   font-size: 80px;
   color: #57a944;
   margin: 24px;
`;

const Heading = styled.h3`
   font-size: 20px;
   font-weight: 600;
   margin: 6px 0;
`;

const Text = styled.p`
   font-size: 14px;
   font-weight: normal;
   color: #868686;
   margin-bottom: 24px;
`;

const TextButton = styled(Button)`
   display: inline-block;
   border: none;
   background: none;
   margin: 16px 0 12px 0;
   font-weight: 600;

   &:hover {
      border: none;
      background: none;
   }

   :focus {
      outline: none;
   }
`;

const modalStyle = {
   width: 328,
};

interface Props {
   onUpdate: () => void;
   onRemindLater: () => void;
}

const UpdateDialog: FC<Props> = ({ onUpdate, onRemindLater }) => {
   return (
      <Modal
         style={modalStyle}
         dimension='regular'
         backdrop
         showCloseButton={false}>
         <Dialog>
            <Smiley className='material-icons'>mood</Smiley>
            <Heading>We're Better than Ever</Heading>
            <Text>Please update to the better version of InNote</Text>
            <Button expanded appearance='primary' onClick={onUpdate}>
               Update
            </Button>
            <TextButton onClick={onRemindLater}>Remind me later</TextButton>
         </Dialog>
         {/** ? Next line required to fix type error from Modal. Modal expects it children prop to be an array of elements */}
         <></>
      </Modal>
   );
};

export default UpdateDialog;
