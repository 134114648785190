import createToast from 'helpers/toastHelper';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 40px;
   width: 32px;
   margin-right: 12px;
   cursor: pointer;
   ${({ disabled }) => (disabled ? 'opacity: 0.5' : '')}

   i {
      font-size: 18px;
   }
`;

export const ALLOWED_MIME_TYPES = [
   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
   'application/msword',
   'application/pdf',
   'application/vnd.ms-powerpoint',
   'application/octet-stream',
   'application/vnd.oasis.opendocument.text',
   'text/plain',
   'image/png',
   'image/jpeg',
   'image/gif',
   'audio/ogg',
   'audio/mpeg',
   'video/mp4',
   'video/mpeg',
   'video/webm',
   'video/x-matroska',
];

const Attach = ({ onChange, disabled }) => {
   const handleChange = e => {
      e.preventDefault();

      const file = e.target.files[0];
      if (file) {
         if (file.size > 104857600) {
            createToast({
               message:
                  'File size limit exceeded. Make sure the file size is less than 100MB.',
            });
         } else {
            onChange(file);
         }
         e.target.value = '';
      }
   };

   return (
      <Wrapper disabled={disabled}>
         <input
            type='file'
            hidden
            onChange={handleChange}
            disabled={disabled}
            accept={ALLOWED_MIME_TYPES.join(',')}
         />
         <i className='material-icons'>attach_file</i>
      </Wrapper>
   );
};

export default Attach;
