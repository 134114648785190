import React, { Fragment } from 'react';
import styled from 'styled-components';

import Input from '@datashop/input';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import axios from 'helpers/axios';
import PUIForm from 'components/EvaluatePatient/PUIForm';
import { Mixpanel } from 'helpers/mixpanelHelper';
import SingleSelectQuestion from 'components/Questions/SingleSelectQuestion';
import MultiSelectQuestion from 'components/Questions/MultiSelectQuestion';
import ViewResponse from './ViewResponse';
import TextInputQuestion from 'components/Questions/TextInputQuestion';

const Wrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const Header = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: bold;
   line-height: 24px;
`;

const HeaderHelpText = styled.p`
   margin-top: 4px;
   color: #868686;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;

const SurveyWrapper = styled.ul`
   margin-top: 8px;
`;

const SurveyItem = styled.li`
   padding: 8px 0 12px;
   border-bottom: 1px solid #e5e5e5;

   :last-child {
      border-bottom: none;
      padding-bottom: 0;
   }
`;

const ViewResponseWrapper = styled.div`
   padding: 16px;
   background-color: #fff;
`;

const Item = styled.div``;

const Question = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 20px;
`;

const LoaderWrapper = styled.div`
   display: flex;
   flex: 1;
   justify-content: space-evenly;
   flex-direction: column;
`;

export default class Survey extends React.Component {
   state = {
      isLoading: true,
      covidAssessment: {
         data: [],
      },
      puiAssessment: {
         data: [],
      },
      dependentSurveyList: [],
   };

   componentDidMount() {
      axios
         .get(
            `/innote-survey/assessments/${
               this.props.showResult
                  ? this.props.assessmentId
                  : '5e7b840c98c5bf3ad547f6a2'
            }/${
               this.props.showResult || this.props.covidaSubmitted
                  ? `responses/${this.props.responseId}/`
                  : ''
            }_manage`
         )
         .then(
            ({
               data: {
                  data: { surveyList, dependentSurveyList = [] },
               },
            }) => {
               this.props.finishLoading();
               this.setState({ dependentSurveyList });
               if (this.props.covidResponse) {
                  this.setState({ covidAssessment: this.props.covidResponse });
                  this.checkIfCompleted('covidAssessment');
               } else {
                  this.setState({ covidAssessment: { data: surveyList } });
                  let stateObject = {};
                  let newList = [...surveyList, ...dependentSurveyList];
                  newList.forEach(item => {
                     if (!!item.userResponse) {
                        stateObject[item.id] = item.userResponse;
                     }
                  });
                  if (Object.keys(stateObject).length > 0) {
                     this.setState(
                        prevState => {
                           let state = { ...prevState };
                           state.covidAssessment = {
                              ...state.covidAssessment,
                              ...stateObject,
                              data: surveyList,
                           };
                           return state;
                        },
                        () => {
                           this.checkIfCompleted('covidAssessment');
                        }
                     );
                  }
               }
               // Get PUI assessment
               axios
                  .get(
                     `/innote-survey/assessments/5e70364798c5bf3ad543dd99/${
                        this.props.isPuiFilled
                           ? `responses/${this.props.responseId}/`
                           : ''
                     }${this.props.isPuiFilled ? '_manage' : '_fetch'}`
                  )
                  .then(
                     ({
                        data: {
                           data: { surveyList },
                        },
                     }) => {
                        this.setState({ puiAssessment: { data: surveyList } });
                        let stateObject = {};
                        surveyList.forEach(item => {
                           if (typeof item.userResponse !== 'undefined') {
                              stateObject[item.id] = item.userResponse;
                           }
                        });
                        if (Object.keys(stateObject).length > 0) {
                           this.setState(
                              prevState => {
                                 let state = { ...prevState };
                                 state.puiAssessment = {
                                    ...state.puiAssessment,
                                    ...stateObject,
                                    data: surveyList,
                                 };
                                 return state;
                              },
                              () => {
                                 this.checkIfCompleted('puiAssessment');
                              }
                           );
                        }
                     }
                  );
            }
         )
         .finally(() => {
            this.setState({ isLoading: false });
         });
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.surveyList.length !== this.props.surveyList.length) {
         this.setState(prevState => {
            const state = { ...prevState };
            state.covidAssessment.data = this.props.surveyList;
            state.dependentSurveyList = this.props.dependentSurveyList;
            return state;
         });
      }
   }

   onChange = event => {
      let type;
      const {
         target: { name, value },
      } = event;
      if (type === 'covidAssessment') {
         this.props.saveCovidResponse();
      }
      this.setState(
         () => ({ [name]: value }),
         () => {
            this.checkIfCompleted('covidAssessment');
         }
      );
   };

   answerQuestion = (ind, id, answer, type = 'covidAssessment') => {
      Mixpanel.track(
         `${type === 'covidAssessment' ? 'Step 2' : 'Step 3'} - Question ${
            ind + 1
         } completed`,
         {
            category: 'conductAssessment',
         }
      );
      const state = { ...this.state };
      if (type === 'covidAssessment') {
         //  Check if answer has a child dependency
         const item = this.state.covidAssessment.data.find(
            item => item.id === id
         );
         if (item) {
            // Check if we have to check for child dependency
            if (item.answer.choiceList) {
               // Check if one of the choices have a child depencdency
               const choiceChild = item.answer.choiceList.find(
                  cItem => cItem.childId
               );
               if (choiceChild) {
                  // Check if selected answer have a child dependency
                  const dependencyItem = item.answer.choiceList.find(
                     cItem => cItem.id === answer
                  );
                  // Check if the clicked option isn't the one which contains a child id
                  if (dependencyItem && !dependencyItem.childId) {
                     delete state[type][choiceChild.childId];
                  }
               }
            }
         }
         this.setState(
            prevState => {
               state[type][id] = answer;
               // let index = state[type].data.findIndex(item => item.id === id);
               // state[type].data[index].userResponse = answer;
               return { state };
            },
            () => {
               if (type === 'covidAssessment')
                  this.checkIfCompleted('covidAssessment');
            }
         );
      } else {
         this.setState(prevState => {
            const state = { ...prevState };
            state[type][id] = answer;
            let index = state[type].data.findIndex(item => item.id === id);
            state[type].data[index].userResponse = answer;
            return { state };
         });
      }
   };

   checkIfCompleted = assessment => {
      let state = { ...this.state[assessment] };
      delete state.data;
      let stateCopy = { ...state };
      let dependentQuestionNeeded = 0;
      if ('covidAssessment' === assessment) {
         // Get no of Child question that need to be answered
         dependentQuestionNeeded = this.state.covidAssessment.data.filter(
            item => {
               if (
                  this.state.covidAssessment[item.id] &&
                  item.answer.choiceList &&
                  item.answer.choiceList.find(
                     cItem =>
                        cItem.id === this.state.covidAssessment[item.id] &&
                        cItem.childId
                  )
               ) {
                  return item;
               } else {
                  return false;
               }
            }
         );
         // Update the state list
         state = Object.keys(state).filter(item => {
            if (
               this.state.covidAssessment.data.find(
                  cItem => cItem.id === item
               ) ||
               dependentQuestionNeeded.find(dItem => dItem.id === item)
            ) {
               return item;
            } else {
               return false;
            }
         });
      }
      if (
         Object.keys(stateCopy).length ===
            dependentQuestionNeeded.length +
               this.state.covidAssessment.data.length &&
         !Object.values(stateCopy).some(item => !item.length)
      ) {
         this.props.finishSurvey(
            {
               answerMap: {
                  ...stateCopy,
               },
            },
            assessment
         );
      } else {
         if (assessment === 'covidAssessment') this.props.disableNext();
      }
   };

   getPuiPayload = payload => {
      this.props.finishSurvey(payload, 'puiAssessment');
   };

   componentWillUnmount() {
      this.props.saveCovidResponse(this.state.covidAssessment);
   }

   render() {
      return (
         <Wrapper>
            {this.state.isLoading ? (
               <LoaderWrapper>
                  <CardPlaceholder />
                  <CardPlaceholder />
                  <CardPlaceholder />
               </LoaderWrapper>
            ) : +this.props.currentStep === 2 ? (
               this.props.showResult || this.props.covidaSubmitted ? (
                  <ViewResponse
                     dependentQuestionList={this.state.dependentSurveyList}
                     questionList={this.state.covidAssessment.data}
                  />
               ) : (
                  <Fragment>
                     <Header>Questions</Header>
                     <HeaderHelpText>
                        All questions must be answered unless specified
                     </HeaderHelpText>
                     <SurveyWrapper>
                        {this.state.covidAssessment.data.map((item, ind) => {
                           return (
                              <SurveyItem key={item.id}>
                                 <Item>
                                    <Question>{item.question}</Question>
                                    {item.type === 'SINGLECHOICE' && (
                                       <SingleSelectQuestion
                                          providerFlow
                                          question={item}
                                          dependentSurveyList={
                                             this.state.dependentSurveyList
                                          }
                                          answerMap={this.state.covidAssessment}
                                          response={
                                             this.state.covidAssessment[item.id]
                                          }
                                          id={item.id}
                                          onChange={(...op) =>
                                             this.answerQuestion(ind, ...op)
                                          }
                                       />
                                    )}
                                    {item.type === 'MULTIPLECHOICE' && (
                                       <MultiSelectQuestion
                                          providerFlow
                                          question={item}
                                          dependentSurveyList={
                                             this.state.dependentSurveyList
                                          }
                                          answerMap={this.state.covidAssessment}
                                          response={
                                             this.state.covidAssessment[item.id]
                                          }
                                          id={item.id}
                                          onChange={(...op) =>
                                             this.answerQuestion(ind, ...op)
                                          }
                                       />
                                    )}
                                    {item.type === 'TEXTINPUT' && (
                                       <TextInputQuestion
                                          providerFlow
                                          question={item}
                                          dependentSurveyList={
                                             this.state.dependentSurveyList
                                          }
                                          answerMap={this.state.covidAssessment}
                                          response={
                                             this.state.covidAssessment[item.id]
                                          }
                                          id={item.id}
                                          onChange={(...op) =>
                                             this.answerQuestion(ind, ...op)
                                          }
                                       />
                                    )}
                                    {item.type === 'RANGE' && (
                                       <Input
                                          value={this.state[item.id]}
                                          name={item.id}
                                          onChange={this.onChange}
                                          type='number'
                                       />
                                    )}
                                 </Item>
                              </SurveyItem>
                           );
                        })}
                     </SurveyWrapper>
                  </Fragment>
               )
            ) : this.props.isPuiFilled ? (
               <ViewResponseWrapper>
                  <ViewResponse questionList={this.state.puiAssessment.data} />
               </ViewResponseWrapper>
            ) : (
               <Fragment>
                  {/* Pui Assessment */}
                  <PUIForm
                     answerQuestion={this.answerQuestion}
                     fromPui
                     getPuiPayload={this.getPuiPayload}
                     disableNext={this.props.disableNext}
                     responseId={this.props.responseId}
                     data={this.state.puiAssessment.data}
                  />
               </Fragment>
            )}
         </Wrapper>
      );
   }
}
