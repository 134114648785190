import React, { Fragment } from 'react';
import { Loader } from '../../packages/loader';
import * as S from './style';

/**
 * Help Articles component
 * @param {Array} articlesList List of articles
 * @param {boolean} isLoading Is loading
 * @param {Function} openArticle To open a particular article
 * @param {Function} back Handles back icon click
 * @param {string} sectionName Name of section
 */
const HelpArticles = ({
   articles: { articlesList: list, isLoading },
   openArticle,
   back,
   close,
   sectionName,
}) => (
   <Fragment>
      {isLoading ? (
         <S.LoaderWrapper>
            <Loader />
         </S.LoaderWrapper>
      ) : (
         <S.Wrapper color={list.length > 0 ? '#f8f8f8' : '#fff'}>
            <S.SectionWrapper>
               <S.HeaderWrapper>
                  <S.HeaderContainer>
                     <S.Icon
                        name='arrow_back'
                        className='cursor-pointer'
                        size={32}
                        onClick={back}
                     />
                     <S.Header>{sectionName}</S.Header>
                     <S.Icon
                        name='close'
                        className='cursor-pointer'
                        size={24}
                        onClick={close}
                     />
                  </S.HeaderContainer>
               </S.HeaderWrapper>
               <S.ContentWrapper style={{ paddingTop: 32 }}>
                  <S.ListWrapper>
                     {list.map(item => (
                        <S.ListItem
                           key={item.articleID}
                           onClick={() => openArticle(item)}>
                           <S.Description>{item.name}</S.Description>
                           <S.Icon
                              name='keyboard_arrow_right'
                              className='icon-hover ml-4'
                              size={16}
                              appearance='subtle'
                           />
                        </S.ListItem>
                     ))}
                  </S.ListWrapper>
               </S.ContentWrapper>
            </S.SectionWrapper>
         </S.Wrapper>
      )}
   </Fragment>
);

export default HelpArticles;
