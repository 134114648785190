import axios, { AxiosResponse } from 'axios';

import isElectron from 'utils/isElectron';
import packageJson from '../../package.json';
interface UpdateMeta {
   version: string;
   forceUpdate: boolean;
   buildUpdate: boolean;
   shallowUpdate: boolean;
   feedUrl: string;
}

interface UpdateSchema {
   isUpdateAvailable: boolean;
   forceUpdate: boolean;
   buildUpdate: boolean;
   shallowUpdate: boolean;
}

const parseVersion = (versionString: string): number => {
   const parsedVersionSting = `${versionString}`
      .split('-')[0]
      .split('.')
      .map(v => (v.length === 1 ? `0${v}` : v))
      .join('');
   return parseInt(parsedVersionSting, 10);
};

export const checkForUpdates = async (): Promise<
   | {
        error: boolean;
     }
   | UpdateSchema
> => {
   try {
      if (isElectron()) {
         const { data }: AxiosResponse<UpdateMeta> = await axios({
            url:
               'https://innote-releases.s3.amazonaws.com/Covid-19/version.json',
            method: 'GET',
         });
         const { ipcRenderer } = window.require('electron');

         const runningVersion = parseVersion(packageJson.version);
         const latestVersion = parseVersion(data.version);
         if (latestVersion > runningVersion) {
            const { forceUpdate, shallowUpdate, buildUpdate, feedUrl } = data;
            if (data.buildUpdate) {
               ipcRenderer.send('setFeedUrl', feedUrl);
            }

            return {
               isUpdateAvailable: true,
               forceUpdate,
               shallowUpdate,
               buildUpdate,
            };
         }
      }

      return await {
         isUpdateAvailable: false,
         forceUpdate: false,
         shallowUpdate: false,
         buildUpdate: false,
      };
   } catch (err) {
      console.error(err);
      return await {
         error: true,
      };
   }
};

export const requestUpdate = (): void => {
   if (isElectron()) {
      const { ipcRenderer } = window.require('electron');

      ipcRenderer.send('checkForUpdate');
   }
};
