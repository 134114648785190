import React, { Component } from 'react';
import { FlexBox, StyledCard } from './commons';
import Text from '@datashop/text';
import styled from 'styled-components';
import Radio from '@datashop/radio';
import Input from '@datashop/input';
import {
   CardNumberElement,
   CardExpiryElement,
   CardCvcElement,
} from '@stripe/react-stripe-js';
import theme from '@datashop/theme';

const InputRow = styled.div`
   display: flex;
   margin-bottom: 16px;
   font-family: inherit;
   > * {
      width: 100%;
      :not(:last-child) {
         margin-right: 8px;
      }
   }
`;

const AmountCard = styled(StyledCard)`
   > *:not(:last-child) {
      margin-bottom: 16px;
   }
`;

const Container = styled.div`
   border-radius: 3px;
   height: 32px;
   line-height: 1.4;
   font-size: 15px;
   box-sizing: border-box;
   color: #2d3142;
   padding: 7px 0 7px 12px;
   border: 1px solid #d2d2d2;
`;

const baseStyles = {
   fontFamily: 'inherit',
   fontSize: '15px',
   '::placeholder': {
      color: '#868686',
      fontFamily: 'inherit',
   },
};

const AddNewCard = styled(StyledCard)``;

const LinkButton = styled.div`
   border-top: 1px solid ${props => props.theme.datashop.palette.stone.light};
   padding-top: 16px;
   cursor: pointer;
   color: ${props => props.theme.datashop.palette.blue.main};
`;

const SplitFormElements = {
   CardNumberElement: { element: CardNumberElement, key: 'cardNumber' },
   CardExpiryElement: { element: CardExpiryElement, key: 'cardExpiry' },
   CardCvcElement: { element: CardCvcElement, key: 'cardCvv' },
};

class PaymentOptions extends Component {
   validateCard = (element, e) => {
      const { stripe, elements, onUpdate } = this.props;
      if (!stripe || !elements) {
         return;
      }

      onUpdate({
         [SplitFormElements[element].key]:
            e && e.complete
               ? elements.getElement(SplitFormElements[element].element)
               : null,
      });
   };

   render() {
      const { onInsuranceEdit, selfPay, paymentDetails } = this.props;
      return (
         <>
            <AmountCard>
               <FlexBox justifyContent='space-between'>
                  <div>
                     <Text fontSize='large'>
                        {selfPay || !paymentDetails.copayAccepted
                           ? 'Default Copay'
                           : 'Copay'}
                     </Text>
                     <Text
                        style={{
                           marginTop: 4,
                           color: theme.datashop.palette.neutral.lighter,
                        }}>
                        {selfPay
                           ? 'Organization charges a default copay.'
                           : paymentDetails.copayAccepted
                           ? 'Copay information is calculated from your inusrance plan.'
                           : 'Copay information is not available for this insurance. Organization charges a default copay.'}
                     </Text>
                  </div>
                  <Text
                     style={{ marginLeft: 4 }}
                     fontWeight='bold'
                     fontSize='large'>
                     ${(paymentDetails.copayAmount || 0).toFixed(2)}
                  </Text>
               </FlexBox>
               <LinkButton onClick={onInsuranceEdit}>
                  {selfPay ? 'Add insurance' : 'Edit insurance'}
               </LinkButton>
            </AmountCard>
            <Text
               style={{ margin: '16px 0px 12px' }}
               fontSize='small'
               fontWeight='bold'>
               PAY WITH
            </Text>
            <AddNewCard>
               <div style={{ marginBottom: 16 }}>
                  <Radio
                     checked={true}
                     label={
                        <Text fontSize='large'>Add debit or credit card</Text>
                     }
                  />
               </div>
               <InputRow>
                  <Input
                     style={{ fontfamilyy: 'inherit' }}
                     onChange={e => {
                        this.props.onUpdate({ name: e.target.value });
                     }}
                     placeholder='Name on card'></Input>
               </InputRow>
               <Container style={{ marginBottom: '16px' }}>
                  <CardNumberElement
                     options={{
                        hidePostalCode: true,
                        placeholder: 'Enter card number',
                        style: {
                           base: baseStyles,
                        },
                     }}
                     onChange={e => {
                        this.validateCard('CardNumberElement', e);
                     }}
                  />
               </Container>
               <FlexBox style={{ justifyContent: 'space-between' }}>
                  <Container style={{ flex: 1, marginRight: '8px' }}>
                     <CardExpiryElement
                        options={{
                           hidePostalCode: true,
                           placeholder: 'MM/YY',
                           style: {
                              base: baseStyles,
                           },
                        }}
                        onChange={e => {
                           this.validateCard('CardExpiryElement', e);
                        }}
                     />
                  </Container>
                  <Container style={{ flex: 1 }}>
                     <CardCvcElement
                        options={{
                           hidePostalCode: true,
                           placeholder: 'CVV',
                           style: {
                              base: baseStyles,
                           },
                        }}
                        onChange={e => {
                           this.validateCard('CardCvcElement', e);
                        }}
                     />
                  </Container>
               </FlexBox>
            </AddNewCard>
         </>
      );
   }
}

export default PaymentOptions;
