import React from 'react';
import * as S from './style';

const CardLogOut = ({ onClick }) => {
   return (
      <S.CardWrapper shadow='light'>
         <S.TextWrapperLogout onClick={onClick}>
            <S.HeaderIcon className='material-icons' color='#868686'>
               help
            </S.HeaderIcon>
            <S.LogoutTextWrapper>
               <S.SupportText>Support</S.SupportText>
            </S.LogoutTextWrapper>
         </S.TextWrapperLogout>
      </S.CardWrapper>
   );
};

export default CardLogOut;
