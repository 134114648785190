import React, { useEffect, useRef } from 'react';

const ObserverItem = ({
   children: Component,
   loading,
   endReached,
   options = {},
   onIntersect = () => {},
}) => {
   const observerElementRef = useRef(null);
   let observer = useRef(null);

   useEffect(() => {
      if (loading || endReached) return;

      if ((observer || {}).current) observer.current.disconnect();

      const callBack = entries => {
         entries.forEach(item => {
            if (item.isIntersecting) {
               onIntersect();
            }
         });
      };

      const combinedOptions = {
         root: null,
         threshold: 0.9,
         ...options,
      };

      const targetElement = observerElementRef.current;
      observer.current = new IntersectionObserver(callBack, combinedOptions);
      if (targetElement) {
         observer.current.observe(targetElement);
      }

      return () => {
         if (observer.current) {
            observer.current.unobserve(targetElement);
         }
      };
   }, [loading, endReached, options, onIntersect]);

   return <Component ref={observerElementRef} />;
};

export default ObserverItem;
