let isWalkmeLoaded = false;

function loadWalkMe(isWalkMeEnable) {
   if (!isWalkmeLoaded && isWalkMeEnable) {
      isWalkmeLoaded = true;
      var walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src =
         'https://cdn.walkme.com/users/8929660d42564465845cddc1a33046ce/walkme_8929660d42564465845cddc1a33046ce_https.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(walkme, s);
      window._walkmeConfig = { smartLoad: true };
   }
}

export { loadWalkMe };
