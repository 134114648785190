import { get } from 'lodash-es';

const categoryList = {
   login: {
      PROVIDER: 'InNote Assistant Provider - Login',
      STAFF: 'InNote Assistant Staff - Login',
   },
   conductAssessment: {
      PROVIDER: 'InNote Assistant Provider - Conduct Assessment',
      STAFF: 'InNote Assistant Staff - Conduct Assessment',
   },
   sendAssessment: {
      PROVIDER: 'InNote Assistant Provider - Send Assessment',
      STAFF: 'InNote Assistant Staff - Send Assessment',
   },
   reviewAssessment: {
      PROVIDER: 'InNote Assistant Provider - Review Assessment',
      STAFF: 'InNote Assistant Staff - Review Assessment',
   },
   covidAssessment: {
      PROVIDER: 'InNote Assistant Provider - COVID-19 Assessment',
      STAFF: 'InNote Assistant Staff - COVID-19 Assessment',
   },
   virtualVisit: {
      PROVIDER: 'InNote Assistant Provider - Virtual Visit',
      STAFF: 'InNote Assistant Staff - Virtual Visit',
   },
   bulkAssessment: {
      PROVIDER: 'InNote Assistant Provider - Bulk Assessment',
      STAFF: 'InNote Assistant Staff - Bulk Assessment',
   },
   notification: {
      PROVIDER: 'InNote Assistant Provider - Notification',
      STAFF: 'InNote Assistant Staff - Notification',
   },
   search: {
      PROVIDER: 'InNote Assistant Provider - Search',
      STAFF: 'InNote Assistant Staff - Search',
   },
   audioCall: {
      PROVIDER: 'InNote Assistant Provider - Audio Call',
      STAFF: 'InNote Assistant Staff - Audio Call',
   },
   groupCall: {
      PROVIDER: 'InNote Assistant Provider - Group Call',
      STAFF: 'InNote Assistant Staff - Group Call',
   },
   incompleteCall: {
      PROVIDER: 'InNote Assistant Provider - Incomplete Call',
      STAFF: 'InNote Assistant Staff - Incomplete Call',
   },
   chat: {
      PROVIDER: 'InNote Assistant Provider - Chat',
      STAFF: 'InNote Assistant Staff - Chat',
   },
   VFD: {
      PROVIDER: 'InNote Assistant Provider - VFD',
      STAFF: 'InNote Assistant Staff - VFD',
   },
   browser: {
      PROVIDER: 'InNote Assistant Provider - Browser',
      STAFF: 'InNote Assistant Staff - Browser',
   },
   audioNotification: {
      PROVIDER: 'InNote Assistant Provider - Audio notification',
      STAFF: 'InNote Assistant Staff - Audio notification',
   },
   visitReason: {
      PROVIDER: 'InNote Assistant Provider - Visit Reason',
      STAFF: 'InNote Assistant Staff - Visit Reason',
   },
   testCall: {
      PROVIDER: 'InNote Assistant Provider - Test Call',
      STAFF: 'InNote Assistant Staff - Test Call',
   },
   feedBack: {
      PROVIDER: 'InNote Assistant Provider - User feedback',
      STAFF: 'InNote Assistant Staff - User feedback',
   },
   facility: {
      PROVIDER: 'InNote Assistant Provider - Facility',
      STAFF: 'InNote Assistant Staff - Facility',
   },
};

const getCategory = category => {
   let val = '';
   const role = localStorage.getItem('access');
   switch (category) {
      case 'login':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Login'
         );
         break;
      case 'conductAssessment':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Conduct Assessment'
         );
         break;
      case 'sendAssessment':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Send Assessment'
         );
         break;
      case 'reviewAssessment':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Review Assessment'
         );
         break;
      case 'covidAssessment':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - COVID-19 Assessment'
         );
         break;
      case 'virtualVisit':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Virtual Visit'
         );
         break;
      case 'bulkAssessment':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Bulk Assessment'
         );
         break;
      case 'notification':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Notification'
         );
         break;
      case 'search':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Search'
         );
         break;
      case 'audioCall':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Audio Call'
         );
         break;
      case 'groupCall':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Group Call'
         );
         break;
      case 'incompleteCall':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Incomplete Call'
         );
         break;
      case 'VFD':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - VFD'
         );
         break;
      case 'chat':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Chat'
         );
         break;
      case 'browser':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Browser'
         );
         break;
      case 'audioNotification':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Audio notification'
         );
         break;
      case 'visitReason':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Visit Reason'
         );
         break;
      case 'testCall':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Test Call'
         );
         break;
      case 'feedBack':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - User feedback'
         );
         break;
      case 'facility':
         val = get(
            categoryList,
            `${category}.${role}`,
            'InNote Assistant - Facility'
         );
         break;
      default:
         val = category;
         break;
   }

   return val;
};

export { getCategory };
