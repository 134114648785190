import React, { Component } from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';
import { format } from 'date-fns';

import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import EmptyList from './EmptyList';
import AssessmentItem from './AssessmentItem';
import axios from 'helpers/axios';
import AssessmentHeader from './AssessmentHeader';
import Search from './Seach';
import ScheduleNewVisitModal from 'packages/ScheduleNewVisitModal';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';

const Button = styled(StyledButton)`
   font-weight: 300;
`;

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const ButtonWrapper = styled.div`
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-evenly;
   padding: 16px;

   ${Button} {
      :last-child {
         margin-left: 0px;
      }
      font-weight: 300;
      margin-left: 8px;
   }
`;

const AssessmentList = styled.ul`
   background-color: #fff;
   overflow: overlay;
   max-height: ${window.innerHeight - 215}px;
`;

const LoaderWrapper = styled.div`
   padding: 8px;
`;

class Assessment extends Component {
   state = {
      showVideoVisit: false,
      openVideoVisitModal: false,
      availableForVideo: true,
      showVideoPopup: false,
      showIncomingCall: false,
      showToast: false,
      isLoading: false,
      showPermissionModal: false,
      item: { patientInfo: {} },
   };

   componentWillMount() {
      if (localStorage.getItem('isAvailable')) {
         this.setState({
            availableForVideo: JSON.parse(localStorage.getItem('isAvailable')),
         });
      }
   }

   changeAvailabilityForVideo = status => {
      this.setState(prevState => ({
         availableForVideo: !prevState.availableForVideo,
      }));
      axios
         .patch('innote-survey/user/availability', {
            isAvailable: status === 'Yes' ? true : false,
         })
         .then(() => {
            localStorage.setItem(
               'isAvailable',
               status === 'Yes' ? true : false
            );
         });
   };

   toggleVideoVisit = item => {
      const { type: callType, ...rest } = item;
      this.setState(
         {
            showVideoVisit: !this.state.showVideoVisit,
            item: {
               ...rest,
               callType: callType === 'AUDIO' ? 'AUDIO' : 'VIDEO',
            },
            phoneNo: item ? item.patientInfo.unFormattedNo : null,
         },
         () => {
            if (this.state.showVideoVisit) {
               Mixpanel.track('Virtual visit initiated', {
                  category: 'reviewAssessment',
                  'Call Type': callType,
               });
            }
         }
      );
   };

   createRoom = values => {
      const {
         appointmentDate,
         firstName,
         lastName,
         phoneNumber: phoneNo,
         provider: assignedTo,
         time,
         timezone,
         timesuffix,
         dob,
         email,
         callType,
         isTestCall,
         reasonForVisit,
         gender,
         practiceId,
      } = values;
      this.setState({
         isLoading: true,
      });
      const body = {
         scheduledOn: new Date(
            `${appointmentDate} ${time} ${timesuffix}`
         ).getTime(),
         assignedTo,
         dateString: `${format(
            new Date(`${appointmentDate} ${time} ${timesuffix}`),
            'dd LLLL p'
         )} ${timezone || ''}`,
         patientInfo: {
            firstName,
            lastName,
            phoneNo,
            dob,
            email: email || undefined,
            gender,
         },
         type: callType,
         isTestCall,
         reasonForVisit,
         practiceId,
      };
      return axios
         .post(`/innote-survey/telehealth/video/user/schedule`, body)
         .then(() => {
            createToast({
               message: 'Visit scheduled successfully',
               appearance: 'success',
            });
            Mixpanel.track('Virtual Visit Scheduled', {
               category: 'reviewAssessment',
               'Call Type': callType,
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
            }
         )
         .finally(() => {
            this.setState({
               isLoading: false,
            });
            this.toggleVideoVisit();
         });
   };

   showVideoAvailablePopup = () => {
      this.setState(prevState => ({
         showVideoPopup: !prevState.showVideoPopup,
      }));
   };

   handleOutsideClick = () => {
      if (this.state.showVideoPopup) {
         this.setState({ showVideoPopup: false });
      }
   };

   onChange = event => {
      const {
         target: { value, name },
      } = event;
      this.setState({ [name]: value });
   };

   render() {
      const {
         sendAssessment,
         getAssessments,
         date,
         startAssessment,
         searching,
         data,
         toggleModal,
         toggleSendAssessment,
         searchAssessments,
      } = this.props;
      return (
         <Wrapper>
            {this.state.showVideoVisit && (
               <ScheduleNewVisitModal
                  item={this.state.item}
                  onClose={this.toggleVideoVisit}
                  onSubmitCallback={this.createRoom}
               />
            )}
            <ButtonWrapper>
               <Button
                  onClick={sendAssessment}
                  appearance='primary'
                  expanded
                  data-test='send-assessment-button'>
                  Send Assessment
               </Button>
               <Button
                  onClick={startAssessment}
                  expanded
                  data-test='take-assessment-button'>
                  Take Assessment
               </Button>
            </ButtonWrapper>

            <Search
               searching={searching}
               getChildContext={this.props.getChildContext}
               isLoading={this.props.isLoading}
               length={data.length}
               searchAssessments={searchAssessments}
            />
            {!searching && (
               <AssessmentHeader
                  getAssessments={getAssessments}
                  date={date}
                  isLoading={this.props.isLoading}
                  length={data.length}
               />
            )}
            {this.props.isLoading ? (
               <LoaderWrapper>
                  <CardPlaceholder smallList />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
                  <CardPlaceholder smallList style={{ marginTop: 2 }} />
               </LoaderWrapper>
            ) : data.length === 0 ? (
               <EmptyList
                  searching={searching}
                  sendAssessment={toggleSendAssessment}
                  startAssessment={toggleModal}
               />
            ) : (
               <AssessmentList data-test='assessment-list'>
                  {data.map(item => (
                     <AssessmentItem
                        toggleVideoVisit={this.toggleVideoVisit}
                        {...this.props}
                        item={item}
                     />
                  ))}
               </AssessmentList>
            )}
         </Wrapper>
      );
   }
}

export default Assessment;
