import React, { FC, JSXElementConstructor, ComponentProps } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import Main from 'components/Main/Main';

interface Props extends RouteProps {
   component: JSXElementConstructor<ComponentProps<any>>;
}

const ProtectedRoute: FC<Props> = ({ component: Component, ...rest }) => {
   return (
      <Route
         {...rest}
         render={props => {
            return localStorage.getItem('token') ? (
               <Main {...props}>
                  <Component {...props} />
               </Main>
            ) : (
               <Redirect to='/signup' />
            );
         }}
      />
   );
};

export default ProtectedRoute;
