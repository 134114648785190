import {
   REQUEST_ARTICLE_SEARCH,
   ARTICLE_SEARCH_SUCCESS,
   ARTICLE_SEARCH_FAILURE,
} from './constants';

// Initial State
const initialState = {
   searchResult: [],
   isLoading: false,
   error: null,
};

/**
 * Search reducer
 * @param {Object} [state=initialState] State
 * @param {Object} action Action
 * @return {Object} Action
 */
function search(state = initialState, action) {
   switch (action.type) {
      case REQUEST_ARTICLE_SEARCH:
         return {
            ...initialState,
            isLoading: true,
         };
      case ARTICLE_SEARCH_SUCCESS:
         return {
            ...state,
            isLoading: false,
            searchResult: action.data,
         };
      case ARTICLE_SEARCH_FAILURE:
         return {
            ...state,
            isLoading: false,
            error: action.error,
         };
      default:
         return state;
   }
}

export default search;
