import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Loader } from 'packages/loader';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   background: linear-gradient(
      180deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.87) 25.87%,
      rgba(0, 0, 0, 0.87) 74.88%,
      rgb(0, 0, 0) 100%
   );
   flex: 1;
   padding: 16px;
`;

const DataWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
`;

const HeaderText = styled.div`
   color: #ffffff;
   font-size: 28px;
   line-height: 40px;
   margin: 56px 0 24px;
`;

const SubText = styled.div`
   display: flex;
   align-items: center;
   color: #ffffff;
   line-height: 20px;
   margin-bottom: 8px;
`;

const Icon = styled.img`
   width: 16px;
   margin: 0 6px;
`;

const Image = styled.i`
   font-size: 48px;
   color: #fff;
`;

class MobilePermissionModal extends Component {
   render() {
      return (
         <Wrapper>
            <DataWrapper>
               {this.props.loading ? (
                  <Loader stroke='#fff' />
               ) : this.props.showErrorMessage ? (
                  <SubText>{this.props.message}</SubText>
               ) : this.props.modalType === 'google' ? (
                  <Fragment>
                     <Image className='material-icons'>videocam_off</Image>
                     <HeaderText>
                        Your microphone and camera is blocked
                     </HeaderText>
                     <SubText>
                        • Please check your browser or device settings to turn
                        on mic or camera permission.
                     </SubText>
                     <SubText>
                        • After enabling camera and microphone, please close the
                        browser and click on the link again to join.
                     </SubText>
                  </Fragment>
               ) : (
                  <Fragment>
                     <Image className='material-icons'>videocam_off</Image>
                     <HeaderText>
                        Your microphone and camera is blocked
                     </HeaderText>
                     <div style={{ alignItems: 'left' }}>
                        <SubText>Follow these steps to allow access:</SubText>
                        <SubText>
                           • Open Settings,
                           <Icon
                              src={`${process.env.PUBLIC_URL}/img/settings.png`}
                              alt='Datashop Care'
                           />
                           and scroll to Safari
                           <Icon
                              src={`${process.env.PUBLIC_URL}/img/safari.png`}
                              alt='Datashop Care'
                           />
                        </SubText>
                        <SubText>
                           • Click on ‘Camera’ or ‘Microphone’ under ‘Settings
                           for Websites’ section.
                        </SubText>
                        <SubText>• Change access to ‘Allow’.</SubText>
                        <SubText>
                           • Close the browser and click on the link again to
                           join.
                        </SubText>
                     </div>
                  </Fragment>
               )}
            </DataWrapper>
         </Wrapper>
      );
   }
}

export default MobilePermissionModal;
