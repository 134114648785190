import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from '@datashop/select';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';

const SelectWrapper = styled.div`
   > div {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
   }
   li {
      padding: 8px 8px 8px 12px;
      :hover {
         background-color: #f4f4f4;
      }
   }
`;

const OptionWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const Label = styled.div`
   margin-left: 8px;
   color: #2f2f2f;
   line-height: 20px;
`;

const providerOptions = [
   {
      value: '1',
      label: 'IN_PROGRESS',
      Component: (
         <OptionWrapper data-test='waiting-queue-option'>
            <Label>In progress</Label>
         </OptionWrapper>
      ),
   },
   {
      value: '2',
      label: 'POST_VISIT_QUEUE',
      Component: (
         <OptionWrapper data-test='ready-for-provider-option'>
            <Label>Post visit queue</Label>
         </OptionWrapper>
      ),
   },
];

const options = [
   {
      value: '1',
      label: 'Waiting',
      Component: (
         <OptionWrapper data-test='waiting-queue-option'>
            <Label>Waiting Queue</Label>
         </OptionWrapper>
      ),
   },
   {
      value: '2',
      label: 'Ready',
      Component: (
         <OptionWrapper data-test='ready-for-provider-option'>
            <Label>Ready for Provider</Label>
         </OptionWrapper>
      ),
   },
];

function StatusChangeDropdown({
   item: { visitId, callStatus: status, patientInfo },
   onStatusChange,
   userType,
}) {
   const [selectedOption, changeSelectedOption] = useState(
      userType === 'PROVIDER' ? providerOptions[0] : options[0]
   );

   useEffect(() => {
      if (status === 'READY_FOR_DOCTOR' || status === 'POST_VISIT_QUEUE') {
         changeSelectedOption(
            userType === 'PROVIDER' ? providerOptions[1] : options[1]
         );
      }
   }, [status, userType]);

   const changeStatus = label => {
      createToast({ message: 'Changing status', appearance: 'default' });
      axios
         .post(`/innote-survey/telehealth/video/rooms/status/_update`, {
            visitId,
            status: label === 'POST_VISIT_QUEUE' ? label : 'READY_FOR_DOCTOR',
         })
         .then(() => {
            onStatusChange(label);
            createToast({
               message: 'Status changed successfully',
               appearance: 'success',
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               changeSelectedOption(
                  userType === 'PROVIDER' ? providerOptions[0] : options[0]
               );
               createToast({ message });
            }
         );
   };

   return (
      <SelectWrapper
         disabled={selectedOption.value === '2'}
         onClick={event => event.stopPropagation()}>
         <Select
            options={userType === 'PROVIDER' ? providerOptions : options}
            disabled={selectedOption.value === '2'}
            onChange={option => {
               changeSelectedOption(option);
               option.value === '2' && changeStatus(option.label);
            }}
            selectedOption={selectedOption}
            labelRenderer={({ Component }) => Component}
         />
      </SelectWrapper>
   );
}

export default StatusChangeDropdown;
