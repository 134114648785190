import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from '@krishnaxv/react-surface';
import Heading from '@datashop/heading';
import Icon from '@datashop/icon';
import ScheduleForm from './ScheduleForm';

// Should be used to position modal on the surface
const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
};

const ModalContainer = styled.div`
   > div {
      max-width: 400px;
      display: flex;
      left: 0;
      background: none;
      justify-content: center;
      margin: 0 auto;
      left: 0;
      right: 0;
   }
`;

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
`;

const HeadingWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 12px 16px;
   max-height: 56px;
   position: sticky;
   background-color: ${({ theme }) => theme.datashop.palette.white.main};
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const StyledHeading = styled(Heading)`
   height: 32px;
   color: #151414;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 32px;
`;

const ContentWrapper = styled.div`
   background-color: #ececec;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   overflow: hidden;
`;

// TODO: move this modal from helpers/ to components/
class ScheduleNewVisitModal extends Component {
   render() {
      const {
         isRequestSchedule = false,
         item,
         onSubmitCallback,
         ...restProps
      } = this.props;
      return (
         <ModalContainer>
            <Modal style={modalStyle} {...restProps}>
               {onModalClose => (
                  <Wrapper>
                     <HeadingWrapper>
                        <StyledHeading as='h4'>
                           Schedule a New Visit
                        </StyledHeading>
                        <Icon
                           name='close'
                           style={{ fontSize: 18, cursor: 'pointer' }}
                           onClick={() => onModalClose()}
                        />
                     </HeadingWrapper>
                     <ContentWrapper>
                        <ScheduleForm
                           isRequestSchedule={isRequestSchedule}
                           item={item}
                           onSubmitCallback={onSubmitCallback}
                           onModalClose={onModalClose}
                        />
                     </ContentWrapper>
                  </Wrapper>
               )}
            </Modal>
         </ModalContainer>
      );
   }
}

export default ScheduleNewVisitModal;
