import React, {
   useEffect,
   useState,
   useRef,
   Fragment,
   useCallback,
} from 'react';
import { useStore } from 'react-redux';
import styled from 'styled-components';
import StyledCard from '@datashop/card';

import LoadingState from './TabLoadingState';
import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';
import ItemRequest from './ItemRequest';

import CardPlaceholder from 'packages/cardPlaceholder/placeholder';

const Wrapper = styled.div`
   padding: 8px 12px;
   height: ${window.innerHeight - 185}px;
   overflow: auto;
`;

const DataWrapper = styled.div``;

const Card = styled(StyledCard)`
   padding: 12px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const Requests = ({ toggleModal, deleteRequest }) => {
   const store = useStore();
   const {
      virtualVisit: { tab, reloadActiveTab },
   } = store.getState();

   const [loading, toggleLoading] = useState(true);
   const [data, setData] = useState([]);
   const [scheduleId, setScheduleId] = useState(null);
   const [isLoadingMore, setLoadingMore] = useState(true);
   const [endReached, setEndReached] = useState(false);
   const [offset, setOffset] = useState(0);

   const targetElement = useRef(null);

   const fetchData = (shouldAppend = false) => {
      if (shouldAppend) setLoadingMore(true);
      axios
         .get(
            `/innote-survey/telehealth/video/schedule/requests?offset=${offset}`
         )
         .then(({ data: { visitRequests } }) => {
            const list = shouldAppend
               ? [...data, ...visitRequests]
               : visitRequests;
            setData(list);
            if (visitRequests.length >= 20) {
               setOffset(offset + 20);
            } else {
               setEndReached(true);
            }
         })
         .catch(() => {
            setEndReached(true);
         })
         .finally(() => {
            toggleLoading(false);
            setLoadingMore(false);
         });
   };

   const reset = useCallback(() => {
      toggleLoading(true);
      setEndReached(false);
      setOffset(0);
      setData([]);
      fetchData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (tab === 2) {
         reset();
      }
   }, [tab, reloadActiveTab, reset]);

   useEffect(() => {
      if (!isLoadingMore && !endReached) {
         const callBack = entries => {
            entries.forEach(item => {
               item.isIntersecting && fetchData(true);
            });
         };

         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadingMore, endReached, data]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      Mixpanel.track('Requests tab clicked', {
         category: 'virtualVisit',
      });
      fetchData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (deleteRequest) {
         axios
            .delete(`/innote-survey/telehealth/video/schedule/requests`, {
               data: { scheduleId },
            })
            .then(() => {
               setData(data => {
                  return data.filter(
                     request => request.scheduleId !== scheduleId
                  );
               });
            });
      }
   }, [deleteRequest, scheduleId]); // eslint-disable-line react-hooks/exhaustive-deps

   const cancelSchedule = scheduleId => {
      createToast({
         message: 'Cancelling request',
         appearance: 'default',
      });
      axios
         .delete(`/innote-survey/telehealth/video/schedule/requests`, {
            data: { scheduleId },
         })
         .then(() => {
            fetchData();
            createToast({
               message: 'Request discarded successfully',
               appearance: 'success',
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => createToast({ message })
         );
   };

   return (
      <Wrapper>
         {loading ? (
            <LoadingState />
         ) : data.length > 0 ? (
            <Fragment>
               <DataWrapper innerRef={targetElement}>
                  {data.map((item, index) => (
                     <ItemRequest
                        item={item}
                        key={index}
                        cancelSchedule={cancelSchedule}
                        toggleModal={toggleModal}
                        setScheduleId={setScheduleId}
                     />
                  ))}
               </DataWrapper>
            </Fragment>
         ) : (
            <Card shadow='light'>No record to show</Card>
         )}
         <Fragment>
            {isLoadingMore ? (
               <CardPlaceholder smallList />
            ) : (
               endReached &&
               data.length > 20 && (
                  <Card shadow='light'>No more records to show</Card>
               )
            )}
         </Fragment>
      </Wrapper>
   );
};

export default Requests;
