import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Dialog } from '@krishnaxv/react-surface';
import Text from '@datashop/text';
import * as S from 'components/Messages/style';
import Button from '@datashop/button';
import Icon from '@datashop/icon';

const Wrapper = styled.div`
   > div {
      width: 100%;
   }
`;

const CardWrapper = styled.div`
   background-color: #ffffff;
   border-radius: 8px;
   box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
   width: 328px;
   height: 216px;
`;

const StyledHeading = styled.div`
   height: 32px;
   color: #151414;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 32px;
`;

const PatientAttributeModal = ({ patientName }) => {
   const history = useHistory();

   const closeModal = () => {
      history.push('/messages');
   };

   return (
      <Wrapper>
         <Dialog>
            {onCloseDialog => (
               <CardWrapper>
                  <S.Row
                     isCenter={true}
                     style={{
                        padding: '16px 24px 12px 24px',
                        justifyContent: 'space-between',
                     }}>
                     <StyledHeading as='h4'>
                        Patient not attributed
                     </StyledHeading>
                     <Icon
                        name='close'
                        style={{ fontSize: 18, cursor: 'pointer' }}
                        onClick={() => onCloseDialog()}
                     />
                  </S.Row>
                  <S.Row
                     style={{
                        padding: '8px 24px 32px 24px',
                        width: '328px',
                        lineHeight: '20px',
                     }}>
                     <Text fontSize='regular' fontWeight='semiBold'>
                        Looks like {patientName} is an unattributed patient. You
                        can only converse with patients that are attributed to
                        you.
                     </Text>
                  </S.Row>
                  <S.Row
                     isCenter={true}
                     style={{
                        justifyContent: 'flex-end',
                        paddingBottom: '24px',
                        paddingRight: '24px',
                     }}>
                     <Button appearance='primary' onClick={closeModal}>
                        Go Back
                     </Button>
                  </S.Row>
               </CardWrapper>
            )}
         </Dialog>
      </Wrapper>
   );
};

export default PatientAttributeModal;
