export const isMobile = () => {
   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
   const isMobileAndroid = /Android/i.test(userAgent);
   const isMobileIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
   if (isMobileAndroid || isMobileIOS) {
      return true;
   }
   return false;
};

export const orientationHandler = () => {
   let status = '';
   if (window.orientation === 90 || window.orientation === -90) {
      status = 'landscape';
   } else if (window.orientation === 0 || window.orientation === -180) {
      status = 'portrait';
   } else if (window.orientation === undefined) {
      status = 'none';
   } else {
      status = '';
   }
   return status;
};

export const isElectron = () =>
   navigator.userAgent.toLowerCase().indexOf('electron/') > -1;
