import React, { Fragment } from 'react';
import returnCamelCasedName from 'helpers/nameTransformer';
import { format } from 'date-fns';
import styled from 'styled-components';
import Tooltip from 'react-tooltip';

const Bullet = styled.p`
   padding: 0 4px;
   color: #868686;
`;

const RiskStatus = styled.p`
   color: ${({ color }) => color};
   font-size: 12px;
   font-weight: 700;
`;

const VideoIcon = styled.i`
   margin-right: 4px;
   padding: 4px;
   font-size: 20px;
   color: ${({ color }) => color && color};

   &:hover {
      background-color: #e5e5e5;
      border-radius: 4px;
      color: ${({ hoverColor }) => hoverColor};
      background-color: ${({ hoverBColor }) => hoverBColor};
   }
`;

const DetailsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const ListItem = styled.li`
   display: flex;
   cursor: pointer;
   padding: 8px 16px 8px 8px;
   border-bottom: 1px solid #e7e5e5;
   opacity: ${({ action }) => (action !== 'UNREAD' ? '0.6' : '1')};
`;

const NameWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const PatientNameWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const TickIcon = styled.i`
   font-size: 16px;
   line-height: 16px;
   margin-right: 8px;
   margin-top: 4px;
   color: ${({ finished }) => (finished ? '#0070DD' : '#151414')};
`;

const PatientName = styled.p`
   color: #151414;
   font-size: 16px;
   display: flex;
   font-weight: bold;
   line-height: 24px;
`;

const PatientInfo = styled.div`
   display: flex;
   align-items: center;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
   justify-content: space-between;
`;

const Info = styled.div`
   display: flex;
`;

const BadgeWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const DOB = styled.p`
   color: #151414;
`;

const PhoneNumber = styled.p`
   color: #151414;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;

const RiskColorList = {
   HIGH: '#9E2929',
   MEDIUM: '#B35F13',
   LOW: '#217B3D',
};

const AssessmentItem = props => {
   const { item, toggleVideoVisit, showResult } = props;
   return (
      <ListItem
         action={item.providerAction}
         onClick={() =>
            showResult({
               stauts: item.status,
               responseId: item.responseId,
               isPuiFilled: item.linkedAssessments.length > 0,
               assessmentId: item.assessmentId,
               patientInfo: item.patientInfo,
               owner: item.owner,
            })
         }
         key={item.responseId}>
         <TickIcon className='material-icons' finished={item.finished}>
            {item.finished ? 'done_all' : 'done'}
         </TickIcon>
         <DetailsWrapper>
            <NameWrapper>
               <PatientNameWrapper>
                  <PatientName>
                     {returnCamelCasedName(
                        item.patientInfo.firstName,
                        item.patientInfo.lastName
                     )}
                  </PatientName>
                  {item.patientInfo.phoneNumber && (
                     <Fragment>
                        <Bullet>&bull;</Bullet>
                        <PhoneNumber>
                           {item.patientInfo.phoneNumber}
                        </PhoneNumber>
                     </Fragment>
                  )}
               </PatientNameWrapper>
            </NameWrapper>
            <PatientInfo>
               <Info>
                  {RiskColorList[item.status] && (
                     <Fragment>
                        <RiskStatus
                           color={
                              RiskColorList[item.status]
                           }>{`${item.status.toUpperCase()} RISK`}</RiskStatus>
                        <Bullet>&bull;</Bullet>
                     </Fragment>
                  )}
                  <DOB>
                     {format(new Date(item.patientInfo.dob), 'MM/dd/yyyy')}
                  </DOB>
               </Info>
            </PatientInfo>
         </DetailsWrapper>
         <BadgeWrapper>
            <VideoIcon
               hoverColor={item.callbackRequested === 'Yes' && '#fff'}
               hoverBColor={item.callbackRequested === 'Yes' && '#D93737'}
               color={item.callbackRequested === 'Yes' ? '#D93737' : '#2F2F2F'}
               onClick={e => {
                  e.stopPropagation();
                  toggleVideoVisit(item);
               }}
               data-tip
               data-for={`${item.responseId}`}
               data-effect='solid'
               data-class='opaque'
               className='material-icons'>
               video_call
            </VideoIcon>
            <Tooltip place='left' id={`${item.responseId}`}>
               {item.callbackRequested === 'No'
                  ? 'Schedule virtual visit'
                  : 'Virtual visit requested'}
            </Tooltip>
         </BadgeWrapper>
      </ListItem>
   );
};

export default AssessmentItem;
