import React from 'react';

import * as S from './style';
import SelfContainer from './SelfContainer';
import PatientContainer from './PatientContainer';
import MessageCard from './MessageCard';

const MessageContainer = ({
   index,
   msgDetails,
   handleMarksAsRead,
   sendAttachment,
   onAttachmentUploadStart,
   addAttachmentIdentifier,
   onAttachmentUploadDone,
}) => {
   const { sender = '', ...rest } = msgDetails;

   if (sender.toUpperCase().includes('PATIENT')) {
      return (
         <S.Left>
            <PatientContainer
               el={msgDetails}
               handleMarksAsRead={handleMarksAsRead}>
               <MessageCard messageDetails={rest} isStatus={false} />
            </PatientContainer>
         </S.Left>
      );
   }

   return (
      <S.Right>
         <SelfContainer el={msgDetails}>
            <MessageCard
               index={index}
               messageDetails={rest}
               isStatus={true}
               sendAttachment={sendAttachment}
               onAttachmentUploadStart={onAttachmentUploadStart}
               addAttachmentIdentifier={addAttachmentIdentifier}
               onAttachmentUploadDone={onAttachmentUploadDone}
            />
         </SelfContainer>
      </S.Right>
   );
};

export default MessageContainer;
