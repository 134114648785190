import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CALL_STATUS } from '../../../../constants';

const OnCallWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const OnCallIndicator = styled.span`
   height: 8px;
   width: 8px;
   border-radius: 50%;
   margin-right: 8px;
   background-color: ${({ onCall }) => (onCall ? '#D93737' : '#ffc208')};
`;

const InfoText = styled.p`
   color: #2f2f2f;
   font-weight: 600;
`;

const ParticipantStatus = ({ status, infoText }) => {
   if (infoText) {
      return (
         <OnCallWrapper>
            <OnCallIndicator onCall={status === CALL_STATUS.onCall} />
            <InfoText>{infoText}</InfoText>
         </OnCallWrapper>
      );
   }

   return null;
};

ParticipantStatus.propTypes = {
   status: PropTypes.oneOf([...Object.values(CALL_STATUS)]).isRequired,
   infoText: PropTypes.string.isRequired,
};

export default ParticipantStatus;
