import { Component } from 'react';

const eventsChanged = (oldEvents, newEvents) =>
   oldEvents.sort().toString() !== newEvents.sort().toString();

/**
 * Idle component
 * @author https://github.com/ReactTraining/react-idle (original author)
 * @class Idle
 * @extends {Component}
 */
class Idle extends Component {
   timeout = null;

   static defaultProps = {
      defaultIdle: false,
      render: () => null,
      onChange: () => {},
      timeout: 1000,
      events: ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'],
   };

   state = {
      idle: this.props.defaultIdle,
   };

   componentDidMount() {
      this.attachEvents();
      this.setTimeout();
   }

   componentWillUnmount() {
      this.unMounted = true;

      this.removeEvents();
      clearTimeout(this.timeout);
   }

   componentDidUpdate(prevProps) {
      if (eventsChanged(prevProps.events, this.props.events)) {
         this.removeEvents();
         this.attachEvents();
      }
   }

   attachEvents() {
      this.props.events.forEach(event => {
         window.addEventListener(event, this.handleEvent, true);
      });
   }

   removeEvents() {
      this.props.events.forEach(event => {
         window.removeEventListener(event, this.handleEvent, true);
      });
   }

   handleChange(idle) {
      if (!this.unMounted) {
         this.props.onChange({ idle });
         this.setState({ idle });
      }
   }

   handleEvent = () => {
      if (this.state.idle) {
         this.handleChange(false);
      }
      clearTimeout(this.timeout);
      this.setTimeout();
   };

   setTimeout() {
      this.timeout = setTimeout(() => {
         this.handleChange(true);
      }, this.props.timeout);
   }

   render() {
      return this.props.render(this.state.idle);
   }
}

export default Idle;
