import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Socket from 'services/socket';
import {
   onClose,
   onError,
   onMessage,
   onOpen,
   setSocket,
   setLoadMoreMyInbox,
   setMyTabLoading,
   setLoadMoreUnassignedInbox,
   setUnassignTabLoading,
   chatLoading,
   setResetMyTab,
   chatHeaderLoading,
} from 'store/frontDoorSocket/action';

const ChatSocket = ({
   onClose,
   onError,
   onMessage,
   onOpen,
   setSocket,
   children,
   isSocketOpen,
   socket,
   setLoadMoreMyInbox,
   setMyTabLoading,
   setLoadMoreUnassignedInbox,
   setUnassignTabLoading,
   chatLoading,
   next,
   myInboxNext,
   setResetMyTab,
   chatHeaderLoading,
   userInfo: { accessRole = '' },
}) => {
   const [isFirst, setIsFirst] = useState(true);
   const [isMyInboxLoaded, setIsMyInboxLoaded] = useState(false);

   const memoizedCallback = useCallback(() => {
      const onSocketClose = data => {
         onClose(data);
         if (localStorage.getItem('token')) {
            setTimeout(() => {
               memoizedCallback();
            }, 1000);
         }
      };

      if (socket) {
         socket.close();
         setSocket(null);
      }

      const createSocket = new Socket();
      createSocket.connect(onSocketClose, onError, onMessage, onOpen);
      setSocket(createSocket.socket);
      return () => {
         createSocket.disConnect();
         setSocket(null);
      };
   }, [onClose, onError, onMessage, onOpen, setSocket]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (isFirst && accessRole !== '') {
         setIsFirst(false);
         memoizedCallback();
      }
      return () => {
         if (socket) {
            socket.close();
            setSocket(null);
         }
      };
   }, [memoizedCallback, isFirst, accessRole, socket, setSocket]);

   useEffect(() => {
      if (isSocketOpen && !isMyInboxLoaded && socket && socket.send) {
         setIsMyInboxLoaded(true);
         const isProvider = localStorage.getItem('access') === 'PROVIDER';
         const exclusion = isProvider ? { exclude_empty: true } : {};
         socket.send(
            JSON.stringify({
               query_params: {
                  type:
                     localStorage.getItem('access') === 'PROVIDER'
                        ? 'ONE_TO_ONE'
                        : 'GROUP',
                  no_page: false,
                  is_closed: false,
                  ...exclusion,
               },
               event_type: 'InboxViewset_list',
            })
         );
      }
   }, [isMyInboxLoaded, isSocketOpen, socket]);

   const handleMarksAsRead = details => {
      if (isSocketOpen && socket) {
         socket.send(
            JSON.stringify({
               kwargs: {
                  message_id: details.message_id,
                  inbox_id: details.inbox_id,
               },
               message_id: details.message_id,
               event_type: 'MarkMessageAsRead_partial_update',
            })
         );
      }
   };

   const sendMessage = ({
      recipientId,
      chatType,
      messageType,
      messageText,
      attachment,
      external_id,
   }) => {
      if (isSocketOpen && socket) {
         const message = {
            type: messageType,
            body: messageText || '',
         };

         if (messageType === 'ATTACHMENT') {
            message.attachment = attachment;
            delete message.body;
         }

         socket.send(
            JSON.stringify({
               sender: `PROVIDER.${localStorage.getItem('userId')}`,
               recipient: recipientId,
               message,
               type: chatType,
               event_type: 'SendMessage_create',
               external_id,
            })
         );
      }
   };

   const assignToMe = inbox_id => {
      if (isSocketOpen && socket) {
         socket.send(
            JSON.stringify({
               inbox_id,
               event_type: 'AssignChatToMeViewset_create',
            })
         );
      }
   };

   const getMyInbox = (shouldAppend, shouldReset) => {
      if (isSocketOpen && socket) {
         const isProvider = localStorage.getItem('access') === 'PROVIDER';
         const exclusion = isProvider ? { exclude_empty: true } : {};
         if (shouldAppend) {
            setLoadMoreMyInbox(true);
            socket.send(
               JSON.stringify({
                  query_params: {
                     type: isProvider ? 'ONE_TO_ONE' : 'GROUP',
                     no_page: false,
                     is_closed: false,
                     reference_id: myInboxNext.split('reference_id=')[1],
                     ...exclusion,
                  },
                  event_type: 'InboxViewset_list',
               })
            );
         } else {
            if (shouldReset) {
               setResetMyTab();
            }
            setMyTabLoading(true);
            socket.send(
               JSON.stringify({
                  query_params: {
                     type:
                        localStorage.getItem('access') === 'PROVIDER'
                           ? 'ONE_TO_ONE'
                           : 'GROUP',
                     no_page: false,
                     is_closed: false,
                     ...exclusion,
                  },
                  event_type: 'InboxViewset_list',
               })
            );
         }
      }
   };

   const getUnassignedInbox = shouldAppend => {
      if (isSocketOpen && socket) {
         if (shouldAppend) {
            setLoadMoreUnassignedInbox(true);
            socket.send(
               JSON.stringify({
                  query_params: {
                     type: 'BOT',
                     no_page: false,
                     is_closed: false,
                     reference_id: next.split('reference_id=')[1],
                  },
                  event_type: 'InboxViewset_list',
               })
            );
         } else {
            setUnassignTabLoading(true);
            socket.send(
               JSON.stringify({
                  query_params: {
                     type: 'BOT',
                     no_page: false,
                     is_closed: false,
                  },
                  event_type: 'InboxViewset_list',
               })
            );
         }
      }
   };

   const retrieveInboxMessages = indexId => {
      if (isSocketOpen && socket) {
         chatLoading(true);
         socket.send(
            JSON.stringify({
               kwargs: {
                  inbox_id: indexId,
               },
               event_type: 'RetrieveInboxMessages_list',
            })
         );
      }
   };

   const retrieveInboxDetails = indexId => {
      if (isSocketOpen && socket) {
         chatHeaderLoading(true);
         socket.send(
            JSON.stringify({
               kwargs: {
                  inbox_id: indexId,
               },
               event_type: 'InboxViewset_retrieve',
            })
         );
      }
   };

   return (
      <>
         {React.Children.map(children, Component => {
            if (React.isValidElement(Component)) {
               return React.cloneElement(Component, {
                  handleMarksAsRead,
                  sendMessage,
                  assignToMe,
                  getMyInbox,
                  getUnassignedInbox,
                  retrieveInboxMessages,
                  retrieveInboxDetails,
               });
            }
         })}
      </>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      socketDisConnectCount,
      isSocketOpen,
      socket,
      next,
      myInboxNext,
      userInfo,
   },
}) => ({
   socketDisConnectCount,
   isSocketOpen,
   socket,
   next,
   myInboxNext,
   userInfo,
});

const mapDispatchToProps = {
   onClose,
   onError,
   onMessage,
   onOpen,
   setSocket,
   setLoadMoreMyInbox,
   setMyTabLoading,
   setLoadMoreUnassignedInbox,
   setUnassignTabLoading,
   chatLoading,
   setResetMyTab,
   chatHeaderLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSocket);
