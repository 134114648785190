import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@datashop/button';

import { CALL_TYPES } from '../../../../constants';

const ButtonWrapper = styled.div`
   display: flex;
   align-items: center;
   margin-left: auto;
`;

const Icon = styled.i`
   font-size: 12px;
   color: #fff;
   margin-right: 4px;
`;

const JoinCall = ({ disabled, onClick, type }) => (
   <ButtonWrapper>
      <Button
         data-test='join-call-button'
         onClick={onClick}
         // disabled={disabled}
         dimension='tiny'
         appearance='primary'>
         <Icon className='material-icons'>
            {type === CALL_TYPES.audio ? 'call' : 'videocam'}
         </Icon>
         Join Call
      </Button>
   </ButtonWrapper>
);

JoinCall.propTypes = {
   disabled: PropTypes.bool,
   onClick: PropTypes.func.isRequired,
   type: PropTypes.oneOf(Object.values(CALL_TYPES)).isRequired,
};

export default JoinCall;
