import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import StyledCard from '@datashop/card';
import Item from './Item';

const Wrapper = styled.div``;

const Card = styled(StyledCard)`
   padding: 12px;
   background-color: #fff;
   margin-bottom: 8px;
`;

function CallLogItem({
   data,
   fetchData,
   isLoadingMore,
   endReached,
   toggleModal,
   markAsTest,
   markInComplete,
}) {
   const targetElement = useRef(null);

   const callBack = useCallback(
      entries => {
         entries.forEach(item => {
            item.isIntersecting && fetchData(true);
         });
      },
      [fetchData]
   );

   useEffect(() => {
      if (!isLoadingMore && !endReached) {
         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadingMore, endReached, callBack]);

   return (
      <Wrapper innerRef={targetElement}>
         {data.map((item, index) => (
            <Card key={index} shadow='light'>
               <Item
                  item={item}
                  toggleModal={toggleModal}
                  markAsTest={markAsTest}
                  markInComplete={markInComplete}
               />
            </Card>
         ))}
      </Wrapper>
   );
}

export default CallLogItem;
