import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from '@krishnaxv/react-surface';
import { clearChat, changeChat } from 'store/frontDoorSocket/action';
import { getPatientAttributionDetails } from 'store/chat/action';
import * as S from './style';
import ChatBody from './ChatBody';
import ChatHeader from './ChatHeader';

const modalStyle = {
   backgroundColor: '#fff',
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
};

const Chat = ({
   sendMessage,
   sendAttachment,
   handleMarksAsRead,
   getMyInbox,
   selectedInbox: { messages, userId },
   retrieveInboxMessages,
   isSocketOpen,
   retrieveInboxDetails,
   clearChat,
   changeChat,
   userInfo: { accessRole },
   getPatientAttributionDetails,
}) => {
   const [modalType, setModalType] = useState('chat');
   const [firstLoad, setFirstLoad] = useState(true);
   let { inboxId } = useParams();

   useEffect(() => {
      return () => {
         clearChat();
      };
   }, [clearChat]);

   useEffect(() => {
      if (messages.length === 0 && isSocketOpen && firstLoad && inboxId) {
         changeChat(true);
         setFirstLoad(false);
         retrieveInboxDetails(inboxId);
         retrieveInboxMessages(inboxId);
      }
   }, [
      retrieveInboxMessages,
      messages,
      isSocketOpen,
      firstLoad,
      inboxId,
      retrieveInboxDetails,
      changeChat,
   ]);

   useEffect(() => {
      if (accessRole === 'PROVIDER' && userId) {
         getPatientAttributionDetails(userId.split('PATIENT.')[1]);
      }
   }, [userId, accessRole, getPatientAttributionDetails]);

   return (
      <S.ModalContainer>
         <Modal style={modalStyle}>
            {onModalClose => (
               <S.WrapperParent>
                  <S.HeadingWrapper>
                     <ChatHeader
                        onModalClose={onModalClose}
                        modalType={modalType}
                        setModalType={setModalType}
                        getMyInbox={getMyInbox}
                     />
                  </S.HeadingWrapper>

                  <S.ContentWrapper>
                     <ChatBody
                        modalType={modalType}
                        handleMarksAsRead={handleMarksAsRead}
                        sendMessage={sendMessage}
                        sendAttachment={sendAttachment}
                        setModalType={setModalType}
                     />
                  </S.ContentWrapper>
               </S.WrapperParent>
            )}
         </Modal>
      </S.ModalContainer>
   );
};

const mapStateToProps = ({
   frontDoorSocket: { selectedInbox, isSocketOpen, userInfo },
}) => ({
   selectedInbox,
   isSocketOpen,
   userInfo,
});

const mapDispatchToProps = {
   clearChat,
   changeChat,
   getPatientAttributionDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
