import React, { Component } from 'react';
import createToast from 'helpers/toastHelper';
import returnCamelCasedName from 'helpers/nameTransformer';
import { get } from 'lodash-es';

class ElectronSocket extends Component {
   _isMounted = true;

   createSocketConnection = () => {
      const URL =
         process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_SOCKET_URL`];
      this.socket = new WebSocket(
         `${URL}?app=INNOTE_ASSISTANT&token=${
            localStorage.getItem('token') &&
            localStorage.getItem('token').split(' ')[1]
         }`
      );

      this.socket.onopen = () => {
         const assessmentSocket = { ...this.socket };
         this.setState({ socket: assessmentSocket });
      };

      this.socket.onmessage = ({ data }) => {
         const details = JSON.parse(data);
         const {
            message: { event, eventDetails },
         } = details;

         switch (event) {
            case 'UNMUTE_REQUEST':
               if (
                  navigator.userAgent.toLowerCase().indexOf('electron/') !== -1
               ) {
                  createToast({
                     title: 'Request to unmute',
                     appearance: 'default',
                     message: `${returnCamelCasedName(
                        get(eventDetails, 'requester.firstName', ''),
                        get(eventDetails, 'requester.lastName', '')
                     )} has requested you to unmute your mic.`,
                     time: 30,
                     button: {
                        text: 'Unmute',
                        function: this.props.toggleAudio,
                     },
                  });
               }
               break;
            case 'MUTE':
               if (
                  navigator.userAgent.toLowerCase().indexOf('electron/') !== -1
               ) {
                  this.props.toggleAudio();
                  createToast({
                     message: `${returnCamelCasedName(
                        get(eventDetails, 'requester.firstName', ''),
                        get(eventDetails, 'requester.lastName', '')
                     )} has muted your audio.`,
                     time: 5,
                     appearance: 'default',
                  });
               }
               break;
            default:
               break;
         }
      };

      this.socket.onclose = () => {
         if (this._isMounted && localStorage.getItem('token'))
            setTimeout(() => {
               this.createSocketConnection();
            }, 1000);
      };
   };

   componentDidMount() {
      this.props.onRef(this);
      this.createSocketConnection();
   }

   componentWillUnmount() {
      this.props.onRef(undefined);
      this._isMounted = false;
      this.socket.close();
   }

   doMute = ({ scheduleId, visitId, participantId, participantType }) => {
      this.socket.send(
         JSON.stringify({
            messageType: 'DIRECTIVE',
            message: {
               directive: 'PARTICIPANT_CONTROL',
               directiveDetails: {
                  scheduleId,
                  visitInstanceId: '',
                  visitId,
                  controlEvent: 'MUTE',
                  participantId,
                  participantType,
               },
            },
         })
      );
   };

   doUnMute = ({ scheduleId, visitId, participantId, participantType }) => {
      this.socket.send(
         JSON.stringify({
            messageType: 'DIRECTIVE',
            message: {
               directive: 'PARTICIPANT_CONTROL',
               directiveDetails: {
                  scheduleId,
                  visitInstanceId: '',
                  visitId,
                  controlEvent: 'UNMUTE_REQUEST',
                  participantId,
                  participantType,
               },
            },
         })
      );
   };

   render() {
      return <div id='electronSocket'></div>;
   }
}

export default ElectronSocket;
