import React from 'react';
import * as S from './style';
import MessageStatus from './MessageStatus';
import { returnCreatedOn } from 'helpers/returnCreatedTime';

const TextMessage = ({ messageDetails, isStatus }) => {
   const { message, sent_at, status, ticks } = messageDetails;
   return (
      <>
         {message.body}
         {sent_at && (
            <S.Row
               style={{
                  marginTop: '4px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
               }}>
               <S.TimeWrapper>{returnCreatedOn(sent_at)}</S.TimeWrapper>
               {isStatus && <MessageStatus status={status} ticks={ticks} />}
            </S.Row>
         )}
      </>
   );
};

export default TextMessage;
