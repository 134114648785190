import React, { Component } from 'react';
import styled from 'styled-components';
import Heading from '@datashop/heading';
import Icon from '@datashop/icon';
import InsuranceCard from './InsuranceCard';
import PaymentOptions from './PaymentOptions';
import CheckinCard from './CheckinCard';
import Button from '@datashop/button';
import { debounce, get, omit } from 'lodash';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';
import { loadStripe } from '@stripe/stripe-js';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { Loader } from 'packages/loader';
import PaymentStatus from './PaymentStatus';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 100%;
   padding: 16px;
   background: ${props => props.theme.datashop.palette.stone.lighter};
`;
const ScreenLoader = styled.div.attrs({
   children: <Loader />,
})`
   position: absolute;
   left: 0;
   top: 0;
   background: white;
   z-index: 99999;
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0.5;
`;

const Header = styled.div`
   margin-bottom: 8px;
`;

const Body = styled.div`
   margin-bottom: 16px;
`;
const Actions = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: auto;
   bottom: 0;
   > * {
      :not(:last-child) {
         margin-bottom: 8px;
      }
      width: 100%;
   }
`;

class StatusLoader extends React.Component {
   state = {
      status: null,
   };

   render() {
      return <ScreenLoader />;
   }

   intervalTimer;
   timeoutTimer;
   componentDidMount() {
      const { scheduleId, onUpdate } = this.props;
      this.intervalTimer = setInterval(() => {
         axios
            .get(
               `/innote-survey/telehealth/video/schedule/${scheduleId}/payment/status`
            )
            .then(({ data }) => {
               if (
                  ['FAILED', 'SUCCESS'].includes(
                     (data.status || '').toUpperCase()
                  )
               ) {
                  onUpdate(data);
               } else {
                  this.setState({ status: data });
               }
            });
      }, 2000);
      this.timeoutTimer = setTimeout(() => {
         onUpdate(this.state.status);
      }, 7000);
   }

   componentWillUnmount() {
      clearInterval(this.intervalTimer);
      clearTimeout(this.timeoutTimer);
   }
}

class Payments extends Component {
   stripePromise = null;
   state = {
      // User Details
      userDetails: {},
      userValid: false,
      // Inssurance Details
      insuranceDetails: {},
      insuranceValid: false,
      // card details
      paymentDetails: {},
      selfPay: false,
      cardDetails: {
         name: '',
         cardNumber: '',
         cardExpiry: '',
         cardCvv: '',
      },
      cardValid: false,
      transactionStatus: null,
      transactionComplete: false,
      // rest state
      activeState: 0,
      loading: false,
      error: false,
   };

   moveToPrevStep = () => {
      const { activeState } = this.state;
      if (!activeState) {
         this.props.onDismiss();
      }
      this.setState({
         activeState: activeState - 1,
      });
   };

   moveToNextStep = () =>
      this.setState({
         activeState: this.state.activeState + 1,
      });

   // user details methods
   checkUserValidity = debounce(() => {
      const { userDetails } = this.state;
      this.setState({
         userValid:
            userDetails.firstName &&
            userDetails.lastName &&
            userDetails.gender &&
            userDetails.dob &&
            userDetails.dobValid,
      });
   }, 300);
   updatePersonalInfo = (patch = {}) => {
      const { userDetails = {} } = this.state;
      this.setState(
         {
            userDetails: { ...userDetails, ...patch },
         },
         this.checkUserValidity
      );
   };

   // user insurance methods
   checkInsuranceValidity = debounce(() => {
      const { insuranceDetails } = this.state;
      this.setState({
         insuranceValid:
            insuranceDetails.insuranceProvider &&
            insuranceDetails.memberId &&
            insuranceDetails.groupId,
      });
   }, 300);
   updateInsurance = (patch = {}) => {
      const { insuranceDetails = {} } = this.state;
      this.setState(
         {
            insuranceDetails: { ...insuranceDetails, ...patch },
         },
         this.checkInsuranceValidity
      );
   };
   validateInsurance = selfPay => {
      const { insuranceDetails, userDetails } = this.state;
      const { scheduleId, onComplete } = this.props;
      this.setState({
         loading: true,
      });
      const stripeLoaded = this.stripePromise
         ? Promise.resolve()
         : axios
              .get(
                 `innote-survey/telehealth/video/schedule/${this.props.scheduleId}/publishable-key-linked-account`
              )
              .then(({ data }) => {
                 const { publishableKey, stripeAccountId } = data;
                 this.stripePromise = loadStripe(publishableKey, {
                    stripeAccount: stripeAccountId,
                 });
              });

      stripeLoaded
         .then(() => {
            return axios.post(
               '/innote-survey/telehealth/video/insurance/_validate',
               {
                  scheduleId,
                  ...(selfPay
                     ? {}
                     : { insuranceInfo: omit(insuranceDetails, 'name') }),
                  patientInfo: omit(userDetails, 'dobValid'),
               }
            );
         })
         .then(({ data }) => {
            this.setState(
               {
                  selfPay: !!selfPay,
                  paymentDetails: data,
               },
               this.moveToNextStep
            );
         })
         .catch(error => {
            if (error.response.status === 422) {
               onComplete();
            }
            createToast({
               message: get(
                  error,
                  'response.data.error.message',
                  selfPay
                     ? `Couldn't calculate co pay`
                     : 'Unable to validate insurance details'
               ),
            });
         })
         .finally(() => {
            this.setState({
               loading: false,
            });
         });
   };

   // paymentOptions
   checkCardValidity = debounce(async stripe => {
      const { cardNumber, cardExpiry, cardCvv, name } =
         this.state.cardDetails || {};
      this.setState({
         cardValid: name && cardNumber && cardExpiry && cardCvv,
      });
      if (name && cardNumber) {
         const card = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
         });
         if (card && card.paymentMethod) {
            this.setState(({ cardDetails }) => ({
               cardDetails: {
                  ...cardDetails,
                  paymentMethod: card.paymentMethod,
               },
            }));
         }
      }
   }, 300);
   updateCardDetails = (patch = {}, stripe) => {
      const { cardDetails = {} } = this.state;
      this.setState(
         {
            cardDetails: { ...cardDetails, ...patch },
         },
         () => this.checkCardValidity(stripe)
      );
   };
   payLater = () => {
      const { scheduleId, onComplete } = this.props;
      this.setState({
         loading: true,
      });
      axios
         .post(
            `/innote-survey/telehealth/video/schedule/${scheduleId}/payment/_postpone`
         )
         .then(() => {
            onComplete();
         })
         .finally(() => {
            this.setState({
               loading: false,
            });
         });
   };
   confirmPayment = (stripe, elements) => {
      const { scheduleId } = this.props;
      const {
         userDetails,
         insuranceDetails,
         selfPay,
         paymentDetails,
         cardDetails,
      } = this.state;
      this.setState({
         loading: true,
      });
      axios
         .post(
            `/innote-survey/telehealth/video/schedule/${scheduleId}/payment/_initiate`,
            {
               ...(selfPay
                  ? {}
                  : { insuranceInfo: omit(insuranceDetails, 'name') }),
               patientInfo: omit(userDetails, 'dobValid'),
               payableAmount: paymentDetails.copayAmount,
            }
         )
         .then(({ data = {} }) => {
            const { clientSecret } = data;
            return stripe.confirmCardPayment(clientSecret, {
               payment_method: {
                  card: cardDetails.cardNumber,
                  billing_details: {
                     name: cardDetails.name,
                  },
               },
            });
         })
         .then(({ error }) => {
            // todo: payment fails from stripe's end
            this.setState({
               transactionComplete: true,
            });
         })
         .catch(error => {
            createToast({
               message: get(
                  error,
                  'response.data.error.message',
                  'Unable to initiate payment'
               ),
            });
         })
         .finally(() => {
            this.setState({
               loading: false,
            });
         });
   };

   render() {
      const {
         activeState,
         userDetails,
         userValid,
         insuranceDetails,
         insuranceValid,
         paymentDetails,
         selfPay,
         cardValid,
         cardDetails,
         transactionStatus,
         transactionComplete,
         loading,
      } = this.state;

      const { scheduleId, onComplete } = this.props;

      const components = [
         {
            title: 'Self check-in',
            element: (
               <CheckinCard
                  details={userDetails}
                  onUpdate={this.updatePersonalInfo}
               />
            ),
            actions: [
               <Button
                  disabled={!userValid}
                  onClick={this.moveToNextStep}
                  appearance='primary'>
                  Next
               </Button>,
            ],
         },
         {
            title: 'Add insurance',
            element: (
               <InsuranceCard
                  details={insuranceDetails}
                  onUpdate={this.updateInsurance}
               />
            ),
            actions: [
               <Button
                  disabled={!insuranceValid}
                  onClick={() => this.validateInsurance(false)}
                  appearance='primary'>
                  Verify
               </Button>,
               <Button onClick={() => this.validateInsurance(true)}>
                  I don’t have insurance
               </Button>,
            ],
         },
         {
            title: 'Payment',
            element: (
               <ElementsConsumer>
                  {({ stripe, elements }) => (
                     <PaymentOptions
                        stripe={stripe}
                        elements={elements}
                        paymentDetails={paymentDetails}
                        selfPay={selfPay}
                        onInsuranceEdit={this.moveToPrevStep}
                        onUpdate={patch =>
                           this.updateCardDetails(patch, stripe)
                        }
                     />
                  )}
               </ElementsConsumer>
            ),
            actions: [
               <ElementsConsumer>
                  {({ stripe, elements }) => (
                     <Button
                        disabled={!cardValid}
                        onClick={() => this.confirmPayment(stripe, elements)}
                        appearance='primary'>
                        Pay ${paymentDetails.copayAmount}
                     </Button>
                  )}
               </ElementsConsumer>,
               <Button onClick={this.payLater}>Pay later</Button>,
            ],
         },
      ];

      const { title, element, actions } = components[activeState];

      if (transactionStatus) {
         return (
            <PaymentStatus
               paymentMethod={(cardDetails || {}).paymentMethod}
               joinCall={onComplete}
               payLater={this.payLater}
               transactionStatus={transactionStatus}
            />
         );
      }

      return (
         <Wrapper>
            {loading && <ScreenLoader />}
            {transactionComplete && (
               <StatusLoader
                  onUpdate={status =>
                     this.setState({ transactionStatus: status })
                  }
                  scheduleId={scheduleId}
               />
            )}
            <Elements stripe={this.stripePromise}>
               <Header>
                  <Icon
                     style={{ fontSize: 22, cursor: 'pointer' }}
                     name='arrow_back'
                     onClick={this.moveToPrevStep}
                  />
                  <Heading as='h2' style={{ margin: '8px 0px' }}>
                     {title}
                  </Heading>
               </Header>
               <Body>{element}</Body>
               <Actions>{actions}</Actions>
            </Elements>
         </Wrapper>
      );
   }
}

export default Payments;
