import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { requestUpdate } from 'services/updateService';
import styled from 'styled-components';
import isElectron from 'utils/isElectron';

const Wrapper = styled.div`
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   padding: 24px 32px;
`;

const Logo = styled.img`
   width: 200px;
   margin-bottom: 16px;
`;

const CompleteIcon = styled.i`
   font-size: 80px;
   color: #57a944;
`;

const Progress = styled.p`
   font-size: 14px;
   color: #868686;
`;

const Heading = styled.h3`
   font-size: 20px;
   font-weight: 900;
   margin: 48px 0 12px 0;
`;

const Text = styled.p`
   font-size: 16px;
   line-height: 24px;
   font-weight: normal;
   color: #868686;
   margin-bottom: 24px;
`;

interface HistoryLocationState {
   redirectedForUpdate: boolean;
}
const Update = () => {
   const [progress, setProgress] = useState(0);
   const history = useHistory<HistoryLocationState>();
   useEffect(() => {
      if (!history.location.state?.redirectedForUpdate) {
         history.push('/');
         return;
      }

      try {
         requestUpdate();
         if (isElectron()) {
            listenForDownloadProgress();
         }
      } catch (err) {}
   }, [history]);

   const listenForDownloadProgress = () => {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.on(
         'downloadProgress',
         (event: any, downloadProgress: number) => {
            setProgress(parseFloat(downloadProgress.toFixed(2)));
         }
      );
   };

   const renderContent = () => {
      if (progress >= 100) {
         return (
            <>
               <CompleteIcon className='material-icons'>
                  check_circle_outline
               </CompleteIcon>
               <Heading>Download Completed !!</Heading>
               <Text>This new version of InNote is ready to be installed.</Text>
            </>
         );
      }

      return (
         <>
            <Logo
               src={`${process.env.PUBLIC_URL}/img/InNote.png`}
               alt='Datashop Care'
            />
            {progress > 0 && <Progress>{progress}% completed</Progress>}
            <Heading>We're Better than Ever !!</Heading>
            <Text>
               This current version of the application is no longer supported.
               Please wait until we complete the download.
            </Text>
         </>
      );
   };

   return <Wrapper>{renderContent()}</Wrapper>;
};

export default Update;
