import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Popup from '@datashop/popup';
import Button from '@datashop/button';
import ListDesign from './ListDesign';
import { Mixpanel } from 'helpers/mixpanelHelper';

const MoreButton = styled(Button)`
   margin-left: 32px;
   @media (max-width: 1024px) {
      margin-left: 24px;
   }
`;

const Wrapper = styled.div`
   background: #fff;
   border-radius: 4px;
   box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
   width: 320px;
   height: 280px;
   padding: 8px 0;
   bottom: 104px;
`;

const ListHeader = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 0 8px;
`;

const Count = styled.div`
   background-color: #e5e5e5;
   border-radius: 20px;
   width: 20px;
   height: 20px;
   display: flex;
   margin-top: 1px;
   justify-content: center;
   margin-left: 8px;
   align-items: center;
   font-size: 12px;
`;

const HeaderTitle = styled.p`
   color: #151111;
   display: flex;
   font-size: 16px;
   font-weight: bold;
   line-height: 24px;
   margin-bottom: 8px;
`;

const ListWrapper = styled.div`
   display: flex;
   max-height: 240px;
   height: 240px;
   flex-direction: column;
   padding: 0 8px;
   ${({ isSafari }) =>
      isSafari
         ? 'overflow: scroll; -webkit-overflow-scrolling: touch;'
         : 'overflow: auto;'}
`;

const ParticipantsList = ({
   onCallParticipants,
   remove,
   flowType,
   mute,
   unmute,
   isAudioCall,
}) => {
   const [showExpanded, toggleExpanded] = useState(false);

   useEffect(() => {
      if (showExpanded) {
         Mixpanel.track('List of participants', { category: 'groupCall' });
      }
   }, [showExpanded]);

   return (
      <Popup
         trigger={
            <MoreButton appearance='secondary'>
               <i className='material-icons'>group</i>
               {window.innerWidth > 1024 && (
                  <div style={{ marginLeft: 8 }}>All participants</div>
               )}
            </MoreButton>
         }
         onToggle={open => {
            toggleExpanded(open);
         }}
         position='TopLeft'
         verticalOffset={42}
         open={showExpanded}>
         <Wrapper>
            <ListHeader>
               <HeaderTitle>
                  {`Participants `}
                  {onCallParticipants && onCallParticipants.length > 0 && (
                     <Count>{onCallParticipants.length}</Count>
                  )}
               </HeaderTitle>
               <Button
                  dimension='tiny'
                  appearance='transparent'
                  onClick={() => toggleExpanded(false)}>
                  <i className='material-icons'>close</i>
               </Button>
            </ListHeader>
            <ListWrapper isSafari={navigator.userAgent.indexOf('Safari') > -1}>
               {onCallParticipants &&
                  onCallParticipants.length !== 0 &&
                  onCallParticipants.map((el, index) => (
                     <ListDesign
                        key={index}
                        pData={el.pData}
                        isAudio={el.isAudio}
                        participant={el.participant}
                        remove={remove}
                        flowType={flowType}
                        mute={mute}
                        unmute={unmute}
                        isAudioCall={isAudioCall}
                     />
                  ))}
            </ListWrapper>
         </Wrapper>
      </Popup>
   );
};

export default ParticipantsList;
