import styled from 'styled-components';
import IconBase from '@datashop/icon';
import Input from '@datashop/input';

// Should be used to position modal on the surface
export const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
};

export const ModalContainer = styled.div`
   > div {
      max-width: 400px;
      background: none;
      margin: 0 auto;
      left: 0;
      right: 0;
   }
`;

export const SearchWrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: 12px 16px;
   border-bottom: 1px solid #ededed;
`;

export const Form = styled.form`
   flex-grow: 1;
   background-color: white;
   border: 1px solid #d2d2d2;
   border-radius: 4px;
`;

export const TextFieldWrapper = styled.div`
   color: #868686;
   display: flex;
   padding: 6px 8px;
   font-size: 16px;
   line-height: 24px;
   align-items: center;
   > div {
      flex: 1;
   }
`;

export const TextField = styled(Input)`
   // border: 0;
   // font-size: 14px;
   // outline: none;
   // width: 100%;
`;

export const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   flex: 1;
   max-width: 100%;
   ${({ color }) => color && `background-color: ${color}`};
`;

export const SectionWrapper = styled.div`
   overflow: hidden;
`;

export const HeaderWrapper = styled.div`
   position: relative;
   padding: 16px 16px 12px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: #ffffff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const HeaderContainer = styled.div`
   max-width: 100%;
   display: flex;
   flex: 1;
   align-items: center;
   justify-content: space-between;
   span {
      display: flex;
      align-items: center;
      i {
         margin-right: 8px;
      }
   }
`;

export const Header = styled.p`
   flex: 1;
   font-size: 20px;
   line-height: 32px;
   color: #151414;
   text-overflow: ellipsis;
   margin: 0 8px;
   overflow: hidden;
   white-space: nowrap;
`;

export const ListHeaderWrapper = styled.div`
   border-bottom: 1px solid #ededed;
   display: flex;
   font-size: 12px;
   line-height: 24px;
   background-color: #f8f8f8;
   padding: 12px 16px 4px;
   text-transform: uppercase;
   font-weight: 800;
   color: #868686;
`;

export const ContentWrapper = styled.div`
   overflow: auto;
   flex: 1;
   background-color: #f8f8f8;
`;

export const ListWrapper = styled.ul`
   background-color: #fff;
`;

export const ListItem = styled.li`
   border-bottom: 1px solid #ededed;
   display: flex;
   flex-direction: row;
   padding: 12px 16px;
   cursor: pointer;
   align-items: center;

   :last-child {
      border-bottom: none;
   }

   .icon-hover {
      visibility: hidden;
   }

   :hover {
      .icon-hover {
         visibility: visible;
      }
   }
`;

export const Item = styled.div`
   display: flex;
   flex-direction: row;
   padding: 12px 16px;
   cursor: pointer;
   align-items: center;
   .icon-hover {
      visibility: hidden;
   }

   :hover {
      .icon-hover {
         visibility: visible;
      }
   }
`;

export const Description = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 20px;
   margin-right: auto;
`;

export const LoaderWrapper = styled.div`
   display: flex;
   // height: 100%;
   width: 100%;
   justify-content: center;
   align-items: center;
`;

export const Icon = styled(IconBase)`
   font-size: 18px;
`;

export const ArticleWrapper = styled.div`
   display: inherit;
   line-height: 20px;
   padding: 24px 0;

   p {
      margin: 4px 12px;
   }

   ul {
      margin: 20px 24px;
      list-style-position: outside;
      list-style-type: disc;
      padding-left: 20px;
   }
   ol {
      margin: 20px 24px;
      list-style-position: outside;
      padding-left: 20px;
   }
   img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
      max-width: 100%;
   }
`;

export const NoResultWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 16px 32px 32px;
   text-align: center;
   flex: 1;
`;

export const NoResult = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
   margin-top: 12px;
`;

export const NoResultSubtext = styled.p`
   color: #868686;
   line-height: 20px;
`;
