import React, { Fragment } from 'react';
import Toast from '@datashop/toast';
import { Mixpanel } from './helpers/mixpanelHelper';
import styled, { injectGlobal } from 'styled-components';
import { Loader } from 'packages/loader';
import axios, { addTokenToAllInstances } from 'helpers/axios';
import createToast from 'helpers/toastHelper';
import history from './history';
import { logSentry } from 'services/sentry';
import UpdateDialog from 'components/Update/UpdateDialog';
import { checkForUpdates } from 'services/updateService';
import isElectron from 'utils/isElectron';

injectGlobal`
html, .modal__container, .onboarding   {
    max-width: ${
       navigator.userAgent.toLowerCase().indexOf(' electron/') > -1
          ? '100%'
          : '400px'
    }!important;
}
`;

const LoaderWrapper = styled.div`
   right: 0;
   bottom: 0;
   left: 0;
   top: 0;
   position: fixed;
   height: 32px;
   margin: auto;
`;

class App extends React.Component {
   interval;
   state = {
      issueId: 0,
      showRefreshPrompt: false,
      buildUpdateAvailable: false,
   };

   keyDownEventListener = event => {
      if (
         event.ctrlKey &&
         event.shiftKey &&
         event.altKey &&
         event.keyCode === 73
      ) {
         this.openDevTools();
      }
   };

   openDevTools = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') > -1) {
         const { ipcRenderer } = window.require('electron');
         ipcRenderer.send('openDevTools');
      }
   };

   componentWillUnmount() {
      clearInterval(this.interval);
      window.removeEventListener('keydown', this.keyDownEventListener);
   }

   componentDidMount() {
      if (this.state.isLoading) {
         axios
            .post('/innote-survey/user/sso_token/_validate', {
               ssoToken: this.state.secretToken,
            })
            .then(
               ({
                  data: {
                     token,
                     userId,
                     userName: email,
                     organization = '',
                     accessRole,
                     name: { firstName = '', lastName = '' },
                     clientInfo: { timezoneCode },
                  },
               }) => {
                  localStorage.setItem('organization', organization);
                  localStorage.setItem('token', token);
                  localStorage.setItem('userId', userId);
                  localStorage.setItem('access', accessRole);
                  localStorage.setItem('email', email);
                  localStorage.setItem('timezone', timezoneCode);
                  localStorage.setItem('npi', userId);

                  addTokenToAllInstances(token);

                  Mixpanel.track('Token Validated', { category: 'login' });
                  Mixpanel.people.set({
                     $name: `${firstName} ${lastName}`,
                     $email: email,
                     NPI: userId || '',
                  });
                  // @TODO remove this call after debugging
                  logSentry({
                     data: {
                        $name: `${firstName} ${lastName}`,
                        $email: email,
                        NPI: userId || '',
                        AppName: 'COVID-19',
                     },
                     identify: email,
                  });

                  Mixpanel.identify(`${email}`);
                  history.push('/');
               }
            )
            .catch(
               ({
                  response: {
                     data: { error },
                  },
               }) => {
                  Mixpanel.track('Invalid Token', { category: 'login' });
                  createToast({ message: error.message });
               }
            )
            .finally(() => {
               this.setState({ isLoading: false });
            });
      }
   }

   async componentWillMount() {
      const params = new URLSearchParams(window.location.href.split('#/')[1]);
      const secretToken = params.get('OTT') || '';
      if (secretToken) {
         this.setState({ isLoading: true, secretToken });
      }

      window.addEventListener('keydown', this.keyDownEventListener);
      Mixpanel.init().then(() => {
         Mixpanel.mixpanelInitiated = true;
      });

      if (isElectron()) {
         this.manageUpdates();

         this.interval = setInterval(this.manageUpdates, 300000);
      }
   }

   manageUpdates = async () => {
      const {
         error,
         isUpdateAvailable,
         forceUpdate,
         buildUpdate,
         shallowUpdate,
      } = await checkForUpdates();
      if (error) return;

      if (isUpdateAvailable) {
         if (forceUpdate) {
            this.redirectToUpdate();
            return;
         }

         if (buildUpdate) {
            this.setState({ buildUpdateAvailable: true });
            return;
         }

         if (shallowUpdate) {
            this.setState({ showRefreshPrompt: true });
         }
      }
      return;
   };

   refreshWebsite = () => {
      Mixpanel.track('Refresh Button Clicked', {
         category: 'notification',
      });
      window.location.reload();
   };

   setUpdateDialogVisibility = visibility => {
      this.setState({ buildUpdateAvailable: visibility });
   };

   redirectToUpdate = () => {
      history.push({
         pathname: '/update',
         state: {
            redirectedForUpdate: true,
         },
      });
   };

   onUpdate = () => {
      this.setUpdateDialogVisibility(false);
      this.redirectToUpdate();
   };

   remindUpdateLater = () => {
      this.setUpdateDialogVisibility(false);
   };

   render() {
      return (
         <Fragment>
            {this.state.showRefreshPrompt && (
               <Toast
                  title='Update available'
                  appearance='information'
                  message='Refresh to apply new updates.'
                  onDismissClick={() =>
                     this.setState({ showRefreshPrompt: false })
                  }>
                  <Toast.Button onClick={this.refreshWebsite}>
                     Refresh
                  </Toast.Button>
               </Toast>
            )}
            {this.state.isLoading ? (
               <LoaderWrapper>
                  <Loader />
               </LoaderWrapper>
            ) : (
               this.props.children
            )}
            {this.state.buildUpdateAvailable && (
               <UpdateDialog
                  onUpdate={this.onUpdate}
                  onRemindLater={this.remindUpdateLater}
               />
            )}
         </Fragment>
      );
   }
}

export default App;
