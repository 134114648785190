import { _getArticleList } from 'helpers/helpArticleHelper';
import {
   REQUEST_ARTICLE_LIST,
   ARTICLE_LIST_SUCCESS,
   ARTICLE_LIST_FAILURE,
} from './constants';

/**
 * Invoked before requesting data from articles API
 * @return {Object} Action
 */
function requestArticleList() {
   return {
      type: REQUEST_ARTICLE_LIST,
   };
}

/**
 * Invoked after successfully retriving data from articles API
 * @param {Array} articles List of articles
 * @return {Object} Action
 */
function getArticleListSuccess(articles) {
   return {
      type: ARTICLE_LIST_SUCCESS,
      articles,
   };
}

/**
 * Invoked if articles API returns error
 * @param {Object} error
 * @return {Object} Action
 */
function getArticleListFailure(error) {
   return {
      type: ARTICLE_LIST_FAILURE,
      error,
   };
}

/**
 * Action creator to get article list from API
 * @param {number} sectionId
 * @return {Function}
 */
function getArticleList(sectionId) {
   return dispatch => {
      dispatch(requestArticleList());
      _getArticleList(sectionId)
         .then(({ articles }) => {
            dispatch(getArticleListSuccess(articles));
         })
         .catch(({ error }) => {
            dispatch(getArticleListFailure(error));
         });
   };
}

export { getArticleList };
