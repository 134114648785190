import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dropdown from '@datashop/dropdown';
import { isMobile } from 'utils/checkMobile';

const SelectWrapper = styled.div`
   ${({ disabled }) =>
      disabled &&
      `
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    `};
   li {
      :hover {
         background-color: #f4f4f4;
      }
   }

   > div {
      > div:last-child {
         max-height: 108px;
      }
   }
`;

const OptionWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const Icon = styled.i`
   font-size: 14px;
`;

const Label = styled.div`
   margin-left: 8px;
   color: #2f2f2f;
   line-height: 20px;
`;

function SelectProviderDropdown({
   disabled,
   onChange,
   owner,
   source,
   callList,
   isProviderLoading = false,
   providerList,
   isPractice = false,
   selected = '',
}) {
   const options = providerList || [];
   const [selectedOption, changeSelectedOption] = useState({});

   useEffect(() => {
      if (owner && !isPractice) {
         const provider = options.find(element => element.userId === owner);
         if (provider) {
            changeSelectedOption(provider);
            onChange(provider.userId);
         }
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const labelRenderer = ({ label }) => {
      return (
         <OptionWrapper>
            <Icon className='material-icons'>person</Icon>
            <Label>{label}</Label>
         </OptionWrapper>
      );
   };
   return (
      <SelectWrapper
         disabled={disabled}
         onClick={event => {
            if (callList) {
               callList();
            }
            event.stopPropagation();
         }}>
         <Dropdown
            loading={isProviderLoading}
            placeholder={isPractice ? 'Select Practice' : 'Select Provider'}
            options={options}
            search={!isMobile()}
            selected={[
               (selected &&
                  providerList.filter(el => el.userId === selected)[0].value) ||
                  selectedOption.value,
            ]}
            onChange={(_, allOption) => {
               changeSelectedOption(allOption[0]);
               onChange(allOption[0].userId);
            }}
            customPlaceholderRenderer={(__, [selected]) =>
               selected
                  ? labelRenderer(selected)
                  : `${isPractice ? 'Select Practice' : 'Select Provider'}`
            }
            label={labelRenderer}
         />
      </SelectWrapper>
   );
}

export default SelectProviderDropdown;
