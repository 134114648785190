import React, { Component } from 'react';
import styled from 'styled-components';
import Input from '@datashop/input';
import StyledButton from '@datashop/button';
import { Mixpanel } from 'helpers/mixpanelHelper';
import { Loader } from 'packages/loader';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';

const Form = styled.form`
   display: flex;
   flex-direction: column;
`;

const Header = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 16px;
   margin-bottom: 4px;
   margin-top: 8px;
`;
const Subtext = styled.p`
   margin-top: 4px;
   color: #868686;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;
const ButtonWrapper = styled.div`
   margin-top: 20px;
   display: flex;
`;

const Button = styled(StyledButton)`
   font-weight: 300;
`;

class SendNumberAssessment extends Component {
   state = {
      phoneNo: '',
      firstname: '',
      lastName: '',
      isLoading: false,
   };

   onChange = event => {
      const {
         target: { value, name },
      } = event;
      this.setState(() => ({ [name]: value }));
   };

   sendAssessment = event => {
      Mixpanel.track('Patient details entered', {
         category: 'sendAssessment',
      });
      event.preventDefault();
      this.setState({ isLoading: true });
      axios
         .post(
            `/innote-survey/assessments/5e7b840c98c5bf3ad547f6a2/patients/${this.state.phoneNo}/_manage`,
            {
               firstName: this.state.firstName,
               lastName: this.state.lastName,
               assignedTo: this.props.assignedTo,
            }
         )
         .then(() => {
            this.props.showSuccessToast();
            this.props.close();
         })
         .catch(
            ({
               response: {
                  data: { error },
               },
            }) => {
               createToast({ message: error.message });
            }
         )
         .finally(() => {
            Mixpanel.track('Assessment sent to patient', {
               category: 'sendAssessment',
            });
            this.setState({ isLoading: false });
         });
   };

   render() {
      const { phoneNo, firstName, lastName, isLoading } = this.state;
      return (
         <React.Fragment>
            <Form onSubmit={this.sendAssessment}>
               <Header>Patient's First Name</Header>
               <Input
                  icon='person'
                  value={firstName}
                  name='firstName'
                  onChange={this.onChange}
                  placeholder='John'
               />
               <Header>Patient's Last Name</Header>
               <Input
                  icon='person'
                  value={lastName}
                  name='lastName'
                  onChange={this.onChange}
                  placeholder='Doe'
               />
               <Header>Patient's Phone Number</Header>
               <Input
                  icon='phone'
                  value={phoneNo}
                  name='phoneNo'
                  onChange={this.onChange}
                  placeholder='(999) 999-9999'
               />
               <Subtext>
                  We'll send a secure link on this number. Patient can use the
                  link to take the assessment.
               </Subtext>
               <ButtonWrapper>
                  <Button
                     disabled={
                        isLoading ||
                        !phoneNo ||
                        phoneNo.length !== 10 ||
                        !firstName ||
                        !lastName
                     }
                     expanded
                     appearance='primary'>
                     {isLoading ? (
                        <Loader height='18px' width='18px' stroke='#fff' />
                     ) : (
                        'Send Assessment'
                     )}
                  </Button>
               </ButtonWrapper>
            </Form>
         </React.Fragment>
      );
   }
}

export default SendNumberAssessment;
