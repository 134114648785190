import React, { Fragment } from 'react';
import styled from 'styled-components';
import Placeholder from '@datashop/placeholder';

const BWMB = {
   width: '80%',
   height: '10px',
   marginBottom: '18px',
};

const BWOMB = {
   width: '80%',
   height: '10px',
   marginBottom: '0px',
};

const LWOMB = {
   width: '33%',
   height: '10px',
};

const HWMB = {
   width: '44%',
   height: '10px',
   marginBottom: '18px',
};

const CardPlaceholderWrapper = styled.div`
   display: flex;
   flex-direction: column;
   background: #ffffff;
   padding: ${({ smallList }) => (smallList ? '8px' : '16px')} 12px;
   border-radius: 4px;
`;

const CardPlaceholder = props => (
   <CardPlaceholderWrapper smallList={props.smallList} {...props}>
      {props.smallList ? (
         <Fragment>
            <Placeholder>
               <Placeholder.Paragraph style={HWMB} />
            </Placeholder>
            <Placeholder>
               <Placeholder.Paragraph style={LWOMB} />
            </Placeholder>
         </Fragment>
      ) : (
         <Fragment>
            <Placeholder>
               <Placeholder.Paragraph style={HWMB} />
            </Placeholder>
            <Placeholder>
               <Placeholder.Paragraph style={LWOMB} />
               <Placeholder.Paragraph style={BWMB} />
            </Placeholder>
            {!props.ignore && (
               <Placeholder>
                  <Placeholder.Paragraph style={LWOMB} />
                  <Placeholder.Paragraph style={BWOMB} />
               </Placeholder>
            )}
         </Fragment>
      )}
   </CardPlaceholderWrapper>
);

export default CardPlaceholder;
