import React, { Component, Fragment } from 'react';
import { format } from 'date-fns';

import VirtualVisit from 'components/VirtualVisit/VirtualVisit';
import InviteModal from './InviteModal';

import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';
import ScheduleNewVisitModal from 'packages/ScheduleNewVisitModal';

class VideoVisitContainer extends Component {
   isProvider = localStorage.getItem('access') === 'PROVIDER';
   state = {
      scheduleVisit: false,
      visitScheduled: false,
      shouldEditVisit: false,
      deleteRequest: false,
      showInviteModal: false,
      sendingInvite: false,
      isRequestSchedule: false,
      item: {},
      scheduleId: '',
      shouldRefreshScheduled: false,
   };

   componentDidMount() {
      Mixpanel.track('Virtual visits Tab clicked', {
         category: 'virtualVisit',
      });
   }

   toggleModal = (
      item = {},
      shouldEditVisit = false,
      scheduleId = null,
      isRequestSchedule = false
   ) => {
      const { type: callType, ...rest } = item;
      this.setState(
         prevState => ({
            scheduleVisit: !prevState.scheduleVisit,
            item: {
               ...rest,
               callType: callType === 'AUDIO' ? 'AUDIO' : 'VIDEO',
            },
            shouldEditVisit,
            scheduleId,
            deleteRequest: false,
            isRequestSchedule,
         }),
         () => {
            if (this.state.scheduleVisit) {
               Mixpanel.track('Schedule new visit clicked', {
                  category: 'virtualVisit',
               });
            }
         }
      );
   };

   toggleInviteModal = (scheduleId = '') => {
      this.setState({ scheduleId });
      this.setState(prevState => ({
         showInviteModal: !prevState.showInviteModal,
      }));
   };

   sendInvitation = participantList => {
      this.setState({ sendingInvite: true, shouldRefreshScheduled: false });
      axios
         .post(
            `/innote-survey/telehealth/video/schedule/${this.state.scheduleId}/visitors`,
            participantList
         )
         .then(() => {
            createToast({
               message: 'Invite sent',
               appearance: 'success',
            });
            this.setState({
               shouldRefreshScheduled: true,
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
            }
         )
         .finally(() => {
            this.setState({
               sendingInvite: false,
               showInviteModal: false,
               scheduleId: '',
            });
         });
   };

   scheduleVisit = values => {
      const {
         appointmentDate,
         firstName,
         lastName,
         phoneNumber: phoneNo,
         time,
         timezone,
         timesuffix,
         provider: assignedTo,
         scheduleId,
         dob,
         email,
         callType,
         isTestCall,
         reasonForVisit,
         gender,
         practiceId,
      } = values;
      const body = {
         scheduledOn: new Date(
            `${appointmentDate} ${time} ${timesuffix}`
         ).getTime(),
         assignedTo,
         scheduleId,
         dateString: `${format(
            new Date(`${appointmentDate} ${time} ${timesuffix}`),
            'dd LLLL p'
         )} ${timezone || ''}`,
         patientInfo: {
            firstName,
            lastName,
            phoneNo,
            dob,
            email: email || undefined,
            gender,
         },
         type: callType,
         isTestCall,
         reasonForVisit,
         practiceId,
      };
      this.setState({ visitScheduled: false });
      return axios[this.state.shouldEditVisit ? 'patch' : 'post'](
         `/innote-survey/telehealth/video/user/schedule`,
         body
      )
         .then(() => {
            this.setState(
               {
                  visitScheduled: true,
               },
               () => {
                  this.setState({ visitScheduled: false });
               }
            );
            createToast({
               message: `Visit ${
                  this.state.shouldEditVisit ? 'rescheduled' : 'scheduled'
               } successfully`,
               appearance: 'success',
            });
            if (callType === 'AUDIO') {
               Mixpanel.track('Audio call scheduled', {
                  category: 'audioCall',
               });
            }
            if (isTestCall) {
               Mixpanel.track(
                  `Test call - ${callType === 'AUDIO' ? 'audio' : 'video'}`,
                  {
                     category: 'testCall',
                  }
               );
            }
            if (reasonForVisit) {
               Mixpanel.track('Reason for Visit added while scheduling', {
                  category: 'visitReason',
               });
            }
            Mixpanel.track('Virtual Visit Scheduled', {
               category: 'virtualVisit',
            });
            if (this.state.scheduleId) {
               this.setState({ deleteRequest: true });
            }
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
            }
         );
   };

   joinRoom = item => {
      const { scheduleId, visitId, roomInfo, callStatus, role } = item;
      if (this.isProvider) {
         Mixpanel.track(
            `Virtual call joined - ${
               callStatus === 'READY_FOR_DOCTOR' ? 'after staff' : 'direct'
            }`,
            {
               category: 'virtualVisit',
            }
         );
      } else {
         Mixpanel.track('Virtual call joined', { category: 'virtualVisit' });
      }
      return this.props.createRoom(scheduleId, roomInfo, visitId, role);
   };

   render() {
      return (
         <Fragment>
            {this.state.scheduleVisit && (
               <ScheduleNewVisitModal
                  isRequestSchedule={this.state.isRequestSchedule}
                  item={this.state.item}
                  onClose={this.toggleModal}
                  onSubmitCallback={this.scheduleVisit}
               />
            )}
            {this.state.showInviteModal && (
               <InviteModal
                  onClose={this.toggleInviteModal}
                  sendInvitation={this.sendInvitation}
                  scheduleId={this.state.scheduleId}
                  refreshScheduled={this.fetchData}
                  sendingInvite={this.state.sendingInvite}
               />
            )}
            <VirtualVisit
               visitScheduled={this.state.visitScheduled}
               socketData={this.props.socketData}
               joinRoom={this.joinRoom}
               deleteRequest={this.state.deleteRequest}
               toggleModal={this.toggleModal}
               toggleInviteModal={this.toggleInviteModal}
               shouldRefreshScheduled={this.state.shouldRefreshScheduled}
            />
         </Fragment>
      );
   }
}

export default VideoVisitContainer;
