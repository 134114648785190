import React from 'react';
import styled from 'styled-components';
import { LabelWrapper, Wrapper } from './PoorEmoji';

const SvgWrapper = styled.svg`
   &:hover {
      circle {
         fill: #ffc208;
      }
      ellipse,
      path {
         fill: #6b4a06;
      }
   }
`;

const BadEmoji = ({ selected, showLabel }) => (
   <Wrapper>
      <SvgWrapper width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fill-rule='evenodd'>
            <circle
               fill={selected ? '#ffc208' : '#E5E5E5'}
               fill-rule='nonzero'
               cx='15.998'
               cy='15.998'
               r='15.998'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='10.3'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='21.7'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
            <path
               d='M16 20.4c3.376 0 6.317 2.003 7.858 4.967C21.703 23.51 18.97 22.4 16 22.4s-5.702 1.11-7.857 2.967C9.683 22.403 12.624 20.4 16 20.4z'
               fill={selected ? '#6b4a06' : '#595959'}
            />
         </g>
      </SvgWrapper>
      {showLabel && <LabelWrapper isSelected={selected}>Bad</LabelWrapper>}
   </Wrapper>
);

export default BadEmoji;
