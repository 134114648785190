import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Loader } from 'packages/loader';

const Wrapper = styled.div``;

const Header = styled.p`
   color: #2f2f2f;
   font-size: 16px;
   font-weight: bold;
   line-height: 24px;
`;

const AnswerList = styled.ul``;

const AnswerItem = styled.li`
   border-bottom: 1px solid #e5e5e5;
   padding: 12px 0;

   :last-child {
      border-bottom: none;
   }
`;
const Question = styled.p`
   color: #868686;
   line-height: 20px;
`;

const Answer = styled.p`
   margin-top: 8px;
   color: ${({ color }) => color};
   font-weight: 600;
   line-height: 20px;
`;

const ViewResponse = ({ questionList, dependentQuestionList }) => {
   const [responseList, setResponse] = useState([]);
   useEffect(() => {
      let newList = [];
      let skipPush = false;
      questionList.forEach(
         ({
            id,
            question,
            type,
            colorCoding,
            userResponse,
            answer: { choiceList },
         }) => {
            let answer, color;
            if (typeof userResponse === 'string') {
               if (type !== 'TEXTINPUT') {
                  const {
                     value,
                     colorCoding = '#D93737',
                     childId = null,
                  } = choiceList.find(({ id }) => id === userResponse);
                  if (!childId) {
                     answer = value;
                     color = colorCoding;
                  } else {
                     const {
                        userResponse: value,
                        colorCoding = '#D93737',
                     } = dependentQuestionList.find(({ id }) => childId === id);
                     answer = value;
                     color = colorCoding;
                  }
               } else {
                  answer = userResponse;
                  color = colorCoding || '#2f2f2f';
               }
            } else {
               if (userResponse.length) {
                  const answerList = choiceList.filter(({ id }) =>
                     userResponse.some(answerId => id === answerId)
                  );
                  color = answerList[0].colorCoding || '#D93737';
                  answer = answerList.reduce(
                     (prev, { value }) => (prev += `${prev && ', '}${value}`),
                     ''
                  );
               } else skipPush = true;
            }
            !skipPush && newList.push({ question, answer, color, id });
         }
      );
      setResponse(newList);
   }, [dependentQuestionList, questionList]);

   return (
      <Wrapper>
         {!responseList.length ? (
            <Loader />
         ) : (
            <Fragment>
               <Header>Questions</Header>
               <AnswerList>
                  {responseList.map(({ question, answer, color, id }) => (
                     <AnswerItem key={id}>
                        <Question>{question}</Question>
                        <Answer color={color}>{answer}</Answer>
                     </AnswerItem>
                  ))}
               </AnswerList>
            </Fragment>
         )}
      </Wrapper>
   );
};

export default ViewResponse;
