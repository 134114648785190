import React, { useState, useEffect } from 'react';
import { WrapperParent, StyledHeading } from 'components/Chat/style';
import * as S from 'components/Messages/style';

import { Mixpanel } from 'helpers/mixpanelHelper';

import PoorEmoji from './PoorEmoji.jsx';
import BadEmoji from './BadEmoji.jsx';
import AverageEmoji from './AverageEmoji.jsx';
import GoodEmoji from './GoodEmoji.jsx';
import AmazingEmoji from './AmazingEmoji.jsx';

import Icon from '@datashop/icon';
import Checkbox from '@datashop/checkbox';
import Textarea from '@datashop/textarea';
import StyledButton from '@datashop/button';
import axios from 'helpers/axios';
import styled from 'styled-components';

const Wrapper = styled.div`
   position: relative;
   font-weight: 600;
`;

const FeedBackWrapper = styled.div`
   bottom: 0;
   position: absolute;
   background-color: #f4f4f4;
   width: 320px;
   margin: 0 auto;
   left: 0;
   right: 0;
`;

const DetailWrapper = styled.div`
   max-height: ${props => (props.showDetail ? 'min-content' : '0')};
   overflow: hidden;
   transition: all 0.3s ease-in;
`;

const CheckboxWrapper = styled.div`
   margin: 16px 0px;
`;

const Button = styled(StyledButton)`
   display: flex;
   align-items: center;
`;

function FeedBack(props) {
   const [userChoice, setUserChoice] = useState('');
   const [rating, setRating] = useState(1);
   const [feedBack, setFeedBack] = useState('');
   const [canContact, setCanContact] = useState(false);
   const [showFooter, setShowFooter] = useState(true);

   useEffect(() => {
      Mixpanel.track('Feedback started', {
         category: 'feedBack',
      });
   }, []);

   const getReview = () => {
      let review = '';
      switch (userChoice) {
         case 'poor':
            review = `We're sorry we've let you down; what can be improved?`;
            break;
         case 'bad':
            review = `We're sorry; what can be improved?`;
            break;
         case 'average':
            review = `Can you share what can be improved?`;
            break;
         case 'good':
            review = `We're glad to hear that; what did you like the best?`;
            break;
         case 'amazing':
            review = `We're glad to hear that; what did you like the best?`;
            break;

         default:
            break;
      }
      return <p style={{ fontSize: 14 }}>{review}</p>;
   };

   const onSubmit = () => {
      axios
         .post('/innote-survey/user/feedback ', {
            rating,
            feedback: feedBack,
         })
         .then(() => {
            setShowFooter(false);
            props.hideFeedBackComponent();
            Mixpanel.track('Feedback submitted', {
               category: 'feedBack',
            });
         });
   };

   return (
      <Wrapper>
         {showFooter && (
            <FeedBackWrapper>
               <WrapperParent>
                  <S.HeadingWrapper
                     style={{
                        padding: 12,
                        height: '100%',
                        maxHeight: 'min-content',
                     }}>
                     <S.Row
                        isCenter={true}
                        style={{
                           justifyContent: 'space-between',
                           paddingBottom: '12px',
                        }}>
                        <S.Column>
                           <StyledHeading
                              style={{
                                 width: '242px',
                                 fontSize: 14,
                              }}>
                              What do you think about the product?
                           </StyledHeading>
                        </S.Column>
                        <Icon
                           name='close'
                           style={{
                              fontSize: 16,
                              cursor: 'pointer',
                              fontWeight: 600,
                           }}
                           onClick={() => {
                              setShowFooter(false);
                              props.hideFeedBackComponent();
                           }}
                        />
                     </S.Row>
                     <S.Row
                        isCenter={true}
                        style={{
                           justifyContent: 'space-between',
                           paddingTop: '12px',
                           paddingBottom: '16px',
                        }}>
                        <S.Column
                           onClick={() => {
                              setUserChoice('poor');
                              setRating(1);
                           }}>
                           <PoorEmoji
                              selected={userChoice === 'poor'}
                              showLabel={userChoice !== ''}
                           />
                        </S.Column>
                        <S.Column
                           onClick={() => {
                              setUserChoice('bad');
                              setRating(2);
                           }}>
                           <BadEmoji
                              selected={userChoice === 'bad'}
                              showLabel={userChoice !== ''}
                           />
                        </S.Column>
                        <S.Column
                           onClick={() => {
                              setUserChoice('average');
                              setRating(3);
                           }}>
                           <AverageEmoji
                              selected={userChoice === 'average'}
                              showLabel={userChoice !== ''}
                           />
                        </S.Column>
                        <S.Column
                           onClick={() => {
                              setUserChoice('good');
                              setRating(4);
                           }}>
                           <GoodEmoji
                              selected={userChoice === 'good'}
                              showLabel={userChoice !== ''}
                           />
                        </S.Column>
                        <S.Column
                           onClick={() => {
                              setUserChoice('amazing');
                              setRating(5);
                           }}>
                           <AmazingEmoji
                              selected={userChoice === 'amazing'}
                              showLabel={userChoice !== ''}
                           />
                        </S.Column>
                     </S.Row>

                     {userChoice !== '' && (
                        <DetailWrapper showDetail={userChoice !== ''}>
                           <S.Row
                              isCenter={true}
                              style={{
                                 justifyContent: 'space-between',
                                 paddingBottom: '12px',
                                 width: '280px',
                              }}>
                              <S.Column>{getReview()}</S.Column>
                           </S.Row>
                           <S.Row>
                              <Textarea
                                 placeholder='Tell us what you think'
                                 rows='5'
                                 maxLength='140'
                                 name='feedBack'
                                 value={feedBack}
                                 onChange={e => setFeedBack(e.target.value)}
                                 style={{
                                    color: '#2f2f2f',
                                    width: '100%',
                                 }}
                              />
                           </S.Row>
                           <S.Row>
                              <CheckboxWrapper>
                                 <Checkbox
                                    name='canContact'
                                    checked={canContact}
                                    onChange={event => {
                                       event.persist();
                                       setCanContact(!canContact);
                                    }}
                                    onClick={event => {
                                       event.persist();
                                       setCanContact(!canContact);
                                    }}
                                    id='canContact'
                                    label='Innovaccer can contact me about the feedback'
                                 />
                              </CheckboxWrapper>
                           </S.Row>
                           <S.Row>
                              <Button
                                 onClick={onSubmit}
                                 appearance='primary'
                                 expanded
                                 data-test='feedback-submit'>
                                 Submit
                              </Button>
                           </S.Row>
                        </DetailWrapper>
                     )}
                  </S.HeadingWrapper>
               </WrapperParent>
            </FeedBackWrapper>
         )}
      </Wrapper>
   );
}

export default FeedBack;
