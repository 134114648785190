import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import Text from '@datashop/text';
import Avatar from '@datashop/avatar';
import Card from '@datashop/card';
import IconComp from '@datashop/icon';
import Button from '@datashop/button';

// error page
export const Wrapper = styled.div`
   align-items: center;
   justify-content: center;
   display: flex;
   flex: 1;
`;

export const DataWrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: 12px 16px;
   align-items: center;
   justify-content: center;
`;

export const Header = styled.div`
   margin-top: 12px;
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

export const SubHeader = styled.div`
   margin-top: 8px;
   color: #868686;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
`;

export const Image = styled.img`
   height: 143px;
   width: 260px;
`;

export const Icon = styled.i`
   color: #868686;
   font-size: 48px;
`;

// index page
export const SpaceDot = styled.div`
   height: 4px;
   width: 4px;
   background: #868686;
   border-radius: 50%;
   margin: 0 4px;
`;

export const TimeWrapper = styled.div`
   color: #868686;
   font-size: 12px;
   line-height: 14px;
`;

export const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

export const RowWrapper = styled(Row)`
   padding-top: 12px;
   padding-bottom: 12px;
   border-bottom: #e5e5e5 solid 1px;
   :last-child {
      border-bottom: none;
   }
`;

export const Right = styled.div`
   display: flex;
   justify-content: flex-end;
`;

export const Left = styled.div`
   display: flex;
   justify-content: flex-start;
`;

export const MarginAuto = styled.div`
   display: flex;
   margin-left: -12px;
   height: ${window.innerHeight - 136}px;
`;

export const ContainerBody = styled.div`
   height: ${window.innerHeight - 136}px;
   max-height: ${window.innerHeight - 136}px;
   overflow-y: auto;
   padding-top: 8px;
   display: flex;
   flex-direction: column-reverse;
`;

export const Column = styled.div`
   display: flex;
   flex-direction: column;
`;

export const Bottom = styled.div``;

export const FooterWrapper = styled.div`
   display: flex;
   align-items: center;
   min-height: 64px;
   max-height: 134px;
   padding: 24px 8px 12px 16px;
   background-color: white;
   bottom: 0;
   position: sticky;
   border-top: 1px solid #e5e5e5;

   input {
      background-color: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 24px;
      min-height: 48px;
   }
`;

export const TextBoxOuterWrapper = styled.div`
   flex: 1;
   position: relative;
`;

export const NewLineInfo = styled.div`
   position: absolute;
   top: -15px;
   left: 24px;
   font-size: 11px;
   color: #828282;
   code {
      color: #444;
      font-weight: bold;
   }
`;

export const TextBoxWrapper = styled.div`
   display: flex;
   justify-content: center;
   flex-direction: column;
   position: relative;
   pointer-events: auto;
   border-radius: 24px;
   overflow: hidden;
   border: 1px solid rgb(213, 213, 213);

   :focus-within {
      outline: 0px;
      border: 1px solid rgb(0, 112, 221);
      box-shadow: rgb(2 112 221 / 16%) 0px 0px 0px 3px;
   }
`;

export const TextBox = styled(TextareaAutosize)`
   width: 100%;
   background-color: rgb(255, 255, 255);
   border: none;
   border-radius: 24px;
   min-height: 48px;
   height: 32px;
   line-height: 24px;
   font-size: 15px;
   box-sizing: border-box;
   color: rgb(45, 49, 66);
   padding: 12px;
   resize: none;

   :focus {
      outline: none;
   }
`;

export const ButtonWrapper = styled.div`
   margin-left: 8px;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;

   button {
      border-radius: 100%;
      height: 40px;
   }
`;

export const CardWrapper = styled(Card)`
   width: calc(100% - 24px);
   background-color: #ffffff;
   padding: 12px;
   margin: 0 12px 12px 12px;
   :first-child {
      margin-top: 12px;
   }
`;

export const CardWrapperOverflow = styled(CardWrapper)`
   max-height: 280px;
`;

export const Available = styled.div`
   display: flex;
   align-items: flex-end;
   margin-right: 8px;
`;

export const AvatarWrapper = styled(Avatar)`
   width: 40px;
   height: 40px;
`;

export const StyledHeading = styled(Text)`
   height: 32px;
   width: 193px;
   color: #151414;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 32px;
`;

export const TextWrapper = styled(Text)`
   color: ${({ color }) => (color ? color : '#868686')};
`;

export const IconWrapper = styled(IconComp)`
   color: #868686;
   font-size: 16px;
   margin-right: 8px;
`;

export const SwitchWrapper = styled.div`
   display: flex;
   flex: auto;
   justify-content: flex-end;
   ${({ isBottom = false }) => isBottom && 'align-self: flex-end;'}
   ${({ isTop }) => isTop && 'height: 45px;'}
`;

export const AvailableDot = styled.div`
   background-color: ${({ isAvailable }) =>
      isAvailable ? '#2ea843;' : '#868686'}
   border: 2px solid #ffffff;
   border-radius: 100%;
   width: 12px;
   height: 12px;
   margin-left: -12px;
`;

export const ModalContainer = styled.div`
   > div {
      max-width: 400px;
      display: flex;
      left: 0;
      background: none;
      justify-content: center;
      margin: 0 auto;
      left: 0;
      right: 0;
   }
`;

export const WrapperParent = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
   width: 100%;
`;

export const HeadingWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 12px 16px;
   height: 72px;
   max-height: 72px;
   position: sticky;
   background-color: ${({ theme }) => theme.datashop.palette.white.main};
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const ContentWrapper = styled.div`
   background-color: ${({ theme }) => theme.datashop.palette.white.main};
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   overflow: hidden;
`;

export const ErrorButton = styled(Button)`
   margin-top: 16px;
   background-color: #e5e5e5;
   color: #fff;
`;

export const LoaderWrapper = styled.div`
   right: 0;
   bottom: 0;
   left: 0;
   top: 0;
   position: fixed;
   height: 32px;
   margin: auto;
`;
