import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dropdown from '@datashop/dropdown';
import timezoneList from './timeZones';

const SelectWrapper = styled.div`
   ${({ disabled }) =>
      disabled &&
      `
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    `};
   li {
      :hover {
         background-color: #f4f4f4;
      }
   }

   > div {
      > div:last-child {
         max-height: 108px;
      }
   }
`;

const OptionWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const Icon = styled.i`
   font-size: 14px;
`;

const Label = styled.div`
   margin-left: 8px;
   color: #2f2f2f;
   line-height: 20px;
`;

function TimezoneDropdown({ disabled, onChange, owner }) {
   const options = timezoneList;
   const [selectedOption, changeSelectedOption] = useState({});

   useEffect(() => {
      const userTimezone = localStorage.getItem('timezone') || '';
      const timezone = timezoneList.findIndex(
         ({ abbr }) => abbr === userTimezone
      );
      if (timezone > -1) {
         localStorage.setItem('timezone', timezoneList[timezone].abbr);
         onChange(timezoneList[timezone].abbr);
         changeSelectedOption(timezoneList[timezone]);
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const labelRenderer = ({ label, value }) => (
      <OptionWrapper>
         <Icon className='material-icons'>schedule</Icon>
         <Label>{`${label.split(' ')[0]} ${value}`}</Label>
      </OptionWrapper>
   );
   return (
      <SelectWrapper
         disabled={disabled}
         onClick={event => event.stopPropagation()}>
         <Dropdown
            placeholder='Select timezone'
            options={options}
            search
            selected={[selectedOption.value]}
            onChange={(_, allOption) => {
               changeSelectedOption(allOption[0]);
               localStorage.setItem('timezone', allOption[0].abbr);
               onChange(allOption[0].abbr);
            }}
            customPlaceholderRenderer={(__, [selected]) =>
               selected ? labelRenderer(selected) : `Select timezone`
            }
            label={labelRenderer}
         />
      </SelectWrapper>
   );
}

export default TimezoneDropdown;
