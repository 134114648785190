import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Error from 'components/Chat/Error';
import CardCopyRight from './CardCopyRight';
import CardLogOut from './CardLogOut';
import CardSoundNotification from './CardSoundNotification';
import CardAvailable from './CardAvailable';
import CardUserInfo from './CardUserInfo';
import CardSupport from './CardSupport';
import * as S from './style';
import HelpArticles from 'components/HelpArticles';

const Profile = ({ isUserInfoError, userInfo }) => {
   const [showHelpModal, setShowHelpModal] = useState(false);

   const toggleHelpModal = useCallback(() => {
      setShowHelpModal(show => !show);
   }, []);

   const closeHelpModal = useCallback(() => {
      setShowHelpModal(() => false);
   }, []);

   if (isUserInfoError) {
      return (
         <S.MarginAuto>
            <Error type='error' />
         </S.MarginAuto>
      );
   }

   return (
      <>
         {showHelpModal && <HelpArticles close={closeHelpModal} />}
         <S.Wrapper>
            <CardUserInfo />
            <CardAvailable />
            <CardSoundNotification />
            <CardSupport onClick={toggleHelpModal} />
            <CardLogOut />
            <CardCopyRight />
         </S.Wrapper>
      </>
   );
};

const mapStateToProps = ({
   frontDoorSocket: { userInfo, isUserInfoError },
}) => ({
   userInfo,
   isUserInfoError,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
