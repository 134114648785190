import React from 'react';
import StyledCard from '@datashop/card';
import styled from 'styled-components';
import SelectProviderDropdown from './SelectProviderDropdown';

const Card = styled(StyledCard)`
   margin-top: 12px;
   padding: 16px;
   background-color: #fff;
`;

const SelectProviderText = styled.p`
   color: #868686;
   line-height: 20px;
   margin-bottom: 16px;
`;

function SelectProvider({ providerList, onChange }) {
   return (
      <Card shadow='light'>
         <SelectProviderText>
            Please select a provider to review the assessment response
         </SelectProviderText>
         <SelectProviderDropdown
            providerList={providerList}
            onChange={props => {
               onChange(props);
            }}
            source='Assessment'
         />
      </Card>
   );
}

export default SelectProvider;
