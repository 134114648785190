import React, { useState } from 'react';
import Popup from '@datashop/popup';
import styled from 'styled-components';

const MoreIcon = styled.i`
   margin-left: 4px;
   border-radius: 4px;
   padding: 4px;
   font-size: 12px;
   cursor: pointer;

   :hover {
      background-color: #e5e5e5;
   }
`;

const MoreOptionWrapper = styled.ul`
   border-radius: 4px;
   background: #fff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
`;

const OptionItem = styled.li`
   padding: 8px;
   display: flex;
   align-items: center;
   cursor: pointer;

   :hover {
      background-color: #f4f4f4;
   }
`;

const OptionIcon = styled.i`
   font-size: 20px;
   color: #2f2f2f;
   margin-right: 8px;
`;

const OptionText = styled.p`
   color: #2f2f2f;
   line-height: 20px;
`;

export default function MoreOptionDropdown({
   reschedule,
   cancelSchedule,
   source = '',
   invite,
   copyInvitation,
   markTest,
   item,
   reSendMsg,
   markInComplete,
}) {
   const [showMoreOption, toggleMoreOption] = useState(false);

   return (
      <Popup
         trigger={
            <MoreIcon
               onClick={event => event.stopPropagation()}
               className='material-icons'>
               more_vert
            </MoreIcon>
         }
         verticalOffset={4}
         onToggle={() => {
            toggleMoreOption(!showMoreOption);
         }}
         position='BottomRight'
         open={showMoreOption}>
         <MoreOptionWrapper
            onClick={event => {
               event.stopPropagation();
               toggleMoreOption(!showMoreOption);
            }}>
            {/* {reSendMsg && !item.messageSent && ( */}
            {reSendMsg && (
               <OptionItem onClick={reSendMsg}>
                  <OptionIcon className='material-icons'>message</OptionIcon>
                  <OptionText>Resend message</OptionText>
               </OptionItem>
            )}
            {invite && (
               <OptionItem onClick={invite}>
                  <OptionIcon className='material-icons'>
                     add_ic_call
                  </OptionIcon>
                  <OptionText>Invite</OptionText>
               </OptionItem>
            )}
            {copyInvitation && (
               <OptionItem onClick={copyInvitation}>
                  <OptionIcon className='material-icons'>
                     content_copy
                  </OptionIcon>
                  <OptionText>Copy Invitation</OptionText>
               </OptionItem>
            )}
            {reschedule && (
               <OptionItem onClick={reschedule}>
                  <OptionIcon className='material-icons'>
                     insert_invitation
                  </OptionIcon>
                  <OptionText>Reschedule</OptionText>
               </OptionItem>
            )}
            {markTest && (
               <OptionItem onClick={markTest}>
                  <OptionIcon className='material-icons'>ballot</OptionIcon>
                  <OptionText>
                     {item.isTestCall
                        ? 'Unmark as test call'
                        : 'Mark as test call'}
                  </OptionText>
               </OptionItem>
            )}
            {cancelSchedule && (
               <OptionItem onClick={cancelSchedule}>
                  <OptionIcon color='#D93737' className='material-icons'>
                     cancel
                  </OptionIcon>
                  <OptionText>{`${
                     source === 'Requests' ? 'Discard Request' : 'Cancel Appt.'
                  }`}</OptionText>
               </OptionItem>
            )}
            {markInComplete && (
               <OptionItem onClick={markInComplete}>
                  <OptionIcon className='material-icons'>error</OptionIcon>
                  <OptionText>
                     {item.callStatus === 'INCOMPLETE'
                        ? 'Mark complete'
                        : 'Mark incomplete'}
                  </OptionText>
               </OptionItem>
            )}
         </MoreOptionWrapper>
      </Popup>
   );
}
