export const ROLES = {
   staff: 'STAFF',
   provider: 'PROVIDER',
   patient: 'PATIENT',
   visitor: 'VISITOR',
};

export const USER_TYPE = {
   staff: 'STAFF',
   provider: 'PROVIDER',
};
