import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import LoadingState from 'components/VirtualVisit/TabLoadingState';
import CardWrapper from './Card';
import Error from 'components/Chat/Error';
import ObserveCard from './ObserveCard';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import { changeChat, setSelectedChat } from 'store/frontDoorSocket/action';

const Wrapper = styled.div`
   max-height: ${window.innerHeight - 130}px;
   overflow: auto;
`;
const MarginAuto = styled.div`
   display: flex;
   margin-left: -12px;
   height: ${window.innerHeight - 130}px;
`;

const MyPatientsTab = props => {
   const {
      myInboxList,
      endReachedMyInbox,
      isSocketError,
      isMyTabLoading,
      isLoadMoreMyInbox,
      getMyInbox,
   } = props;

   const openChat = item => {
      props.setSelectedChat(item);
   };

   if (myInboxList.length === 0 && isSocketError) {
      return (
         <MarginAuto>
            <Error type='error' />
         </MarginAuto>
      );
   }
   return (
      <Wrapper>
         {isMyTabLoading ? (
            <LoadingState marginRight={true} />
         ) : (
            <>
               {myInboxList.length > 0 ? (
                  <ObserveCard
                     chats={myInboxList}
                     isMyPatientTab={true}
                     openChat={openChat}
                     isLoadingMore={isLoadMoreMyInbox}
                     endReached={endReachedMyInbox}
                     fetchData={getMyInbox}
                  />
               ) : (
                  <CardWrapper err='No record to show' />
               )}
            </>
         )}
         {isLoadMoreMyInbox ? (
            <CardPlaceholder
               smallList
               style={{
                  marginRight: '12px',
                  marginTop: '12px',
                  marginBottom: '12px',
               }}
            />
         ) : (
            endReachedMyInbox &&
            myInboxList.length > 50 && (
               <CardWrapper err='No more records to show' />
            )
         )}
      </Wrapper>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      myInboxList,
      isSocketError,
      isMyTabLoading,
      isLoadMoreMyInbox,
      endReachedMyInbox,
   },
}) => ({
   myInboxList,
   isSocketError,
   isMyTabLoading,
   isLoadMoreMyInbox,
   endReachedMyInbox,
});

const mapDispatchToProps = { changeChat, setSelectedChat };

export default connect(mapStateToProps, mapDispatchToProps)(MyPatientsTab);
