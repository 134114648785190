import React, { Component } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import Button from '@datashop/button';
import Radio from '@datashop/radio';
import DatePicker from '@datashop/date-picker';
import { Mixpanel } from 'helpers/mixpanelHelper';

const DialogWrapper = styled.div`
   width: 320px;
   border-radius: 4px;
   padding: 16px;
`;

const HeaderText = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 24px;
`;

const HeaderSubText = styled.div`
   margin-top: 4px;
   color: #868686;
   letter-spacing: 0;
   line-height: 20px;
`;

const RadioButtonWrapper = styled.div`
   margin: 18px 0;
   display: flex;
   flex-direction: column;
`;

const DateWrapper = styled.div`
   margin-bottom: 32px;
`;

const DatePickerWrapper = styled.div`
   > div {
      > div:last-child {
         margin-top: ${({ open }) => open && '80px'};
      }
   }
`;

const DatePickerWrapperEnd = styled.div`
   > div {
      > div:last-child {
         margin-top: ${({ open }) => open && '20px'};
      }
   }
`;
const ButtonWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
`;

const DateText = styled.p`
   margin-top: 12px;
   color: #2f2f2f;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 16px;
`;

class DownloadCallModal extends Component {
   state = {
      radioButtonSelected: '',
      startDate: '',
      endDate: '',
      showStartDate: false,
      showEndDate: false,
   };

   onRadioChange = event => {
      const {
         target: { value, name },
      } = event;
      this.setState(() => ({ [name]: value }));
   };

   handleFocusChange = type => {
      if (!this.timer)
         this.setState(prevState => ({ [type]: !prevState[type] }));
      this.timer = setTimeout(() => {
         this.timer = null;
      }, 400);
   };

   handleOutsideClick = type => {
      if (this.state[type]) {
         this.setState({ [type]: false });
      }
   };

   onDateChange = (date, type) => {
      this.setState({ [type]: new Date(date) });
      let dateType = 'showStartDate';
      if (type === 'endDate') {
         dateType = 'showEndDate';
      }
      this.handleFocusChange(dateType);
   };

   initiateDownload = () => {
      let from = new Date();
      let to = new Date();
      if (this.state.radioButtonSelected === 'Last 7 Days') {
         from.setDate(from.getDate() - 7);
      }
      if (this.state.radioButtonSelected === 'Custom') {
         from = this.state.startDate;
         to = this.state.endDate;
      }
      this.props.downloadFile(
         format(from, 'yyyy-MM-dd'),
         format(to, 'yyyy-MM-dd')
      );
      Mixpanel.track('Call log downloaded', { category: 'virtualVisit' });
   };

   render() {
      const { radioButtonSelected } = this.state;
      return (
         <DialogWrapper>
            <HeaderText>Download call records</HeaderText>
            <HeaderSubText>
               Please select the date for which you want to download the
               records.
            </HeaderSubText>
            <RadioButtonWrapper>
               <Radio
                  name='radioButtonSelected'
                  value='Today'
                  checked={radioButtonSelected === 'Today'}
                  label='Today'
                  onChange={this.onRadioChange}
                  key={'Today'}
                  id={'Today'}
               />
               <div style={{ margin: '6px 0' }} />
               <Radio
                  name='radioButtonSelected'
                  value='Last 7 Days'
                  checked={radioButtonSelected === 'Last 7 Days'}
                  label='Last 7 Days'
                  onChange={this.onRadioChange}
                  key={'Last 7 Days'}
                  id={'Last 7 Days'}
               />
               <div style={{ margin: '6px 0' }} />
               <Radio
                  name='radioButtonSelected'
                  value='Custom'
                  checked={radioButtonSelected === 'Custom'}
                  label='Custom'
                  onChange={this.onRadioChange}
                  key={'Custom'}
                  id={'Custom'}
               />
            </RadioButtonWrapper>
            {radioButtonSelected === 'Custom' && (
               <DateWrapper
                  open={this.state.showEndDate || this.state.showStartDate}>
                  <DateText>Start Date</DateText>
                  <DatePickerWrapper open={this.state.showStartDate}>
                     <DatePicker
                        icon='event'
                        ref={e => (this.dp = e)}
                        date={this.state.startDate}
                        dateFormat='MM/DD/YYYY'
                        disableDate={date => date > new Date()}
                        onDateChange={date =>
                           this.onDateChange(date, 'startDate')
                        }
                        showDatePicker={this.state.showStartDate}
                        onFocusChange={() =>
                           this.handleFocusChange('showStartDate')
                        }
                        position='Top'
                        name='startDate'
                        appendToBody={false}
                     />
                  </DatePickerWrapper>
                  <DateText>End Date </DateText>
                  <DatePickerWrapperEnd open={this.state.showEndDate}>
                     <DatePicker
                        icon='event'
                        date={this.state.endDate}
                        disabled={!this.state.startDate}
                        dateFormat='MM/DD/YYYY'
                        disableDate={date => {
                           const checkDate = new Date(date).getTime();
                           return (
                              checkDate > new Date().getTime() ||
                              checkDate <
                                 new Date(this.state.startDate).getTime()
                           );
                        }}
                        closeOnDateSelect
                        onDateChange={date =>
                           this.onDateChange(date, 'endDate')
                        }
                        showDatePicker={this.state.showEndDate}
                        onFocusChange={() =>
                           this.handleFocusChange('showEndDate')
                        }
                        position='Top'
                        name='endDate'
                        appendToBody={false}
                     />
                  </DatePickerWrapperEnd>
               </DateWrapper>
            )}
            <ButtonWrapper>
               <Button
                  appearance='primary'
                  onClick={this.initiateDownload}
                  disabled={
                     radioButtonSelected === 'Custom'
                        ? !(this.state.startDate && this.state.endDate)
                        : !radioButtonSelected
                  }>
                  Download
               </Button>
            </ButtonWrapper>
         </DialogWrapper>
      );
   }
}

export default DownloadCallModal;
