import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
   ReportingObserver as ReportingObserverIntegration,
   CaptureConsole,
} from '@sentry/integrations';
import { version, name } from '../../package.json';

const SentryService = () => {
   if (process.env.REACT_APP_BUILD === 'PROD') {
      Sentry.init({
         dsn: `https://6938afa992454d758393fbe43385cbb0@sentry.internal.tech/117`,
         sampleRate: 1.0,
         attachStacktrace: true,
         release: `${name}@${version}`,
         integrations: [
            new Integrations.BrowserTracing(),
            new ReportingObserverIntegration(),
            new CaptureConsole({
               levels: ['error', 'warn', 'log', 'info'],
            }),
         ],
      });
   }
};

export const logSentry = param => {
   if (process.env.REACT_APP_BUILD === 'PROD') {
      Sentry.captureMessage(`Debug Mixpanel, ${JSON.stringify(param)}`);
   }
};

export default SentryService;
