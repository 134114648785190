import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '@datashop/card';
import Text from '@datashop/text';
import Avatar from '@datashop/avatar';
import { getLetterIconColor } from 'helpers/badgeColor';
import returnCamelCasedName from 'helpers/nameTransformer';
import { capitalize, isEmpty, get } from 'lodash-es';
import { format } from 'date-fns';
import formatPhoneNumber from 'helpers/formatPhone';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';
import Icon from '@datashop/icon';
import { Loader } from 'packages/loader';

const Wrapper = styled(Card)`
   // width: calc(100% - 12px);
   margin-bottom: 12px;
   background-color: #ffffff;
   padding: 12px;
   :last-child {
      margin-bottom: 0;
   }
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

const Column = styled.div`
   display: flex;
   flex-direction: column;
`;

const AvatarWrapper = styled(Avatar)``;

const TextWrapper = styled(Text)`
   color: #868686;
   margin-top: 4px;
`;

const AvailableDot = styled.div`
   background-color: ${({ isAvailable }) =>
      isAvailable ? '#2ea843;' : '#868686'}
   border: 2px solid #ffffff;
   border-radius: 100%;
   width: 12px;
   height: 12px;
   margin-left: -12px;
`;

const SpaceDot = styled.div`
   color: #868686;
   font-family: NunitoSans;
   font-size: 24px;
   line-height: 16px;
   margin: -2px 8px 0 8px;
`;

const Available = styled.div`
   display: flex;
   align-items: flex-end;
   margin-right: 8px;
`;

const getItem = (item, isMyPatient) => {
   if (isEmpty(item)) {
      return {};
   }

   if (isMyPatient) {
      item.patientInfo = item;
      item.patientInfo.phoneNo = item.phoneNumber;
   }

   let {
      firstName = '',
      lastName = '',
      phoneNo: phone = '',
      patientId = '',
      gender = '',
      dob = '',
   } = item.patientInfo;

   return {
      firstName,
      lastName,
      phone: formatPhoneNumber(phone),
      patientId,
      gender,
      dob,
      callType: item.type || '',
      updatedOn: item.updatedOn || '',
   };
};

const AdvanceCard = ({
   isCenter = false,
   isAvatar = false,
   err,
   item,
   openChat,
   isMyPatient = false,
   sendInvite,
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const data = getItem(item, isMyPatient);

   const openChatHandler = () => {
      setIsLoading(true);
      const payload = {
         type: 'ONE_TO_ONE',
         add_users: [
            {
               user_id: `PATIENT.${data.patientId}` || '',
               is_admin: false,
            },
         ],
         extra_info: {
            ...data,
         },
      };

      axios
         .post('/innote-survey/telehealth/chat/inbox', payload)
         .then(({ data }) => {
            setIsLoading(false);
            const inboxDetails = data;
            openChat(inboxDetails);
         })
         .catch(e => {
            setIsLoading(false);
            if (e.response.status === 400) {
               sendInvite(data);
            } else {
               createToast({
                  message: get(e, 'message', 'Something went wrong!'),
               });
            }
         });
   };

   if (err) {
      return <Wrapper shadow='light'>{err}</Wrapper>;
   }

   return (
      <>
         <Wrapper
            shadow='light'
            onClick={openChatHandler}
            style={{ cursor: 'pointer' }}>
            <Row isCenter={isCenter} style={{ alignItems: 'flex-start' }}>
               {isAvatar && (
                  <Available>
                     <AvatarWrapper
                        text={[data.firstName, data.lastName]
                           .map((el, i) => (i <= 1 && el ? el[0] : ''))
                           .join('')
                           .trimRight()
                           .toUpperCase()}
                        backgroundColor={getLetterIconColor(
                           data.firstName,
                           data.lastName
                        )}
                     />
                     {data.isOnline && (
                        <AvailableDot isAvailable={data.isOnline} />
                     )}
                  </Available>
               )}
               <Column style={{ flex: 1 }}>
                  <Row
                     isCenter={isCenter}
                     style={{ justifyContent: 'space-between' }}>
                     <Text
                        fontSize='large'
                        fontWeight='bold'
                        title={returnCamelCasedName(
                           data.firstName,
                           data.lastName
                        )}
                        ellipsis={true}
                        style={{ maxWidth: '250px' }}>
                        {returnCamelCasedName(data.firstName, data.lastName)}
                     </Text>
                     {isLoading ? (
                        <Loader stroke='#0070DD' height='12px' width='12px' />
                     ) : (
                        <Icon
                           style={{
                              color: '#2F2F2F',
                              fontSize: '16px',
                              marginRight: '8px',
                           }}
                           name='chevron_right'
                        />
                     )}
                  </Row>
                  <Row>
                     {data.phone && <TextWrapper>{data.phone}</TextWrapper>}
                     {data.dob && (
                        <>
                           {data.phone && <SpaceDot>.</SpaceDot>}
                           <TextWrapper>
                              {format(new Date(data.dob), 'MM/dd/yyyy')}
                           </TextWrapper>
                        </>
                     )}
                     {data.gender && (
                        <>
                           {(data.phone || data.dob) && <SpaceDot>.</SpaceDot>}
                           <TextWrapper>{capitalize(data.gender)}</TextWrapper>
                        </>
                     )}
                  </Row>
                  {data.callType && (
                     <Row
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           marginTop: '4px',
                        }}>
                        <Icon
                           style={{
                              color: '#868686',
                              fontSize: '16px',
                              marginRight: '8px',
                           }}
                           name={
                              data.callType === 'VIDEO' ? 'videocam' : 'call'
                           }
                        />
                        <TextWrapper style={{ maxWidth: '300px' }} ellipsis>
                           {`${
                              data.callType === 'VIDEO'
                                 ? 'Video visit'
                                 : 'Visit'
                           } completed ${
                              data.updatedOn &&
                              `on ${format(
                                 new Date(data.updatedOn),
                                 'MM/dd/yyyy'
                              )}`
                           }`}
                        </TextWrapper>
                     </Row>
                  )}
               </Column>
            </Row>
         </Wrapper>
      </>
   );
};

export default AdvanceCard;
