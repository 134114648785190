let __CHAT_SOCKETS__ = [];
const SOCKET_CLOSING_OR_CLOSED_STATE = [2, 3];
class Socket {
   connect = (onClose, onError, onMessage, onOpen) => {
      try {
         __CHAT_SOCKETS__.forEach(socket => {
            if (socket && typeof socket === 'object') {
               if (!(socket.readyState in SOCKET_CLOSING_OR_CLOSED_STATE)) {
                  socket.onclose = null;
                  socket.close();
               }
            }
         });
         __CHAT_SOCKETS__ = [];
      } catch (err) {
         console.log(err);
      }
      this.socket = new WebSocket(
         `${
            process.env[
               `REACT_APP_${process.env.REACT_APP_BUILD}_INNOTE_SOCKET_URL`
            ]
         }/?AUTHORIZATION=${localStorage.getItem('token')}&USERTYPE=provider`
      );

      __CHAT_SOCKETS__.push(this.socket);

      this.socket.onopen = e => {
         const isProvider = localStorage.getItem('access') === 'PROVIDER';
         const exclusion = isProvider ? { exclude_empty: true } : {};
         this.socket.send(
            JSON.stringify({
               query_params: {
                  type:
                     localStorage.getItem('access') === 'PROVIDER'
                        ? 'ONE_TO_ONE'
                        : 'GROUP',
                  no_page: false,
                  is_closed: false,
                  ...exclusion,
               },
               event_type: 'InboxViewset_list',
            })
         );
         onOpen(e);
      };

      this.socket.onmessage = ({ data }) => {
         const details = JSON.parse(data);
         onMessage(details);
      };

      this.socket.onclose = data => {
         onClose(data);
      };

      this.socket.onerror = e => {
         onError(e);
      };
   };

   disConnect = () => {
      this.socket.close();
   };
}

export default Socket;
