import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@datashop/button';
import { Mixpanel } from 'helpers/mixpanelHelper';
import { Redirect } from 'react-router-dom';

const Wrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const ImageWrapper = styled.img``;

const ImageDiv = styled.div`
   display: flex;
   justify-content: center;
   flex: 1;
`;

const ContainerWrapper = styled.div`
   position: sticky;
   bottom: 0;
   max-width: 400px;
   display: flex;
   align-items: center;
   flex-direction: column;
   padding: 24px 16px;
   border-radius: 20px 20px 0 0;
   background-color: #ffffff;
   box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
`;

const DotWrapper = styled.div`
   display: flex;
   align-self: center;
`;

const Dot = styled.p`
   height: 8px;
   width: 8px;
   border-radius: 50%;
   margin-right: 8px;
   background-color: ${({ active }) => (active ? '#0070DD' : '#A6A6A6')};
`;

const Text = styled.p`
   margin-top: 16px;
   color: #151414;
   font-size: 28px;
   font-weight: 700;
   line-height: 40px;
   text-align: center;
`;

const Subtext = styled.p`
   margin-top: 16px;
   color: #868686;
   font-size: 16px;
   font-weight: 500;
   line-height: 24px;
   text-align: center;
`;

const ButtonWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 0 8px;
   margin-top: 8px;
   align-items: center;
   align-self: ${({ end }) => (end ? 'flex-end' : 'normal')};
`;

const TopDiv = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const SkipText = styled.p`
   cursor: pointer;
   color: #0070dd;
   font-weight: 500;
   letter-spacing: -0.33px;
   line-height: 20px;
`;

const mapObject = [
   {
      title: 'Conduct telehealth visit',
      text:
         'Care for your patients – anytime and anywhere. Empower, providers and nurses across your organizations to help more patients through telemedicine and virtual care.',
      url: `${process.env.PUBLIC_URL}/img/1.png`,
   },
   {
      title: 'Virtual waiting room',
      url: `${process.env.PUBLIC_URL}/img/2.png`,
      text:
         'Optimized consultation schedule with live appointment status. Get detailed analyses of call logs, durations and notes right on your fingertips.',
   },
   {
      title: 'Send assessments',
      url: `${process.env.PUBLIC_URL}/img/3.png`,
      text:
         'Screen multiple patients at once. With automated CDC guideline-based self-assessment, identify your high risk COVID-19 patients.',
   },
   {
      title: 'Verify your identity',
      url: `${process.env.PUBLIC_URL}/img/4.png`,
      text:
         'We verify your profile with email / phone associated to protect patient data.',
   },
];

class Onboarding extends Component {
   state = {
      index: 0,
      redirectToLogin: false,
      inviteId: null,
   };

   componentDidMount() {
      const params = new URLSearchParams(this.props.location.search);
      const inviteId = params.get('invite_id');
      if (inviteId) {
         this.setState({ inviteId });
      }
   }

   incrementIndex = () => {
      if (this.state.index < 3) {
         this.setState({ index: this.state.index + 1 });
      } else {
         // Go to signup
         Mixpanel.track('Onboarding Flow Complete', {
            category: 'login',
         });
         this.login(true);
      }
   };

   login = async (isOnboard = false) => {
      await localStorage.setItem('isOnboarded', true);
      if (!isOnboard) {
         Mixpanel.track('Onboarding Flow Skipped', {
            category: 'login',
         });
      }
      this.setState({ redirectToLogin: true });
   };
   render() {
      const { index, redirectToLogin, inviteId } = this.state;
      return (
         <Wrapper>
            {redirectToLogin && (
               <Redirect to={{ pathname: '/signup', state: { inviteId } }} />
            )}
            <ImageDiv>
               <ImageWrapper
                  style={{
                     height: index === 0 ? '694px' : '568px',
                     marginTop: index !== 0 && '40px',
                  }}
                  src={mapObject[this.state.index].url}
               />
            </ImageDiv>
            <ContainerWrapper className='onboarding'>
               <TopDiv>
                  <DotWrapper>
                     {new Array(4).fill('').map((item, index) => (
                        <Dot key={index} active={index === this.state.index} />
                     ))}
                  </DotWrapper>
                  <Text>{mapObject[this.state.index].title}</Text>
                  <Subtext>{mapObject[this.state.index].text}</Subtext>
               </TopDiv>
               <ButtonWrapper end={this.state.index === 3}>
                  {this.state.index !== 3 && (
                     <SkipText onClick={() => this.login()}>
                        Skip to Sign Up
                     </SkipText>
                  )}
                  <Button
                     appearance='primary'
                     onClick={this.incrementIndex}
                     dimension='large'>
                     {this.state.index < 3 ? 'Next' : 'Get Started'}
                  </Button>
               </ButtonWrapper>
            </ContainerWrapper>
         </Wrapper>
      );
   }
}

export default Onboarding;
