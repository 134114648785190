import React from 'react';

import * as S from './style';

/**
 * Show article component
 * @param {Function} close Handles modal close
 * @param {Object} article Article
 * @param {Function} back Handles back icon click
 */
const ShowArticle = ({ close, article, back }) => (
   <S.Wrapper>
      <S.HeaderWrapper>
         <S.HeaderContainer>
            <S.Icon
               name='arrow_back'
               className='cursor-pointer'
               size={24}
               onClick={back}
            />
            <S.Header>{article.name}</S.Header>
            <S.Icon
               name='close'
               className='cursor-pointer'
               size={24}
               onClick={close}
            />
         </S.HeaderContainer>
      </S.HeaderWrapper>
      <S.ContentWrapper>
         <S.ListWrapper>
            <S.ArticleWrapper
               dangerouslySetInnerHTML={{
                  __html: article.body,
               }}
            />
         </S.ListWrapper>
      </S.ContentWrapper>
   </S.Wrapper>
);

export default ShowArticle;
