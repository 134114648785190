import React, { useState } from 'react';
import styled from 'styled-components';
import DatashopCard from '@datashop/card';
import Input from '@datashop/input';
import Button from '@datashop/button';

const Wrapper = styled.div`
   display: flex;
   flex: 1;
   padding: 12px;
   justify-content: center;
   align-items: center;
   background-color: #f4f4f4;
`;

const Card = styled(DatashopCard)`
   padding: 24px 12px 12px;
   background-color: #fff;
`;

const WelcomeText = styled.p`
   color: #000000;
   font-size: 34px;
   font-weight: bold;
   line-height: 40px;
`;

const NameText = styled.p`
   color: #868686;
   font-size: 16px;
   font-weight: 500;
   margin: 24px 0;
   line-height: 24px;
`;

const InputWrapper = styled.div`
   > div {
      > input {
         height: 40px;
      }

      > i {
         top: 12px;
      }
   }
`;

const JoinButton = styled(Button)`
   margin-top: 48px;
`;

function VisitorComponent({ saveVisitorInfo, firstName, lastName }) {
   const [text, setText] = useState('');

   function onChange({ target: { value } }) {
      setText(value.trimLeft());
   }

   return (
      <Wrapper>
         <Card shadow='light'>
            <WelcomeText>Welcome!</WelcomeText>
            <NameText>
               Please enter your name below to let {`${firstName} ${lastName}`}{' '}
               know you are here.
            </NameText>
            <InputWrapper>
               <Input
                  onChange={onChange}
                  value={text}
                  placeholder='Enter your name'
                  icon='person'
               />
            </InputWrapper>
            <JoinButton
               disabled={!text}
               onClick={() => saveVisitorInfo(text)}
               dimension='large'
               expanded
               appearance='primary'>
               Join call
            </JoinButton>
         </Card>
      </Wrapper>
   );
}

export default VisitorComponent;
