import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, motion } from '@krishnaxv/react-surface';
import TeleVisit from 'components/TeleVisit/TeleVisit';
import PermissionModal from 'components/TeleVisit/PermissionModal';
import returnCamelCasedName from 'helpers/nameTransformer';
import { checkPermission } from 'helpers/checkPermission';

const modalStyle = {
   height: '100vh',
   width: '100vw',
   display: 'flex',
};

const Wrapper = styled.div`
   width: ${window.innerWidth}px;
   height: ${window.innerHeight}px;
   left: 0;
   position: fixed;

   > div {
      background: none;
   }
`;

class VideoPermissionChecker extends Component {
   child = undefined;
   state = {
      showPermissionModal: false,
      openVideoVisitModal: false,
      checkInDidMount: true,
      videoTrack: null,
      audioTrack: null,
      userId: localStorage.getItem('userId'),
   };

   componentWillUnmount() {
      this.props.onRef(undefined);
   }

   componentDidMount = async () => {
      this.props.onRef(this);
      if (localStorage.getItem('videoObj')) {
         const permission = await checkPermission();
         if (permission) {
            const { videoTrack, audioTrack } = permission;
            const video = JSON.parse(localStorage.getItem('videoObj'));
            this.setState({
               ...video,
               videoTrack,
               audioTrack,
               openVideoVisitModal: true,
            });
         } else {
            this.setState({
               showPermissionModal: true,
               openVideoVisitModal: false,
            });
         }
      }
   };

   toggleAudio = () => {
      this.child.toggleAudio();
   };

   render() {
      const {
         roomName,
         token,
         lastName,
         visitId,
         firstName,
         scheduleId,
         roomId,
         userId,
         videoTrack,
         audioTrack,
         isProvider,
      } = this.state;
      return (
         <Wrapper>
            {this.state.showPermissionModal && (
               <Modal
                  motion={motion.slideInBottom}
                  style={modalStyle}
                  onClose={() => {
                     this.setState({ showPermissionModal: false });
                     window.reload();
                  }}>
                  {onCloseModal => <PermissionModal />}
               </Modal>
            )}
            {this.state.openVideoVisitModal && (
               <Modal
                  motion={motion.slideInBottom}
                  style={modalStyle}
                  onClose={() => this.setState({ openVideoVisitModal: false })}>
                  {onCloseModal => (
                     <TeleVisit
                        onRef={ref => (this.child = ref)}
                        roomName={roomName}
                        token={token}
                        visitId={visitId}
                        userId={userId}
                        isProvider={isProvider}
                        audioTrack={audioTrack}
                        videoTrack={videoTrack}
                        scheduleId={scheduleId}
                        recipient={returnCamelCasedName(firstName, lastName)}
                        roomId={roomId}
                        viaModal={true}
                        close={this.props.close}
                        doMute={this.props.doMute}
                        doUnMute={this.props.doUnMute}
                     />
                  )}
               </Modal>
            )}
         </Wrapper>
      );
   }
}

export default VideoPermissionChecker;
