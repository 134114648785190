import React, { FC } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import datashopTheme from '@datashop/theme';

import history from './history';
import App from './App';
import ResizeHOC from 'helpers/ResizeHOC';
import ProtectedRoute from 'ProtectedRoute';
import AssessmentContainer from 'components/Main';
import Signup from 'components/Signup/Signup';
import TeleVisit from 'components/TeleVisit/TeleVisit';
import Campaign from 'components/Campaign/Campaign';
import PatientInsuranceContainer from 'components/PatientInsurance/PatientInsuranceContainer';
import TermsOfService from 'components/PatientInsurance/TermsOfService';
import Onboarding from 'components/Onboarding/Onboarding';
import VirtualVisit from 'components/VirtualVisit/VirtualVisitContainer';
import Messages from 'components/Messages';
import FrontDoor from 'components/FrontDoor';
import Profile from 'components/Profile';
import NoPage from 'components/NoPage';
import Chat from 'components/Chat';
import Update from 'components/Update';
/**
 * Define app routes
 * @param {Object} props App props with store data
 */
const ConfigureRoute: FC<any> = props => (
   <Router history={history}>
      <ThemeProvider theme={datashopTheme}>
         <App>
            <ResizeHOC>
               <Switch>
                  <ProtectedRoute
                     {...props}
                     exact
                     path='/'
                     component={VirtualVisit}
                  />
                  <ProtectedRoute
                     {...props}
                     path='/assessments'
                     component={AssessmentContainer}
                  />
                  <ProtectedRoute
                     {...props}
                     exact
                     path='/messages'
                     component={Messages}
                  />
                  <ProtectedRoute
                     {...props}
                     exact
                     path='/frontdoor'
                     component={FrontDoor}
                  />
                  <ProtectedRoute
                     {...props}
                     exact
                     path='/profile'
                     component={Profile}
                  />
                  <ProtectedRoute path='/messages/:inboxId' component={Chat} />
                  <Route
                     render={props => {
                        return <Signup {...props} />;
                     }}
                     path='/signup'
                  />
                  <Route path='/televisit' component={TeleVisit} />
                  <Route path='/room' component={PatientInsuranceContainer} />
                  <Route path='/onboarding' component={Onboarding} />
                  <Route path='/terms-of-service' component={TermsOfService} />
                  <Route path='/campaign' component={Campaign} />
                  <Route path='/update' component={Update} />
                  <Route path='*' component={NoPage} />
               </Switch>
            </ResizeHOC>
         </App>
      </ThemeProvider>
   </Router>
);

export default ConfigureRoute;
