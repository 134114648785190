export const CALL_TYPES = {
   audio: 'AUDIO',
   video: 'VIDEO',
};

export const CALL_STATUS = {
   waiting: 'WAITING',
   onCall: 'ON_CALL',
   inProgress: 'IN_PROGRESS',
   onPostVisitQueue: 'POST_VISIT_QUEUE',
   readyForDoctor: 'READY_FOR_DOCTOR',
};

export const CALL_STATUS_LABELS = {
   readyForProvider: 'READY FOR PROVIDER',
   awaitingProvider: 'AWAITING PROVIDER',
   waiting: 'WAITING',
   inProgress: 'IN PROGRESS',
   postVisitQueue: 'POST VISIT QUEUE',
};

export const BADGE_APPEARANCES = {
   primary: 'primary',
   success: 'success',
   warning: 'warning',
   nimbu: 'nimbu',
};

export const BADGE_ICONS = {
   done: 'done',
   timer: 'timer',
   timelapse: 'timelapse',
};

export const CALL_MODES = {
   WALK_IN: 'WALK-IN',
   ADHOC: 'ADHOC',
};
