import React, { useState } from 'react';
import { Dialog } from '@krishnaxv/react-surface';
import { DialogWrapper } from 'components/Messages/style';
import { WrapperParent } from 'components/Chat/style';
import Text from '@datashop/text';
import * as S from 'components/Messages/style';
import Button from '@datashop/button';
import axios from 'helpers/axios';
import { Loader } from 'packages/loader';
import createToast from 'helpers/toastHelper';
import { get } from 'lodash-es';
import { unPhoneNumber } from 'helpers/formatPhone';
import { Mixpanel } from 'helpers/mixpanelHelper';

const InviteModal = ({ inviteToggle, patientInfo }) => {
   const [isLoading, setIsLoading] = useState(false);

   const sendInvite = () => {
      setIsLoading(true);
      const { phone, ...rest } = patientInfo;
      axios
         .post('/innote-survey/telehealth/inconnect/invitation', {
            patientInfo: { ...rest, phoneNumber: unPhoneNumber(phone) },
         })
         .then(() => {
            setIsLoading(false);
            inviteToggle();
            createToast({
               message: 'Invite has been sent.',
               appearance: 'success',
            });
            Mixpanel.track('Invite sent to unregistered patients', {
               category: 'chat',
            });
         })
         .catch(e => {
            createToast({
               message: get(e, 'message', 'Something went wrong!'),
            });
            inviteToggle();
            setIsLoading(false);
         });
   };
   return (
      <Dialog>
         {onCloseDialog => (
            <DialogWrapper>
               <WrapperParent>
                  <S.Row
                     isCenter={true}
                     style={{
                        justifyContent: 'center',
                        paddingBottom: '80px',
                        marginTop: '40px',
                     }}>
                     <Text fontSize='large' fontWeight='semiBold'>
                        This member is not registered.
                     </Text>
                  </S.Row>
                  <S.Row
                     isCenter={true}
                     style={{
                        justifyContent: 'space-between',
                        paddingBottom: '12px',
                     }}>
                     <Button
                        appearance='primary'
                        onClick={sendInvite}
                        disabled={isLoading}>
                        {isLoading ? (
                           <Loader height='18px' width='18px' stroke='#fff' />
                        ) : (
                           'Send Invitation'
                        )}
                     </Button>
                     <Button
                        appearance='secondary'
                        onClick={() => {
                           inviteToggle();
                           onCloseDialog();
                        }}>
                        Cancel
                     </Button>
                  </S.Row>
               </WrapperParent>
            </DialogWrapper>
         )}
      </Dialog>
   );
};

export default InviteModal;
