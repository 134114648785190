import { EVENT_RESET, EVENT_RECEIVED } from './constants';

// Initial state
const initialState = {
   event: '',
   data: null,
};

// Reducer
function socket(state = initialState, action) {
   switch (action.type) {
      case EVENT_RESET:
         return {
            ...initialState,
         };
      case EVENT_RECEIVED:
         return {
            event: action.event.message,
            data: action.event.data,
         };
      default:
         return state;
   }
}

export default socket;
