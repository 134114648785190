import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import socket from './socket/reducer';
import virtualVisit from './virtualVisit/reducer';
import frontDoorSocket from './frontDoorSocket/reducer';
import articles from './helpArticles/articles/reducer';
import search from './helpArticles/search/reducer';
import sections from './helpArticles/sections/reducer';
import Chat from './chat/reducer';
import Logout from './logout/reducer';

// Enable redux-logger in all environments other than production
const middlewareList =
   process.env.NODE_ENV !== 'production'
      ? applyMiddleware(thunk, createLogger())
      : applyMiddleware(thunk);

// App reducer
const appReducer = combineReducers({
   router: routerReducer,
   socket,
   virtualVisit,
   frontDoorSocket,
   Chat,
   helpArticles: combineReducers({
      articles,
      search,
      sections,
   }),
   Logout,
});

/**
 * Root reducer
 * @param  {Object} state Current state
 * @param  {Object} action Action
 * @returns {Object} App reducer
 */
const rootReducer = (state, action) => {
   if (action.type === 'LOGOUT') {
      state = undefined;
   }
   return appReducer(state, action);
};

// Create store
const configureStore = initialState => createStore(rootReducer, middlewareList);

export default configureStore;
