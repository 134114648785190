import React, { Component } from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';

const Wrapper = styled.div`
   padding: 16px;
   background: #fff;
`;

const Button = styled(StyledButton)`
   display: flex;
   align-items: center;
   color: #2f2f2f;
`;

const Icon = styled.i`
   margin-right: 8px;
   font-size: 16px;
`;

class ScheduleVisit extends Component {
   state = {};
   render() {
      return (
         <Wrapper>
            <Button
               onClick={() => this.props.toggleModal()}
               appearance='secondary'
               expanded
               data-test='schedule-a-new-visit-button'>
               <Icon className='material-icons'>video_call</Icon>
               Schedule a New Visit
            </Button>
         </Wrapper>
      );
   }
}

export default ScheduleVisit;
