import React from 'react';
import styled from 'styled-components';
import { addDays, subDays, format, parse } from 'date-fns';
import { Loader } from 'packages/loader';
import { Mixpanel } from 'helpers/mixpanelHelper';

const DayWrapper = styled.div`
   padding: 12px;
   display: flex;
   background-color: #fff;
   border-bottom: 1px solid #ededed;
`;

const DateChangeIcon = styled.i`
   color: #2f2f2f;
   font-size: 20px;
   line-height: 20px;
   cursor: pointer;
`;

const HeaaderDate = styled.div`
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const DateText = styled.p`
   color: #151414;
   font-weight: 600;
   line-height: 20px;
`;

const AssessmentCount = styled.p`
   margin-left: 8px;
   color: #2f2f2f;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
   background-color: #e5e5e5;
   padding: 2px 4px;
`;

const AssessmentHeader = ({ length, getAssessments, date, isLoading }) => {
   const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
   return (
      <DayWrapper>
         <DateChangeIcon
            onClick={() => {
               getAssessments(
                  false,
                  format(subDays(parsedDate, 1), 'yyyy-MM-dd')
               );
               Mixpanel.track('Date Changed', {
                  category: 'search',
                  Date: format(subDays(parsedDate, 1), 'yyyy-MM-dd'),
               });
            }}
            className='material-icons'>
            arrow_back
         </DateChangeIcon>
         <HeaaderDate>
            <DateText>
               {date === format(new Date(), 'yyyy-MM-dd')
                  ? 'Today'
                  : format(parsedDate, 'LLLL-dd')}
            </DateText>
            {isLoading ? (
               <Loader height='12px' />
            ) : (
               <AssessmentCount>{length}</AssessmentCount>
            )}
         </HeaaderDate>
         <DateChangeIcon
            style={{
               visibility:
                  date === format(new Date(), 'yyyy-MM-dd') && 'hidden',
            }}
            onClick={() => {
               getAssessments(
                  false,
                  format(addDays(parsedDate, 1), 'yyyy-MM-dd')
               );
               Mixpanel.track('Date Changed', {
                  category: 'search',
                  Date: format(addDays(parsedDate, 1), 'yyyy-MM-dd'),
               });
            }}
            className='material-icons'>
            arrow_forward
         </DateChangeIcon>
      </DayWrapper>
   );
};

export default AssessmentHeader;
