import axios from 'helpers/axios';
import {
   GET_ATTRIBUTED_PATIENT_DETAILS_START,
   GET_ATTRIBUTED_PATIENT_DETAILS_FAILURE,
   GET_ATTRIBUTED_PATIENT_DETAILS_SUCCESS,
} from './constants';

export const getPatientAttributionDetails = patientId => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: GET_ATTRIBUTED_PATIENT_DETAILS_START,
            };
         })()
      );
      return axios
         .get(`/innote-survey/user/attributed_patients/${patientId}/_validate`)
         .then(({ data }) => {
            dispatch(
               (() => {
                  return {
                     type: GET_ATTRIBUTED_PATIENT_DETAILS_SUCCESS,
                     data,
                  };
               })()
            );
         })
         .catch(error => {
            dispatch(
               (() => {
                  return {
                     type: GET_ATTRIBUTED_PATIENT_DETAILS_FAILURE,
                  };
               })()
            );
         });
   };
};
