import { _searchArticles } from 'helpers/helpArticleHelper';
import {
   REQUEST_ARTICLE_SEARCH,
   ARTICLE_SEARCH_SUCCESS,
   ARTICLE_SEARCH_FAILURE,
} from './constants';

/**
 * Invoked before requesting data from article search API
 * @return {Object} Action
 */
function requestArticleSearch() {
   return {
      type: REQUEST_ARTICLE_SEARCH,
   };
}

/**
 * Invoked after successfully retriving data from article search API
 * @param {Array} data List of articles
 * @return {Object} Action
 */
function searchArticleSuccess(data) {
   return {
      type: ARTICLE_SEARCH_SUCCESS,
      data,
   };
}

/**
 * Invoked if article search API returns error
 * @param {Object} error
 * @return {Object} Action
 */
function searchArticleFailure(error) {
   return {
      type: ARTICLE_SEARCH_FAILURE,
      error,
   };
}

/**
 * Action creator to get search result from article search API
 * @param {string} query Search query
 * @return {Function}
 */
function searchArticle(query) {
   return dispatch => {
      dispatch(requestArticleSearch());
      _searchArticles(query)
         .then(({ results }) => {
            dispatch(searchArticleSuccess(results));
         })
         .catch(({ error }) => {
            dispatch(searchArticleFailure(error));
         });
   };
}

export { searchArticle };
