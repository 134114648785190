import React, { Fragment } from 'react';
import { Loader } from '../../packages/loader';
import * as S from './style';

/**
 * Search result component
 * @param {Array} searchResult List of articles matching search
 * @param {boolean} isLoading Is loading
 * @param {Function} openArticle To open the article
 */
const SearchResult = ({
   search: { searchResult: list, isLoading: searchLoading },
   openArticle,
}) => (
   <Fragment>
      {
         // eslint-disable-next-line no-nested-ternary
         searchLoading ? (
            <S.LoaderWrapper>
               <Loader />
            </S.LoaderWrapper>
         ) : list.length ? (
            <S.ListWrapper>
               <S.ListHeaderWrapper>Related Articles</S.ListHeaderWrapper>
               {list.map(item => (
                  <S.ListItem
                     key={item.articleID}
                     onClick={() => openArticle(item)}>
                     <S.Description>{item.name}</S.Description>
                     <S.Icon
                        name='keyboard_arrow_right'
                        className='icon-hover ml-4'
                        size={16}
                        appearance='subtle'
                     />
                  </S.ListItem>
               ))}
            </S.ListWrapper>
         ) : (
            <S.NoResultWrapper>
               <S.Icon
                  name='search'
                  style={{ fontSize: 56 }}
                  appearance='subtle'
               />
               <S.NoResult>No results were found</S.NoResult>
               <S.NoResultSubtext>
                  We tried but could not find any results matching your search
               </S.NoResultSubtext>
            </S.NoResultWrapper>
         )
      }
   </Fragment>
);

export default SearchResult;
