import { EVENT_RESET, EVENT_RECEIVED } from './constants';

function eventReset() {
   return {
      type: EVENT_RESET,
   };
}

function eventReceived(event) {
   return dispatch => {
      dispatch(eventReset());
      dispatch({
         type: EVENT_RECEIVED,
         event,
      });
   };
}

export { eventReceived };
