import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import StyledCard from '@datashop/card';
import MoreOptionDropdown from './MoreOptionDropdown';
import { returnScheduleTime } from 'helpers/returnCreatedTime';
import { connect } from 'react-redux';
import { getLetterIconColor } from 'helpers/badgeColor';
import Tooltip from 'react-tooltip';
import Avatar from '@datashop/avatar';
import Text from '@datashop/text';
import createToast from 'helpers/toastHelper';
import { Mixpanel } from 'helpers/mixpanelHelper';
import returnCamelCasedName from 'helpers/nameTransformer';
import IconWrapper from '@datashop/icon';

const Card = styled(StyledCard)`
   padding: 12px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const PatientNameWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const PatientName = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
   margin-right: 4px;
`;

const DetailsWrapper = styled.div`
   display: flex;
   flex: 1;
   margin-top: 4px;
   color: #868686;
   align-items: center;
`;

const StatusText = styled.p`
   display: flex;
   font-weight: 500;
   line-height: 20px;
`;

const Icon = styled.i`
   margin-right: 4px;
   font-size: 14px;
`;

const AvatarWrapper = styled(Avatar)`
   margin-right: 12px;
   width: 40px;
   height: 40px;
`;

const AvatarTop = styled.div`
   display: flex;
   align-self: flex-start;
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`;

const Column = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const TextWrapper = styled(Text)`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 8px;
   border-radius: 3px;
   width: 38px;
   height: 20px;
   ${({ color }) => `background-color: ${color}`}
`;

const InviteCountWrapper = styled.div`
   width: 27px;
   height: 20px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #e5e5e5;
   border-radius: 20px;
   min-width: 27px;
   margin-right: 4px;
   margin-left: 4px;
`;

const InviteCount = styled.p`
   color: #2f2f2f;
   font-size: 12px;
   font-weight: 700;
   line-height: 16px;
`;

const TooltipTextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 200px;
   word-break: break-word;
   white-space: pre-wrap;
   color: #a6a6a6;
   font-size: 12px;
   font-weight: 600;
   line-height: 16px;
`;

const TooltipNames = styled.p`
   color: #ffffff;
   width: 100%;
   line-height: 20px;
`;

const Dot = styled.div`
   color: #868686;
   font-family: NunitoSans;
   font-size: 12px;
   line-height: 14px;
   text-align: left;
   margin: 0 8px;
`;

const EllipsisWrapper = styled.div`
   display: flex;
   min-width: 0;
   font-weight: 500;
   line-height: 20px;
   cursor: pointer;
`;

const EllipsisSection = styled.span`
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width: ${({ wi }) => wi - 39}px;
`;

const EllipsisEnd = styled.span`
   flex-shrink: 0;
`;

const trackScheduleChange = status => {
   Mixpanel.track(`Virtual visit ${status}`, {
      category: 'virtualVisit',
   });
};

const tooltipNameFormatter = names => {
   let nameList = [];
   names.forEach(item => {
      nameList.push(item.name);
   });
   return nameList.join(', ');
};

const InviteTooltip = ({ scheduleId, inviteCount, invitees }) => (
   <Fragment>
      <InviteCountWrapper
         data-tip
         data-for={`${scheduleId}`}
         data-effect='solid'>
         <InviteCount>{inviteCount}</InviteCount>
      </InviteCountWrapper>
      <Tooltip place='bottom' id={`${scheduleId}`}>
         <TooltipTextWrapper>
            <Fragment>Invitees</Fragment>
            <TooltipNames>{tooltipNameFormatter(invitees)}</TooltipNames>
         </TooltipTextWrapper>
      </Tooltip>
   </Fragment>
);

const copyInvitation = text => {
   const textArea = document.createElement('textArea');
   textArea.value = text;
   textArea.setAttribute('style', 'top: 0; left: 0; position: fixed');
   document.body.appendChild(textArea);
   textArea.focus();
   textArea.select();
   try {
      document.execCommand('copy');
      createToast({
         message: 'Invite link has been copied to clipboard',
         appearance: 'success',
      });
      Mixpanel.track('Copy invite link', {
         category: 'virtualVisit',
      });
   } catch (err) {
      createToast({
         message: 'Invite link copying failed',
      });
   }
   document.body.removeChild(textArea);
};

const ItemSchedule = ({
   item,
   toggleInviteModal,
   cancelSchedule,
   toggleModal,
   reSendMsg,
   userInfo: { practices = [] },
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const targetName = useRef(null);
   const [nameWidth, setNameWidth] = useState(100);
   const isProvider = localStorage.getItem('access') === 'PROVIDER';

   useEffect(() => {
      if (targetName && targetName.current) {
         setNameWidth(targetName.current.offsetLeft);
      }
   }, [targetName]);

   return (
      <Card shadow='light'>
         <Row>
            <AvatarTop>
               <AvatarWrapper
                  text={`${item.patientInfo.firstName.charAt(0)}${
                     item.patientInfo.lastName
                        ? item.patientInfo.lastName.charAt(0)
                        : ''
                  }`}
                  backgroundColor={getLetterIconColor(
                     item.patientInfo.firstName,
                     item.patientInfo.lastName
                  )}
               />
            </AvatarTop>
            <Column>
               <PatientNameWrapper>
                  <Row>
                     <PatientName>
                        {returnCamelCasedName(
                           item.patientInfo.firstName,
                           item.patientInfo.lastName
                        )}
                     </PatientName>
                     {!isProvider && item.visitorList.length > 0 && (
                        <InviteTooltip
                           scheduleId={item.scheduleId}
                           inviteCount={item.visitorList.length}
                           invitees={item.visitorList}
                        />
                     )}
                     {item.isTestCall && (
                        <TextWrapper color='#FFF5C7'>TEST</TextWrapper>
                     )}
                  </Row>
                  <MoreOptionDropdown
                     invite={() => {
                        toggleInviteModal(item.scheduleId);
                     }}
                     copyInvitation={() => {
                        copyInvitation(item.link);
                     }}
                     cancelSchedule={() => {
                        cancelSchedule(item.scheduleId, item.scheduledOn);
                        trackScheduleChange('cancelled', item);
                     }}
                     reschedule={() => {
                        toggleModal(
                           {
                              ...item,
                              patientInfo: {
                                 ...item.patientInfo,
                                 unFormattedNo: item.patientInfo.phoneNo,
                              },
                           },
                           true
                        );
                        trackScheduleChange('rescheduled', item);
                     }}
                     reSendMsg={() => {
                        reSendMsg(item);
                     }}
                     item={item}
                  />
               </PatientNameWrapper>
               <DetailsWrapper style={{ maxWidth: '300px' }}>
                  <Icon className='material-icons'>
                     {item.type === 'AUDIO' ? 'call' : 'videocam'}
                  </Icon>
                  <div>
                     <StatusText style={{ width: 'max-content' }}>
                        {returnScheduleTime(item.scheduledOn)}
                     </StatusText>
                  </div>
                  {isProvider && (
                     <Fragment>
                        {(item.practice || item.visitorList.length > 0) &&
                           practices.length !== 1 && (
                              <>
                                 <Dot>•</Dot>
                                 <EllipsisWrapper
                                    innerRef={targetName}
                                    title={item.practice}>
                                    <EllipsisSection wi={nameWidth}>
                                       {item.practice}
                                    </EllipsisSection>
                                    <EllipsisEnd />
                                 </EllipsisWrapper>
                              </>
                           )}
                        {!item.practice && item.visitorList.length > 0 && (
                           <Icon
                              style={{ marginBottom: 2 }}
                              className='material-icons'>
                              person
                           </Icon>
                        )}
                        {item.visitorList.length > 0 && (
                           <InviteTooltip
                              scheduleId={item.scheduleId}
                              inviteCount={item.visitorList.length}
                              invitees={item.visitorList}
                           />
                        )}
                     </Fragment>
                  )}
               </DetailsWrapper>
               {!isProvider && (
                  <DetailsWrapper style={{ maxWidth: '300px' }}>
                     <div>
                        <StatusText style={{ width: 'max-content' }}>
                           {returnCamelCasedName(
                              item.ownerInfo.firstName,
                              item.ownerInfo.lastName
                           )}
                        </StatusText>
                     </div>
                     {item.practice && practices.length !== 1 && (
                        <>
                           <Dot>•</Dot>
                           <EllipsisWrapper
                              innerRef={targetName}
                              title={item.practice}>
                              <EllipsisSection wi={nameWidth}>
                                 {item.practice}
                              </EllipsisSection>
                              <EllipsisEnd />
                           </EllipsisWrapper>
                        </>
                     )}
                  </DetailsWrapper>
               )}
            </Column>
         </Row>
         {item.reasonForVisit && (
            <Column style={{ marginTop: '8px' }}>
               <Row>
                  <Text style={{ color: '#868686' }}>Reason for visit</Text>
                  <IconWrapper
                     onClick={() => {
                        if (!isOpen) {
                           Mixpanel.track(
                              'Reason of visit viewed Scheduled Tab',
                              {
                                 category: 'visitReason',
                              }
                           );
                        }
                        setIsOpen(!isOpen);
                     }}
                     name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                     style={{
                        color: '#868686',
                        marginLeft: '4px',
                        fontSize: '16px',
                     }}
                  />
               </Row>
               {isOpen && (
                  <Text style={{ color: '#2f2f2f', marginTop: '4px' }}>
                     {item.reasonForVisit}
                  </Text>
               )}
            </Column>
         )}
      </Card>
   );
};

const mapStateToProps = ({ frontDoorSocket: { userInfo } }) => ({
   userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSchedule);
