import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import StyledButton from '@datashop/button';
import StyledInput from '@datashop/input';
import { Loader } from './../../packages/loader/';
import axios, { addTokenToAllInstances } from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';

const VerifyDetails = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const VerifyDetailsWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const VerificationCode = styled.p`
   color: #7c7a7a;
   line-height: 18px;
   margin-top: 40px;
`;

const Form = styled.form`
   display: flex;
   flex-direction: column;
`;

const OTPWrapper = styled.div`
   margin: 32px 0 0 -16px;
   display: flex;
   justify-content: space-evenly;
`;

const OTPDiv = styled.div`
   border: 1px solid #d2d2d2;
   border-radius: 4px;
   background-color: #ffffff;
   width: 76px;
`;

const Input = styled(StyledInput)`
   border: none;
   width: 30px;
   align-self: center;

   :focus {
      outline: none;
      border: none;
      box-shadow: none;
   }
`;

const Button = styled(StyledButton)`
   margin-top: 24px;
   height: 40px;
   font-weight: 300;
`;

const ValidityText = styled.p`
   margin-top: 24px;
   color: #7c7a7a;
   line-height: 18px;
   text-align: center;
`;

const GetNewCode = styled.p`
   cursor: pointer;
   margin-top: 8px;
   color: #0070dd;
   font-weight: 600;
   line-height: 24px;
   text-align: center;
`;

const BackIcon = styled.i`
   cursor: pointer;
   color: #151414;
   margin-right: 8px;
   font-size: 20px;
`;

export default class Verify extends React.Component {
   _isMounted = true;
   state = {
      first: '',
      second: '',
      third: '',
      fourth: '',
      button: {},
      isLoading: false,
      minutes: 5,
      seconds: 0,
      showResendOTP: false,
      shouldVerifyDetails: false,
      canContinue: false,
   };

   componentDidMount() {
      this.createInterval();
   }

   componentDidUpdate() {
      if (
         this.state.minutes === 4 &&
         this.state.seconds === 0 &&
         !this.state.showResendOTP
      ) {
         this.setState({ showResendOTP: true });
      }
      if (
         this.state.minutes === 0 &&
         this.state.seconds === 0 &&
         !this.state.showResendOTP
      ) {
         this.setState({ showResendOTP: true });
      }
   }

   createInterval = () => {
      this.myInterval = setInterval(() => {
         const { seconds, minutes } = this.state;

         if (seconds > 0) {
            this.setState(({ seconds }) => ({
               seconds: seconds - 1,
            }));
         }
         if (seconds === 0) {
            if (minutes === 0) {
               clearInterval(this.myInterval);
            } else {
               this.setState(({ minutes }) => ({
                  minutes: minutes - 1,
                  seconds: 59,
               }));
            }
         }
      }, 1000);
   };

   resendOtp = () => {
      axios
         .post('/innote-survey/user/auth ', {
            email: this.props.email,
            loginType: 'email',
         })
         .then(() => {
            createToast({
               message: 'OTP successfully sent',
               appearance: 'success',
            });
         });
      this.setState({ showResendOTP: false, minutes: 5, seconds: 0 });
      clearInterval(this.myInterval);
      this.createInterval();
   };

   checkOtp = event => {
      event.preventDefault();
      this.setState({ isLoading: true });
      axios
         .post('/innote-survey/user/auth/_validate', {
            password: `${this.state.first}${this.state.second}${this.state.third}${this.state.fourth}`,
            email: this.props.email,
            loginType: 'email',
         })
         .then(
            ({
               data: {
                  token,
                  userId,
                  organization = '',
                  accessRole,
                  clientInfo: { timezoneCode },
               },
            }) => {
               localStorage.setItem('organization', organization);
               localStorage.setItem('token', token);
               localStorage.setItem('userId', userId);
               localStorage.setItem('access', accessRole);
               localStorage.setItem('email', this.props.email);
               localStorage.setItem('timezone', timezoneCode);
               localStorage.setItem('npi', userId);

               addTokenToAllInstances(token);

               this.setState({ success: true }, () => {
                  Mixpanel.track(`Logged in (correct OTP)`, {
                     category: 'login',
                  });
               });
            }
         )
         .catch(
            ({
               response: {
                  data: { error },
               },
            }) => {
               createToast({ message: error.message });
            }
         )
         .finally(() => {
            this._isMounted && this.setState({ isLoading: false });
         });
   };

   onChange = (event, autofocusId) => {
      const {
         target: { value, name },
      } = event;
      if (name === 'fourth') {
         if (value.length < 1 || value.length === 1) {
            this.setState({ [name]: value });
         }
      } else {
         this.setState({ [name]: value });
      }
      value && autofocusId && document.getElementById(autofocusId).focus();
   };

   onFocus = event => {
      const {
         target: { name },
      } = event;
      this.setState({
         [name]: '',
      });
   };

   componentWillUnmount() {
      this._isMounted = false;
      clearInterval(this.myInterval);
   }

   onKeyDownHandler = (event, autofocusId) => {
      const {
         keyCode,
         target: { value },
      } = event;
      if (keyCode === 8 && autofocusId && value === '')
         document.getElementById(autofocusId).focus();
   };

   render() {
      const { minutes, seconds } = this.state;
      return (
         <Fragment>
            {this.state.success && <Redirect to='/' />}
            <VerifyDetailsWrapper>
               <BackIcon
                  onClick={this.props.resetDetails}
                  className='material-icons'>
                  arrow_back
               </BackIcon>
               <VerifyDetails>Enter verification code</VerifyDetails>
            </VerifyDetailsWrapper>
            <Fragment>
               <VerificationCode>
                  We’ve sent a verification code on your email{' '}
                  {this.props.email} to verify it’s you.
               </VerificationCode>
               <Form onSubmit={e => e.preventDefault()}>
                  <OTPWrapper>
                     <OTPDiv>
                        <Input
                           type='number'
                           onChange={event => this.onChange(event, 'second')}
                           onFocus={this.onFocus}
                           value={this.state.first}
                           name='first'
                           id='first'
                        />
                     </OTPDiv>
                     <OTPDiv>
                        <Input
                           type='number'
                           id='second'
                           onFocus={this.onFocus}
                           onKeyDown={event =>
                              this.onKeyDownHandler(event, 'first')
                           }
                           onChange={event => this.onChange(event, 'third')}
                           value={this.state.second}
                           name='second'
                        />
                     </OTPDiv>
                     <OTPDiv>
                        <Input
                           type='number'
                           id='third'
                           onFocus={this.onFocus}
                           name='third'
                           onKeyDown={event =>
                              this.onKeyDownHandler(event, 'second')
                           }
                           onChange={event => this.onChange(event, 'fourth')}
                           value={this.state.third}
                        />
                     </OTPDiv>
                     <OTPDiv>
                        <Input
                           type='number'
                           onChange={this.onChange}
                           onFocus={this.onFocus}
                           value={this.state.fourth}
                           onKeyDown={event =>
                              this.onKeyDownHandler(event, 'third')
                           }
                           id='fourth'
                           name='fourth'
                        />
                     </OTPDiv>
                  </OTPWrapper>
                  <Button
                     onClick={this.checkOtp}
                     disabled={
                        !(
                           this.state.first &&
                           this.state.second &&
                           this.state.third &&
                           this.state.fourth
                        ) || this.state.isLoading
                     }
                     appearance='primary'>
                     {this.state.isLoading ? <Loader stroke='#fff' /> : 'Next'}
                  </Button>
               </Form>
               <ValidityText>
                  The verification code is valid for {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds} minutes
               </ValidityText>
               {this.state.showResendOTP && (
                  <GetNewCode onClick={this.resendOtp}>
                     Didn&apos;t get code? Request a new one.
                  </GetNewCode>
               )}
            </Fragment>
         </Fragment>
      );
   }
}
