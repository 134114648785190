const checkAVPermission = async url => {
   const {
      remote: { BrowserWindow, systemPreferences, screen, process },
   } = window.require('electron');
   const { width, height } = screen.getPrimaryDisplay().workAreaSize;
   let virtualVisitWindow = new BrowserWindow({
      width: Math.ceil(width - width * 0.35),
      height: Math.ceil(height * 0.95),
      x: Math.ceil(width * 0.025),
      y: Math.ceil(height - height * 0.95),
      alwaysOnTop: true,
   });
   try {
      if (process.platform === 'darwin') {
         await systemPreferences.askForMediaAccess('camera');
         await systemPreferences.askForMediaAccess('microphone');
         virtualVisitWindow.loadURL(url);
      } else {
         virtualVisitWindow.loadURL(url);
      }
   } catch (err) {
      virtualVisitWindow.loadURL(url.concat('&error=av'));
   }
};

export { checkAVPermission };
