export const ON_ERROR = 'ON_ERROR';
export const ON_CLOSE = 'ON_CLOSE';
export const ON_MESSAGE = 'ON_MESSAGE';
export const REMOVE_MESSAGE_OBSERVER = 'REMOVE_MESSAGE_OBSERVER';
export const ON_OPEN = 'ON_OPEN';
export const AVAILABLE_FOR_ASSISTANCE_START = 'AVAILABLE_FOR_ASSISTANCE_START';
export const AVAILABLE_FOR_ASSISTANCE_FAILURE =
   'AVAILABLE_FOR_ASSISTANCE_FAILURE';
export const AVAILABLE_FOR_ASSISTANCE_SUCCESS =
   'AVAILABLE_FOR_ASSISTANCE_SUCCESS';
export const CHANGE_TAB = 'CHANGE_TAB';
export const UNASSIGN_TAB_LOADING = 'UNASSIGN_TAB_LOADING';
export const MY_TAB_LOADING = 'MY_TAB_LOADING';
export const GET_INBOX_LIST = 'GET_INBOX_LIST';
export const GET_INBOX = 'GET_INBOX';
export const IS_LOAD_MORE_UNASSIGNED_INBOX = 'IS_LOAD_MORE_UNASSIGNED_INBOX';
export const IS_LOAD_MORE_MY_INBOX = 'IS_LOAD_MORE_MY_INBOX';
export const IS_CHAT_OPEN = 'IS_CHAT_OPEN';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_HEADER_LOADING = 'CHAT_HEADER_LOADING';
export const SET_SELECTED_CHAT = 'SET_SELECTED_CHAT';
export const RETRIEVE_INBOX_MESSAGES = 'RETRIEVE_INBOX_MESSAGES';
export const GET_MY_OFFLINE_MESSAGES = 'GET_MY_OFFLINE_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const START_ATTACHMENT_UPLOAD = 'START_ATTACHMENT_UPLOAD';
export const ADD_ATTACHMENT_IDENTIFIER = 'ADD_ATTACHMENT_IDENTIFIER';
export const ATTACHMENT_UPLOAD_DONE = 'ATTACHMENT_UPLOAD_DONE';
export const ASSIGN_TO_ME = 'ASSIGN_TO_ME';
export const GET_USER_INFO_START = 'GET_USER_INFO_START';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const SET_SOCKET = 'SET_SOCKET';
export const ADDED_IN_GROUP = 'ADDED_IN_GROUP';
export const MESSAGE_READ = 'MESSAGE_READ';
export const INBOX_VIEW_SET_DELETE = 'INBOX_VIEW_SET_DELETE';
export const NEW_MESSAGE_RECEIVED = 'NEW_MESSAGE_RECEIVED';
export const MARK_MESSAGE_AS_READ_PARTIAL_UPDATE =
   'MARK_MESSAGE_AS_READ_PARTIAL_UPDATE';
export const SET_SOUND_CARD = 'SET_SOUND_CARD';

export const GET_CONFIG_START = 'GET_CONFIG_START';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const GET_SETTINGS_START = 'GET_SETTINGS_START';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const SET_SETTINGS_START = 'SET_SETTINGS_START';
export const SET_SETTINGS_FAILURE = 'SET_SETTINGS_FAILURE';
export const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const RESET_MY_TAB = 'RESET_MY_TAB';
export const RESET_HAS_NEW_MESSAGES = 'RESET_HAS_NEW_MESSAGES';
