import React from 'react';
import * as S from './style';

const Content = {
   error: {
      image: `${process.env.PUBLIC_URL}/img/messagesError.png`,
      header: 'Oops..Something went wrong',
      subHeader: 'Please wait while we are trying to fix the issue.',
   },
   noMessages: {
      image: `message`,
      header: 'Patient waiting',
      subHeader: 'Send a message for this patient',
   },
   noInbox: {
      image: `message`,
      header: 'No messages',
      subHeader:
         'Start a new conversation by clicking the ‘+’ icon up top or by clicking the button below.',
   },
   noMatchInbox: {
      image: `search`,
      header: 'No matching results found',
      subHeader:
         "We couldn't find any past chats with a matching patient. To initiate a new conversation, click the button below.",
   },
   noResult: {
      image: `search`,
      header: 'No patient',
      subHeader: "We couldn't find any patient.",
   },
   noSearchResult: {
      image: `search`,
      header: 'No matching results found',
      subHeader: "We couldn't find any matching patient. Please search again.",
   },
};

const Error = props => (
   <S.Wrapper>
      <S.DataWrapper>
         {props.type === 'error' ? (
            <S.Image src={Content[props.type].image} alt='img' />
         ) : (
            <S.Icon className='material-icons'>
               {Content[props.type].image}
            </S.Icon>
         )}
         <S.Header>{Content[props.type].header}</S.Header>
         <S.SubHeader>{Content[props.type].subHeader}</S.SubHeader>
         {props.buttonText && (
            <S.ErrorButton onClick={props.buttonClick}>
               {props.buttonText}
            </S.ErrorButton>
         )}
      </S.DataWrapper>
   </S.Wrapper>
);

export default Error;
