import React, { Component, Fragment } from 'react';
import { format } from 'date-fns';

import NewAssessment from './NewAssessment';
import Assessment from './Assessment';
import SendAssessment from '../SendAssessment/SendAssessment';
import axios, { CancelToken, isCancel } from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';

class AssessmentContainer extends Component {
   toastTimer;
   callActive;
   checkAssessmentInterval;
   state = {
      startAssessment: false,
      sendAssessmet: false,
      assessmentList: [],
      isLoading: true,
      responseId: null,
      searching: false,
      owner: '',
      firstName: '',
      lastName: '',
      assessmentId: null,
      date: format(new Date(), 'yyyy-MM-dd'),
      puiSendSuccess: false,
   };

   componentDidMount() {
      this.getAssessments()();
      this.props.getFunction(this.getAssessments());
   }

   componentDidUpdate = prevProps => {
      if (
         !prevProps.socketData &&
         this.props.socketData &&
         this.props.socketData === 'REFRESH_ASSESSMENT'
      ) {
         this.getAssessments()(true);
      }
   };

   getAssessments = () => {
      return (overRide = false, date = this.state.date, searchQuery) => {
         // Update date in state
         this.setState({ date });
         // Check if we want to override the loading state
         if (!overRide) this.setState({ isLoading: true });
         if (this.callActive) {
            this.callActive.cancel();
         }
         this.callActive = CancelToken.source();
         const npi = localStorage.getItem('npi');
         axios
            .get(
               `/innote-survey/assessments/user/${npi}/_search?type=COVID&timezone-offset=${new Date().getTimezoneOffset()}&date=${date}${
                  searchQuery || ''
               }`,
               {
                  cancelToken: this.callActive.token,
               }
            )
            .then(({ data: { data } }) => {
               if (searchQuery) {
                  Mixpanel.track('Patient Result', {
                     category: 'search',
                     'Search Query': searchQuery.split('&search-term=')[1],
                     Results: data.length,
                  });
               }
               this.setState({ assessmentList: data });
               if (!overRide) this.setState({ isLoading: false });
            })
            .catch(err => {
               if (!isCancel(err)) {
                  this.setState({
                     isLoading: false,
                  });
               }
            });
      };
   };

   searchAssessments = (searchQuery = undefined) => {
      this.setState(
         {
            searching: !!searchQuery,
         },
         () => {
            this.getAssessments()(false, this.state.date, searchQuery);
         }
      );
   };

   getChildContext = context => {
      this.childContext = context;
   };

   resetModalState = () => {
      if (this.childContext) {
         this.childContext.setState({
            searchText: '',
         });
      }
      this.setState({
         searching: false,
         firstName: null,
         lastName: null,
         dob: null,
         gender: null,
         isPuiFilled: null,
         responseId: null,
      });
   };

   toggleModal = (event, puiSendSuccess = false) => {
      if (puiSendSuccess) {
         this.showSuccessToast(puiSendSuccess);
      }
      if (this.state.startAssessment) this.resetModalState();

      this.setState(
         prevState => ({
            startAssessment: !prevState.startAssessment,
            isLoading: prevState.startAssessment,
            showResult: false,
         }),
         () => {
            if (!this.state.startAssessment) {
               Mixpanel.track('Conduct assessment started', {
                  category: 'conductAssessment',
               });
               this.getAssessments()();
            }
         }
      );
   };

   toggleSendAssessment = () => {
      this.setState(prevState => ({
         sendAssessment: !prevState.sendAssessment,
      }));
   };

   showSuccessToast = (puiSendSuccess = false) => {
      createToast({
         message: !puiSendSuccess
            ? `Assessment sent successfully`
            : 'PUI downloaded successfully',
         appearance: 'success',
      });
      Mixpanel.track(
         !puiSendSuccess ? 'Provider assessment submitted' : 'PUI Downloaded',
         {
            category: 'conductAssessment',
         }
      );
   };

   showFailureToast = () => {
      createToast({ message: 'Something went wrong!' });
   };

   componentWillUnmount() {
      clearTimeout(this.toastTimer);
      clearInterval(this.checkAssessmentInterval);
   }

   closeResult = () => {
      this.setState({
         showResult: false,
      });
   };

   showResult = itemDetails => {
      const details = { ...itemDetails, ...itemDetails.patientInfo };
      delete details.patientInfo;

      Mixpanel.track('Patient Clicked', {
         category: 'search',
      });
      Mixpanel.track('Assessment Review Started', {
         category: 'reviewAssessment',
      });
      this.setState({
         showResult: true,
         startAssessment: true,
         ...details,
      });
   };

   render() {
      return (
         <Fragment>
            <Assessment
               searching={this.state.searching}
               createRoom={this.props.createRoom}
               searchAssessments={this.searchAssessments}
               isLoading={this.state.isLoading}
               showResult={this.showResult}
               date={this.state.date}
               getChildContext={this.getChildContext}
               getAssessments={this.getAssessments()}
               showFailureToast={this.showFailureToast}
               data={this.state.assessmentList}
               sendAssessment={this.toggleSendAssessment}
               startAssessment={this.toggleModal}
            />
            {(this.state.startAssessment || this.state.showResult) && (
               <NewAssessment
                  state={this.state.status}
                  assessmentId={this.state.assessmentId}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  owner={this.state.owner}
                  gender={this.state.gender}
                  dob={this.state.dob}
                  showResult={this.state.showResult}
                  isPuiFilled={this.state.isPuiFilled}
                  responseId={this.state.responseId}
                  close={this.toggleModal}
                  {...this.props}
               />
            )}
            {this.state.sendAssessment && (
               <SendAssessment
                  showSuccessToast={this.showSuccessToast}
                  close={this.toggleSendAssessment}
               />
            )}
         </Fragment>
      );
   }
}

export default AssessmentContainer;
