import React from 'react';
import styled from 'styled-components';
import { Loader } from 'packages/loader';
import { format } from 'date-fns';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: 16px;
   background-color: #fff;
   z-index: 99999;
   flex: 1;
`;

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
   margin-top: 8px;
`;

const DoctorName = styled.div`
   color: #000000;
   font-size: 28px;
   font-weight: bold;
   letter-spacing: 0.87px;
   line-height: 36px;
`;

const LoaderWrapper = styled.div`
   margin-top: 44px;
   font-size: 32px;
`;

const WaitText = styled.div`
   color: #000000;
   font-size: 16px;
   font-weight: bold;
   letter-spacing: -0.48px;
   line-height: 24px;
   text-align: center;
   margin-top: 20px;
`;

const DateText = styled.p`
   color: #868686;
   margin-top: 8px;
   font-size: 16px;
   font-weight: 500;
   letter-spacing: -0.38px;
   line-height: 24px;
`;

const StaticContentWrapper = styled.div`
   margin-top: 56px;
`;

const StaticTextWrapper = styled.div`
   display: flex;
   margin-bottom: 24px;
`;

const StaticImageWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40px;
`;

const StaticImage = styled.img`
   margin-right: 16px;
   height: ${({ height }) => height && height};
   width: ${({ width }) => width && width};
`;

const StaticText = styled.div`
   color: #000000;
   line-height: 20px;
`;

const staticData = [
   {
      imagePath: `${process.env.PUBLIC_URL}/img/patientWaiting1.png`,
      imageHeight: '32px',
      imageWidth: '24px',
      text:
         'Find a location that is safe, comfortable, private and well lit for your video visit.',
   },
   {
      imagePath: `${process.env.PUBLIC_URL}/img/patientWaiting2.png`,
      imageHeight: '24px',
      imageWidth: '32px',
      text: 'Get a strong wifi or cellular data connection on your device.',
   },
   {
      imagePath: `${process.env.PUBLIC_URL}/img/patientWaiting3.png`,
      imageHeight: '20px',
      imageWidth: '32px',
      text: 'Ensure that your smartphone or tablet is charged or plugged-in.',
   },
   {
      imagePath: `${process.env.PUBLIC_URL}/img/patientWaiting4.png`,
      imageHeight: '32px',
      imageWidth: '28px',
      text:
         'Clear any obstructions from the view of your camera. Keep the volume up.',
   },
];

const PatientWaitingScreen = ({ name, date }) => (
   <Wrapper>
      <ContentWrapper>
         <DoctorName>{name}</DoctorName>
         {date && <DateText>{format(new Date(date), 'LLLL d, P')}</DateText>}
         <LoaderWrapper>
            <Loader stroke='#0070DD' strokeWidth='8' />
         </LoaderWrapper>
         <WaitText>Waiting for the provider to join</WaitText>
      </ContentWrapper>
      <StaticContentWrapper>
         {staticData.map(item => (
            <StaticTextWrapper>
               <StaticImageWrapper>
                  <StaticImage
                     src={item.imagePath}
                     alt='img'
                     height={item.imageHeight}
                     width={item.imageWidth}
                  />
               </StaticImageWrapper>
               <StaticText>{item.text}</StaticText>
            </StaticTextWrapper>
         ))}
      </StaticContentWrapper>
   </Wrapper>
);

export default PatientWaitingScreen;
