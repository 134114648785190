import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const ContentWrapper = styled.div`
   padding: 24px 16px 160px;
`;

const Text = styled.div`
   color: #000000;
   font-size: 14px;
   line-height: 20px;
   margin-bottom: 8px;
`;

const Bold = styled.span`
   color: #000000;
   font-size: 14px;
   line-height: 20px;
   font-weight: 700;
   margin-top: 12px;
`;

const PrivacyNotice = () => (
   <Wrapper>
      <ContentWrapper>
         <Text>
            <Bold>
               This notice describes how medical information about you may be
               used and disclosed and how you can get access to this
               information. Please review it carefully.
            </Bold>
         </Text>
         <Text>
            <Bold>
               All Central Maine Healthcare Corporation (CMHC) hospitals,
               service delivery sites, physician practices, long term care
               facilities, healthcare workers and staff will use and disclose
               personal medical information about you in accordance with this
               Privacy Practices Notice (Notice).
            </Bold>
         </Text>
         <Text>
            &emsp;&emsp;CMHC is committed to protecting the confidentiality of
            your medical information, and are required by law to do so. This
            Notice describes how we may use your medical information and how we
            may disclose it to others. The Notice also describes the rights you
            have concerning your own medical information. Please review it
            carefully and let us know if you have questions. We will ask you to
            sign a form acknowledging that you received this Notice.
         </Text>
         <Text>
            &emsp;&emsp;We regard the safeguarding of your personal identifiable
            information as an important duty. The elements of this notice and
            the consents &amp; or authorizations you sign are required by state
            and federal law for your protection. We have in place safeguards to
            protect the privacy of your information. Our staff is regularly
            trained on the obligation to protect the privacy of our patients. We
            hold medical records in a secure area. Only staff members that have
            a “need to know” are permitted access to your medical records or
            other information. Our staff understands the legal and ethical
            obligation to protect your information. A violation of this Notice
            of Privacy Practices will result in disciplinary action.
         </Text>
         <Text>
            <Bold>
               How will we use your medical information and share it with
               others?
            </Bold>
         </Text>

         <Text>
            <Bold>Treatment:</Bold> We may use your medical information to
            provide you with medical services and supplies. We may also share
            your medical information with others who need to treat you. This
            includes; doctors, physician assistants, nurses, medical and nursing
            students, technicians, therapists, emergency service and medical
            transportation providers, medical equipment providers, medical
            imaging technologists, and others involved in your care. For
            example, we will allow your physician to have access to your medical
            record to assist in your treatment and for follow-up care. We will
            use and share your medical records in an emergency to ensure you
            receive the necessary medical services.
         </Text>

         <Text>
            <Bold>HealthInfoNet (HIN):</Bold> We participate in a state-wide
            arrangement of healthcare organizations who have agreed to work with
            each other to make available electronic health information that may
            be relevant to your care. This is called HealthInfoNet (HIN) and
            allows Maine hospitals, doctors and other health care providers to
            quickly share certain health information about their patients. For
            example, if you are admitted to a hospital participating in
            HealthInfoNet on an emergency basis and cannot provide important
            information about your health condition, this arrangement will help
            those who need to treat you at the hospital to see your health
            information held by another participating provider. When it is
            needed, ready access to your health information means better care
            for you.
         </Text>
         <Text>
            &emsp;&emsp;You may choose to not make your protected health
            information available to this state- wide arrangement by completing
            an “opt-out” election form available online, or from your provider’s
            office. Upon request, CMHC will submit the opt-out form to
            HealthInfoNet for you, at 125 Presumscot Street, Box 8, Portland, ME
            04103. For further information you can visit their website at
            www.hinfonet.org or give them a call at (207) 541-9250 or
            1-866-592-4352.
         </Text>

         <Text>
            <Bold>Patient Directory:</Bold> To help family members and visitors
            locate you while you are in the Hospital, the Hospital has a patient
            directory. This directory includes your name, your general
            condition, where you are in the Hospital, and religion (if any) to
            be given to the clergy. This information is only given out to those
            who ask for you by name. You have the option, at registration, to
            limit who has access to this information.
         </Text>

         <Text>
            <Bold>
               You, Your Family Members and Others Involved in Your Care:
            </Bold>{' '}
            We may share your medical information with you. We may also share
            your medical information with a family member or friend who is
            involved in your medical care or someone who is involved in payment
            for your care. For example, when a patient is not present or without
            capacity, we may allow a third party to pick up prescriptions,
            supplies, or x-rays. We also may share your medical information with
            disaster relief organizations to help locate a family member or
            friend in a disaster. You have a right to request that we do not
            share your medical information with one (or more) family members or
            friends. Please let us know if this is your wish.
         </Text>

         <Text>
            <Bold>Decedent Information:</Bold> Surviving family members or other
            persons involved in your care will have continued access to your
            personal medical information, unless you previously expressed
            preferences to the contrary. We may also share your personal medical
            information with the executor, administrator, or other person who
            has authority to act on behalf of your estate. Otherwise you will be
            protected by these privacy rules for a period of 50 years following
            the date of death.
         </Text>

         <Text>
            <Bold>Payment:</Bold> We may use and share your medical information
            to get paid for the medical services and supplies we provide to you.
            For example, we may submit claims to your health plan or health
            insurance company and it may ask to see parts of your medical record
            (chart) before they will pay us for your treatment.
         </Text>

         <Text>
            <Bold>Health Care Operations:</Bold> We may use and share your
            medical information if it is necessary to improve the quality of
            care we provide to patients or to run the facility. We may use your
            medical information to look for ways to improve your care. For
            example, we may look at your medical record (chart) to evaluate
            whether staff, your doctors, or other health care professionals did
            a good job.
         </Text>

         <Text>
            <Bold>Research:</Bold> We may use or share your medical information
            for research projects, such as studying how well a type of treatment
            worked. These research projects must go through a special process
            that protects the confidentiality (privacy) of your medical
            information. We are prohibited from using or disclosing your genetic
            information for underwriting purposes.
         </Text>

         <Text>
            <Bold>Required by Law:</Bold> Federal, state, or local laws
            sometimes require us to share patients’ medical information. For
            instance, we are required to report child abuse or neglect and must
            provide certain information to law enforcement officials in domestic
            violence cases. We also are required to give information to the
            State Workers’ Compensation Program for work-related injuries.
         </Text>

         <Text>
            <Bold>Public Health:</Bold> We may report certain medical
            information for public health purposes. We may need to report
            patient problems with medications or medical products to the Food
            and Drug Administration (FDA) or notify patients of recalls of
            products they are using. In some circumstances we may need to notify
            schools of immunization records and once received by the school they
            are protected by another set of privacy guidelines.
         </Text>

         <Text>
            <Bold>Public Safety:</Bold> We may share medical information for
            public safety purposes in limited circumstances. We may share
            medical information to law enforcement officials in response to a
            search warrant or a grand jury subpoena. We may share medical
            information to assist law enforcement officials in identifying or
            locating a person. To prosecute a crime of violence, to report
            deaths that may have resulted from criminal conduct, and to report
            criminal conduct at the facility. We may share your medical
            information to law enforcement officials and others to prevent a
            serious threat to health or safety.
         </Text>

         <Text>
            <Bold>Health Oversight Activities:</Bold> We may share medical
            information with a government agency that oversees the Facility or
            its staff, such as the State Department of Health &amp; Human
            Services, the federal agencies that oversee Medicare, the Board of
            Medical Examiners or the Board of Nursing. These agencies need
            medical information to watch how well we follow state and federal
            laws.
         </Text>

         <Text>
            <Bold>Coroners, Medical Examiners and Funeral Directors:</Bold> We
            may share medical information concerning patients who have died to
            coroners, medical examiners and funeral directors.
         </Text>

         <Text>
            <Bold>Organ, Eye, or Tissue Donation.</Bold> We may share medical
            information with groups that handle organ, eye or tissue donation or
            transplantation.
         </Text>

         <Text>
            <Bold>
               Military, Veterans, National Security and Other Government
               Purposes:
            </Bold>{' '}
            If you are a member of the armed forces, we may release your medical
            information as required by military command authorities or to the
            Department of Veterans Affairs. We may share medical information to
            federal officials for intelligence and national security purposes,
            or for presidential Protective Services.
         </Text>

         <Text>
            <Bold>Judicial Proceedings:</Bold> We may share medical information
            if a court orders us to, if we receive a search warrant, or a
            subpoena.
         </Text>

         <Text>
            <Bold>Information with Additional Protection:</Bold> Certain types
            of medical information have additional protection under state or
            federal law. For instance, medical information about HIV/AIDS,
            mental health, and alcohol and drug abuse treatment has more
            protection in Maine. We are required in many circumstances to get
            your permission before sharing this information.
         </Text>

         <Text>
            <Bold>Fundraising:</Bold> We may use your personal information (or
            share it with a related foundation) to contact you for fundraising
            purposes. You have the right to opt-out of such communications. You
            may opt-out of any fundraising communications by contacting the
            appropriate Privacy Contact listed at the end of this notice.
         </Text>

         <Text>
            <Bold>Other Uses and Disclosures:</Bold> If we wish to use or share
            your medical information for a reason that is not discussed in this
            Notice, we must have your written permission. You may give us
            written permission to use your medical information or to disclose it
            to anyone for any purpose. If you give us written permission, you
            may revoke it at any time by notifying us of your revocation in
            writing. Your revocation will not affect any use or disclosure
            permitted by your permission while it was in effect. We need your
            written permission to use or disclose psychotherapy notes, except in
            limited circumstances such as when the disclosure is required by
            law. We also must obtain your written permission to sell information
            about you to a third party or, in most circumstances, to use or
            disclose your medical information to send you communications about
            products and services. We do not need your written permission,
            however, to send you communications about health-related products or
            services that are offered by us.
         </Text>

         <Text>
            <Bold>What are your rights?</Bold>
         </Text>
         <Text>
            <Bold>Right to Request Your Medical Information:</Bold> You have the
            right to look at your own medical information and to get a copy.
            (The law requires us to keep the original record.) This includes
            your medical record, your billing record, and other medical records
            we use to make decisions about your care. To request a copy of your
            medical information, write to the Medical Records Department. We
            will respond to your request as soon as possible, but no later than
            30 days from the date of your request. When you request a copy of
            your information, we will tell you then how much the copy will cost.
            You can look at your record at no cost. You also have the right to
            your medical records in an electronic format that is suitable to you
            at cost.{' '}
         </Text>
         <Text>
            &emsp;&emsp;You do not have the right to access (or receive a copy
            of) psychotherapy notes or information gathered in reasonable
            anticipation of a civil, criminal, or administrative proceeding.
            Your right of access may be limited if providing this information
            could endanger the health or safety of yourself or others.
         </Text>

         <Text>
            <Bold>Right to Request Changes to your Medical Information:</Bold>{' '}
            If you look at your medical information and believe that some of the
            information is wrong or incomplete, you may submit a request to have
            it fixed. To request a change, write to the Medical Records
            Department. We will respond as soon as possible, but no later than
            60 days from the date of your request. If we deny your request, you
            have the right to submit a written statement of reasonable length
            disagreeing with the denial. We then have the right to send a
            rebuttal statement.
         </Text>

         <Text>
            <Bold>
               Right to Get a List of Certain Disclosures of Your Medical
               Information:
            </Bold>{' '}
            You have the right to request a list of many of the disclosures we
            make of your medical information. This list does not include
            information used for treatment, payment, health care operations or
            any information released with your permission. If you would like to
            get a copy of the list, write to the Medical Records Department. We
            will respond as soon as possible, but no later than 60 days from the
            date of request. We will provide the first list to you free, but we
            may charge you for any additional lists you request during the same
            year. We will tell you in advance what this list will cost.
         </Text>

         <Text>
            <Bold>
               Right to Request Restrictions on How We Will Use or Share Your
               Medical Information for Treatment, Payment, or Health Care
               Operations:
            </Bold>{' '}
            You have the right to request us not to share your medical
            information for your treatment, payment for care, or to operate the
            facility. We are required to agree to such requests if the
            disclosure of information is to a health plan regarding payment or
            health care operations and the protected health information relates
            to an item or service that has been paid for out of pocket in full
            to the provider and the disclosure is not required by law. We are
            not required to agree to your requests that do not match these
            criteria, but if we do agree, we will comply with that agreement. If
            you want to make a request that we not share your information, you
            must make this request in writing to the Medical Records Department
            and describe your request in detail.
         </Text>

         <Text>
            <Bold>Right to Request Private Communications:</Bold> You have the
            right to ask us to communicate with you in a way that you feel is
            more private. For example, you can ask us not to call your home, but
            to contact you only by mail. To do this, you must make this request
            in writing of the office at which you receive your care.
         </Text>

         <Text>
            <Bold>Right to a Paper Copy:</Bold> You have the right to a paper
            copy of this Notice at any time. You may download and print a copy
            of the notice from our Web site, at www.cmmc.org or you may obtain a
            paper copy of the notice at any CMHC Office.
         </Text>

         <Text>
            <Bold>Changes to this Notice</Bold>
         </Text>
         <Text>
            &emsp;&emsp;From time to time, we may change our practices
            concerning how we use or share patient medical information, or how
            we will protect patient rights concerning their information. We
            reserve the right to change this Notice and to make the provisions
            in our new Notice effective for all medical information we maintain.
            If we change these practices, we will publish a revised Notice of
            Privacy Practices. You can get a copy of our current notice of
            Privacy Practices at any time by stopping in any of CMHC locations
            or from the website.
         </Text>

         <Text>
            <Bold>Which healthcare providers are covered by this notice?</Bold>
         </Text>
         <Text>
            &emsp;&emsp;This Notice of Privacy Practices applies to our
            personnel, volunteers, students, and trainees. The Notice also
            applies to other health care providers that come to our facility to
            care for patients, such as physicians, physician assistants,
            therapists, and other health care providers who are not employed by
            us, unless these other health care providers give you their own
            Notice that describes how they will protect your medical
            information. We may share your medical information with these
            providers for treatment purposes, payment and health care
            operations. This arrangement is solely for sharing information and
            not for any other purpose.
         </Text>

         <Text>
            <Bold>Do you have concerns or complaints?</Bold>
         </Text>
         <Text>
            &emsp;&emsp;Please tell us about any problems or concerns you have
            with your privacy rights or how we use or share your medical
            information. If you have a concern, please contact the Privacy
            Contact at your facility as listed at the end of this Notice.
         </Text>
         <Text>
            &emsp;&emsp;If for some reason we cannot resolve your concern, you
            may also file a complaint with the federal government at New England
            Region - Office for Civil Rights, U.S. Department of Health and
            Human Services Government Center J.F.Kennedy Federal Building - Room
            1875, Boston, MA 02203; Customer Response Center: (800) 368-1019 or
            TDD: (800)537-7697, Email: ocrmail@hhs.gov. We will not penalize you
            or retaliate against you in any way for filing a complaint with the
            federal government. U.S. Department of Health and Human Services.
         </Text>

         <Text>
            <Bold>Do you have questions?</Bold>
         </Text>
         <Text>
            &emsp;&emsp;We are required by law to maintain the privacy of your
            medical information, to give you this Notice, and to notify you if
            your unsecured personal health information is breached. It is also
            our responsibility to follow the terms of the Notice that is
            currently in effect. If you have any questions about this Notice, or
            have further questions about how we may use and share your medical
            information, please contact the Privacy Officer for Central Maine
            Healthcare at the number listed, or your local practice or
            department
         </Text>
         <Text>
            <Bold>
               Cmhc hospital locations and service delivery sites, along with
               privacy contact and phone number office 207-786-1826 or privacy
               hotline 207-795-2906
            </Bold>
         </Text>
         <Text>
            <Bold>Cmhc privacy contact and phone numbers</Bold>
         </Text>
         <Text>
            &emsp;Central Maine Medica <br /> &emsp;Center 300 Main Street
            Lewiston,
            <br /> &emsp;Maine 04240
            <br />
            <Bold>
               &emsp;Privacy Officer
               <br /> &emsp;(207) 786-1826
            </Bold>
         </Text>
         <Text>
            <Bold>
               <br /> &emsp;Central Maine Healthcare
               <br /> &emsp;Confidential Compliance &amp; Privacy Hotline
               <br /> &emsp;(207)795-2906
            </Bold>
            <br /> &emsp;Or email at: <br />{' '}
            &emsp;Compliance_and_Privacy_Hotline@cmhc.org
         </Text>
      </ContentWrapper>
   </Wrapper>
);

export default PrivacyNotice;
