import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '@datashop/text';
import Icon from '@datashop/icon';
import { Mixpanel } from 'helpers/mixpanelHelper';

const Column = styled.div`
   display: flex;
   flex-direction: column;
   padding-bottom: 8px;
   flex: 1;
   margin-top: 8px;
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`;

const Label = styled(Text)`
   color: #868686;
`;

const Chevron = styled(Icon)`
   color: #868686;
   margin-left: 4px;
   font-size: 16px;
   cursor: pointer;
`;

const Reason = styled(Text)`
   color: #2f2f2f;
   margin-top: 4px;
`;

const ReasonForVisit = ({ reason }) => {
   const [isOpen, setIsOpen] = useState(false);

   const toggle = () => {
      if (!isOpen) {
         Mixpanel.track('Reason of visit viewed Waiting Room', {
            category: 'visitReason',
         });
      }
      setIsOpen(open => !open);
   };

   return (
      <Column>
         <Row>
            <Label>Reason for visit</Label>
            <Chevron
               onClick={toggle}
               name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            />
         </Row>
         {isOpen && <Reason>{reason}</Reason>}
      </Column>
   );
};

ReasonForVisit.propTypes = {
   reason: PropTypes.string.isRequired,
};

export default ReasonForVisit;
