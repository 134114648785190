import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import md5 from 'js-md5';
import axios from 'axios';

import { returnCreatedOn } from 'helpers/returnCreatedTime';
import FileIcon from './FileIcon';
import { axiosAttachment } from 'helpers/axios';
import { Loader } from 'packages/loader';
import * as S from '../style';
import MessageStatus from '../MessageStatus';
import { asyncDelay } from 'utils';
import createToast from 'helpers/toastHelper';

const STATUSES = {
   preparing: 'PREPARING',
   uploading: 'UPLOADING',
   finishing: 'FINISHING',
   completed: 'COMPLETED',
   downloading: 'DOWNLOADING',
   canceled: 'CANCELED',
   error: 'ERROR',
};

const FileName = styled.div`
   font-weight: bold;
   max-width: 160px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
`;

const FileInfo = styled.div`
   color: #868686;
   font-size: 12px;
`;

const Separator = styled.span`
   display: inline-block;
   margin: 0 4px 0 4px;
`;

const UploadStatus = styled.span`
   text-transform: capitalize;
   font-size: 12px;
   line-height: 14px;
   color: #868686;
   margin-top: 4px;
`;

const ActionIconWrapper = styled.span`
   display: inline-flex;
   align-items: center;
   justify-content: center;
   position: relative;
   height: 24px;
   width: 24px;
`;
const Close = styled.i`
   font-size: 14px;
   position: absolute;
   top: 2px;
   left: 5px;
   cursor: pointer;
   z-index: 10;
`;

const Download = styled.span`
   display: inline-flex;
   align-items: center;
   justify-content: center;
   height: 18px;
   width: 18px;
   border-radius: 50%;
   cursor: pointer;
`;

const DownloadIcon = styled.i`
   font-size: 16px;
`;

const ErrorTextWrapper = styled.div`
   font-size: 12px;
   color: #868686;
   display: flex;
   align-items: center;
`;

const ErrorText = styled.span`
   display: inline-flex;
   align-items: center;
   color: #d93737;

   i {
      font-size: 12px;
      margin-right: 4px;
   }
`;

const Retry = styled.button`
   display: inline-block;
   border: none;
   background: none;
   outline: none;
   color: #868686;
   font-family: 'Nunito Sans';
   font-size: 12px;
   line-height: 14px;
   padding: 0;
   cursor: pointer;

   ::hover,
   ::focus,
   :active {
      outline: none;
   }

   ::disabled {
      opacity: 0.5;
   }
`;

const Attachment = ({
   index,
   messageDetails,
   isStatus,
   onSuccess,
   onAttachmentUploadStart,
   addAttachmentIdentifier,
   onAttachmentUploadDone,
}) => {
   const [downloading, setDownloading] = useState(false);
   const [error, setError] = useState(false);
   const [uploadStatus, setUploadStatus] = useState('');

   const { sent_at, status: messageStatus, ticks, message } = messageDetails;
   const {
      file,
      upload,
      attachment: { display_name, size, url, mime_type },
   } = message;

   const axiosCancelSource = useRef(axios.CancelToken.source());

   const download = () => {
      setDownloading(true);
      setError(false);
      setUploadStatus(STATUSES.downloading);
      axios({
         url,
         method: 'GET',
         responseType: 'blob',
         cancelToken: axiosCancelSource.current.token,
      })
         .then(({ data: blob }) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = display_name;
            a.click();
            setTimeout(() => {
               window.URL.revokeObjectURL(url);
            }, 60000);
            setUploadStatus(STATUSES.completed);
         })
         .catch(err => {
            if (axios.isCancel(err)) {
               createToast({
                  message: 'Download canceled!',
               });
            } else {
               createToast({
                  message:
                     'Failed to download a file from cloud. Please try again after sometime.',
               });
               setUploadStatus(STATUSES.error);
            }
            setError(true);
         })
         .finally(() => {
            setDownloading(false);
         });
   };

   const initializeUpload = async (payload = {}) => {
      const { data } = await axiosAttachment({
         url: 'attachments/',
         method: 'POST',
         headers: {
            'content-type': 'application/json',
         },
         data: payload,
         cancelToken: axiosCancelSource.current.token,
      });

      return data;
   };

   const uploadFile = async (presignedUploadUrl, fileAsBinary) => {
      const response = await axios({
         url: presignedUploadUrl,
         method: 'PUT',
         data: fileAsBinary,
         cancelToken: axiosCancelSource.current.token,
      });

      return await response.headers.etag;
   };

   const completeUpload = async (presignedCompletionUrl, eTag) => {
      const maxAttempts = 3;
      let attempts = 1;
      const data = `<CompleteMultipartUpload xmlns="http://s3.amazonaws.com/doc/2006-03-01/">
         <Part>
            <ETag>${eTag}</ETag>
            <PartNumber>1</PartNumber>
         </Part>
      </CompleteMultipartUpload>`;

      const request = async () => {
         return await axios({
            url: presignedCompletionUrl,
            method: 'POST',
            headers: {
               'content-type': 'text/xml',
            },
            data,
            cancelToken: axiosCancelSource.current.token,
         })
            .catch(err => {
               if (attempts <= maxAttempts) {
                  request();
                  throw err;
               }
            })
            .finally(() => {
               attempts++;
            });
      };

      return request();
   };

   const confirmCompletion = async uploadId => {
      const request = async () => {
         const { data } = await axiosAttachment({
            url: `/attachments/${uploadId}/`,
            method: 'GET',
            cancelToken: axiosCancelSource.current.token,
         });

         if (data.status === 'FAILED') {
            createToast({
               message: data.reason,
            });
            throw new Error('Upload failed');
         }
         if (data.status === 'COMPLETE') return data;
         await asyncDelay(500);
         return await request();
      };

      return request();
   };

   const handleUpload = async (uploadPayload, fileAsBuffer) => {
      try {
         setError(false);
         setUploadStatus(STATUSES.preparing);

         const initializeResponse = await initializeUpload(uploadPayload);

         setUploadStatus(STATUSES.uploading);
         addAttachmentIdentifier({ external_id: initializeResponse.id, index });
         const eTag = await uploadFile(
            initializeResponse.presignedUploadUrls[0],
            fileAsBuffer
         );

         setUploadStatus(STATUSES.finishing);
         await completeUpload(initializeResponse.presignedCompletionUrl, eTag);
         const uploadConfirmationResponse = await confirmCompletion(
            initializeResponse.id
         );
         if (uploadConfirmationResponse) {
            const {
               mimeType,
               fileName,
               presignedUrl,
               thumbnailUrl,
               fileSize,
               duration,
            } = uploadConfirmationResponse;

            onSuccess({
               attachment: {
                  mime_type: mimeType,
                  display_name: fileName,
                  url: presignedUrl,
                  thumbnail_url: thumbnailUrl,
                  size: fileSize,
                  duration,
               },
               external_id: initializeResponse.id,
               index,
            });
            setUploadStatus(STATUSES.completed);
         }
      } catch (err) {
         if (axios.isCancel(err)) {
            createToast({
               message: 'Upload canceled!',
            });
         } else {
            setUploadStatus(STATUSES.error);
         }
         onAttachmentUploadDone();
         setError(true);
      }
   };

   const startUpload = () => {
      if (upload && file) {
         onAttachmentUploadStart();
         const reader = new FileReader();

         reader.onload = function (event) {
            const buffer = event.target.result;
            const md5Hash = md5(buffer);

            handleUpload(
               {
                  fileName: file.name,
                  fileSize: file.size,
                  chunkSize: file.size > 5242880 ? file.size : 5242880,
                  md5Hash,
               },
               buffer
            );
         };

         reader.readAsArrayBuffer(file);
      }
   };

   const cancelRequest = () => {
      if (axiosCancelSource.current) {
         axiosCancelSource.current.cancel('Request canceled!');
         axiosCancelSource.current = axios.CancelToken.source();
         setUploadStatus(STATUSES.canceled);
      }
   };

   useEffect(() => {
      if (upload && file) {
         startUpload();
      }
   }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

   const retry = () => {
      if (upload && file) {
         startUpload();
      } else {
         download();
      }
   };

   const displayNameArray = display_name.split('.');
   const extension = displayNameArray.pop();
   const fileName = displayNameArray.join('.');

   const renderFooter = () => {
      if (error) {
         return (
            <ErrorTextWrapper>
               <ErrorText>
                  <i className='material-icons'>error</i>Failed
               </ErrorText>
               <Separator>•</Separator>
               <Retry onClick={retry}>Click to retry</Retry>
            </ErrorTextWrapper>
         );
      }

      if (sent_at) {
         if (uploadStatus && uploadStatus === STATUSES.downloading) {
            return <UploadStatus>Downloading...</UploadStatus>;
         }

         return (
            <S.Row
               style={{
                  marginTop: '4px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
               }}>
               <S.TimeWrapper>{returnCreatedOn(sent_at)}</S.TimeWrapper>
               {isStatus && (
                  <MessageStatus status={messageStatus} ticks={ticks} />
               )}
            </S.Row>
         );
      } else {
         if (upload && uploadStatus !== STATUSES.completed) {
            return <UploadStatus>{uploadStatus.toLowerCase()}...</UploadStatus>;
         }

         return null;
      }
   };

   const renderActions = () => {
      if (error) return null;

      if ((upload && uploadStatus !== STATUSES.completed) || downloading) {
         return (
            <ActionIconWrapper>
               <Loader stroke='#0070DD' height='18px' width='18px' />
               <Close className='material-icons' onClick={cancelRequest}>
                  close
               </Close>
            </ActionIconWrapper>
         );
      }

      return (
         <Download onClick={download}>
            <DownloadIcon className='material-icons'>download</DownloadIcon>
         </Download>
      );
   };

   return (
      <div>
         <div style={{ display: 'flex' }}>
            <FileIcon fileType={upload ? (file || {}).type : mime_type} />
            <div style={{ padding: '0 8px' }}>
               <FileName>{fileName}</FileName>
               <FileInfo>
                  <span>{extension.toUpperCase()}</span>{' '}
                  <Separator>•</Separator>
                  <span>
                     {`${parseFloat((size / (1000 * 1000)).toFixed(2))} `}
                     MB
                  </span>
               </FileInfo>
            </div>
            {renderActions()}
         </div>
         {renderFooter()}
      </div>
   );
};

export default Attachment;
