import React, { Fragment } from 'react';
import DatashopModal from '@datashop/modal';
import MedianEndCallModal from './MedianEndCallModal';
import FootEndCallModal from './FootEndCallModal';

const EndCallModalContainer = ({
   changeStatusToReady,
   close,
   modalType,
   isVisitor,
   visitId,
   canChangeStatus,
   userType,
   leaveVisit,
   endCallHandler,
   innerWidth,
   incompleteHandler,
   skipHandler,
   askForIncomplete,
}) => {
   return (
      <Fragment>
         {modalType === 'median' ? (
            <DatashopModal
               hideScroll
               backdrop
               closeOnEscape
               style={{ width: 360 }}
               onClose={close}
               showCloseButton={false}>
               <MedianEndCallModal
                  changeStatusToReady={changeStatusToReady}
                  close={close}
                  canChangeStatus={canChangeStatus}
                  visitId={visitId}
                  leaveVisit={leaveVisit}
                  isVisitor={isVisitor}
                  userType={userType}
                  endCallHandler={endCallHandler}
                  incompleteHandler={incompleteHandler}
                  skipHandler={skipHandler}
                  askForIncomplete={askForIncomplete}
               />
            </DatashopModal>
         ) : (
            <FootEndCallModal
               changeStatusToReady={changeStatusToReady}
               close={close}
               canChangeStatus={canChangeStatus}
               leaveVisit={leaveVisit}
               visitId={visitId}
               isVisitor={isVisitor}
               userType={userType}
               endCallHandler={endCallHandler}
               innerWidth={innerWidth}
               incompleteHandler={incompleteHandler}
               skipHandler={skipHandler}
               askForIncomplete={askForIncomplete}
            />
         )}
      </Fragment>
   );
};

export default EndCallModalContainer;
