import styled from 'styled-components';
import Button from '@datashop/button';
import Card from '@datashop/card';

export const Wrapper = styled.div`
   display: flex;
   flex: 1;
`;

export const BodyWrapper = styled.div`
   display: flex;
   padding: 12px 0 0 12px;
   flex-direction: column;
   flex: 1;
`;

export const SearchWrapper = styled.div`
   flex-direction: row;
   display: flex;
   align-items: center;
   margin-right: 12px;
`;

export const ButtonWrapper = styled(Button)`
   margin-left: 12px;
`;

export const InputWrapper = styled.div`
   flex: 1;
`;

export const MyPatientWrapper = styled.div`
   flex: 1;
   margin-top: 12px;
   overflow: auto;
`;

export const MarginAuto = styled.div`
   display: flex;
   margin-left: -12px;
   height: ${window.innerHeight - 179}px;
`;

export const TabWrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

export const Column = styled.div`
   display: flex;
   flex-direction: column;
`;

export const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

export const HeadingWrapper = styled.div`
   flex-direction: column;
   display: flex;
   padding: 12px 16px;
   height: 112px;
   max-height: 112px;
   position: sticky;
   background-color: ${({ theme }) => theme.datashop.palette.white.main};
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const DialogWrapper = styled.div`
   align-items: flex-start;
   background-color: #fff;
   display: flex;
   font-family: sans-serif;
   justify-content: center;
   padding: 16px;
   height: 200px;
   width: 320px;
`;

export const ListWrapper = styled.div`
   padding: 0 12px;
   height: calc(100vh - 167px);
   overflow: auto;
`;

export const ListInfoWrapper = styled(Card)`
   // width: calc(100% - 12px);
   margin-bottom: 12px;
   background-color: #ffffff;
   padding: 12px;
   ${({ err }) => err && `margin-top: 12px;`}
   :last-child {
      margin-bottom: ${({ err }) => (err ? '0' : '12px')};
   }
`;

export const ObserverWrapper = styled.div`
   margin-bottom: 12px;
`;
