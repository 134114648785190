import React from 'react';
import styled from 'styled-components';
const TimerWrapper = styled.div`
   top: ${({ isFocusedView, isTopOff }) =>
      isTopOff || !isFocusedView ? '40px;' : '170px;'}
   position: fixed;
   z-index: 2;
   display: flex;
   margin: 0 auto;
   left: 0;
   right: 0;
   justify-content: center;
   color: #ffffff;
   font-size: 18px;
   bottom: 65px;
   letter-spacing: 0;
   line-height: 22px;
   font-weight: 600;
   width: fit-content;
`;

const TimerText = styled.p`
   margin-left: 8px;
`;

const Timer = ({ count, isFocusedView, isTopOff }) => {
   return (
      <TimerWrapper isFocusedView={isFocusedView} isTopOff={isTopOff}>
         <TimerText>
            {(() => {
               let minuteHandler = Math.floor(count / 60);
               let secondHandler = count % 60;
               return `${
                  minuteHandler < 10 ? `0${minuteHandler}` : minuteHandler
               }:${secondHandler < 10 ? `0${secondHandler}` : secondHandler}`;
            })()}
         </TimerText>
      </TimerWrapper>
   );
};

export default Timer;
