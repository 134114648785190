import React from 'react';
import styled from 'styled-components';
import { LabelWrapper, Wrapper } from './PoorEmoji';

const SvgWrapper = styled.svg`
   &:hover {
      circle {
         fill: #ffc208;
      }
      ellipse {
         fill: #6b4a06;
      }
   }
`;

const GoodEmoji = ({ selected, showLabel }) => (
   <Wrapper>
      <SvgWrapper width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fill-rule='evenodd'>
            <circle
               fill={selected ? '#ffc208' : '#E5E5E5'}
               fill-rule='nonzero'
               cx='15.998'
               cy='15.998'
               r='15.998'
            />
            <path
               d='M5.495 19.993c0 4.373 4.702 7.918 10.502 7.918 5.8 0 10.502-3.545 10.502-7.918 0-2.532-2.248-1.609-4.899-1.036-1.928.417-4.105.755-5.603.755-1.457 0-3.655-.338-5.597-.755-2.65-.568-4.905-1.487-4.905 1.036z'
               fill='#595959'
               fill-rule='nonzero'
            />
            <path
               d='M7.199 19.743c0 1.53 6.442 2.786 8.887 2.857 2.446.071 8.713-1.328 8.713-2.857 0-.885-2.413-.04-4.133.309-1.585.321-3.355.582-4.58.582-1.19 0-2.982-.257-4.574-.582-1.71-.35-4.313-1.19-4.313-.309z'
               fill='#FFF'
               fill-rule='nonzero'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='10.3'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
            <ellipse
               fill={selected ? '#6b4a06' : '#595959'}
               cx='21.7'
               cy='11.8'
               rx='2.5'
               ry='3.5'
            />
         </g>
      </SvgWrapper>
      {showLabel && <LabelWrapper isSelected={selected}>Good</LabelWrapper>}
   </Wrapper>
);

export default GoodEmoji;
