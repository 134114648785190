import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@datashop/button';
import StyledCard from '@datashop/card';

import LoadingState from './TabLoadingState';
import CallLogItem from './CallLogItem';

import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';
import createToast from 'helpers/toastHelper';

const Wrapper = styled.div`
   padding: 8px 12px;
   max-height: ${window.innerHeight - 236}px;
   overflow: auto;
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const Card = styled(StyledCard)`
   padding: 12px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const Icon = styled.i`
   margin-right: 4px;
   font-size: 14px;
`;

const ButtonWrapper = styled.div`
   padding: 12px 16px;
   display: flex;
   align-items: center;
   background: #fff;
`;

const CallLog = props => {
   const [loading, toggleLoading] = useState(true);
   const [data, setData] = useState([]);
   const [isLoadingMore, setLoadingMore] = useState(false);
   const [endReached, setEndReached] = useState(false);
   const [offset, setOffset] = useState(0);

   const fetchData = (shouldAppend = false, isEdit = false) => {
      if (shouldAppend) setLoadingMore(true);
      return axios
         .get(
            `/innote-survey/telehealth/video/user/schedule?status=COMPLETED&offset=${
               isEdit ? offset - 20 : offset
            }`
         )
         .then(({ data: { schedule } }) => {
            setData(prevData => {
               if (isEdit) {
                  prevData.splice(prevData.length - 20, 20);
               }
               return shouldAppend ? [...prevData, ...schedule] : schedule;
            });
            if (!isEdit && schedule.length >= 20) {
               setOffset(offset + 20);
            } else {
               setEndReached(true);
            }
         })
         .catch(() => {
            setEndReached(true);
         })
         .finally(() => {
            toggleLoading(false);
            setLoadingMore(false);
         });
   };

   useEffect(() => {
      Mixpanel.track('Call log clicked', { category: 'virtualVisit' });
      fetchData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const markAsTest = item => {
      setLoadingMore(false);
      toggleLoading(true);
      axios
         .patch(
            `innote-survey/telehealth/video/schedule/${item.scheduleId}/mark_test_call`,
            {
               isTestCall: !item.isTestCall,
            }
         )
         .then(() => {
            fetchData(false, true);
            createToast({
               message: 'Call label updated.',
               time: 5,
               appearance: 'success',
            });
            Mixpanel.track(
               `Test call ${item.isTestCall ? 'unmarked' : 'marked'} from log`,
               {
                  category: 'testCall',
               }
            );
         })
         .catch(() => {
            toggleLoading(false);
            setLoadingMore(true);
         });
   };

   const markInComplete = item => {
      setLoadingMore(false);
      toggleLoading(true);
      axios
         .patch(
            `/innote-survey/telehealth/video/schedule/${item.scheduleId}/mark_incomplete`,
            {
               isIncomplete: !(item.callStatus === 'INCOMPLETE'),
            }
         )
         .then(() => {
            fetchData(false, true);
            createToast({
               message: 'Call status updated.',
               time: 5,
               appearance: 'success',
            });
            Mixpanel.track(
               `Incomplete call ${
                  !(item.callStatus === 'INCOMPLETE') ? 'unmarked' : 'marked'
               } from log`,
               {
                  category: 'incompleteCall',
               }
            );
         })
         .catch(() => {
            toggleLoading(false);
            setLoadingMore(true);
         });
   };

   return (
      <Fragment>
         <Wrapper>
            {loading ? (
               <Fragment>
                  <LoadingState />
               </Fragment>
            ) : data.length > 0 ? (
               <CallLogItem
                  toggleModal={props.toggleModal}
                  endReached={endReached}
                  isLoadingMore={isLoadingMore}
                  loading={loading}
                  fetchData={fetchData}
                  markAsTest={markAsTest}
                  markInComplete={markInComplete}
                  data={data}
               />
            ) : (
               <Card shadow='light'>No record to show</Card>
            )}
            <Fragment>
               {isLoadingMore ? (
                  <CardPlaceholder smallList />
               ) : (
                  endReached &&
                  data.length > 20 && (
                     <Card shadow='light'>No more records to show</Card>
                  )
               )}
            </Fragment>
         </Wrapper>
         <ButtonWrapper>
            <Button
               expanded
               onClick={props.downloadFile}
               disabled={props.fileDownloading || data.length === 0}>
               <Icon className='material-icons'>get_app</Icon> Download Visit
               Records
            </Button>
         </ButtonWrapper>
      </Fragment>
   );
};

export default CallLog;
