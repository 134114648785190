import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Verify from './Verify';
import Login from './Login';
import { Mixpanel } from 'helpers/mixpanelHelper';
import axios from 'helpers/axios';
import { Loader } from 'packages/loader';
import createToast from 'helpers/toastHelper';
import { logSentry } from 'services/sentry';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

const LoaderWrapper = styled.div`
   right: 0;
   bottom: 0;
   left: 0;
   top: 0;
   position: fixed;
   height: 32px;
   margin: auto;
`;

const LogoWrapper = styled.div`
   margin: 56px 0 40px;
   display: flex;
   justify-content: center;
`;

const Logo = styled.img`
   width: 200px;
   height: 33.75px;
`;

const BodyWrapper = styled.div`
   padding: 16px;
   display: flex;
   flex-direction: column;
   align-self: center;
   max-width: 400px;
`;

const TroubleWrapper = styled.div`
   margin-top: 32px;
   border: 1px solid #e7e5e5;
   padding: 8px 16px;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const TroubleText = styled.div`
   color: #868686;
   line-height: 19px;
`;

const ContactInfoWrapper = styled.div`
   display: flex;
   align-items: center;
   margin-top: 12px;
`;

const Icon = styled.i`
   font-size: 12px;
   margin: 0 4px 0 0 !important;
`;

const ContactInfo = styled.div`
   color: #2f2f2f;
   font-size: 14px;
   line-height: 16px;
`;

class Signup extends Component {
   state = {
      email: null,
      isLoading:
         this.props.location.state && !!this.props.location.state.inviteId,
   };

   setDetails = details => {
      const {
         email,
         npi,
         available,
         name: { firstName, lastName },
      } = details;
      localStorage.setItem('npi', npi);
      if (available) {
         localStorage.setItem('isAvailable', available);
      }
      Mixpanel.people.set({
         $name: `${firstName} ${lastName}`,
         $email: email,
         NPI: npi || '',
      });
      // @TODO remove this call after debugging
      logSentry({
         data: {
            $name: `${firstName} ${lastName}`,
            $email: email,
            NPI: npi || '',
            AppName: 'COVID-19',
         },
         identify: email,
      });
      Mixpanel.identify(`${email}`);
      Mixpanel.track('Sign Up', {
         category: 'login',
         Name: `${firstName} ${lastName}`,
         Email: email,
         NPI: npi,
      });
      this.setState({ email });
   };

   componentDidMount() {
      if (!localStorage.getItem('isOnboarded')) {
         this.setState({
            redirectToOnboarding: true,
         });
      } else if (this.state.isLoading) {
         axios
            .get(
               `/innote-survey/user/_invite?invitation-id=${this.props.location.state.inviteId}`
            )
            .then(({ data: { email } }) => {
               Mixpanel.track('Valid Invite Id', {
                  Email: email,
                  category: 'login',
               });
               return axios
                  .post('/innote-survey/user/auth ', {
                     email: email,
                     loginType: 'email',
                  })
                  .then(({ data }) => {
                     Mixpanel.track('Valid Email Entered', {
                        category: 'login',
                     });
                     this.setState({ email });
                     this.setDetails(data);
                  });
            })
            .catch(
               ({
                  response: {
                     data: { error: { message = 'Something went wrong' } = {} },
                  },
               }) => {
                  createToast({ message });
               }
            )
            .finally(() => {
               this.setState({ isLoading: false });
            });
      }
   }

   resetDetails = () => {
      this.setState({ email: null });
   };

   render() {
      return this.state.isLoading ? (
         <LoaderWrapper>
            <Loader />
         </LoaderWrapper>
      ) : (
         <Wrapper>
            {this.state.redirectToOnboarding && <Redirect to='/onboarding' />}
            <LogoWrapper>
               <Logo
                  src={`${process.env.PUBLIC_URL}/img/logo.png`}
                  alt='Innovaccer'
               />
            </LogoWrapper>
            <BodyWrapper>
               {this.state.email ? (
                  <Verify
                     email={this.state.email}
                     resetDetails={this.resetDetails}
                  />
               ) : (
                  <Login setDetails={this.setDetails} />
               )}
               <TroubleWrapper>
                  <TroubleText>Trouble logging in?</TroubleText>
                  <ContactInfoWrapper>
                     <Icon className='material-icons'>call</Icon>
                     <ContactInfo>
                        <a
                           href='tel:+18889826819'
                           target='_blank'
                           rel='noopener noreferrer'>
                           1.888.982.6819
                        </a>
                     </ContactInfo>
                  </ContactInfoWrapper>
                  <ContactInfoWrapper>
                     <Icon className='material-icons'>email</Icon>
                     <ContactInfo>
                        <a
                           href='mailto:vcnsupport@innovaccer.com'
                           target='_top'>
                           vcnsupport@innovaccer.com
                        </a>
                     </ContactInfo>
                  </ContactInfoWrapper>
               </TroubleWrapper>
            </BodyWrapper>
         </Wrapper>
      );
   }
}

export default Signup;
