import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
   background-color: #ffffff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
   padding: 12px;
   border-bottom: 1px solid #ededed;
`;

const HeaderWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const Header = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const CloseIcon = styled.i`
   font-size: 20px;
   cursor: pointer;
`;

const StepCounter = styled.p`
   color: #868686;
   line-height: 20px;
`;

const StepToHeader = {
   1: 'COVID-19 Assessment',
   2: 'COVID-19 Assessment',
   3: 'COVID-19 PUI Form',
};

const ModalHeader = ({
   close,
   header,
   subtext,
   currentStep,
   startLoader,
   disableHeader,
}) => {
   return (
      <Wrapper>
         <HeaderWrapper>
            <Header>{header || StepToHeader[currentStep]}</Header>
            <CloseIcon
               onClick={() => {
                  close();
                  startLoader && startLoader();
               }}
               className='material-icons'>
               close
            </CloseIcon>
         </HeaderWrapper>
         {!disableHeader && (
            <StepCounter>{subtext || `Step ${currentStep} of 3`}</StepCounter>
         )}
      </Wrapper>
   );
};

export default ModalHeader;
