import React from 'react';
import * as S from 'components/Messages/style';
import Input from '@datashop/input';

import Error from 'components/Chat/Error';
import LoadingState from 'components/VirtualVisit/TabLoadingState';
import AdvanceCard from 'components/Messages/AdvanceCard';
import ObserverItem from './ObserverItem';
import useInfiniteScroll from './useInfiniteScroll';
import { Mixpanel } from 'helpers/mixpanelHelper';

const RecentVisits = ({ openChat, sendInvite }) => {
   const { state, handleSearchChange, increaseOffset } = useInfiniteScroll({
      apiUrl: '/innote-survey/telehealth/patients/_search',
   });

   const { loading, error, search, data, loadingMore, endReached } = state;

   if (error) {
      return (
         <S.MarginAuto>
            <Error type='error' />
         </S.MarginAuto>
      );
   }

   const renderList = () => {
      if (loading) return <LoadingState count={5} />;

      if (data.length === 0)
         return <S.ListInfoWrapper>No record to show</S.ListInfoWrapper>;

      return (
         <>
            {data.map((item, index) => {
               if (index === data.length - 1) {
                  return (
                     <ObserverItem
                        key={index}
                        loading={loading || loadingMore || data.length < 10}
                        endReached={endReached}
                        onIntersect={increaseOffset}>
                        {React.forwardRef((props, ref) => {
                           return (
                              <S.ObserverWrapper innerRef={ref}>
                                 <AdvanceCard
                                    item={item}
                                    isCenter={true}
                                    isAvatar={true}
                                    openChat={props => {
                                       Mixpanel.track(
                                          `Chat with visited patients`,
                                          {
                                             category: 'chat',
                                          }
                                       );
                                       openChat(props);
                                    }}
                                    sendInvite={sendInvite}
                                 />
                              </S.ObserverWrapper>
                           );
                        })}
                     </ObserverItem>
                  );
               }

               return (
                  <AdvanceCard
                     item={item}
                     key={index}
                     isCenter={true}
                     isAvatar={true}
                     openChat={props => {
                        Mixpanel.track(`Chat with visited patients`, {
                           category: 'chat',
                        });
                        openChat(props);
                     }}
                     sendInvite={sendInvite}
                  />
               );
            })}
            {loadingMore && (
               <S.ListInfoWrapper>Loading more...</S.ListInfoWrapper>
            )}
            {endReached && !loadingMore && data.length > 10 && (
               <S.ListInfoWrapper>No more record to show</S.ListInfoWrapper>
            )}
         </>
      );
   };

   return (
      <S.MyPatientWrapper>
         <S.InputWrapper style={{ padding: '0 12px 12px' }}>
            <Input
               icon='search'
               value={search}
               name='search'
               placeholder='Search for patient by name'
               onChange={handleSearchChange}
            />
         </S.InputWrapper>
         <S.ListWrapper>{renderList()}</S.ListWrapper>
      </S.MyPatientWrapper>
   );
};

export default RecentVisits;
