import {
   getTime,
   format,
   startOfToday,
   startOfTomorrow,
   endOfTomorrow,
   startOfYesterday,
} from 'date-fns';

const returnScheduleTime = time => {
   const startOfTomorrowInMs = getTime(startOfTomorrow());
   const startOfTodayInMs = getTime(startOfToday());

   // Check if time has passed yesterday start of day
   if (time >= startOfTodayInMs) {
      let dayString = '';
      if (time >= startOfTomorrowInMs) {
         if (time >= getTime(endOfTomorrow())) {
            dayString = format(new Date(time), 'MM/dd/yyyy');
         } else {
            dayString = 'Tomorrow';
         }
      } else {
         dayString = 'Today';
      }
      return `${dayString}, ${format(new Date(time), 'p')}`;
   } else {
      return format(new Date(time), 'MM/dd/yyyy');
   }
};

const returnCreatedOn = time => {
   const startOfYesterdayInMs = getTime(startOfYesterday());
   const startOfTodayInMs = getTime(startOfToday());
   const currentTime = getTime(new Date());
   if (time >= startOfTodayInMs) {
      if (currentTime - time <= 300000) {
         return 'Just Now';
      } else {
         return `Today, ${format(new Date(time), 'p')}`;
      }
   } else if (time >= startOfYesterdayInMs) {
      return `Yesterday, ${format(new Date(time), 'p')}`;
   } else {
      return `${format(new Date(time), 'MM/dd/yyyy')}, ${format(
         new Date(time),
         'p'
      )}`;
   }
};

export { returnScheduleTime, returnCreatedOn };
