import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { getLetterIconColor } from 'helpers/badgeColor';
import returnCamelCasedName from 'helpers/nameTransformer';
import formatPhoneNumber from 'helpers/formatPhone';
import { changeChat, clearChat } from 'store/frontDoorSocket/action';
import Icon from '@datashop/icon';
import { useHistory } from 'react-router-dom';
import Placeholder from '@datashop/placeholder';

const ChatHeader = ({
   onModalClose,
   modalType,
   selectedInbox,
   setModalType,
   role,
   isChatHeaderLoading,
   changeChat,
   clearChat,
}) => {
   let history = useHistory();
   const goBack = () => {
      changeChat(false);
      clearChat();
      if (role === 'PROVIDER') {
         history.push('/messages');
         onModalClose();
      } else if (role === 'STAFF') {
         history.push('/frontdoor');
         onModalClose();
      } else {
         history.push('/');
      }
   };

   if (modalType === 'chat') {
      return (
         <>
            <div>
               <S.Row isCenter={true}>
                  <>
                     <S.Available>
                        {isChatHeaderLoading ? (
                           <Placeholder>
                              <Placeholder.Image
                                 round
                                 style={{ width: '40px', height: '40px' }}
                              />
                           </Placeholder>
                        ) : (
                           <>
                              <S.AvatarWrapper
                                 text={[
                                    selectedInbox.firstName,
                                    selectedInbox.lastName,
                                 ]
                                    .map((el, i) => (i <= 1 && el ? el[0] : ''))
                                    .join('')
                                    .trimRight()
                                    .toUpperCase()}
                                 backgroundColor={getLetterIconColor(
                                    selectedInbox.firstName,
                                    selectedInbox.lastName
                                 )}
                              />
                              <S.AvailableDot
                                 isAvailable={selectedInbox.isOnline}
                              />
                           </>
                        )}
                     </S.Available>
                     <S.Column>
                        {isChatHeaderLoading ? (
                           <>
                              <Placeholder style={{ width: '150px' }}>
                                 <Placeholder.Paragraph length='large' />
                                 <Placeholder.Paragraph length='large' />
                              </Placeholder>
                           </>
                        ) : (
                           <>
                              <S.StyledHeading
                                 title={returnCamelCasedName(
                                    selectedInbox.firstName,
                                    selectedInbox.lastName
                                 )}
                                 ellipsis={true}>
                                 {returnCamelCasedName(
                                    selectedInbox.firstName,
                                    selectedInbox.lastName
                                 )}
                              </S.StyledHeading>
                              {selectedInbox.phone && (
                                 <S.TextWrapper>
                                    {formatPhoneNumber(selectedInbox.phone)}
                                 </S.TextWrapper>
                              )}
                           </>
                        )}
                     </S.Column>
                  </>
               </S.Row>
            </div>
            <div>
               <Icon
                  name='videocam'
                  style={{
                     fontSize: 18,
                     cursor: 'pointer',
                     marginRight: '24px',
                     color: isChatHeaderLoading ? '#A6A6A6' : 'inherit',
                  }}
                  onClick={() => {
                     !isChatHeaderLoading && setModalType('virtual');
                  }}
               />
               <Icon
                  name='close'
                  style={{ fontSize: 18, cursor: 'pointer' }}
                  onClick={() => {
                     goBack();
                  }}
               />
            </div>
         </>
      );
   }
   if (modalType === 'virtual') {
      return (
         <S.Row isCenter={true}>
            <Icon
               name='arrow_back'
               style={{
                  fontSize: 16,
                  cursor: 'pointer',
                  marginRight: '12px',
               }}
               onClick={() => {
                  setModalType('chat');
               }}
            />
            <S.StyledHeading>Virtual visit</S.StyledHeading>
         </S.Row>
      );
   }
   if (modalType === 'schedule') {
      return (
         <>
            <div>
               <S.Row isCenter={true}>
                  <S.Column>
                     <S.StyledHeading>Schedule a New Visit</S.StyledHeading>
                  </S.Column>
               </S.Row>
            </div>
            <div>
               <Icon
                  name='close'
                  style={{ fontSize: 18, cursor: 'pointer' }}
                  onClick={() => {
                     setModalType('chat');
                  }}
               />
            </div>
         </>
      );
   }
   return '';
};

const mapStateToProps = ({
   frontDoorSocket: { selectedInbox, role, isChatHeaderLoading },
}) => ({
   selectedInbox,
   role,
   isChatHeaderLoading,
});

const mapDispatchToProps = { changeChat, clearChat };

export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader);
