const formatPhoneNumber = phoneNumber => {
   const cleaned = ('' + phoneNumber).replace(/\D/g, '');
   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
   if (match) {
      return '(' + match[1] + ')' + match[2] + '-' + match[3];
   }
   return null;
};

export const unPhoneNumber = phoneNumber => {
   const cleaned = ('' + phoneNumber).replace(/\D/g, '');
   return cleaned;
};

export default formatPhoneNumber;
