import React from 'react';
import styled from 'styled-components';

const SelfBox = styled.div`
   color: #2f2f2f;
   font-size: 14px;
   line-height: 20px;
   background-color: #f4f4f4;
   padding: 12px;
   border-radius: 8px 8px 8px;
   margin-bottom: 8px;
   margin-right: 12px;
   margin-left: 16px;
   width: auto;
   max-width: 70%;
   overflow-wrap: break-word;
   white-space: pre-wrap;
`;

const SelfContainer = ({ el, children }) => {
   return <SelfBox>{children}</SelfBox>;
};

export default SelfContainer;
