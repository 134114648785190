import {
   REQUEST_ARTICLE_LIST,
   ARTICLE_LIST_SUCCESS,
   ARTICLE_LIST_FAILURE,
} from './constants';

// Initial state
const initialState = {
   articlesList: [],
   isLoading: false,
   error: null,
};

/**
 * Articles Reducer
 * @param {Object} [state=initialState] State
 * @param {Object} action Action
 * @return {Object} State
 */
function articles(state = initialState, action) {
   switch (action.type) {
      case REQUEST_ARTICLE_LIST:
         return {
            ...initialState,
            isLoading: true,
         };
      case ARTICLE_LIST_SUCCESS:
         return {
            ...state,
            articlesList: action.articles,
            isLoading: false,
         };
      case ARTICLE_LIST_FAILURE:
         return {
            ...state,
            isLoading: false,
            error: action.error,
         };
      default:
         return state;
   }
}

export default articles;
