import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setAvailableForAssistance } from 'store/frontDoorSocket/action';
import LoadingState from 'components/VirtualVisit/TabLoadingState';
import Error from 'components/Chat/Error';
import ObserveCard from './ObserveCard';
import CardWrapper from './Card';
import { Mixpanel } from 'helpers/mixpanelHelper';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';

const Wrapper = styled.div`
   max-height: ${window.innerHeight - 130}px;
   overflow: auto;
`;

const MarginAuto = styled.div`
   display: flex;
   margin-left: -12px;
   height: ${window.innerHeight - 130}px;
`;

const UnassignedTab = ({
   isAvailableForAssistanceEnable,
   inboxList,
   isUnassignedTabLoading,
   isSocketError,
   isSocketOpen,
   onLineInbox,
   offLineInbox,
   isLoadMoreUnassignedInbox,
   endReachedUnassignedInbox,
   isApiLoading,
   isStaffAvailableButtonEnable,
   getUnassignedInbox,
   assignToMe,
   setAvailableForAssistance,
}) => {
   const [firstLoad, setFirstLoad] = useState(true);

   useEffect(() => {
      if (
         inboxList.length === 0 &&
         isSocketOpen &&
         !isUnassignedTabLoading &&
         firstLoad
      ) {
         setFirstLoad(false);
         getUnassignedInbox();
      }
   }, [
      inboxList.length,
      isSocketOpen,
      getUnassignedInbox,
      isUnassignedTabLoading,
      firstLoad,
   ]);

   const availableForAssistanceChange = availability => {
      Mixpanel.track(`Marked ${availability} chat assistance`, {
         category: 'VFD',
      });
   };

   const getCards = () => {
      const chats = isAvailableForAssistanceEnable
         ? [...onLineInbox, ...offLineInbox]
         : offLineInbox;
      return chats.length > 0 ? (
         <ObserveCard
            chats={chats}
            assignToMe={assignToMe}
            isLoadingMore={isLoadMoreUnassignedInbox}
            endReached={endReachedUnassignedInbox}
            fetchData={getUnassignedInbox}
         />
      ) : (
         <CardWrapper err='No record to show' />
      );
   };

   if (inboxList.length === 0 && isSocketError) {
      return (
         <MarginAuto>
            <Error type='error' />
         </MarginAuto>
      );
   }
   return (
      <Wrapper>
         {isUnassignedTabLoading ? (
            <LoadingState marginRight={true} />
         ) : (
            <>
               {isStaffAvailableButtonEnable && (
                  <CardWrapper
                     isAssistanceCard={true}
                     isAvailableForAssistanceEnable={
                        isAvailableForAssistanceEnable
                     }
                     isApiLoading={isApiLoading}
                     setAvailableForAssistance={() => {
                        setAvailableForAssistance({
                           isAvailableChat: !isAvailableForAssistanceEnable,
                        });
                        availableForAssistanceChange(
                           !isAvailableForAssistanceEnable
                        );
                     }}
                  />
               )}
               {getCards()}
            </>
         )}
         {isLoadMoreUnassignedInbox ? (
            <CardPlaceholder
               smallList
               style={{
                  marginRight: '12px',
                  marginTop: '12px',
                  marginBottom: '12px',
               }}
            />
         ) : (
            endReachedUnassignedInbox &&
            inboxList.length > 50 && (
               <CardWrapper err='No more records to show' />
            )
         )}
      </Wrapper>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      isAvailableForAssistanceEnable,
      inboxList,
      isUnassignedTabLoading,
      isSocketError,
      isSocketOpen,
      onLineInbox,
      offLineInbox,
      isLoadMoreUnassignedInbox,
      endReachedUnassignedInbox,
      isApiLoading,
      isStaffAvailableButtonEnable,
   },
}) => ({
   isAvailableForAssistanceEnable,
   isStaffAvailableButtonEnable,
   inboxList,
   isUnassignedTabLoading,
   isSocketError,
   isSocketOpen,
   onLineInbox,
   offLineInbox,
   isLoadMoreUnassignedInbox,
   endReachedUnassignedInbox,
   isApiLoading,
});

const mapDispatchToProps = {
   setAvailableForAssistance,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedTab);
