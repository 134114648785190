import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@datashop/button';
import MoreOptionDropdown from './MoreOptionDropdown';

import returnCamelCasedName from 'helpers/nameTransformer';
import { returnCreatedOn } from 'helpers/returnCreatedTime';
import { Mixpanel } from 'helpers/mixpanelHelper';
import StyledCard from '@datashop/card';
import Text from '@datashop/text';
import IconWrapper from '@datashop/icon';

const PatientNameWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const PatientName = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
`;

const DetailsWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const PhoneNumber = styled.div`
   color: #868686;
   font-weight: 600;
   line-height: 20px;
   padding-bottom: 8px;
   ${({ isBorder }) => !isBorder && 'border-bottom: 1px solid #e5e5e5;'}
`;

const RequestTime = styled.div`
   color: #868686;
   line-height: 20px;
   margin: 2px 4px 0 0;
`;

const ScheduleWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 8px 0;
`;

const SourceWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const WebsiteDot = styled.div`
   width: 8px;
   height: 8px;
   margin-right: 8px;
   background-color: #ffc208;
   border-radius: 50%;
`;

const AssessmentDot = styled.div`
   width: 8px;
   height: 8px;
   margin-right: 8px;
   background-color: #d93737;
   border-radius: 50%;
`;

const Icon = styled.div`
   font-size: 12px;
   margin-right: 4px;
`;

const Card = styled(StyledCard)`
   padding: 12px 12px 0;
   background-color: #fff;
   margin-bottom: 8px;
`;

const Column = styled.div`
   display: flex;
   flex-direction: column;
   padding-bottom: 8px;
   flex: 1;
   border-bottom: 1px solid #e5e5e5;
   margin-top: 8px;
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`;

const formatPhoneNumber = phoneNumber => {
   const cleaned = ('' + phoneNumber).replace(/\D/g, '');
   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
   if (match) {
      return '(' + match[1] + ')' + match[2] + '-' + match[3];
   }
   return null;
};

const ItemRequest = ({ item, cancelSchedule, toggleModal, setScheduleId }) => {
   const [isOpen, setIsOpen] = useState(false);

   return (
      <Card key={item.scheduleId} shadow='light'>
         <PatientNameWrapper>
            <PatientName>
               {returnCamelCasedName(
                  item.patientInfo.firstName,
                  item.patientInfo.lastName
               )}
            </PatientName>
            <DetailsWrapper>
               <RequestTime>{returnCreatedOn(item.createdOn)}</RequestTime>
               <MoreOptionDropdown
                  cancelSchedule={() => {
                     cancelSchedule(item.scheduleId);
                  }}
                  source='Requests'
               />
            </DetailsWrapper>
         </PatientNameWrapper>
         <PhoneNumber isBorder={item.reasonForVisit}>
            {formatPhoneNumber(item.patientInfo.phoneNo)}
         </PhoneNumber>
         {item.reasonForVisit && (
            <Column>
               <Row>
                  <Text style={{ color: '#868686' }}>Reason for visit</Text>
                  <IconWrapper
                     onClick={() => {
                        if (!isOpen) {
                           Mixpanel.track(
                              'Reason of visit viewed Requests Tab',
                              {
                                 category: 'visitReason',
                              }
                           );
                        }
                        setIsOpen(!isOpen);
                     }}
                     name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                     style={{
                        color: '#868686',
                        marginLeft: '4px',
                        fontSize: '16px',
                     }}
                  />
               </Row>
               {isOpen && (
                  <Text style={{ color: '#2f2f2f', marginTop: '4px' }}>
                     {item.reasonForVisit}
                  </Text>
               )}
            </Column>
         )}
         <ScheduleWrapper>
            {item.meta && item.meta.source === 'WEBSITE' ? (
               <SourceWrapper>
                  <WebsiteDot />
                  Website
               </SourceWrapper>
            ) : (
               <SourceWrapper>
                  <AssessmentDot />
                  Assessment
               </SourceWrapper>
            )}
            <Button
               onClick={() => {
                  toggleModal(
                     {
                        ...item,
                        patientInfo: {
                           ...item.patientInfo,
                           unFormattedNo: item.patientInfo.phoneNo,
                        },
                     },
                     false,
                     item.scheduleId,
                     true
                  );
                  setScheduleId(item.scheduleId);
               }}
               appearance='primary'
               dimension='small'>
               <>
                  <Icon className='material-icons'>schedule</Icon>
                  Schedule
               </>
            </Button>
         </ScheduleWrapper>
      </Card>
   );
};

export default ItemRequest;
