import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '@datashop/modal';

import DownloadCallModal from './DownloadCallModal';
import ScheduleVisit from './ScheduleVisit';
import Tab from './Tab';
import WaitingRoom from './WaitingRoom/WaitingRoom';
import Scheduled from './Scheduled';
import CallLog from './CallLog';
import Requests from './Requests';

import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const getTabList = () => {
   const array = [
      {
         name: 'Waiting Room',
         id: 1,
      },
      {
         name: 'Requests',
         id: 2,
      },
      {
         name: 'Scheduled',
         id: 3,
      },
      {
         name: 'Log',
         id: 4,
      },
   ];
   let tabList = [];
   array.forEach(item => {
      if (item.id !== 2) tabList.push(item);
      else {
         if (
            localStorage.getItem('organization') &&
            !['5eb4080810b3e9000169c6d6', '5ee34dff895c5f02efc38dd2'].some(
               orgId => orgId === localStorage.getItem('organization')
            )
         ) {
            tabList.push(item);
         }
      }
   });
   return tabList;
};

class VirtualVisit extends Component {
   tabList = getTabList();
   static defaultProps = {
      fileDownloading: false,
      showDownloadCallRecordsModal: false,
   };

   state = {
      activeTabId: 1,
   };

   setActiveTab = activeTabId => {
      this.setState({
         activeTabId,
      });
   };

   downloadCallRecordsModal = () => {
      this.setState({ showDownloadCallRecordsModal: true });
   };

   downloadCallRecords = (from, to) => {
      createToast({
         message: 'Downloading call records',
         appearance: 'default',
      });
      this.setState({
         fileDownloading: false,
         showDownloadCallRecordsModal: false,
      });
      axios
         .get(
            `/innote-survey/telehealth/video/user/_download?start-date=${from}&end-date=${to}`
         )
         .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'visit_records.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            createToast({
               message: 'Downloaded successfully',
               appearance: 'success',
            });
         })
         .catch(() => {
            createToast({ message: 'Download failed' });
         });
   };

   componentDidMount() {
      const activeTab = this.props.tab;
      if (activeTab && activeTab !== this.state.activeTabId) {
         this.setState({
            activeTabId: activeTab,
         });
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.tab !== this.props.tab) {
         this.setState({
            activeTabId: this.props.tab,
         });
      }
   }

   render() {
      return (
         <Wrapper>
            {this.state.showDownloadCallRecordsModal && (
               <Modal
                  hideScroll
                  backdrop
                  closeOnEscape
                  style={{ width: 320 }}
                  onClose={() =>
                     this.setState({ showDownloadCallRecordsModal: false })
                  }
                  showCloseButton>
                  <DownloadCallModal downloadFile={this.downloadCallRecords} />
               </Modal>
            )}
            <ScheduleVisit toggleModal={this.props.toggleModal} />
            <Tab
               activeTabId={this.state.activeTabId}
               setActiveTab={this.setActiveTab}
               list={this.tabList}
            />
            {this.state.activeTabId === 1 && (
               <WaitingRoom
                  socketData={this.props.socketData}
                  joinRoom={this.props.joinRoom}
               />
            )}
            {this.state.activeTabId === 2 && (
               <Requests
                  deleteRequest={this.props.deleteRequest}
                  socketData={this.props.socketData}
                  toggleModal={this.props.toggleModal}
                  visitScheduled={this.props.visitScheduled}
               />
            )}
            {this.state.activeTabId === 3 && (
               <Scheduled
                  socketData={this.props.socketData}
                  toggleModal={this.props.toggleModal}
                  visitScheduled={this.props.visitScheduled}
                  toggleInviteModal={this.props.toggleInviteModal}
                  shouldRefreshScheduled={this.props.shouldRefreshScheduled}
               />
            )}
            {this.state.activeTabId === 4 && (
               <CallLog
                  toggleModal={this.props.toggleModal}
                  downloadFile={this.downloadCallRecordsModal}
                  fileDownloading={this.state.fileDownloading}
               />
            )}
         </Wrapper>
      );
   }
}

const mapStateToProps = ({ virtualVisit, socket }) => ({
   ...virtualVisit,
   socket,
});

export default connect(mapStateToProps, null)(VirtualVisit);
