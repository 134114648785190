import { LOGOUT } from './constants';

// Initial state
const initialState = {};

// Reducer
function logout(state = initialState, action) {
   switch (action.type) {
      case LOGOUT:
         return state;
      default:
         return state;
   }
}

export default logout;
