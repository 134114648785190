import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';
import { Mixpanel } from 'helpers/mixpanelHelper';
import { Loader } from 'packages/loader';
import axios from 'helpers/axios';
import JSONToCSVConvertor from 'helpers/fileDownload';
import createToast from 'helpers/toastHelper';

const Header = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 16px;
   margin-bottom: 4px;
   margin-top: 8px;
`;

const ButtonWrapper = styled.div`
   margin-top: 20px;
   display: flex;
`;

const Button = styled(StyledButton)`
   font-weight: 300;
`;

const DownloadErrorButton = styled(StyledButton)`
   font-family: 'Nunito Sans';
   height: 20px;
   padding: 0;
   color: #868686;
   font-weight: 600;
   line-height: 20px;
   margin-top: 8px;
   cursor: pointer;
   text-align: center;
   margin-bottom: 16px;

   &:hover,
   &:active,
   &:focus {
      background-color: transparent;
      border-color: transparent;
      outline: none;
   }
`;

const HeaderIcon = styled.i`
   font-size: 40px;
   margin-bottom: ${({ color }) => color === '#a6a6a6' && '16px'};
   color: ${({ color }) => color && color};
`;

const FileWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const StatusCardHeader = styled.div`
   color: #151414;
   font-weight: 600;
   line-height: 20px;
   margin: 12px 0 8px;
   max-width: 330px;
   white-space: nowrap;
   overflow: auto;
   text-overflow: ellipsis;
`;

const StatusCardSubHeader = styled.div`
   color: #868686;
   line-height: 20px;
   margin-bottom: 8px;
`;

class SendBulkAssessment extends Component {
   state = {
      fileName: '',
      fileSelected: false,
      isFileUploading: false,
      fileUploadSuccess: false,
      fileUploadObject: { error: {} },
      fileUploadError: false,
      showButtonLoader: false,
      fileValidateError: false,
      errorListLength: 0,
      patientListLength: 0,
      totalRecords: 0,
      uploadId: null,
      sendingAssessment: false,
      errorList: [],
   };

   fileInput = React.createRef();

   fileUnselected = () => {
      this.setState(prevProps => ({
         fileSelected: !prevProps.fileSelected,
      }));
   };

   onClickFileUpload = () => {
      this.fileInput.current.value = null;
      this.fileInput.current.click();
   };

   downloadErrorReport = () => {
      Mixpanel.track('Error Report Downloaded', {
         category: 'bulkAssessment',
      });
      JSONToCSVConvertor(this.state.errorList, 'errorReport', true);
   };

   retryUpload = () => {
      this.setState({
         isFileUploading: false,
         fileUploadSuccess: false,
         fileUploadError: false,
         fileValidateError: false,
      });
   };

   uploadFile = () => {
      Mixpanel.track('Bulk assessment initiated', {
         category: 'bulkAssessment',
      });
      this.setState({
         isFileUploading: true,
         fileName: this.fileInput.current.files[0].name,
         showButtonLoader: true,
      });
      if (this.fileInput.current.files[0].size / (1024 * 1024 * 3) > 1) {
         createToast({ message: 'File size limit is 3mb' });
      } else {
         let data = new FormData();
         data.append('file', this.fileInput.current.files[0]);
         axios
            .post(
               `innote-survey/assessments/5e7b840c98c5bf3ad547f6a2/bulk/_validate`,
               data
            )
            .then(({ data: { uploadId, errorList, patientList } }) => {
               const errorListLength = errorList.length;
               if (errorListLength > 0) {
                  Mixpanel.track('Bulk File Upload Error', {
                     category: 'bulkAssessment',
                  });
               } else {
                  Mixpanel.track('Bulk File Upload Success', {
                     category: 'bulkAssessment',
                  });
               }
               const patientListLength = patientList.length;
               const totalRecords = errorListLength + patientListLength;
               const fileValidateError = errorList.length > 0;
               this.setState({
                  errorList,
                  isFileUploading: false,
                  fileUploadSuccess: true,
                  fileValidateError,
                  errorListLength,
                  patientListLength,
                  totalRecords,
                  uploadId,
               });
            })
            .catch(() => {
               this.setState({ isFileUploading: false, fileUploadError: true });
            })
            .finally(() => {
               this.setState({ showButtonLoader: false });
            });
      }
   };

   sendAssessment = () => {
      this.setState({ sendingAssessment: true });
      axios
         .post(
            `/innote-survey/assessments/uploads/${this.state.uploadId}/bulk/_send`,
            {
               assignedTo: this.props.assignedTo,
            }
         )
         .then(() => {
            this.props.showSuccessToast();
            this.props.close();
            Mixpanel.track('Bulk Assessment Sent', {
               category: 'bulkAssessment',
            });
         })
         .catch(() => {
            createToast({ message: 'Something wend wrong, please try again' });
         })
         .finally(() => {
            this.setState({ isFileLoading: false, sendingAssessment: false });
         });
   };

   handleFileSubmit = this.uploadFile.bind(this);

   render() {
      const {
         fileName,
         isFileUploading,
         fileUploadSuccess,
         fileUploadError,
         fileValidateError,
         errorListLength,
         totalRecords,
      } = this.state;
      return (
         <React.Fragment>
            {isFileUploading && (
               <Fragment>
                  <FileWrapper>
                     <Loader />
                     <StatusCardHeader>{fileName}</StatusCardHeader>
                     <StatusCardSubHeader>
                        Please wait while we upload and verify the information
                     </StatusCardSubHeader>
                  </FileWrapper>
               </Fragment>
            )}
            {fileUploadSuccess && !fileValidateError && (
               <Fragment>
                  <FileWrapper>
                     <HeaderIcon className='material-icons' color='#2EA843'>
                        done
                     </HeaderIcon>
                     <StatusCardHeader>{fileName}</StatusCardHeader>
                     <StatusCardSubHeader>{`${totalRecords} recipients verified`}</StatusCardSubHeader>
                  </FileWrapper>
                  <Button
                     expanded
                     appearance='primary'
                     onClick={this.sendAssessment}
                     disabled={this.state.sendingAssessment}>
                     Send Assessment
                  </Button>
               </Fragment>
            )}
            {fileUploadSuccess && fileValidateError && (
               <Fragment>
                  <FileWrapper>
                     <HeaderIcon className='material-icons' color='#F07D00'>
                        error
                     </HeaderIcon>
                     <StatusCardHeader>{`${errorListLength} errors found in ${totalRecords} records`}</StatusCardHeader>
                     <StatusCardSubHeader>
                        We found errors in your file. Please correct the errors
                        and reupload the file.
                     </StatusCardSubHeader>
                     <DownloadErrorButton
                        appearance='transparent'
                        onClick={this.downloadErrorReport}
                        style={{}}>
                        <i
                           className='material-icons'
                           style={{
                              marginRight: '8px',
                              fontSize: '12px',
                           }}>
                           cloud_download
                        </i>
                        Download error report
                     </DownloadErrorButton>
                  </FileWrapper>
                  <Button expanded onClick={this.retryUpload}>
                     Reupload
                  </Button>
               </Fragment>
            )}
            {fileUploadError && (
               <Fragment>
                  <FileWrapper>
                     <HeaderIcon className='material-icons' color='#D93737'>
                        error
                     </HeaderIcon>
                     <StatusCardHeader style={{ marginBottom: '16px' }}>
                        Upload failed
                     </StatusCardHeader>
                  </FileWrapper>
                  <Button
                     appearance='primary'
                     expanded
                     onClick={this.retryUpload}>
                     Retry Upload
                  </Button>
               </Fragment>
            )}
            {!isFileUploading &&
               !fileUploadError &&
               !fileUploadSuccess &&
               !fileValidateError && (
                  <Fragment>
                     <FileWrapper>
                        <HeaderIcon className='material-icons' color='#a6a6a6'>
                           insert_drive_file
                        </HeaderIcon>
                        <Header style={{ textAlign: 'center' }}>
                           Upload a .xls or .xlsx file having the list of phone
                           numbers. Please ensure that the file format is same
                           as mentioned in the sample file.
                        </Header>
                        <a
                           onClick={() =>
                              Mixpanel.track('Sample file downloaded', {
                                 category: 'bulkAssessment',
                              })
                           }
                           style={{
                              color: '#0070dd',
                              fontWeight: '600',
                              lineHeight: '20px',
                              marginTop: '8px',
                              cursor: 'pointer',
                           }}
                           href={`${process.env.PUBLIC_URL}/bulkUploadAssessmentsSample.xlsx`}
                           download>
                           Download sample file
                        </a>
                        {this.state.showButtonLoader && (
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 marginTop: '8px',
                                 lineHeight: '20px',
                              }}>
                              {this.state.fileName}{' '}
                              <div
                                 style={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    marginLeft: '4px',
                                 }}>
                                 <Loader />
                              </div>
                           </div>
                        )}
                     </FileWrapper>
                     <ButtonWrapper>
                        <Button
                           expanded
                           onClick={this.onClickFileUpload}
                           disabled={this.state.showButtonLoader}>
                           {this.state.showButtonLoader
                              ? 'Uploading...'
                              : 'Upload'}
                           <input
                              style={{ display: 'none' }}
                              type='file'
                              ref={this.fileInput}
                              accept='.xls,.xlsx'
                              onChange={e => {
                                 const { target } = e;
                                 if (target.value.length > 0) {
                                    this.uploadFile();
                                 } else {
                                    this.fileUnselected();
                                 }
                              }}
                           />
                        </Button>
                     </ButtonWrapper>
                  </Fragment>
               )}
         </React.Fragment>
      );
   }
}

export default SendBulkAssessment;
