import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
   text-align: center;
   align-items: center;
`;

const SvgWrapper = styled.svg`
   &:hover {
      circle {
         fill: #f07d00;
      }
      ellipse {
         fill: #743208;
      }
   }
`;

export const LabelWrapper = styled.div`
   color: ${props => (props.isSelected ? '#f07d00' : '#868686')}
   font-size: 12px;
   line-height: 14px;
   font-weight: 600;
   padding-top: 8px;
`;

const PoorEmoji = ({ selected, showLabel }) => (
   <Wrapper>
      <SvgWrapper width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fill-rule='evenodd'>
            <circle
               id='Face'
               fill={selected ? '#f07d00' : '#E5E5E5'}
               fill-rule='nonzero'
               cx='15.998'
               cy='15.998'
               r='15.998'
            />
            <ellipse
               fill={selected ? '#743208' : '#595959'}
               cx='10.9'
               cy='16.1'
               rx='2.5'
               ry='3'
            />
            <ellipse
               fill={selected ? '#743208' : '#595959'}
               transform='matrix(-1 0 0 1 43 0)'
               cx='21.5'
               cy='16.1'
               rx='2.5'
               ry='3'
            />
            <path
               d='M16.1 22.4c3.396 0 6.338 2.17 7.771 5.334C21.765 25.658 19.011 24.4 16 24.4c-2.841 0-5.452 1.119-7.507 2.99 1.485-2.976 4.335-4.99 7.607-4.99zM7.448 9.905c1.237-.182 2.172-.244 3.863.545 1.691.788 2.375 1.754 2.9 2.609.527.854-.01 2.006-.556 1.194-.546-.811-1.232-1.772-2.923-2.56-1.691-.79-2.845-.74-3.84-.593-.997.146-.68-1.014.556-1.195zM24.965 9.905c-1.237-.182-2.172-.244-3.863.545-1.69.788-2.374 1.754-2.9 2.609-.527.854.01 2.006.557 1.194.546-.811 1.232-1.772 2.923-2.56 1.69-.79 2.844-.74 3.84-.593.996.146.68-1.014-.557-1.195z'
               fill={selected ? '#743208' : '#595959'}
            />
         </g>
      </SvgWrapper>
      {showLabel && <LabelWrapper isSelected={selected}>Poor</LabelWrapper>}
   </Wrapper>
);

export default PoorEmoji;
