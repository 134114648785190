import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from '@datashop/date-picker';
import { isEmpty, capitalize, intersection } from 'lodash';
import { getLetterIconColor } from 'helpers/badgeColor';
import OutsideClick from '@datashop/outside-click';
import { format, startOfDay } from 'date-fns';
import SelectProviderDropdown from 'components/Assessments/SelectProviderDropdown';
import TimezoneDropdown from 'helpers/timezoneDropdown';
import returnCamelCasedName from 'helpers/nameTransformer';
import formatPhoneNumber from 'helpers/formatPhone';
import TimeDropdown from 'helpers/timeDropdown';
import Radio from '@datashop/radio';
import Checkbox from '@datashop/checkbox';
import Textarea from '@datashop/textarea';
import Text from '@datashop/text';
import Avatar from '@datashop/avatar';
import Heading from '@datashop/heading';
import Card from '@datashop/card';
import Input from '@datashop/input';
import timezoneList from 'helpers/timeZones';
import { isMobile } from 'utils/checkMobile';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';
import { connect } from 'react-redux';
import { Mixpanel } from 'helpers/mixpanelHelper';

const Field = styled.div``;

const LabelWrapper = styled.div`
   display: flex;
   margin-bottom: 4px;
`;

const Label = styled(Text)`
   color: #2f2f2f;
   font-family: 'Nunito Sans';
   font-size: 14px;
   font-weight: 600;
   letter-spacing: 0;
   line-height: 16px;
`;

const TextWrapper = styled(Text)`
   ${({ color }) => color && `color: ${color};`}
   margin-top: 4px;
`;

const MandatoryDot = styled.p`
   width: 6px;
   height: 6px;
   margin-left: 4px;
   margin-top: 2px;
   background-color: rgb(225, 95, 95);
   border-radius: 50%;
`;

const Dot = styled.span`
   color: #2f2f2f;
   font-size: 20px;
   line-height: 14px;
   text-align: left;
   margin: 0 8px;
`;

const ContainerBody = styled.div`
   height: calc(100vh - 112px);
   max-height: calc(100vh - 112px);
   overflow-y: auto;
`;

const StyledCard = styled(Card)`
   background-color: ${({ theme }) => theme.datashop.palette.white.main};
   margin: 8px;
   padding: 16px;

   > div:not(:first-child) {
      margin-top: 12px;
   }
`;

const AdvanceOption = styled.div`
   border-radius: 4px;
   font-size: 16px;
   height: 32px;
   display: flex;
   align-items: center;
   padding-left: 12px;
   cursor: pointer;
   margin-top: 16px;

   > i {
      font-size: 16px;
   }
`;

const CheckboxWrapper = styled.div`
   margin-top: 16px;
`;

const TimeWrapper = styled.div`
   display: flex;
`;

const RadioWrapper = styled.div`
   display: flex;
   margin-top: 8px;

   > div {
      margin-right: 12px;
   }
`;

const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

const Available = styled.div`
   display: flex;
   align-items: flex-end;
   margin-right: 12px;
`;

const Column = styled.div`
   display: flex;
   flex-direction: column;
`;

const FormComponent = ({
   isRequestSchedule,
   formikProps,
   firstName,
   lastName,
   email,
   unFormattedNo,
   dob,
   gender,
   meta,
   scheduledOn,
   reasonForVisit,
   owner,
   userInfo: { practices = [], id },
}) => {
   const [datePickerVisible, setDatePickerVisible] = useState(false);
   const [dobDatePickerVisible, setDobDatePickerVisible] = useState(false);
   const [isAdvanceOptionsOpen, setIsAdvanceOptionsOpen] = useState(false);
   const [isPIEdit, setIsPIEdit] = useState(false);
   const [isProviderLoading, setIsProviderLoading] = useState(false);
   const [allPractices, setAllPractices] = useState({});
   const [providerList, setProviderList] = useState([]);
   const [practiceList, setPracticeList] = useState([]);

   const isProvider = localStorage.getItem('access') === 'PROVIDER';
   const isStaff = localStorage.getItem('access') === 'STAFF';

   useEffect(() => {
      if (practiceList.length === 1) {
         formikProps.setFieldValue('practiceId', practiceList[0].userId);
         Mixpanel.track(`Provider Facility Selected`, {
            category: 'facility',
            'Selected Facility': practiceList[0].userId,
         });
      } else {
         formikProps.setFieldValue('practiceId', '');
      }
   }, [practiceList]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (isProvider) {
         providerListCall();
      }
   }, [isProvider]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (isProvider) {
         selectPractices(id);
      }
   }, [allPractices, id, isProvider]); // eslint-disable-line react-hooks/exhaustive-deps

   const fields = [
      {
         label: 'First Name',
         children: ({
            values: { firstName },
            errors: { firstName: error },
            handleChange,
            isSubmitting,
         }) => (
            <Input
               name='firstName'
               error={firstName.length && error}
               icon='person'
               placeholder='First Name'
               value={firstName}
               onChange={handleChange}
               disabled={isSubmitting}
            />
         ),
         required: true,
      },
      {
         label: 'Last Name',
         children: ({
            values: { lastName },
            errors: { lastName: error },
            handleChange,
            isSubmitting,
         }) => (
            <Input
               name='lastName'
               icon='person'
               error={lastName.length && error}
               placeholder='Last Name'
               value={lastName}
               onChange={handleChange}
               disabled={isSubmitting}
            />
         ),
         required: true,
      },
      {
         label: 'Phone Number',
         children: ({
            values,
            errors: { phoneNumber },
            handleChange,
            handleBlur,
            isSubmitting,
         }) => (
            <Input
               name='phoneNumber'
               icon='phone'
               error={values.phoneNumber && phoneNumber}
               placeholder='Phone Number'
               onChange={event => {
                  typeof parseInt(event.target.value.slice(-1)) === 'number' &&
                     !isNaN(event.target.value.slice(-1)) &&
                     handleChange(event);
               }}
               onBlur={handleBlur}
               value={values.phoneNumber || ''}
               disabled={isSubmitting}
            />
         ),
         required: true,
      },
   ];

   const handleDatePickerFocusChange = focus => {
      setDatePickerVisible(focus);
   };

   const handleTimeChange = (value, onChange, stateTime) => {
      let newValue;
      const lastValue = value.slice(-1);
      let minuteString = value.split(':')[1];
      if (
         value.replace(':', '').length < 5 &&
         (!minuteString || minuteString.length < 3)
      ) {
         if (stateTime && value.length < stateTime.length) {
            if (
               stateTime.split(':')[0].length < 3 &&
               stateTime.slice(-1) !== ':'
            ) {
               onChange('time', stateTime.slice(0, -1));
            } else if (
               stateTime &&
               stateTime.slice(-1).includes(':') &&
               lastValue !== ':'
            ) {
               if (value.length === 2) {
                  newValue = value[0];
               } else {
                  newValue = '';
               }
               onChange('time', newValue);
            }
         } else if (
            typeof parseInt(lastValue) === 'number' &&
            !isNaN(parseInt(lastValue))
         ) {
            // Set Value
            const number = parseInt(lastValue);
            if (stateTime && stateTime.includes(':')) {
               if (value.split(':')[1].length === 1) {
                  if (number < 6) {
                     onChange('time', value);
                  }
               } else {
                  if (number < 10) {
                     onChange('time', value);
                  }
               }
            } else {
               if (stateTime.length === 1) {
                  if (number < 3 || (stateTime === '0' && number < 10)) {
                     onChange('time', `${value}:`);
                  }
               } else {
                  if (number > 1) {
                     onChange('time', `${value}:`);
                  } else {
                     onChange('time', value);
                  }
               }
            }
         }
      }
   };

   const handleAdvanceClick = () => {
      setIsAdvanceOptionsOpen(!isAdvanceOptionsOpen);
   };

   const getTimeZone = val => {
      const index = timezoneList.findIndex(({ abbr }) => abbr === val);
      const { label, value } = timezoneList[index];
      return index === -1 ? '' : `${label.split(' ')[0]} ${value}`;
   };

   const handleDobOutsideClick = () => {
      handleDobDatePickerFocusChange(false);
   };

   const handleDobDatePickerFocusChange = focus => {
      setDobDatePickerVisible(focus);
   };

   const getProviderName = providerId => {
      const providerList = JSON.parse(localStorage.getItem('userList')) || [];
      const provideObj = providerList.find(el => el.userId === providerId);

      return provideObj ? provideObj.label : '';
   };

   const handleOutsideClick = () => {
      handleDatePickerFocusChange(false);
   };

   const providerListCall = () => {
      if (
         isProvider ||
         (formikProps.values.phoneNumber &&
            formikProps.values.dob &&
            formikProps.values.firstName !== '' &&
            formikProps.values.lastName)
      ) {
         setIsProviderLoading(true);
         const url = isProvider
            ? '/innote-survey/user/organization/providers'
            : `/innote-survey/user/organization/providers?patientPhone=${
                 formikProps.values.phoneNumber
              }&patientDob=${format(
                 new Date(`${formikProps.values.dob}`),
                 'yyyyMMdd'
              )}&patientFirstName=${
                 formikProps.values.firstName
              }&patientLastName=${formikProps.values.lastName}${
                 formikProps.values.gender &&
                 `&patientGender=${formikProps.values.gender}`
              }`;
         axios
            .get(url)
            .then(({ data }) => {
               setAllPractices(data.practiceMap);
               if (isStaff) {
                  setProviderList(
                     data.userList.map(({ userId, name, ...rest }, index) => ({
                        value: index + 1,
                        userId,
                        label: returnCamelCasedName(
                           name.firstName,
                           name.lastName
                        ),
                        ...rest,
                     }))
                  );
               }
               setIsProviderLoading(false);
            })
            .catch(err => {
               setIsProviderLoading(false);
            });
      } else {
         createToast({ message: 'Please fill the patient information.' });
      }
   };

   const selectPractices = selected => {
      let getProvider = providerList.filter(el => el.userId === selected);
      getProvider = getProvider.length && getProvider[0];

      if (isProvider) {
         if (isEmpty(practices)) {
            setPracticeList(
               Object.keys(allPractices).map((el, index) => ({
                  value: index + 1,
                  userId: el,
                  label: allPractices[el],
               }))
            );
         } else {
            const list = practices.map(el => el._id);
            setPracticeList(
               Object.keys(allPractices)
                  .filter(el => list.indexOf(el) !== -1)
                  .map((el, index) => ({
                     value: index + 1,
                     userId: el,
                     label: allPractices[el],
                  }))
            );
         }
      } else {
         if (isEmpty(practices) && isEmpty(getProvider.practices)) {
            setPracticeList(
               Object.keys(allPractices).map((el, index) => ({
                  value: index + 1,
                  userId: el,
                  label: allPractices[el],
               }))
            );
         } else if (isEmpty(practices)) {
            setPracticeList(
               Object.keys(allPractices)
                  .filter(el => getProvider.practices.indexOf(el) !== -1)
                  .map((el, index) => ({
                     value: index + 1,
                     userId: el,
                     label: allPractices[el],
                  }))
            );
         } else if (isEmpty(getProvider.practices)) {
            const list = practices.map(el => el._id);
            setPracticeList(
               Object.keys(allPractices)
                  .filter(el => list.indexOf(el) !== -1)
                  .map((el, index) => ({
                     value: index + 1,
                     userId: el,
                     label: allPractices[el],
                  }))
            );
         } else {
            const list = intersection(
               practices.map(el => el._id),
               getProvider.practices
            );
            setPracticeList(
               Object.keys(allPractices)
                  .filter(el => list.indexOf(el) !== -1)
                  .map((el, index) => ({
                     value: index + 1,
                     userId: el,
                     label: allPractices[el],
                  }))
            );
         }
      }
   };

   return (
      <ContainerBody>
         {(!isRequestSchedule || isPIEdit) && (
            <StyledCard shadow='light'>
               <Heading as='h5' withMargin>
                  Patient information
               </Heading>
               {
                  <Field>
                     <LabelWrapper>
                        <Label>{fields[0].label}</Label>
                        {fields[0].required && <MandatoryDot />}
                     </LabelWrapper>
                     {fields[0].children(formikProps)}
                  </Field>
               }
               {
                  <Field>
                     <LabelWrapper>
                        <Label>{fields[1].label}</Label>
                        {fields[1].required && <MandatoryDot />}
                     </LabelWrapper>
                     {fields[1].children(formikProps)}
                  </Field>
               }
               <Field>
                  <LabelWrapper>
                     <Label>Date of Birth</Label>
                     <MandatoryDot />
                  </LabelWrapper>
                  <OutsideClick onOutsideClick={handleDobOutsideClick}>
                     <DatePicker
                        icon='cake'
                        disabled={formikProps.isSubmitting}
                        date={formikProps.values.dob}
                        dateFormat='MM/DD/YYYY'
                        disableDate={date => date > new Date()}
                        onDateChange={(selectedDate, valid) => {
                           const dateValid = valid && !isEmpty(selectedDate);
                           formikProps.setFieldValue('dob', selectedDate);
                           formikProps.setFieldError('dob', !dateValid);
                           handleDobDatePickerFocusChange(!dateValid);
                        }}
                        showDatePicker={
                           isMobile() ? false : dobDatePickerVisible
                        }
                        onFocusChange={focus => {
                           handleDobDatePickerFocusChange(focus);
                        }}
                        position='Bottom'
                        appendToBody={false}
                     />
                  </OutsideClick>
               </Field>
               <Field>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}>
                     <div style={{ width: 155 }}>
                        <LabelWrapper>
                           <Label>Gender</Label>
                           <MandatoryDot />
                        </LabelWrapper>
                     </div>
                     <RadioWrapper>
                        <Radio
                           key='Male'
                           value='Male'
                           id='Male'
                           label='Male'
                           name='gender'
                           checked={
                              'MALE' === formikProps.values.gender.toUpperCase()
                           }
                           onChange={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                           onClick={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                        />
                        <Radio
                           key='Female'
                           value='Female'
                           id='Female'
                           label='Female'
                           name='gender'
                           checked={
                              'FEMALE' ===
                              formikProps.values.gender.toUpperCase()
                           }
                           onChange={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                           onClick={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                        />
                        <Radio
                           key='Other'
                           value='Other'
                           id='Other'
                           label='Other'
                           name='gender'
                           checked={
                              'OTHER' ===
                              formikProps.values.gender.toUpperCase()
                           }
                           onChange={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                           onClick={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                        />
                     </RadioWrapper>
                  </div>
               </Field>
               {
                  <Field>
                     <LabelWrapper>
                        <Label>{fields[2].label}</Label>
                        {fields[2].required && <MandatoryDot />}
                     </LabelWrapper>
                     {fields[2].children(formikProps)}
                  </Field>
               }
               <Field>
                  <LabelWrapper>
                     <Label>Email</Label>
                  </LabelWrapper>
                  <Input
                     name='email'
                     icon='email'
                     placeholder='Email'
                     error={
                        formikProps.values.email && formikProps.errors.email
                     }
                     value={formikProps.values.email}
                     onChange={formikProps.handleChange}
                     disabled={formikProps.isSubmitting}
                  />
               </Field>
            </StyledCard>
         )}
         {isRequestSchedule && (
            <StyledCard shadow='light'>
               {isPIEdit && (
                  <Heading as='h5' withMargin>
                     Patient preferences
                  </Heading>
               )}
               {!isPIEdit && (
                  <>
                     <Row isCenter={false}>
                        <Available>
                           <Avatar
                              text={[firstName, lastName]
                                 .map((el, i) => (i <= 1 && el ? el[0] : ''))
                                 .join('')
                                 .trimRight()
                                 .toUpperCase()}
                              backgroundColor={getLetterIconColor(
                                 firstName,
                                 lastName
                              )}
                           />
                        </Available>
                        <Column>
                           <Heading as='h3'>
                              {returnCamelCasedName(firstName, lastName)}
                           </Heading>
                           {email && (
                              <TextWrapper color='#868686'>{email}</TextWrapper>
                           )}
                           {unFormattedNo && (
                              <TextWrapper color='#868686'>
                                 {formatPhoneNumber(unFormattedNo)}
                              </TextWrapper>
                           )}
                        </Column>
                     </Row>

                     <Row
                        isCenter={false}
                        style={{
                           justifyContent: 'space-between',
                           borderBottom: 'solid 1px #E5E5E5',
                           paddingBottom: '12px',
                        }}>
                        {dob && (
                           <Column>
                              <TextWrapper color='#868686'>
                                 Date of birth
                              </TextWrapper>
                              <TextWrapper color='#2F2F2F'>{dob}</TextWrapper>
                           </Column>
                        )}
                        {gender && (
                           <Column>
                              <TextWrapper color='#868686'>Gender</TextWrapper>
                              <TextWrapper color='#2F2F2F'>
                                 {capitalize(gender)}
                              </TextWrapper>
                           </Column>
                        )}
                        <Column>
                           <TextWrapper
                              color='#0070DD'
                              style={{
                                 cursor: 'pointer',
                                 marginTop: '24px',
                              }}
                              onClick={() => setIsPIEdit(true)}>
                              Edit
                           </TextWrapper>
                        </Column>
                     </Row>
                  </>
               )}
               {isPIEdit &&
                  !meta.providerPreference &&
                  !scheduledOn &&
                  !reasonForVisit && (
                     <Column>
                        <TextWrapper color='#868686'>
                           No preference available.
                        </TextWrapper>
                     </Column>
                  )}
               {meta.providerPreference && (
                  <Column>
                     <TextWrapper color='#868686'>
                        Preferred provider
                     </TextWrapper>
                     <TextWrapper color='#2F2F2F'>
                        {getProviderName(meta.providerPreference)}
                     </TextWrapper>
                  </Column>
               )}
               {scheduledOn && (
                  <Column>
                     <TextWrapper color='#868686'>
                        Preferred date and time
                     </TextWrapper>
                     <Row isCenter={false}>
                        <TextWrapper color='#2F2F2F'>
                           {format(scheduledOn, 'h:m aaa')}
                        </TextWrapper>
                        <Dot>.</Dot>
                        <TextWrapper color='#2F2F2F'>
                           {format(scheduledOn, 'MMM d, Y')}
                        </TextWrapper>
                     </Row>
                  </Column>
               )}
               {reasonForVisit && (
                  <Column>
                     <TextWrapper color='#868686'>
                        Reason for the visit
                     </TextWrapper>
                     <TextWrapper color='#2F2F2F'>{reasonForVisit}</TextWrapper>
                  </Column>
               )}
            </StyledCard>
         )}
         <StyledCard shadow='light'>
            <Heading as='h5' withMargin>
               Appointment details
            </Heading>
            {(isProvider || isStaff) && (
               <Field>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}>
                     <div style={{ width: 155 }}>
                        <LabelWrapper>
                           <Label>Type of call</Label>
                           <MandatoryDot />
                        </LabelWrapper>
                     </div>
                     <RadioWrapper>
                        <Radio
                           key='video'
                           value='VIDEO'
                           id='video'
                           label='Video call'
                           name='callType'
                           checked={'VIDEO' === formikProps.values.callType}
                           onChange={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                           onClick={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                        />
                        <Radio
                           key='voice'
                           value='AUDIO'
                           id='voice'
                           label='Voice call'
                           name='callType'
                           checked={'AUDIO' === formikProps.values.callType}
                           onChange={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                           onClick={event => {
                              event.persist();
                              formikProps.handleChange(event);
                           }}
                        />
                     </RadioWrapper>
                  </div>
               </Field>
            )}

            {!isProvider && (
               <Field>
                  <LabelWrapper>
                     <Label>Select Provider</Label>
                     <MandatoryDot />
                  </LabelWrapper>
                  <SelectProviderDropdown
                     isProviderLoading={isProviderLoading}
                     providerList={providerList}
                     category='virtualVisit'
                     callList={providerListCall}
                     source='Virtual Visits'
                     owner={owner}
                     disabled={formikProps.isSubmitting}
                     onChange={selected => {
                        selectPractices(selected);
                        formikProps.setFieldValue('provider', selected);
                        formikProps.setFieldError('provider', !selected);
                        Mixpanel.track(`Provider selected for visit`, {
                           category: 'virtualVisit',
                           Source: 'Virtual Visits',
                           'Selected Provider': selected,
                        });
                     }}
                  />
               </Field>
            )}
            <Field>
               <LabelWrapper>
                  <Label>Select Practice</Label>
                  <MandatoryDot />
               </LabelWrapper>
               <SelectProviderDropdown
                  isPractice={true}
                  providerList={practiceList}
                  source='Virtual Visits'
                  owner={owner}
                  selected={formikProps.values.practiceId}
                  disabled={
                     isStaff
                        ? formikProps.isSubmitting ||
                          !formikProps.values.provider
                        : formikProps.isSubmitting
                  }
                  onChange={selected => {
                     formikProps.setFieldValue('practiceId', selected);
                     formikProps.setFieldError('practiceId', !selected);
                     Mixpanel.track(`Provider Facility Selected`, {
                        category: 'facility',
                        'Selected Facility': selected,
                     });
                  }}
               />
            </Field>
            <Field>
               <div
                  style={{
                     display: 'flex',
                  }}>
                  <div style={{ width: 155 }}>
                     <LabelWrapper>
                        <Label>Appointment Date</Label>
                        <MandatoryDot />
                     </LabelWrapper>
                     <OutsideClick onOutsideClick={handleOutsideClick}>
                        <DatePicker
                           disabled={formikProps.isSubmitting}
                           disableDate={date => date < startOfDay(new Date())}
                           date={formikProps.values.appointmentDate}
                           dateFormat='MM/DD/YYYY'
                           onDateChange={(selectedDate, valid) => {
                              const dateValid = valid && !isEmpty(selectedDate);
                              formikProps.setFieldValue(
                                 'appointmentDate',
                                 selectedDate
                              );
                              formikProps.setFieldError(
                                 'appointmentDate',
                                 !dateValid
                              );
                              handleDatePickerFocusChange(!dateValid);
                           }}
                           showDatePicker={
                              isMobile() ? false : datePickerVisible
                           }
                           onFocusChange={focus => {
                              handleDatePickerFocusChange(focus);
                           }}
                           position='Bottom'
                           appendToBody={false}
                        />
                     </OutsideClick>
                  </div>
               </div>
            </Field>
            <Field>
               <div
                  style={{
                     display: 'flex',
                  }}>
                  <div>
                     <LabelWrapper>
                        <Label>Time</Label>
                        <MandatoryDot />
                     </LabelWrapper>
                     <TimeWrapper>
                        <Input
                           style={{
                              width: 80,
                              marginRight: 4,
                              paddingLeft: 28,
                           }}
                           error={
                              formikProps.values.time && formikProps.errors.time
                           }
                           name='time'
                           icon='schedule'
                           placeholder='12:00'
                           onChange={({ target: { value } }) => {
                              handleTimeChange(
                                 value,
                                 formikProps.setFieldValue,
                                 formikProps.values.time
                              );
                           }}
                           onBlur={formikProps.handleBlur}
                           value={formikProps.values.time || ''}
                           disabled={formikProps.isSubmitting}
                        />
                        <TimeDropdown
                           disabled={formikProps.isSubmitting}
                           onChange={selected =>
                              formikProps.setFieldValue('timesuffix', selected)
                           }
                        />
                     </TimeWrapper>
                     {formikProps.values.timezone && (
                        <TextWrapper color='#868686' fontSize='small'>
                           {getTimeZone(formikProps.values.timezone)}
                        </TextWrapper>
                     )}
                  </div>
               </div>
            </Field>
            {(!isRequestSchedule || isPIEdit) && (
               <Field>
                  <LabelWrapper>
                     <Label>Reason for the visit</Label>
                  </LabelWrapper>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'end',
                        paddingTop: '4px',
                     }}>
                     <Textarea
                        disabled={formikProps.isSubmitting}
                        placeholder='Use this space to inform the provider about the reason behind scheduling this visit.'
                        rows='5'
                        maxLength='140'
                        name='reasonForVisit'
                        value={formikProps.values.reasonForVisit}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        style={{
                           color: '#2f2f2f',
                        }}
                     />
                     <span
                        style={{
                           marginTop: '-21px',
                           marginRight: '10px',
                           zIndex: '1',
                           color: '#868686',
                        }}>
                        {`${formikProps.values.reasonForVisit.length}`}
                        /140
                     </span>
                  </div>
               </Field>
            )}
            {isProvider || isStaff ? (
               <Field>
                  {isAdvanceOptionsOpen && (
                     <>
                        <Field>
                           <LabelWrapper>
                              <Label>Time zone</Label>
                              <MandatoryDot />
                           </LabelWrapper>

                           <TimezoneDropdown
                              disabled={formikProps.isSubmitting}
                              onChange={selected =>
                                 formikProps.setFieldValue('timezone', selected)
                              }
                           />
                        </Field>
                        <Field>
                           <CheckboxWrapper>
                              <Checkbox
                                 name='isTestCall'
                                 checked={formikProps.values.isTestCall}
                                 onChange={event => {
                                    event.persist();
                                    formikProps.handleChange(event);
                                 }}
                                 onClick={event => {
                                    event.persist();
                                    formikProps.setFieldValue(
                                       'isTestCall',
                                       !formikProps.values.isTestCall
                                    );
                                 }}
                                 id='isTestCall'
                                 label='Mark this as test visit'
                              />
                           </CheckboxWrapper>
                        </Field>
                     </>
                  )}
                  <AdvanceOption onClick={handleAdvanceClick}>
                     <i className='material-icons'>
                        {isAdvanceOptionsOpen
                           ? 'keyboard_arrow_up'
                           : 'keyboard_arrow_down'}
                     </i>
                     <div
                        style={{
                           marginLeft: 8,
                        }}>
                        Advanced options
                     </div>
                  </AdvanceOption>
               </Field>
            ) : (
               <Field>
                  <LabelWrapper>
                     <Label>Time zone</Label>
                     <MandatoryDot />
                  </LabelWrapper>

                  <TimezoneDropdown
                     disabled={formikProps.isSubmitting}
                     onChange={selected =>
                        formikProps.setFieldValue('timezone', selected)
                     }
                  />
               </Field>
            )}
         </StyledCard>
      </ContainerBody>
   );
};

const mapStateToProps = ({ frontDoorSocket: { userInfo } }) => ({
   userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
