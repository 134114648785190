import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '@datashop/button';

import { getLetterIconColor } from 'helpers/badgeColor';

const BodyWrapper = styled.div`
   padding: 68px 12px 16px;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const InviterName = styled.p`
   color: #151414;
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
`;

const Text = styled.p`
   color: #2f2f2f;
   line-height: 20px;
`;

const PatientName = styled.p`
   margin-top: 8px;
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
`;

const PatientDetails = styled.p`
   color: #2f2f2f;
   line-height: 20px;
`;

const DetailsWrapper = styled.div`
   margin-top: 24px;
   padding: 12px;
   border: 1px solid #ececec;
   border-radius: 8px;
   background-color: #ececec;
   width: 100%;
`;

const DetailHeader = styled.p`
   color: #151414;
   font-size: 12px;
   font-weight: 800;
   line-height: 24px;
`;

const ButtonWrapper = styled.div`
   display: flex;
   flex: 1;
   margin-top: 48px;
   width: 100%;
`;

const MemberLogo = styled.div`
   border-radius: 50%;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 48px;
   width: 48px;
   font-size: 24px;
   color: white;
   margin-bottom: 4px;
   background-color: ${({ color }) => color};
`;

const JoinCallTextWrapper = styled.div`
   display: flex;
   align-items: center;
   color: #fff;
`;

const JoinCallIcon = styled.i`
   font-size: 20px;
   color: #fff;
   margin-right: 4px;
`;

const JoinCallModal = ({
   data: { patientDetails, providerDetails, scheduleId, visitId },
   close,
   joinCall,
}) => (
   <Fragment>
      <BodyWrapper>
         <MemberLogo
            color={getLetterIconColor(
               providerDetails.firstName,
               providerDetails.lastName
            )}>
            {`${providerDetails.firstName[0].toUpperCase()}${providerDetails.lastName[0].toUpperCase()}`}
         </MemberLogo>
         <InviterName>{`${providerDetails.firstName} ${providerDetails.lastName}`}</InviterName>
         <Text>is inviting you to a virtual visit</Text>
         <DetailsWrapper>
            <DetailHeader>PATIENT DETAILS</DetailHeader>
            <PatientName>{`${patientDetails.firstName} ${patientDetails.lastName}`}</PatientName>
            <PatientDetails>{`${patientDetails.dob}, ${patientDetails.phoneNo}`}</PatientDetails>
         </DetailsWrapper>
         <ButtonWrapper>
            <Button
               style={{ marginRight: '12px' }}
               appearance='secondary'
               dimension='large'
               expanded
               onClick={close}>
               Ignore
            </Button>
            <Button
               appearance='primary'
               dimension='large'
               expanded
               onClick={() => {
                  joinCall(scheduleId, '', visitId, 'VISITOR');
                  close();
               }}>
               <JoinCallTextWrapper>
                  <JoinCallIcon className='material-icons'>
                     videocam
                  </JoinCallIcon>
                  Join Call
               </JoinCallTextWrapper>
            </Button>
         </ButtonWrapper>
      </BodyWrapper>
   </Fragment>
);

export default JoinCallModal;
