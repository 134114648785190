import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledCard from '@datashop/card';
import Badge from '@datashop/badge';

import JoinCall from './JoinCall';
import ParticipantStatus from './ParticipantStatus';
import {
   BADGE_APPEARANCES,
   BADGE_ICONS,
   CALL_STATUS_LABELS,
   USER_TYPE,
   CALL_MODES,
   CALL_STATUS,
} from '../../../../constants';
import CallStatus from './CallStatus';
import ReasonForVisit from './ReasonForVisit';
import { Mixpanel } from 'helpers/mixpanelHelper';

const Card = styled(StyledCard)`
   padding: 12px 12px 0;
   background-color: #fff;
   margin-bottom: 8px;
`;

const PatientNameWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const PatientName = styled.p`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
   text-transform: capitalize;
`;

const DetailsWrapper = styled.div`
   padding-bottom: 8px;
   display: flex;
   flex: 1;
   margin-top: 4px;
   align-items: center;
   justify-content: space-between;
`;

const DetailText = styled.div`
   display: flex;
   color: #868686;
   font-weight: 600;
   line-height: 20px;
`;

const StatusChangeWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 8px 0;
   border-top: 1px solid #e5e5e5;
`;

const ModeBadge = styled(Badge)`
   display: inline-flex;
   font-weight: bold;
   padding: 0 6px;
`;

const Separator = styled.span`
   margin: 0 8px;
`;

const formatTime = (time = '') => time.split(' ').slice(2).join(' ');

const WaitingItem = ({
   userType,
   item,
   joinRoom,
   fetchData,
   badgeDetails,
   onStatusChange,
}) => {
   const handleJoinCall = () => {
      joinRoom(item).then(() => {
         if (
            userType === USER_TYPE.staff &&
            item.callStatus === CALL_STATUS.onPostVisitQueue
         ) {
            Mixpanel.track('Joined for post-visit', {
               category: 'virtualVisit',
            });
         }

         fetchData();
      });
   };

   const renderDetails = () => {
      const { mode, scheduleDate, patientInfo } = item;
      return (
         <DetailsWrapper>
            <DetailText>
               {mode ? (
                  <ModeBadge>{CALL_MODES[mode]}</ModeBadge>
               ) : (
                  formatTime(scheduleDate)
               )}
               <Separator>•</Separator>
               {patientInfo.formattedPhoneNo}
            </DetailText>
         </DetailsWrapper>
      );
   };

   return (
      <Card key={item.scheduleId} shadow='light'>
         <PatientNameWrapper>
            <PatientName>
               {`${item.patientInfo.firstName} ${item.patientInfo.lastName}`}
            </PatientName>
            <CallStatus
               userType={userType}
               item={item}
               badgeDetails={badgeDetails}
               onStatusChange={onStatusChange}
            />
         </PatientNameWrapper>
         {renderDetails()}
         {item.reasonForVisit && (
            <ReasonForVisit reason={item.reasonForVisit} />
         )}
         <StatusChangeWrapper>
            <ParticipantStatus
               userType={userType}
               status={item.callStatus}
               infoText={item.bottomStatus}
               ownerInfo={item.ownerInfo}
            />
            <JoinCall
               disabled={!item.canJoin}
               type={item.type}
               onClick={handleJoinCall}
            />
         </StatusChangeWrapper>
      </Card>
   );
};

WaitingItem.propTypes = {
   userType: PropTypes.oneOf([...Object.values(USER_TYPE)]).isRequired,
   item: PropTypes.shape({}),
   joinRoom: PropTypes.func.isRequired,
   fetchData: PropTypes.func.isRequired,
   badgeDetails: PropTypes.shape({
      appearance: PropTypes.oneOf(Object.values(BADGE_APPEARANCES)),
      icon: PropTypes.oneOf(Object.values(BADGE_ICONS)),
      text: PropTypes.oneOf(Object.values(CALL_STATUS_LABELS)),
   }),
   onStatusChange: PropTypes.func,
};

export default WaitingItem;
