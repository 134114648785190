import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
   padding: 8px 16px 0 16px;
   background-color: #fff;
   height: 56px;
`;

const TabList = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   height: 100%;
`;

const TabWrapper = styled.div`
   height: 100%;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   ${({ active }) => active && 'border-bottom: 2px solid #0070dd'};
`;

const TabItem = styled.div`
   display: flex;
`;

const TabText = styled.p`
   color: #2d2b2b;
   font-weight: 600;
   line-height: 20px;
`;

const CountWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;
const Count = styled.div`
   background-color: #e5e5e5;
   border-radius: 50%;
   width: 24px;
   height: 24px;
   margin-right: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Tab = props => {
   return (
      <Wrapper>
         <TabList>
            {props.list.map(item => (
               <TabWrapper
                  data-test={`${item.name
                     .replace(' ', '-')
                     .toLowerCase()}-tab-button`}
                  active={props.activeTabId === item.id}
                  onClick={() => props.setActiveTab(item.id)}
                  key={item.id}>
                  <TabItem>
                     {item.count ? (
                        <CountWrapper>
                           <Count>{item.count}</Count>
                           <TabText>{item.name}</TabText>
                        </CountWrapper>
                     ) : (
                        <TabText>{item.name}</TabText>
                     )}
                  </TabItem>
               </TabWrapper>
            ))}
         </TabList>
      </Wrapper>
   );
};

export default Tab;
