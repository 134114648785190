export const mixpanelConfigProd = {
   token: '112b00768252de2f07420f4fabc3ce5d',
   isActive: true,
};

export const mixpanelConfigPreProd = {
   token: 'c8710ea46075ce98673f55ff339cfde2',
   isActive: true,
};

export const mixpanelConfigStaging = {
   token: '',
   isActive: false,
};
