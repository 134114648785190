const returnCamelCasedName = (...str) => {
   if (str[0] && str[1])
      return str
         .map(item => `${item[0].toUpperCase()}${item.slice(1).toLowerCase()}`)
         .join(' ');

   if (str[0]) {
      const firstName = str[0];
      return `${firstName[0].toUpperCase()}${firstName.slice(1).toLowerCase()}`;
   }
   return '';
};

export default returnCamelCasedName;
