import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import CardWrapper from './Card';

const Wrapper = styled.div``;

const ObserveCard = ({
   chats,
   assignToMe,
   isMyPatientTab = false,
   openChat,
   isLoadingMore,
   endReached,
   fetchData,
}) => {
   const targetElement = useRef(null);

   useEffect(() => {
      if (!isLoadingMore && !endReached) {
         const callBack = entries => {
            entries.forEach(item => {
               item.isIntersecting && fetchData(true);
            });
         };

         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadingMore, endReached]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <Wrapper innerRef={targetElement}>
         {isMyPatientTab
            ? chats.map((item, index) => (
                 <CardWrapper
                    item={item}
                    key={index}
                    isCenter={true}
                    isAvatar={true}
                    isMenu={false}
                    isTop={true}
                    isCreated={true}
                    openChat={openChat}
                 />
              ))
            : chats.map((item, index) => (
                 <CardWrapper
                    item={item}
                    key={index}
                    isCenter={true}
                    isAvatar={true}
                    isBottom={true}
                    assignToMe={assignToMe}
                 />
              ))}
      </Wrapper>
   );
};

export default ObserveCard;
