/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty, get, isEqual } from 'lodash-es';
import { getUserInfo, resetHasNewMessages } from 'store/frontDoorSocket/action';

// Wrapper
const Wrapper = styled.header`
   background-color: #fff;
   border-bottom: 1px solid #ededed;
`;

// Navigation
const Nav = styled.nav`
   display: flex;
`;

// Icon
const Icon = styled.i`
   margin-bottom: 4px;
   font-size: 24px;
   height: 26px;
`;

// Navigation item
const NavItem = styled(NavLink)`
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   flex: auto;
   cursor: pointer;
   color: #a6a6a6;
   padding: 8px 0;

   :nth-child(1) {
      ${Icon} {
         margin-bottom: 2px;
         font-size: 26px;
      }
   }

   &:hover {
      color: #0070dd;
   }
`;

// Header icon text
const HeaderIconText = styled.p`
   font-size: 12px;
`;

const AvailableDot = styled.div`
   background-color: #2ea843;
   border: 2px solid #ffffff;
   border-radius: 100%;
   width: 12px;
   height: 12px;
   margin-left: -12px;
`;

const Available = styled.div`
   display: flex;
   align-items: baseline;
`;

const Oval = styled.div`
   background-color: #0070dd;
   ${({ count }) => count > 3 && `border: 2.67px solid #dcecf9;`}
   border-radius: 100%;
   width: ${({ count }) => (count > 3 ? '16px' : '12px')};
   height: ${({ count }) => (count > 3 ? '16px' : '12px')};
   margin-left: -12px;
`;

/**
 * Header component
 * @class Header
 * @extends {Component}
 */

const Header = ({
   userInfo,
   getUserInfo,
   isAvailableForAssistanceEnable,
   navItemList,
   isUserInfoLoading,
   configLoading,
   notificationDetails,
   hasNewMessages,
   resetHasNewMessages,
}) => {
   const [navListItems, setNavListItems] = useState(navItemList);
   const [isInfoCalled, setIsInfoCalled] = useState(false);
   const [count, setCount] = useState(0);
   const [notificationDetailsOld, setNotificationDetailsOld] = useState({});
   let location = useLocation();

   useEffect(() => {
      if (
         !isEqual(notificationDetailsOld, notificationDetails) &&
         !(
            location.pathname === '/messages' ||
            location.pathname === '/frontdoor'
         )
      ) {
         setCount(count => count + 1);
         setNotificationDetailsOld(notificationDetails);
      }
   }, [notificationDetails, location.pathname, notificationDetailsOld]);

   useEffect(() => {
      if (
         !(
            location.pathname === '/messages' ||
            location.pathname === '/frontdoor'
         ) &&
         hasNewMessages
      ) {
         setCount(count => count + 1);
      }
   }, [hasNewMessages, location.pathname]);

   useEffect(() => {
      if (isEmpty(userInfo) && !isInfoCalled) {
         setIsInfoCalled(true);
         getUserInfo();
      }
   }, [userInfo, isInfoCalled, getUserInfo]);

   useEffect(() => {
      if (
         location.pathname === '/messages' ||
         location.pathname === '/frontdoor'
      ) {
         setCount(0);
         resetHasNewMessages();
      }
   }, [location.pathname, resetHasNewMessages]);

   const getIcon = item => {
      const type = get(userInfo, 'accessRole', '');
      const isAvailable =
         type === 'STAFF'
            ? isAvailableForAssistanceEnable
            : get(userInfo, 'isAvailableWalkIn', false);
      if (item.name === 'Profile' && isAvailable) {
         return (
            <Available>
               <Icon className='material-icons'>{item.icon}</Icon>
               <AvailableDot />
            </Available>
         );
      } else if (
         (item.name === 'Front door' || item.name === 'Messages') &&
         count !== 0
      ) {
         return (
            <Available>
               <Icon className='material-icons'>{item.icon}</Icon>
               <Oval count={count} />
            </Available>
         );
      } else {
         return <Icon className='material-icons'>{item.icon}</Icon>;
      }
   };

   useEffect(() => {
      if (!isEmpty(userInfo) && !isEmpty(navItemList)) {
         const type = get(userInfo, 'accessRole', '');
         const navList = navItemList.map(item => {
            if (item.name === 'Front door') {
               return {
                  ...item,
                  visible:
                     item.visible &&
                     type === 'STAFF' &&
                     !isUserInfoLoading &&
                     !configLoading,
               };
            } else if (item.name === 'Messages') {
               return {
                  ...item,
                  visible:
                     item.visible &&
                     type === 'PROVIDER' &&
                     !isUserInfoLoading &&
                     !configLoading,
               };
            } else {
               return { ...item };
            }
         });
         setNavListItems(navList);
      }
   }, [navItemList, userInfo, isUserInfoLoading, configLoading]);

   return (
      <Wrapper>
         <Nav>
            {navListItems
               .filter(item => item.visible)
               .map((item, index) => {
                  return (
                     <NavItem
                        data-test={`${item.name
                           .replace(' ', '-')
                           .toLowerCase()}-nav-button`}
                        key={index}
                        exact={item.exact}
                        to={item.link}
                        activeClassName='active'>
                        {getIcon(item)}
                        <HeaderIconText>{item.name}</HeaderIconText>
                     </NavItem>
                  );
               })}
         </Nav>
      </Wrapper>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      isAvailableForAssistanceEnable,
      userInfo,
      navItemList,
      isUserInfoLoading,
      configLoading,
      notificationDetails,
      hasNewMessages,
   },
}) => {
   return {
      isAvailableForAssistanceEnable,
      navItemList,
      userInfo,
      isUserInfoLoading,
      configLoading,
      notificationDetails,
      hasNewMessages,
   };
};

const mapDispatchToProps = {
   getUserInfo,
   resetHasNewMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
