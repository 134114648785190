const returnCallDuration = time => {
   if (time < 60) {
      return `${time} sec${time > 1 ? 's' : ''}`;
   } else if (time < 3600) {
      return `${Math.floor(time / 60)} min${
         Math.floor(time / 60) > 1 ? 's' : ''
      }`;
   } else {
      return `${Math.floor(time / 3600)} hr ${Math.floor(
         (time % 3600) / 60
      )} min${Math.floor((time % 3600) / 60) > 1 ? 's' : ''}`;
   }
};

export default returnCallDuration;
