import mixpanel from 'mixpanel-browser';
// import axios from 'helpers/axios';
// import mixpanelUserDetail from 'helpers/mixpanelUserDetail';
import { isElectron } from 'utils/checkMobile';

import {
   mixpanelConfigPreProd,
   mixpanelConfigStaging,
   mixpanelConfigProd,
} from '../services/mixpanelService';
import { getCategory } from './analyticsCategory';
import { getItem } from 'helpers/localStore';

const envType = process.env.REACT_APP_BUILD;

const mixpanelConfiguration =
   envType === 'PROD'
      ? mixpanelConfigProd
      : envType === 'PREPROD'
      ? mixpanelConfigPreProd
      : mixpanelConfigStaging;

const { NODE_ENV } = process.env;
// let distinct_id = '';
// let userDetails;

// const postMixPanelEvent = ({ method, eventName, properties }) => {
//    const eventDetail = {
//       method,
//       params: {
//          distinct_id,
//          event_name: eventName,
//          properties: { ...properties, ...userDetails },
//       },
//    };
//    axios.post('/innote-survey/user/track', eventDetail);
// };

const eventFrom = isElectron() ? 'electron' : 'web';

const getProps = () => {
   const practicesItem = getItem('practices');
   const practices = practicesItem
      ? practicesItem.map(practice => practice.name)
      : null;

   return {
      'App Name': 'InNote Assistant',
      'Device Type': eventFrom,
      Organization: getItem('organization'),
      Practices: practices,
   };
};

const actions = {
   mixpanelInitiated: false,
   track: (name, props) => {
      if (NODE_ENV && mixpanelConfiguration.isActive) {
         const { category, ...rest } = props;
         // const categoryName = getCategory(category);
         mixpanel.track(name, {
            Category: getCategory(category),
            ...getProps(),
            ...rest,
         });
         // postMixPanelEvent({
         //    method: 'track',
         //    eventName: name,
         //    properties: { category: categoryName, ...getProps(), ...rest },
         // });
      }
   },
   register: defaultProps => {
      if (NODE_ENV && mixpanelConfiguration.isActive)
         mixpanel.register(defaultProps);
   },
   unregister: defaultProps => {
      if (NODE_ENV && mixpanelConfiguration.isActive)
         mixpanel.unregister(defaultProps);
   },
   identify: id => {
      if (NODE_ENV && mixpanelConfiguration.isActive) {
         mixpanel.identify(id);
      }
   },
   alias: id => {
      if (NODE_ENV && mixpanelConfiguration.isActive) mixpanel.alias(id);
   },
   people: {
      set: props => {
         if (NODE_ENV && mixpanelConfiguration.isActive) {
            // postMixPanelEvent({ method: 'people_set', meta: props });
            mixpanel.people.set({
               ...getProps(),
               ...props,
            });
         }
      },
   },
   init: () => {
      return new Promise(resolve => {
         const init = mixpanel.init(mixpanelConfiguration.token);
         // const init = mixpanel.init(mixpanelConfiguration.token, {
         //    loaded: mixpanel => {
         //       distinct_id = mixpanel.get_distinct_id();
         //    },
         //    persistence: `localStorage`,
         //    persistence_name: 'mixpanel_data',
         // });
         // userDetails = mixpanelUserDetail();
         resolve(init);
      });
   },
};

export { actions as Mixpanel };
