import styled from 'styled-components';
import Card from '@datashop/card';
import Avatar from '@datashop/avatar';
import Icon from '@datashop/icon';
import Button from '@datashop/button';
import Text from '@datashop/text';

export const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 12px 12px 16px 12px;
   max-height: ${window.innerHeight - 56}px;
   overflow: auto;
`;

export const CardWrapper = styled(Card)`
   width: 100%;
   background-color: #ffffff;
   padding: 12px;
   margin-bottom: 12px;
`;

export const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: ${({ isCenter = false }) =>
      isCenter ? 'center' : 'flex-start'};
`;

export const SpaceBetween = styled(Row)`
   flex: 1;
   justify-content: space-between;
`;

export const AvatarWrapper = styled(Avatar)`
   width: 40px;
   height: 40px;
`;

export const Column = styled.div`
   display: flex;
   flex-direction: column;
`;

export const MarginAuto = styled.div`
   display: flex;
   margin-left: -12px;
   height: ${window.innerHeight - 56}px;
`;

export const Available = styled.div`
   display: flex;
   align-items: flex-end;
   margin-right: 12px;
`;

export const TextWrapper = styled(Text)`
   color: #2f2f2f;
   margin-top: 4px;
`;

export const TextWrapperStaff = styled(Text)`
   color: #868686;
   margin-top: 4px;
`;

export const IconWrapper = styled(Icon)`
   color: #868686;
   font-size: 16px;
   margin-right: 8px;
`;

export const SwitchWrapper = styled.div`
   display: flex;
   flex: auto;
   justify-content: flex-end;
   ${({ isBottom = false }) => isBottom && 'align-self: flex-end;'}
   ${({ isTop }) => isTop && 'height: 45px;'}
`;

export const IconWrapperLogout = styled(Icon)`
   cursor: pointer;
   font-size: 16px;
   color: #777a7f;
   visibility: hidden;
   margin-left: 4px;
`;

export const TextWrapperLogout = styled.span`
   display: flex;
   align-items: center;
   background-color: #fff;
   cursor: pointer;
   padding: 4px 0;

   :hover {
      ${IconWrapper} {
         visibility: visible;
      }
   }
`;

export const HeaderIcon = styled.i`
   font-size: 16px;
   cursor: pointer;
   color: ${({ color }) => (color ? color : '#d93737')};
`;

export const LogoutTextWrapper = styled.div`
   display: flex;
   align-items: center;
   margin-left: 16px;
`;

export const LogoutText = styled.p`
   color: #d93737;
   font-weight: 600;
   line-height: 20px;
`;

export const DialogWrapper = styled.div`
   width: 320px;
   border-radius: 4px;
   padding: 16px;
`;

export const DialogHeader = styled.p`
   font-size: 20px;
   font-weight: 600;
   line-height: 32px;
   color: #2d2d2d;
`;

export const DialogButtonWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-top: 16px;
   ${Button} {
      font-weight: 400;
      :last-child {
         margin-left: 8px;
      }
   }
`;

export const VersionContainer = styled.div`
   padding: 16px;
`;

export const LogoWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 6px;
`;

export const Logo = styled.img`
   align-self: center;
   width: 60px;
`;

export const CopyRightText = styled.p`
   color: #868686;
   font-size: 12px;
`;

export const Version = styled.div`
   padding: 2px 4px;
   background-color: #e5e5e5;
   border-radius: 3px;
`;

export const VersionText = styled.p`
   font-weight: 600;
   color: #2f2f2f;
   font-size: 12px;
   line-height: 16px;
   font-weight: bold;
`;

export const RadioWrapper = styled.div`
   margin-top: 10px;
   padding-bottom: 8px;
   border-bottom: 1px solid #e5e5e5;
   :nth-child(1) {
      margin-top: 0;
   }
   :last-child {
      padding-bottom: 0;
      border-bottom: none;
   }
   div {
      margin-bottom: 4px;
   }
`;

export const MarginRadio = styled.div`
   ${({ isAvailableForAssistanceEnable }) =>
      isAvailableForAssistanceEnable && 'margin-top: 14px;'}
`;

export const Status = styled.div`
   background-color: ${({ color }) => color};
   border-radius: 100%;
   width: 8px;
   height: 8px;
   margin-right: 8px;
`;

export const TextPartition = styled(Text)`
   color: #868686;
   min-width: 108px;
`;

export const SupportText = styled(LogoutText)`
   color: inherit;
`;

export const CopyWriteWrapper = styled(CardWrapper)`
   margin-top: 24px;
`;
