import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import axios from 'helpers/axios';
import { connect } from 'twilio-video';
import { Mixpanel } from 'helpers/mixpanelHelper';
import { Modal, motion } from '@krishnaxv/react-surface';
import PermissionModal from 'components/TeleVisit/PermissionModal';
import bandwidthProfile from './bandwidthProfile';
import ActionButton from './ActionButton';
import Timer from './Timer';
import createToast from 'helpers/toastHelper';
import EndCallModalContainer from './EndCallModalContainer';
import { Loader } from 'packages/loader';
import PoorNetwork from './PoorNetwork';
import { checkPermission, getMediaDevices } from 'helpers/checkPermission';
import SingleParticipantIndicator from './SingleParticipantIndicator';
import Message from './Message';
import { orientationHandler } from 'utils/checkMobile';
import { getItem } from 'helpers/localStore';
import { get, isEmpty } from 'lodash-es';
import ViewVideo from './ViewVideo';
import ElectronSocket from 'helpers/electronSocket';

const modalStyle = {
   height: '100vh',
   width: '100vw',
   display: 'flex',
};

const ErrorPage = styled.div`
   font-size: 18px;
   font-weight: bold;
   position: absolute;
   z-index: 10;
   background: #fff;
   height: 100vh;
   width: 100vw;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Wrapper = styled.div`
   width: ${({ innerWidth }) => innerWidth}px;
   height: ${({ innerHeight }) => innerHeight}px;
   left: 0;
   position: fixed;
`;

const ParticipantWrapper = styled.div`
   width: ${({ innerWidth }) => innerWidth}px;
   background: linear-gradient(
      180deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.87) 25.87%,
      rgba(0, 0, 0, 0.87) 74.88%,
      rgb(0, 0, 0) 100%
   );
   left: 0;
   height: ${({ innerHeight }) => innerHeight}px;
   position: fixed;
   z-index: 0;
`;

const CallEndedText = styled.div`
   margin-top: 2px;
   color: #ffffff;
   font-size: 20px;
   -webkit-letter-spacing: 0;
   -moz-letter-spacing: 0;
   -ms-letter-spacing: 0;
   height: 40px;
   z-index: 1;
   letter-spacing: 0;
   line-height: 22px;
   text-align: center;
   margin: auto;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   position: fixed;
`;

const ParticipantLayout = styled.div`
   width: ${({ innerWidth }) => innerWidth}px;
   height: ${({ innerHeight }) => innerHeight - 96}px;
   display: flex;
   flex-flow: column;
   justify-content: ${({ isFocusedView }) =>
      isFocusedView ? 'flex-start;' : 'center'};
   align-items: center;
   align-content: center;
   z-index: 1;
   top: 0;
   position: absolute;
   ${({ isTopOff }) => !isTopOff && 'padding-top: 16px;'}
   padding-left: 16px;
   padding-right: 16px;
   padding-bottom: 8px;

   :last-child {
      padding-bottom: 16px;
   }
`;

const trackpubsToTracks = trackMap =>
   Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

class TeleVisit extends Component {
   videoTrack = this.props.videoTrack;
   audioTrack = this.props.audioTrack;
   room;
   child = undefined;
   state = {
      screenMode: '',
      isVideoChanged: false,
      facingMode: 'user',
      isParticipantConnected: false,
      isAudioEnabled: true,
      isVideoEnabled: true,
      userHasDisconnectedOnce: false,
      title: '',
      isProvider: false,
      recipient: '',
      isLoading: true,
      callEnded: false,
      link: '',
      count: 0,
      newTab: false,
      canChangeStatus: true,
      poorNetworkArray: [],
      showPermissionModal: false,
      roomDisconnected: false,
      showEndCallModal: false,
      endCallViaStaff: false,
      flowType: this.props.type,
      endCallModalType: '',
      isSelfNetworkPoor: false,
      participantVideoArray: [],
      isParticipantNetworkPoor: false,
      endCallMsg: '',
      width: 0,
      height: 0,
      totalParticipants: [],
      onCallParticipants: [],
      isFocusedView: true,
      pinedParticipant: '',
      rowCount: 0,
      itemCount: 0,
      isAudioCall: false,
      askForIncomplete: false,
      from: '',
      camDevices: null,
      isGroupCall: false,
   };

   componentWillMount = () => {
      if (this.props.patientFlow) {
         const {
            roomName,
            roomId,
            token,
            participantId,
            scheduleId,
            visitId,
            providerName: name,
         } = this.props;
         this.setState({
            roomName,
            token,
            visitId,
            roomId,
            scheduleId,
            participantId,
            name,
         });
      } else if (this.props.viaModal) {
         const {
            roomId,
            roomName,
            recipient,
            token,
            visitId,
            scheduleId,
            isProvider,
            userId,
         } = this.props;
         this.setState({
            roomName,
            roomId,
            visitId,
            userId,
            flowType:
               isProvider === 'VISITOR'
                  ? 'VISITOR'
                  : isProvider
                  ? 'PROVIDER'
                  : 'STAFF',
            scheduleId,
            token,
            name: recipient,
            isProvider: true,
         });
      } else {
         const params = new URLSearchParams(this.props.location.search);
         const roomName = params.get('roomName');
         const scheduleId = params.get('scheduleId');
         const roomId = params.get('roomId');
         const token = params.get('token');
         const visitId = params.get('visitId');
         const recipient = params.get('recipient');
         const isProvider = params.get('isProvider');
         const error = params.get('error');
         const userId = params.get('userId');

         this.setState({
            showPermissionModal: !!error,
            roomName,
            newTab: true,
            roomId,
            visitId,
            userId,
            scheduleId,
            token,
            flowType: isProvider,
            name: recipient,
            isProvider: true,
         });
      }
      this.setState({
         endCallModalType: window.innerWidth > 600 ? 'median' : 'foot',
         width: window.innerWidth,
         height: window.innerHeight,
      });
   };

   getVideoLink = () => {
      axios
         .post('/innote-survey/telehealth/video/room/link', {
            visitId: this.state.visitId,
         })
         .then(({ data: { link } }) => [this.setState({ link })]);
   };

   changeStatusToReady = status => {
      axios
         .post(`/innote-survey/telehealth/video/rooms/status/_update`, {
            visitId: this.state.visitId,
            status: status ? status : 'READY_FOR_DOCTOR',
         })
         .then(() => {
            if (status === 'POST_VISIT_QUEUE') {
               Mixpanel.track('Moved to Post-visit queue', {
                  category: 'virtualVisit',
               });
            } else {
               Mixpanel.track('Marked ready for provider', {
                  category: 'virtualVisit',
               });
            }
            this.setState({ canChangeStatus: false });

            createToast({
               message: 'Status changed',
               time: 5,
               appearance: 'success',
            });
         });
   };

   getConnectData = () => {
      this.videoTrack.enable();
      this.audioTrack.enable();

      const { name, token } = this.state;
      return Promise.resolve({
         name,
         token,
      });
   };

   leaveRoom = () => {
      axios.delete('/innote-survey/telehealth/video/rooms/_leave', {
         data: {
            visitId: this.state.visitId,
            participantId: this.state.userId,
         },
      });
   };

   handleExit = () => {
      this.isExiting = true;
      if (this.room) this.room.disconnect();
   };

   statusUpdate = () => {
      axios
         .get(
            `/innote-survey/telehealth/video/rooms/status/_update?visit_id=${this.state.visitId}`
         )
         .then(({ data: { status = '' } }) => {
            if (!(status === 'WAITING' || status === 'ON_CALL')) {
               this.setState({ canChangeStatus: false });
            }
            if (this.state.from === 'endCall' && status === 'IN_PROGRESS') {
               this.setState({ canChangeStatus: true });
            }
         })
         .finally(() => {
            if (this.state.from === 'endCall') {
               this.setState({
                  showEndCallModal: true,
                  endCallModalType: window.innerWidth > 600 ? 'median' : 'foot',
               });
            }
         });
   };

   getAvailableParticipants = data => {
      const { onCallParticipants } = this.state;
      let re = data
         .map(el => {
            let participant;
            const pData = el;
            pData.isLocal = false;
            if (this.room.participants) {
               participant = this.room.participants.get(
                  el.twilioParticipantSid
               );
            }
            if (
               isEmpty(participant) &&
               el.twilioParticipantSid === this.room.localParticipant.sid
            ) {
               participant = this.room.localParticipant;
               pData.isLocal = true;
            }
            if (el.twilioParticipantSid && el.name && !isEmpty(participant)) {
               const findIndex = onCallParticipants.findIndex(
                  ele => ele.id === el.twilioParticipantSid
               );
               if (findIndex === -1) {
                  const element = document.createElement('div');
                  element.setAttribute('id', participant.sid);
                  element.style.height = '100%';
                  const audio = trackpubsToTracks(participant.audioTracks);
                  const video = trackpubsToTracks(participant.videoTracks);
                  let isAudio = true;
                  let isVideo = true;
                  if (audio[0] && audio[0].attach) {
                     element.appendChild(audio[0].attach());
                     isAudio = audio[0].isEnabled;
                  }
                  if (video[0] && video[0].attach) {
                     element.appendChild(video[0].attach());
                     isVideo = this.state.isAudioCall
                        ? false
                        : video[0].isEnabled;
                  }

                  return {
                     pData,
                     participant,
                     id: participant.sid,
                     element,
                     isAudio,
                     isVideo,
                  };
               } else {
                  return { ...onCallParticipants[findIndex] };
               }
            } else {
               return {};
            }
         })
         .filter(el => !isEmpty(el));

      if (!this.duration && re.length > 1) {
         this.startTimer();
      }
      return re;
   };

   getParticipantList = () => {
      axios
         .get(
            `/innote-survey/telehealth/video/rooms/participants?visitId=${
               this.state.visitId
            }&userType=${this.state.flowType}&participantId=${
               this.state.participantId
                  ? this.state.participantId
                  : this.state.userId
                  ? this.state.userId
                  : getItem('visitorId').split('_')[0]
            }`
         )
         .then(res => {
            const data = get(res, 'data.participants', []);
            const participants = this.getAvailableParticipants(data);
            this.getGridDetails(participants.length);
            const isAudioCall = data.some(el => el.callType === 'AUDIO');

            if (isAudioCall) {
               this.videoTrack.disable();
            }

            this.setState(prevState => ({
               onCallParticipants: participants,
               totalParticipants: data,
               isAudioCall,
               isVideoEnabled: isAudioCall ? false : prevState.isVideoEnabled,
               isGroupCall: this.state.isGroupCall
                  ? this.state.isGroupCall
                  : participants.length > 1,
            }));
         });
   };

   /**
    * Switches between front and rare camera
    */
   switchCamera = () => {
      if (!this.state.isAudioCall && this.state.isVideoEnabled) {
         const { facingMode } = this.state;
         this.setState(
            { facingMode: facingMode === 'user' ? 'enviornment' : 'user' },
            () => {
               this.videoTrack.restart({
                  facingMode: this.state.facingMode,
                  deviceId: {
                     exact:
                        this.state.facingMode === 'user'
                           ? this.state.camDevices.frontCamId
                           : this.state.camDevices.rearCamId,
                  },
               });
            }
         );
      }
   };

   orientationHandlerL = () => {
      const mode = orientationHandler();
      if (
         mode === 'landscape' &&
         this.videoTrack &&
         this.videoTrack.isEnabled
      ) {
         this.toggleVideo();
         this.setState({ screenMode: mode, isVideoChanged: true });
      }
      if (
         mode === 'portrait' &&
         this.state.isVideoChanged &&
         this.videoTrack &&
         !this.videoTrack.isEnabled
      ) {
         this.toggleVideo();
         this.setState({ screenMode: mode, isVideoChanged: false });
      }
   };

   async componentDidMount() {
      // Get Media Devices
      if (navigator.userAgent.toLowerCase().indexOf('electron/') === -1) {
         this.props.onRef(this);
      }
      this.setState({ camDevices: await getMediaDevices() });

      this._isMounted = true;
      let permission = true;
      window.addEventListener('beforeunload', this.handleExit);
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', this.orientationHandlerL);

      if (this.state.flowType === 'STAFF') {
         this.setState({ from: '' }, this.statusUpdate);
      }

      if (this.state.newTab) {
         permission = await checkPermission();
         if (permission) {
            const { videoTrack, audioTrack } = permission;
            this.videoTrack = videoTrack;
            this.audioTrack = audioTrack;
         } else {
            Mixpanel.track('Showing Permission Page - New Tab', {
               category: 'browser',
            });
            permission = false;
            this.setState({ showPermissionModal: true });
         }
      }

      if (permission)
         this.getConnectData()
            .then(({ name, token }) => {
               return connect(token, {
                  name,
                  tracks: [this.videoTrack, this.audioTrack],
                  trackSwitchOffMode: 'detected',
                  networkQuality: {
                     local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                     remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
                  },
                  bandwidthProfile,
                  preferredVideoCodecs: ['VP8', 'H264'],
                  maxAudioBitrate: 16000,
               }).then(room => {
                  this.getVideoLink();

                  if (this.state.isProvider)
                     Mixpanel.track(`Waiting For patient`, {
                        category: 'groupCall',
                     });
                  else if (this.state.flowType === 'PATIENT')
                     Mixpanel.track('Patient Waiting For Recipient', {
                        category: 'groupCall',
                        Provider: this.state.name || '',
                     });

                  // Current Room
                  this.room = room;

                  // Log your Client's LocalParticipant in the Room
                  this.localParticipant = room.localParticipant;

                  // Subscribe to local participant's events
                  this.handleLocalParticipant(this.localParticipant);

                  // Log any Participants already connected to the Room
                  room.participants.forEach((participant, index) => {
                     this.handleParticipantEvent(participant);
                  });

                  room.on('participantConnected', participant => {
                     this.handleParticipantEvent(participant);
                  });

                  room.on('participantDisconnected', participant => {
                     const myNode = document.getElementById(participant.sid);
                     if (myNode) {
                        myNode.remove();
                     }
                     const onCallParticipants = [
                        ...this.state.onCallParticipants,
                     ];

                     const findIndex = onCallParticipants.findIndex(
                        el => el.participant.sid === participant.sid
                     );
                     onCallParticipants.splice(findIndex, 1);
                     const name = this.returnParticipantName(participant);
                     if (onCallParticipants.length === 1) {
                        clearInterval(this.duration);
                        this.duration = undefined;
                     }

                     this.setState({ userHasDisconnectedOnce: true });
                     Mixpanel.track(`${name || ''} disconnected`, {
                        category: 'groupCall',
                     });
                     if (
                        !(
                           this.state.height < 600 &&
                           this.state.height < this.state.width
                        )
                     ) {
                        createToast({
                           message: `${name || ''} disconnected`,
                           time: 5,
                           appearance: 'default',
                        });
                     }
                     this.setState(
                        {
                           onCallParticipants,
                        },
                        this.getGridDetails
                     );
                  });

                  room.on('disconnected', room => {
                     // Detach the local media elements
                     this.endCall(false);
                     Mixpanel.track(`Call ended`, {
                        category: 'groupCall',
                        'Call Type': this.state.isAudioCall ? 'Audio' : 'Video',
                     });
                  });
               });
            })
            .catch(error => {
               // this.setState({ callEnded: true });
               setTimeout(() => {
                  localStorage.removeItem('videoObj');
                  this.props.close();
               }, 5000);
            })
            .finally(() => {
               this.setState({
                  isLoading: false,
               });
            });
   }

   handleLocalParticipant = participant => {
      participant.on(
         'networkQualityLevelChanged',
         (networkQualityLevel, networkQualityStats) => {
            const isSelfNetworkPoor = networkQualityLevel === 0;
            if (isSelfNetworkPoor !== this.state.isSelfNetworkPoor)
               this.setState({ isSelfNetworkPoor });
         }
      );
   };

   startTimer = () => {
      if (!this.duration) {
         this.duration = setInterval(() => {
            this.setState(prevState => ({ count: prevState.count + 1 }));
         }, 1000);
      }
   };

   toggleVideo = () => {
      if (this.videoTrack && !this.state.isAudioCall) {
         const { onCallParticipants } = this.state;
         const findIndex = onCallParticipants.findIndex(el => el.pData.isLocal);
         if (findIndex !== -1) {
            const newOnCallParticipants = [...onCallParticipants];
            newOnCallParticipants[findIndex].isVideo = !this.state
               .isVideoEnabled;
            this.videoTrack.isEnabled
               ? this.videoTrack.disable()
               : this.videoTrack.enable();
            // if (this.videoTrack.isEnabled) {
            //    this.videoTrack.disable();
            //    this.videoTrack.stop();
            // } else {
            //    this.videoTrack.enable();
            //    this.videoTrack.restart({
            //       facingMode: this.state.facingMode,
            //       deviceId: {
            //          exact:
            //             this.state.facingMode === 'user'
            //                ? this.state.camDevices.frontCamId
            //                : this.state.camDevices.rearCamId,
            //       },
            //    });
            // }
            debugger;
            this.setState(prevState => ({
               isVideoEnabled: !prevState.isVideoEnabled,
               onCallParticipants: newOnCallParticipants,
            }));
         }
      }
   };

   toggleAudio = () => {
      if (this.audioTrack) {
         const { onCallParticipants } = this.state;
         const findIndex = onCallParticipants.findIndex(el => el.pData.isLocal);
         if (findIndex !== -1) {
            const newOnCallParticipants = [...onCallParticipants];
            newOnCallParticipants[findIndex].isAudio = !this.state
               .isAudioEnabled;

            this.audioTrack.isEnabled
               ? this.audioTrack.disable()
               : this.audioTrack.enable();

            this.setState(prevState => ({
               isAudioEnabled: !prevState.isAudioEnabled,
               onCallParticipants: newOnCallParticipants,
            }));
         }
      }
   };

   stopTracks = () => {
      if (this.room) {
         this.room.disconnect();
         this.audioTrack && this.audioTrack.disable();
         this.videoTrack && this.videoTrack.disable();
         if (this.room.localParticipant) {
            this.room.localParticipant.tracks.forEach(publication => {
               const attachedElements = publication.track.detach();
               attachedElements.forEach(element => element.remove());
               publication.track.stop();
            });
            // localParticipant.tracks.forEach(function(track) {
            //    track.stop()
            //  });
         }
      }
      this.videoTrack = null;
      this.audioTrack = null;
      clearInterval(this.duration);
      this.duration = undefined;
   };

   leaveVisit = (isUpdate = false) => {
      if (isUpdate && this.state.flowType === 'PROVIDER') {
         this.changeStatusToReady('POST_VISIT_QUEUE');
      }
      if (
         this.state.flowType === 'STAFF' ||
         this.state.flowType === 'PROVIDER'
      ) {
         Mixpanel.track(
            `${
               this.state.flowType === 'PROVIDER' ? 'Provider' : 'Staff'
            } left call`,
            {
               category: 'virtualVisit',
               'Call Type': this.state.isAudioCall ? 'Audio' : 'Video',
               'Is Group Call': this.state.isGroupCall,
            }
         );
         this.leaveRoom();
      }
      this.stopTracks();
      this.setState({ callEnded: true });
      setTimeout(() => {
         window.close();
      }, 3000);
   };

   trackPublished = (publication, participant) => {
      publication.on('subscribed', remoteTrack => {
         remoteTrack.on('switchedOff', () => {
            //You may update your UI accordingly
            const { identity } = participant;
            const name = identity.split('_')[1].split('+').join(' ');
            const { poorNetworkArray } = this.state;
            const newPoorNetworkArray = [...poorNetworkArray];
            newPoorNetworkArray.concat({ identity, name });
            this.setState({ poorNetworkArray: newPoorNetworkArray });
         });

         remoteTrack.on('switchedOn', () => {
            //You may update your UI accordingly
            const { identity } = participant;
            const { poorNetworkArray } = this.state;
            const newPoorNetworkArray = [...poorNetworkArray];
            const index = newPoorNetworkArray.findIndex(
               item => item.identity === identity
            );
            newPoorNetworkArray.splice(index, 1);
            this.setState({ poorNetworkArray: newPoorNetworkArray });
         });
      });
   };

   returnParticipantName = ({ identity }) => {
      return (identity.split('_')[1] || ' ').split('+').join(' ');
   };

   handleParticipantEvent = participant => {
      const name = this.returnParticipantName(participant);

      if (this.state.flowType) {
         Mixpanel.track(`Connected to call`, {
            category: 'virtualVisit',
            'Call Type': this.state.isAudioCall ? 'Audio' : 'Video',
            'Is Group Call': this.state.isGroupCall,
         });
      }

      if (this.state.flowType === 'PATIENT') {
         Mixpanel.track('Patient Connected to call', { category: 'groupCall' });
      }

      if (!(this.state.height < 600 && this.state.height < this.state.width)) {
         createToast({
            message: `${name || ''} connected`,
            time: 5,
            appearance: 'default',
         });
      }
      participant.tracks.forEach(publication => {
         this.trackPublished(publication, participant);
      });

      participant.on('trackPublished', publication => {
         this.trackPublished(publication, participant);
      });

      participant.on(
         'networkQualityLevelChanged',
         (networkQualityLevel, networkQualityStats) => {
            const { identity } = participant;

            const { poorNetworkArray } = this.state;
            const newPoorNetworkArray = [...poorNetworkArray];
            const index = newPoorNetworkArray.findIndex(
               item => item.identity === identity
            );
            if (networkQualityLevel === 0 && index > -1) {
               newPoorNetworkArray.splice(index, 1);
            } else if (index === -1) {
               //You may update your UI accordingly
               newPoorNetworkArray.concat({ name, identity });
            }
            this.setState({ poorNetworkArray: newPoorNetworkArray });
         }
      );

      participant.on('trackSubscribed', track => {
         this.getParticipantList();
      });

      participant.on('trackDisabled', track => {
         if (
            !(this.state.height < 600 && this.state.height < this.state.width)
         ) {
            createToast({
               message: `${name || ''} disabled ${track.kind}`,
               time: 5,
               appearance: 'default',
            });
         }
         const { onCallParticipants } = this.state;
         const findIndex = onCallParticipants.findIndex(
            el => el.id === participant.sid
         );
         const newOnCallParticipants = [...onCallParticipants];
         if (track.kind === 'video' && findIndex !== -1) {
            newOnCallParticipants[findIndex].isVideo = false;
         }
         if (track.kind === 'audio' && findIndex !== -1) {
            newOnCallParticipants[findIndex].isAudio = false;
         }
         this.setState({
            onCallParticipants: newOnCallParticipants,
         });
      });

      participant.on('trackEnabled', track => {
         const { onCallParticipants } = this.state;
         const findIndex = onCallParticipants.findIndex(
            el => el.id === participant.sid
         );
         const newOnCallParticipants = [...onCallParticipants];
         if (track.kind === 'video' && findIndex !== -1) {
            newOnCallParticipants[findIndex].isVideo = true;
         }
         if (track.kind === 'audio' && findIndex !== -1) {
            newOnCallParticipants[findIndex].isAudio = true;
         }
         this.setState({
            onCallParticipants: newOnCallParticipants,
         });
      });
   };

   updateNetworkArray = newPoorNetworkArray =>
      this.setState({ poorNetworkArray: newPoorNetworkArray });

   incompleteHandler = () => {
      axios
         .patch(
            `/innote-survey/telehealth/video/schedule/${this.state.scheduleId}/mark_incomplete`,
            {
               isIncomplete: true,
            }
         )
         .then(() => {
            Mixpanel.track('Incomplete call from video', {
               category: 'incompleteCall',
            });
            this.setState(
               {
                  askForIncomplete: false,
               },
               this.statusUpdate
            );
         })
         .catch(() =>
            this.setState({ askForIncomplete: false }, this.statusUpdate)
         );
   };

   skipHandler = () => {
      this.setState(
         {
            askForIncomplete: false,
         },
         this.statusUpdate
      );
   };

   checkInCompleteCall = () => {
      axios
         .get(
            `/innote-survey/telehealth/video/schedule/${this.state.scheduleId}/check_incomplete`
         )
         .then(({ data }) => {
            if (data.askForIncomplete) {
               this.setState({
                  askForIncomplete: data.askForIncomplete,
                  from: 'endCall',
               });
            } else {
               this.setState(
                  {
                     askForIncomplete: data.askForIncomplete,
                     from: 'endCall',
                  },
                  this.statusUpdate
               );
            }
         })
         .catch(error =>
            this.setState(
               { askForIncomplete: false, from: 'endCall' },
               this.statusUpdate
            )
         );
   };

   endCallHandler = (selfInitiated = true) => {
      if (
         this.state.flowType === 'STAFF' ||
         this.state.flowType === 'PROVIDER'
      ) {
         this.checkInCompleteCall();
      } else {
         this.setState({ from: 'endCall' }, this.statusUpdate);
      }
   };

   endCall = async (selfInitiated = true) => {
      this.setState({ showEndCallModal: false });
      this.stopTracks();
      if (!this.isExiting) {
         this.setState({ callEnded: true });
         if (selfInitiated) {
            Mixpanel.track(`Virtual call ended`, {
               category: 'virtualVisit',
               'Call Type': this.state.isAudioCall ? 'Audio' : 'Video',
               'Is Group Call': this.state.isAudioCall,
            });
         } else
            Mixpanel.track('Recipient Ended Visit', {
               category: 'virtualVisit',
               'Call Type': this.state.isAudioCall ? 'Audio' : 'Video',
               'Is Group Call': this.state.isAudioCall,
            });
         if (selfInitiated) {
            this.closeRoom();
         } else {
            if (this.props.viaModal) {
               setTimeout(() => {
                  this.props.close();
               }, 2000);
               localStorage.removeItem('videoObj');
            } else {
               setTimeout(() => {
                  if (this._isMounted) window.close();
               }, 2000);
            }
         }
      }
   };

   closeRoom = () => {
      axios
         .delete(`/innote-survey/telehealth/video/rooms/_close`, {
            data: { visitId: this.state.visitId },
         })
         .then(() => {
            setTimeout(() => {
               if (this._isMounted) window.close();
            }, 5000);
         })
         .finally(() => {
            if (this.props.viaModal) {
               setTimeout(() => {
                  this.props.close();
               }, 2000);
               localStorage.removeItem('videoObj');
            } else {
               if (this._isMounted) window.close();
            }
         });
   };

   handleResize = () => {
      this.setState({
         width: window.innerWidth,
         height: window.innerHeight,
      });
   };

   //check for resize
   componentWillUnmount() {
      this._isMounted = false;
      if (navigator.userAgent.toLowerCase().indexOf('electron/') === -1) {
         this.props.onRef(undefined);
      }
      this.stopTracks();
      window.removeEventListener('beforeunload', this.handleExit);
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.orientationHandlerL);

      if (this.room) {
         this.room.disconnect();
      }
   }

   onClose = (isUpdate = false) => {
      if (isUpdate === true && this.state.flowType === 'PROVIDER') {
         this.changeStatusToReady('POST_VISIT_QUEUE');
      }
      this.setState({ showEndCallModal: false });
   };

   changeLayout = (participantSid = '') => {
      if (this.state.onCallParticipants.length > 2) {
         this.setState(
            prevState => ({
               pinedParticipant: participantSid,
               isFocusedView: participantSid ? true : !prevState.isFocusedView,
            }),
            this.getGridDetails
         );
         Mixpanel.track('Pin view', {
            category: 'groupCall',
         });
      }
   };

   getGridDetails = count => {
      const dLength = count || this.state.onCallParticipants.length;
      if (!this.state.isFocusedView) {
         let rows = 1;
         let pRows = 1;
         if (this.state.width < 600) {
            if (dLength === 1 || dLength === 2) {
               pRows = 1;
               rows = 2;
            } else if (dLength === 3 || dLength === 4) {
               pRows = 2;
               rows = 2;
            } else if (dLength === 5 || dLength === 6) {
               pRows = 2;
               rows = 3;
            } else if (dLength === 7 || dLength === 8) {
               pRows = 2;
               rows = 4;
            } else {
               pRows = 2;
               rows = 5;
            }
         } else {
            rows = dLength > 8 ? 3 : 2;
            if (dLength === 1 || dLength === 2) {
               pRows = 1;
            } else if (dLength === 3 || dLength === 4) {
               pRows = 2;
            } else if (dLength === 5 || dLength === 6) {
               pRows = 3;
            } else {
               pRows = 4;
            }
         }
         this.setState({
            rowCount: rows,
            itemCount: pRows,
         });
      } else {
         this.forceUpdate();
      }
   };

   setPinParticipant = id => {
      this.setState({
         pinedParticipant: id,
      });
   };

   remove = id => {
      axios.post('/innote-survey/telehealth/video/rooms/participants/_remove', {
         twilioParticipantId: id,
         visitId: this.state.visitId,
      });
   };

   mute = pData => {
      if (
         this.props.doMute &&
         navigator.userAgent.toLowerCase().indexOf('electron/') === -1
      ) {
         this.props.doMute({
            scheduleId: this.state.scheduleId,
            visitId: this.state.visitId,
            participantId: pData.participantId,
            participantType: pData.type,
         });
      } else {
         this.child.doMute({
            scheduleId: this.state.scheduleId,
            visitId: this.state.visitId,
            participantId: pData.participantId,
            participantType: pData.type,
         });
      }
   };

   unmute = pData => {
      if (
         this.props.doUnMute &&
         navigator.userAgent.toLowerCase().indexOf('electron/') === -1
      ) {
         this.props.doUnMute({
            scheduleId: this.state.scheduleId,
            visitId: this.state.visitId,
            participantId: pData.participantId,
            participantType: pData.type,
         });
      } else {
         this.child.doUnMute({
            scheduleId: this.state.scheduleId,
            visitId: this.state.visitId,
            participantId: pData.participantId,
            participantType: pData.type,
         });
      }
   };

   render() {
      return (
         <Wrapper
            id='parent-media'
            innerHeight={this.state.height}
            innerWidth={this.state.width}>
            {navigator.userAgent.toLowerCase().indexOf('electron/') !== -1 &&
               (this.state.flowType === 'STAFF' ||
                  this.state.flowType === 'PROVIDER') && (
                  <ElectronSocket
                     onRef={ref => (this.child = ref)}
                     token={this.state.token}
                     toggleAudio={this.toggleAudio}
                  />
               )}
            {this.state.showPermissionModal && (
               <Modal
                  motion={motion.slideInBottom}
                  style={modalStyle}
                  onClose={() => this.setState({ showPermissionModal: false })}>
                  {onCloseModal => (
                     <PermissionModal
                        source={'electron'}
                        close={onCloseModal}
                        tryAgain={this.toggleVideoVisit}
                     />
                  )}
               </Modal>
            )}
            {(this.state.askForIncomplete || this.state.showEndCallModal) && (
               <EndCallModalContainer
                  visitId={this.state.visitId}
                  canChangeStatus={this.state.canChangeStatus}
                  leaveVisit={this.leaveVisit}
                  close={this.onClose}
                  changeStatusToReady={this.changeStatusToReady}
                  userType={this.state.flowType}
                  modalType={this.state.endCallModalType}
                  endCallHandler={this.endCall}
                  innerWidth={this.state.width}
                  incompleteHandler={this.incompleteHandler}
                  skipHandler={this.skipHandler}
                  askForIncomplete={this.state.askForIncomplete}
               />
            )}
            <ParticipantWrapper
               innerHeight={this.state.height}
               innerWidth={this.state.width}
            />
            {this.state.callEnded && (
               <CallEndedText>
                  {this.state.isLoading && !this.props.callEnded ? (
                     <Loader stroke='#fff' />
                  ) : (
                     'Your visit has ended.'
                  )}
               </CallEndedText>
            )}
            {!this.state.callEnded && (
               <Fragment>
                  {this.state.onCallParticipants.length > 1 && (
                     <ParticipantLayout
                        isFocusedView={this.state.isFocusedView}
                        innerHeight={this.state.height}
                        innerWidth={this.state.width}
                        isTopOff={this.state.onCallParticipants.length === 2}>
                        <ViewVideo
                           isFocusedView={this.state.isFocusedView}
                           onCallParticipants={this.state.onCallParticipants}
                           pinedParticipant={this.state.pinedParticipant}
                           flowType={this.state.flowType}
                           innerWidth={this.state.width}
                           rowCount={this.state.rowCount}
                           itemCount={this.state.itemCount}
                           innerHeight={this.state.height}
                           isAudioCall={this.state.isAudioCall}
                           isVideoEnabled={this.state.isVideoEnabled}
                           isAudioEnabled={this.state.isAudioEnabled}
                           endCallModalType={this.state.endCallModalType}
                           changeLayout={this.changeLayout}
                           setPinParticipant={this.setPinParticipant}
                           remove={this.remove}
                           mute={this.mute}
                           unmute={this.unmute}
                        />
                     </ParticipantLayout>
                  )}
                  {this.state.isLoading && (
                     <CallEndedText>
                        <Loader stroke='#fff' />
                     </CallEndedText>
                  )}
                  {!this.state.isLoading && (
                     <Fragment>
                        {this.room &&
                           this.state.onCallParticipants.length < 2 && (
                              <SingleParticipantIndicator />
                           )}
                        {this.state.onCallParticipants.length > 0 && (
                           <Fragment>
                              {(this.state.isSelfNetworkPoor ||
                                 this.state.poorNetworkArray.length > 0) && (
                                 <PoorNetwork {...this.state} />
                              )}
                              <Timer
                                 count={this.state.count}
                                 isTopOff={
                                    this.state.onCallParticipants.length === 2
                                 }
                                 isFocusedView={this.state.isFocusedView}
                              />
                           </Fragment>
                        )}

                        <ActionButton
                           isVideoEnabled={this.state.isVideoEnabled}
                           isAudioEnabled={this.state.isAudioEnabled}
                           endCallHandler={this.endCallHandler}
                           toggleVideo={this.toggleVideo}
                           toggleAudio={this.toggleAudio}
                           link={this.state.link}
                           isAudioCall={this.state.isAudioCall}
                           deviceType={this.state.endCallModalType}
                           isMessage={
                              this.state.flowType !== 'PATIENT' &&
                              this.state.flowType !== 'VISITOR' &&
                              this.state.isProvider
                           }
                           isFocusedView={this.state.isFocusedView}
                           changeLayout={this.changeLayout}
                           messageComp={
                              <Message
                                 userId={this.state.userId}
                                 visitId={this.state.visitId}
                              />
                           }
                           onCallParticipants={this.state.onCallParticipants}
                           innerWidth={this.state.width}
                           switchCamera={this.switchCamera}
                           isCamSwitchable={
                              this.state.camDevices &&
                              this.state.camDevices.frontCamId &&
                              this.state.camDevices.rearCamId
                           }
                           remove={this.remove}
                           flowType={this.state.flowType}
                           mute={this.mute}
                           unmute={this.unmute}
                        />
                     </Fragment>
                  )}
               </Fragment>
            )}
            {this.state.screenMode === 'landscape' && (
               <ErrorPage>Please use portrait view!</ErrorPage>
            )}
         </Wrapper>
      );
   }
}

export default TeleVisit;
