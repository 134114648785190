import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Modal, motion } from '@krishnaxv/react-surface';
import { Mixpanel } from 'helpers/mixpanelHelper';
import PatientDetails from './PatientDetails';
import ModalHeader from '../Modal/ModalHeader';
import Footer from '../Modal/ModalFooter';
import Survey from '../Survey/Survey';
import Results from './Results';
import axios from 'helpers/axios';
import createToast from 'helpers/toastHelper';
import SelectProvider from './SelectProvider';
import returnCamelCasedName from 'helpers/nameTransformer';

// Should be used to position modal on the surface
const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: window.innerHeight,
   width: '100%',
   display: 'flex',
};

const CardWrapper = styled.div`
   padding: ${({ currentStep }) => currentStep !== 3 && '16px'};
   border-radius: 4px;
   background-color: ${({ currentStep }) => currentStep !== 3 && '#ffffff;'};
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const ModalContainer = styled.div`
   > div {
      max-width: 400px;
      display: flex;
      left: 0;
      background: none;
      justify-content: center;
      margin: 0 auto;
      left: 0;
      right: 0;
   }
`;

const BodyWrapper = styled.div`
   padding: ${({ currentStep, isPuiFilled }) =>
      (currentStep !== 3 || isPuiFilled) && '8px'};
   overflow: auto;
   height: ${({ submitted }) => !submitted && `${window.innerHeight - 124}px`};
`;

const ModalWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

class NewAssessment extends Component {
   state = {
      currentStep: this.props.showResult ? 2 : 1,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      DOB: this.props.dob,
      gender: this.props.gender,
      phoneNo: '',
      assignedTo: this.props.owner,
      canMoveForward: false,
      dobError: false,
      occupation: 'Other',
      email: '',
      submitted: false,
      dependentSurveyList: [],
      surveyList: [],
      body: {},
      canFinishAssessment: false,
      status: null,
      responseId: this.props.responseId || null,
      isLoading: false,
      isCovidLoading: true,
   };

   onDateChange = date => {
      let dobError = false;
      const pattern = /^((0)[1-9]|(1)[0-2]|[0-9])(\/)((0)[1-9]|(1|2)[0-9]|(3)[0-1]|[1-9])(\/)(\d{4})$/g;
      dobError = !pattern.test(date);
      this.setState({ DOB: date, dobError }, this.checkForNextStep);
   };

   onChange = event => {
      const {
         target: { value, name },
      } = event;

      this.setState(() => ({ [name]: value }), this.checkForNextStep);
   };
   finishLoading = () => {
      this.setState({ isCovidLoading: false });
   };

   nextStep = () => {
      if (this.state.currentStep === 1) {
         Mixpanel.track('Step 1 - Patient details entered', {
            category: 'conductAssessment',
         });
         this.checkForNextStep();
         this.setState(prevState => ({
            currentStep: prevState.currentStep + 1,
            canMoveForward: false,
         }));
      } else {
         this.submitSurvey(
            this.state.currentStep === 2
               ? {
                    ...this.state.covidAssessment,
                    patientInfo: this.props.showResult
                       ? undefined
                       : {
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            dob: this.state.DOB,
                            email: this.state.email,
                            phoneNo: this.state.phoneNo,
                            occupation: this.state.occupation,
                            gender: this.state.gender,
                         },
                 }
               : this.state.puiAssessment,
            this.state.currentStep
         );
      }
   };

   submitSurvey = (body, step) => {
      this.setState({ isLoading: true });
      if (step === 2) {
         const shouldPatch =
            this.props.showResult || this.state.covidaSubmitted;

         if (!shouldPatch) {
            axios
               .post(
                  `innote-survey/assessments/${
                     this.props.assessmentId || '5e7b840c98c5bf3ad547f6a2'
                  }/_manage`,
                  {
                     ...body,
                     assignedTo: this.state.assignedTo,
                  }
               )
               .then(
                  ({
                     data: {
                        assessment: {
                           status,
                           responseId,
                           assessmentResponse: {
                              surveyList,
                              dependentSurveyList,
                           },
                        },
                     },
                  }) => {
                     this.setState({
                        status,
                        responseId,
                        currentStep: 3,
                        surveyList,
                        dependentSurveyList,
                     });

                     // @TODO needto check the use case

                     // if (this.state.covidaSubmitted) {
                     //    Mixpanel.track(
                     //       'Assessment Edited',
                     //       {
                     //          category: 'COVID-19 : Assessment',
                     //          'Assessment Name': 'COVID',
                     //       },
                     //       true
                     //    );
                     // } else {
                     //    Mixpanel.track(
                     //       'Assessment Completed',
                     //       {
                     //          category: 'COVID-19 : Assessment',
                     //          'Assessment Name': 'COVID',
                     //       },
                     //       true
                     //    );
                     // }
                     Mixpanel.track('Assessment Completed', {
                        category: 'covidAssessment',
                     });

                     this.setState({
                        covidaSubmitted: true,
                     });
                  }
               )
               .catch(() => {
                  const message = 'Please enter a valid phone number';
                  this.setState({
                     currentStep: 1,
                  });
                  createToast({ message });
               })
               .finally(() => {
                  this.setState({
                     isLoading: false,
                  });
                  // Scroll to top of the screen
                  this.body.scrollTo({ top: 0 });
               });
         } else {
            this.setState({
               currentStep: 3,
               isLoading: false,
            });
            Mixpanel.track('Step 2 completed', {
               category: 'reviewAssessment',
            });
         }
      } else {
         this.setState({ isLoading: true });
         if (!this.props.isPuiFilled) {
            axios
               .post(
                  `/innote-survey/assessments/5e70364798c5bf3ad543dd99/responses/${this.state.responseId}/_manage`,
                  {
                     ...this.state.puiAssessment,
                     assignedTo: this.state.assignedTo,
                  }
               )
               .then(() => {
                  Mixpanel.track('PUI complete', {
                     category: 'reviewAssessment',
                     'Assessment Status':
                        this.props.isPuiFilled && this.state.responseId
                           ? 'Assessment Edited'
                           : 'Assessment Completed',
                     'Assessment Name': 'COVID',
                  });
                  this.setState({
                     submitted: true,
                  });
               });
         } else {
            Mixpanel.track('Step 3 completed', {
               category: 'reviewAssessment',
            });
            this.setState({
               submitted: true,
               isLoading: false,
            });
         }
      }
   };

   disableNext = () => {
      this.setState({
         canMoveForward: false,
      });
   };

   onButtonChange = event => {
      const {
         target: { name },
      } = event;
      this.setState(() => ({ gender: name }), this.checkForNextStep);
   };

   previousStep = () => {
      this.setState(
         prevState => ({ currentStep: prevState.currentStep - 1 }),
         this.checkForNextStep
      );
   };

   checkForNextStep = () => {
      if (
         (!!this.state.firstName &&
            !!this.state.lastName &&
            !!this.state.DOB &&
            !!this.state.gender &&
            !this.state.dobError &&
            !!this.state.occupation &&
            this.state.phoneNo.length === 10) ||
         this.props.showResult
      ) {
         this.setState({ canMoveForward: true });
      } else {
         this.setState({ canMoveForward: false });
      }
   };

   finishSurvey = (body, type) => {
      this.setState({
         canMoveForward: true,
         [type]: body,
         canFinishAssessment: type === 'puiAssessment',
      });
   };

   saveCovidResponse = covidResponse => {
      this.setState({ covidResponse });
   };

   render() {
      const {
         firstName,
         lastName,
         DOB,
         gender,
         phoneNo,
         email,
         canMoveForward,
         dobError,
         status,
         occupation,
         isCovidLoading,
         currentStep,
      } = this.state;
      const isProvider = localStorage.getItem('access') === 'PROVIDER';
      const providerList = JSON.parse(localStorage.getItem('userList')) || [];
      return (
         <ModalContainer>
            <Modal motion={motion.slideInBottom} style={modalStyle} onClose>
               {onCloseModal => (
                  <ModalWrapper>
                     <ModalHeader
                        disableHeader={currentStep < 2}
                        subtext={`${returnCamelCasedName(
                           firstName,
                           lastName
                        )}, ${gender}, ${DOB}`}
                        currentStep={this.state.currentStep}
                        startLoader={this.props.close}
                        close={onCloseModal}
                     />
                     <BodyWrapper
                        isPuiFilled={this.props.isPuiFilled}
                        innerRef={e => (this.body = e)}
                        isCovidLoading={isCovidLoading}
                        currentStep={this.state.currentStep}
                        submitted={this.state.submitted}>
                        {this.state.submitted ? (
                           <Results
                              assessmentId={this.props.assessmentId}
                              close={this.props.close}
                              responseId={this.state.responseId}
                              status={status}
                           />
                        ) : (
                           <Fragment>
                              <CardWrapper currentStep={this.state.currentStep}>
                                 {this.state.currentStep === 1 ? (
                                    <PatientDetails
                                       onButtonChange={this.onButtonChange}
                                       gender={gender}
                                       DOB={DOB}
                                       email={email}
                                       phoneNo={phoneNo}
                                       dobError={dobError}
                                       occupation={occupation}
                                       lastName={lastName}
                                       firstName={firstName}
                                       onChange={this.onChange}
                                       onDateChange={this.onDateChange}
                                    />
                                 ) : (
                                    <Survey
                                       currentStep={this.state.currentStep}
                                       isLoading={this.state.isLoading}
                                       disableNext={this.disableNext}
                                       isPuiFilled={this.props.isPuiFilled}
                                       responseId={this.state.responseId}
                                       covidAssessmentResponse={
                                          this.state.covidAssessmentResponse
                                       }
                                       finishLoading={this.finishLoading}
                                       surveyList={this.state.surveyList}
                                       dependentSurveyList={
                                          this.state.dependentSurveyList
                                       }
                                       assessmentId={this.props.assessmentId}
                                       covidResponse={this.state.covidResponse}
                                       saveCovidResponse={
                                          this.saveCovidResponse
                                       }
                                       showResult={this.props.showResult}
                                       finishSurvey={this.finishSurvey}
                                       covidaSubmitted={
                                          this.state.covidaSubmitted
                                       }
                                    />
                                 )}
                              </CardWrapper>
                              {!isProvider && this.state.currentStep === 1 && (
                                 <SelectProvider
                                    providerList={providerList}
                                    onChange={assignedTo => {
                                       this.setState({ assignedTo });
                                       Mixpanel.track(
                                          `Provider selected for assessment`,
                                          {
                                             category: 'conductAssessment',
                                             Source: 'Assessment',
                                             'Selected Provider': assignedTo,
                                          }
                                       );
                                    }}
                                 />
                              )}
                           </Fragment>
                        )}
                     </BodyWrapper>
                     {!this.state.submitted && (
                        <Footer
                           isProvider={isProvider}
                           assignedTo={this.state.assignedTo}
                           isLoading={this.state.isLoading}
                           previousStep={this.previousStep}
                           dobError={dobError}
                           canFinishAssessment={this.state.canFinishAssessment}
                           canMoveForward={canMoveForward}
                           showResult={this.props.showResult}
                           isPuiFilled={this.props.isPuiFilled}
                           nextStep={this.nextStep}
                           currentStep={this.state.currentStep}
                        />
                     )}
                  </ModalWrapper>
               )}
            </Modal>
         </ModalContainer>
      );
   }
}

export default NewAssessment;
