import {
   REQUEST_SECTION_LIST,
   SECTION_LIST_SUCCESS,
   SECTION_LIST_FAILURE,
} from './constants';

const initialState = {
   sectionsList: [],
   isLoading: false,
   error: null,
};

/**
 * Sections reducer
 * @param {Object} [state=initialState] State
 * @param {Object} action Action
 * @return {Object} State
 */
function sections(state = initialState, action) {
   switch (action.type) {
      case REQUEST_SECTION_LIST:
         return {
            ...initialState,
            isLoading: true,
         };
      case SECTION_LIST_SUCCESS:
         return {
            ...state,
            isLoading: false,
            sectionsList: action.sections,
         };
      case SECTION_LIST_FAILURE:
         return {
            ...state,
            isLoading: false,
            error: action.error,
         };
      default:
         return state;
   }
}

export default sections;
