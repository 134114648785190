import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { removeMSGObserver } from 'store/frontDoorSocket/action';
import { connect } from 'react-redux';

const PatientBox = styled.div`
   background-color: #ffffff;
   border: 1px solid #e7e5e5;
   border-radius: 8px 8px 8px 0;
   padding: 12px;
   margin-bottom: 8px;
   margin-left: 16px;
   margin-right: 12px;
   width: auto;
   max-width: 70%;
   color: #2f2f2f;
   font-size: 14px;
   line-height: 20px;
   overflow-wrap: break-word;
`;

const ChangeView = ({ el, children, handleMarksAsRead, removeMSGObserver }) => {
   const targetElement = useRef(null);
   const [isFirst, setIsFirst] = useState(true);

   useEffect(() => {
      const observeElement = targetElement.current.lastElementChild;
      const options = {
         root: null,
         threshold: 1,
      };
      let observer = new IntersectionObserver(entries => {
         entries.forEach(item => {
            if (item.isIntersecting && isFirst) {
               setIsFirst(false);
               handleMarksAsRead(el);
               removeMSGObserver(el);
            }
         });
      }, options);
      observer.observe(observeElement);
      return () => {
         observer.unobserve(observeElement);
      };
   }, [el, isFirst, handleMarksAsRead, removeMSGObserver]);

   return <PatientBox innerRef={targetElement}>{children}</PatientBox>;
};

const mapDispatchToProps = { removeMSGObserver };

export default connect(null, mapDispatchToProps)(ChangeView);
