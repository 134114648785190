import React, { useEffect, useRef, useState } from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { getLetterIconColor } from 'helpers/badgeColor';
import returnCamelCasedName from 'helpers/nameTransformer';
import formatPhoneNumber from 'helpers/formatPhone';
import Error from './Error';
import PatientAttributeModal from './PatientAttributeModal';
import { debounce } from 'lodash-es';
import MessageContainer from './MessageContainer';
import Button from '@datashop/button';
import { format } from 'date-fns';
import Heading from '@datashop/heading';
import ScheduleForm from 'packages/ScheduleNewVisitModal/ScheduleForm';
import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';
import Avatar from '@datashop/avatar';
import Text from '@datashop/text';
import timezoneList from 'helpers/timeZones';
import { Loader } from 'packages/loader';
import Attach from './Attach';
import {
   addAttachment,
   startAttachmentUpload,
   addAttachmentIdentifier,
   attachmentUploadDone,
} from 'store/frontDoorSocket/action';
import capitalizeSentence from 'utils/capitalizeSentence';

const ChatBody = ({
   modalType,
   isChatLoadingError,
   isAttachmentUploading,
   selectedInbox,
   handleMarksAsRead,
   sendMessage,
   setModalType,
   isChatLoading,
   userInfo: { accessRole },
   isPatientAttributionLoading,
   attributed,
   addAttachment,
   startAttachmentUpload,
   addAttachmentIdentifier,
   attachmentUploadDone,
}) => {
   const chatBodyRef = useRef(null);
   const bottomRef = useRef(null);
   const chatFormRef = useRef(null);
   const submitButtonRef = useRef(null);
   const [isBottom, setIsBottom] = useState(true);
   const [msg, setMsg] = useState('');
   const [providerList, setProviderList] = useState([]);
   const [isProviderLoad, setIsProviderLoad] = useState(false);
   const [isWalkInLoading, setIsWalkInLoading] = useState(false);
   const [cursor, setCursor] = useState(null);
   const chatBoxRef = useRef(null);

   useEffect(() => {
      if (chatBodyRef.current && bottomRef.current) {
         if (isBottom) {
            chatBodyRef.current.firstElementChild.lastElementChild.scrollIntoView(
               false
            );
         }
      }
   }, [isBottom, selectedInbox.messages.length]);

   useEffect(() => {
      const input = chatBoxRef.current;
      if (input) input.setSelectionRange(cursor, cursor);
   }, [chatBoxRef, cursor, msg]);

   useEffect(() => {
      if (providerList.length === 0 && !isProviderLoad) {
         axios
            .get(
               '/innote-survey/user/organization/_fetch?userType=PROVIDER&availableWalkIn=1&socketAlive=1'
            )
            .then(({ data }) => {
               setProviderList(data.userList);
               setIsProviderLoad(true);
            })
            .catch(err => {
               setIsProviderLoad(true);
            });
      }
   });

   const setScrollPosition = debounce(() => {
      if (chatBodyRef.current && bottomRef.current) {
         const {
            bottom: parentBottom,
         } = chatBodyRef.current.getBoundingClientRect();
         const {
            bottom: childBottom,
         } = bottomRef.current.getBoundingClientRect();

         if (isBottom && parentBottom <= childBottom - 10) {
            setIsBottom(!isBottom);
         }
      }
   }, 100);

   const submitMessage = () => {
      if (msg) {
         sendMessage({
            recipientId: selectedInbox.inboxId,
            messageText: msg,
            messageType: 'CHAT_MESSAGE_TEXT',
            chatType: 'GROUP',
         });
         setMsg('');
      }
   };

   const clickHandler = e => {
      e.preventDefault();
      e.stopPropagation();
      submitMessage();
   };

   const handleAttachmentUploadSuccess = ({ attachment, external_id }) => {
      attachmentUploadDone();
      sendMessage({
         recipientId: selectedInbox.inboxId,
         messageType: 'ATTACHMENT',
         chatType: 'ONE_TO_ONE',
         attachment,
         external_id,
      });
   };

   const onSubmitCallback = values => {
      const {
         appointmentDate,
         firstName,
         lastName,
         phoneNumber: phoneNo,
         time,
         timezone,
         timesuffix,
         provider: assignedTo,
         scheduleId,
         dob,
         email,
         callType,
         isTestCall,
         reasonForVisit,
         gender,
         practiceId,
      } = values;
      const body = {
         scheduledOn: new Date(
            `${appointmentDate} ${time} ${timesuffix}`
         ).getTime(),
         assignedTo,
         scheduleId,
         dateString: `${format(
            new Date(`${appointmentDate} ${time} ${timesuffix}`),
            'dd LLLL p'
         )} ${timezone || ''}`,
         patientInfo: {
            firstName,
            lastName,
            phoneNo,
            dob,
            email: email || undefined,
            gender,
         },
         type: callType,
         isTestCall,
         reasonForVisit,
         practiceId,
      };

      return axios
         .post(`/innote-survey/telehealth/video/user/schedule`, body)
         .then(({ data }) => {
            if (callType === 'AUDIO') {
               Mixpanel.track('Audio call scheduled', {
                  category: 'audioCall',
               });
            }
            if (isTestCall) {
               Mixpanel.track(
                  `Test call - ${callType === 'AUDIO' ? 'audio' : 'video'}`,
                  {
                     category: 'testCall',
                  }
               );
            }
            if (reasonForVisit) {
               Mixpanel.track('Reason for Visit added while scheduling', {
                  category: 'visitReason',
               });
            }
            Mixpanel.track('Virtual Visit Scheduled', {
               category: 'virtualVisit',
            });
            setModalType('chat');
            sendMessage({
               recipientId: selectedInbox.inboxId,
               messageText: data.smsMsg || data.patientInviteLink,
               messageType: 'CHAT_MESSAGE_TEXT',
               chatType: 'GROUP',
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               createToast({ message });
            }
         );
   };

   const defaultTimeZone = () => {
      const userTimezone = localStorage.getItem('timezone') || '';
      const timezone = timezoneList.findIndex(
         ({ abbr }) => abbr === userTimezone
      );
      return timezone > -1
         ? timezoneList[timezone].abbr
         : Intl.DateTimeFormat().resolvedOptions().timeZone || '';
   };

   const createWalkIn = providerEl => {
      setIsWalkInLoading(true);
      axios
         .post('/innote-survey/telehealth/video/user/schedule', {
            patientInfo: {
               firstName: selectedInbox.firstName,
               lastName: selectedInbox.lastName,
               phoneNo: selectedInbox.phone,
               dob: format(new Date(selectedInbox.dob), 'MM/dd/yyyy'),
               email: selectedInbox.email,
            },
            assignedTo: providerEl.userId,
            timezone: defaultTimeZone(),
            walkIn: true,
         })
         .then(({ data }) => {
            setModalType('chat');
            setIsWalkInLoading(false);
            sendMessage({
               recipientId: selectedInbox.inboxId,
               messageText: data.smsMsg || data.patientInviteLink,
               messageType: 'CHAT_MESSAGE_TEXT',
               chatType: 'GROUP',
            });
            Mixpanel.track('Walk in scheduled from chat', {
               category: 'VFD',
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => {
               setIsWalkInLoading(false);
               createToast({ message });
               Mixpanel.track('Walk in call failed', {
                  category: 'VFD',
               });
            }
         );
   };

   const onKeyPress = e => {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13 && !e.shiftKey) {
         e.preventDefault();
         try {
            if (chatFormRef.current && submitButtonRef.current) {
               chatFormRef.current.requestSubmit(submitButtonRef.current);
            }
         } catch (err) {
            console.log(err);
         }
      }
   };

   if (modalType === 'chat') {
      let content = '';
      if (isChatLoadingError) {
         content = (
            <S.MarginAuto>
               <Error type='error' />
            </S.MarginAuto>
         );
      } else if (selectedInbox.messages.length === 0) {
         content = (
            <S.MarginAuto>
               <Error type='noMessages' />
            </S.MarginAuto>
         );
      } else {
         content = (
            <S.ContainerBody
               innerRef={chatBodyRef}
               onScroll={setScrollPosition}>
               <S.Column>
                  {selectedInbox.messages.map((el, index) => (
                     <MessageContainer
                        msgDetails={el}
                        key={index}
                        index={index}
                        handleMarksAsRead={handleMarksAsRead}
                        sendAttachment={handleAttachmentUploadSuccess}
                        onAttachmentUploadStart={startAttachmentUpload}
                        addAttachmentIdentifier={addAttachmentIdentifier}
                        onAttachmentUploadDone={attachmentUploadDone}
                     />
                  ))}
               </S.Column>
               <S.Bottom innerRef={bottomRef} />
            </S.ContainerBody>
         );
      }

      return isChatLoading || isPatientAttributionLoading ? (
         <S.LoaderWrapper>
            <Loader stroke='#0070DD' height='24px' width='24px' />
         </S.LoaderWrapper>
      ) : (
         <>
            {accessRole === 'PROVIDER' && !attributed && (
               <PatientAttributeModal
                  patientName={`${selectedInbox.firstName} ${selectedInbox.lastName}`}
               />
            )}
            {content}
            <form onSubmit={clickHandler} ref={chatFormRef}>
               <S.FooterWrapper>
                  {accessRole === 'PROVIDER' && (
                     <Attach
                        onChange={addAttachment}
                        disabled={
                           (accessRole === 'PROVIDER' && !attributed) ||
                           isAttachmentUploading
                        }
                     />
                  )}
                  <S.TextBoxOuterWrapper>
                     <S.NewLineInfo>
                        Press <code>Shift + Enter</code> for a new line
                     </S.NewLineInfo>
                     <S.TextBoxWrapper>
                        <S.TextBox
                           innerRef={chatBoxRef}
                           minRows={1}
                           maxRows={3}
                           value={msg}
                           name='msg'
                           disabled={
                              (accessRole === 'PROVIDER' && !attributed) ||
                              isAttachmentUploading
                           }
                           placeholder='Write a message...'
                           onChange={e => {
                              setCursor(e.target.selectionStart);
                              let str = capitalizeSentence(e.target.value);
                              setMsg(str);
                           }}
                           onKeyPress={onKeyPress}
                        />
                     </S.TextBoxWrapper>
                  </S.TextBoxOuterWrapper>
                  <S.ButtonWrapper>
                     <Button
                        innerRef={submitButtonRef}
                        type='submit'
                        dimension='small'
                        disabled={
                           (accessRole === 'PROVIDER' && !attributed) ||
                           isAttachmentUploading
                        }
                        appearance='primary'>
                        <i className='material-icons'>send</i>
                     </Button>
                  </S.ButtonWrapper>
               </S.FooterWrapper>
            </form>
         </>
      );
   }

   if (modalType === 'virtual') {
      return (
         <div>
            <S.CardWrapper shadow='light'>
               <S.Row isCenter={true}>
                  <S.Available>
                     <S.AvatarWrapper
                        text={[selectedInbox.firstName, selectedInbox.lastName]
                           .map((el, i) => (i <= 1 && el ? el[0] : ''))
                           .join('')
                           .trimRight()
                           .toUpperCase()}
                        backgroundColor={getLetterIconColor(
                           selectedInbox.firstName,
                           selectedInbox.lastName
                        )}
                     />
                     <S.AvailableDot isAvailable={selectedInbox.isOnline} />
                  </S.Available>
                  <S.Column>
                     <S.StyledHeading
                        title={returnCamelCasedName(
                           selectedInbox.firstName,
                           selectedInbox.lastName
                        )}
                        ellipsis={true}>
                        {returnCamelCasedName(
                           selectedInbox.firstName,
                           selectedInbox.lastName
                        )}
                     </S.StyledHeading>
                     {selectedInbox.phone && (
                        <S.TextWrapper>
                           {formatPhoneNumber(selectedInbox.phone)}
                        </S.TextWrapper>
                     )}
                  </S.Column>
               </S.Row>
               <S.Row isCenter={true} style={{ marginTop: '16px' }}>
                  {selectedInbox.dob && (
                     <S.Column style={{ marginRight: '48px' }}>
                        <S.TextWrapper style={{ marginBottom: '4px' }}>
                           Date of birth
                        </S.TextWrapper>
                        <S.TextWrapper color='#2F2F2F'>
                           {format(new Date(selectedInbox.dob), 'MM/dd/yy')}
                        </S.TextWrapper>
                     </S.Column>
                  )}
                  {selectedInbox.gender && (
                     <S.Column>
                        <S.TextWrapper style={{ marginBottom: '4px' }}>
                           Gender
                        </S.TextWrapper>
                        <S.TextWrapper color='#2F2F2F'>
                           {selectedInbox.gender}
                        </S.TextWrapper>
                     </S.Column>
                  )}
               </S.Row>
            </S.CardWrapper>
            {isProviderLoad && accessRole === 'STAFF' && (
               <S.CardWrapperOverflow shadow='light'>
                  <S.Row isCenter={false}>
                     <S.IconWrapper name='directions_run' />
                     <S.Column>
                        <Heading as='h5'>
                           Providers available for walk-in
                        </Heading>
                        <S.TextWrapper style={{ marginTop: '4px' }}>
                           Select one of the following doctors for the patient.
                           The call link will be sent to the patient to join.
                        </S.TextWrapper>
                     </S.Column>
                  </S.Row>
                  <S.Row isCenter={false}>
                     <S.Column
                        style={{
                           marginTop: '8px',
                           flex: 1,
                           maxHeight: '200px',
                           overflow: 'auto',
                        }}>
                        {providerList.length > 0 ? (
                           providerList.map((providerEl, index) => (
                              <S.RowWrapper key={index} isCenter={true}>
                                 <S.Available>
                                    <Avatar
                                       text={[
                                          providerEl.name.firstName,
                                          providerEl.name.lastName || '',
                                       ]
                                          .map((el, i) =>
                                             i <= 1 && el ? el[0] : ''
                                          )
                                          .join('')
                                          .trimRight()
                                          .toUpperCase()}
                                       backgroundColor={getLetterIconColor(
                                          providerEl.name.firstName,
                                          providerEl.name.lastName || ''
                                       )}
                                    />
                                 </S.Available>
                                 <Text fontWeight='semiBold' fontSize='large'>
                                    {returnCamelCasedName(
                                       providerEl.name.firstName,
                                       providerEl.name.lastName || ''
                                    )}
                                 </Text>
                                 <S.SwitchWrapper
                                    isBottom={false}
                                    isTop={true}
                                    style={{ alignItems: 'center' }}>
                                    <Button
                                       appearance='transparent'
                                       disabled={isWalkInLoading}
                                       dimension='tiny'
                                       onClick={() => createWalkIn(providerEl)}>
                                       <S.IconWrapper
                                          name='send'
                                          style={{
                                             marginRight: 0,
                                          }}
                                       />
                                    </Button>
                                 </S.SwitchWrapper>
                              </S.RowWrapper>
                           ))
                        ) : (
                           <S.Row>
                              <S.TextWrapper
                                 style={{
                                    marginTop: '12px',
                                    marginLeft: '24px',
                                 }}
                                 fontSize='large'>
                                 No available providers at this time
                              </S.TextWrapper>
                           </S.Row>
                        )}
                     </S.Column>
                  </S.Row>
               </S.CardWrapperOverflow>
            )}
            <S.CardWrapper
               shadow='light'
               onClick={() => {
                  setModalType('schedule');
                  if (accessRole === 'STAFF') {
                     Mixpanel.track('Scheduled for later from chat', {
                        category: 'VFD',
                     });
                  }
               }}
               style={{ cursor: 'pointer' }}>
               <S.Row isCenter={false}>
                  <S.IconWrapper name='event' />
                  <S.Column>
                     <Heading as='h5'>Schedule a visit</Heading>
                     <S.TextWrapper style={{ marginTop: '4px' }}>
                        Schedule the appointment for later.
                     </S.TextWrapper>
                  </S.Column>
                  <S.SwitchWrapper isBottom={false} isTop={true}>
                     <S.IconWrapper
                        name='chevron_right'
                        style={{
                           cursor: 'pointer',
                        }}
                     />
                  </S.SwitchWrapper>
               </S.Row>
            </S.CardWrapper>
         </div>
      );
   }

   if (modalType === 'schedule') {
      return (
         <ScheduleForm
            fromChat={true}
            item={{
               patientInfo: {
                  firstName: selectedInbox.firstName,
                  lastName: selectedInbox.lastName,
                  unFormattedNo: selectedInbox.phone,
                  dob: selectedInbox.dob,
                  email: selectedInbox.email,
                  gender: selectedInbox.gender,
               },
            }}
            onSubmitCallback={onSubmitCallback}
         />
      );
   }

   return '';
};

const mapStateToProps = ({
   Chat: { isPatientAttributionLoading, attributed },
   frontDoorSocket: {
      isChatLoadingError,
      isAttachmentUploading,
      selectedInbox,
      userInfo,
      isChatLoading,
   },
}) => ({
   isChatLoadingError,
   isAttachmentUploading,
   selectedInbox,
   userInfo,
   isPatientAttributionLoading,
   attributed,
   isChatLoading,
});

const mapDispatchToProps = {
   addAttachment,
   startAttachmentUpload,
   addAttachmentIdentifier,
   attachmentUploadDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatBody);
