const timezone = [
   {
      value: 'Eastern Daylight Time',
      abbr: 'EDT',
      offset: -4,
      isdst: true,
      label: '(UTC-04:00) Eastern Time (US & Canada)',
   },
   {
      value: 'Eastern Standard Time',
      abbr: 'EST',
      offset: -5,
      isdst: true,
      label: '(UTC-05:00) Eastern Time (US & Canada)',
   },
   {
      value: 'Central Daylight Time',
      abbr: 'CDT',
      offset: -5,
      isdst: true,
      label: '(UTC-05:00) Central Time (US & Canada)',
   },
   {
      value: 'Central Standard Time',
      abbr: 'CST',
      offset: -6,
      isdst: false,
      label: '(UTC-06:00) Central America',
   },
   {
      value: 'Mountain Daylight Time',
      abbr: 'MDT',
      offset: -6,
      isdst: true,
      label: '(UTC-06:00) Mountain Time (US & Canada)',
   },
   {
      value: 'Pacific Daylight Time',
      abbr: 'PDT',
      offset: -7,
      isdst: true,
      label: '(UTC-07:00) Pacific Time (US & Canada)',
   },
   {
      value: 'Mountain Standard Time',
      abbr: 'MST',
      offset: -7,
      isdst: false,
      label: '(UTC-07:00) Arizona',
   },
   {
      value: 'Alaskan Daylight Time',
      abbr: 'AKDT',
      offset: -8,
      isdst: true,
      label: '(UTC-08:00) Alaska',
   },
   {
      value: 'Pacific Standard Time',
      abbr: 'PST',
      offset: -8,
      isdst: false,
      label: '(UTC-08:00) Pacific Time (US & Canada)',
   },
   {
      value: 'Alaskan Standard Time',
      abbr: 'AKST',
      offset: -9,
      isdst: true,
      label: '(UTC-09:00) Alaska',
   },
   {
      value: 'Hawaiian Standard Time',
      abbr: 'HST',
      offset: -10,
      isdst: false,
      label: '(UTC-10:00) Hawaii',
   },
];

export default timezone;
