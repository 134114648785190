import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import * as S from 'components/Messages/style';
import Error from 'components/Chat/Error';
import LoadingState from 'components/VirtualVisit/TabLoadingState';
import { changeTab } from 'store/frontDoorSocket/action';
import CardWrapper from 'components/FrontDoor/Card';
import returnCamelCasedName from 'helpers/nameTransformer';
import { get } from 'lodash-es';
import MyInboxCards from './MyInboxCards';
import CardPlaceholder from 'packages/cardPlaceholder/placeholder';

const filterList = (searchKey, inboxList, callback) => {
   const filteredList = inboxList.filter(item => {
      return returnCamelCasedName(
         get(item, 'users[0].first_name', ''),
         get(item, 'users[0].last_name', '')
      )
         .toLowerCase()
         .includes(searchKey.toLowerCase());
   });
   callback(filteredList);
};

const debouncedFilterList = debounce(filterList, 300);

const MyInBox = ({
   myInboxList,
   getMyInbox,
   isSocketError,
   isMyTabLoading,
   changeTab,
   advanceClick,
   openChat,
   search,
   isLoadMoreMyInbox,
   endReachedMyInbox,
}) => {
   const [firstLoad, setFirstLoad] = useState(true);
   const [chats, setChats] = useState([...myInboxList]);

   useEffect(() => {
      if (firstLoad) {
         changeTab(2);
         setFirstLoad(false);
      }
   }, [firstLoad, changeTab]);

   useEffect(() => {
      if (!Boolean(search)) {
         debouncedFilterList.cancel();
         setChats(myInboxList);
      } else {
         debouncedFilterList(search, myInboxList, setChats);
      }
   }, [search, myInboxList]);

   if (chats.length === 0 && isSocketError) {
      return (
         <S.MarginAuto>
            <Error type='error' />
         </S.MarginAuto>
      );
   }

   return isMyTabLoading ? (
      <LoadingState marginRight={true} />
   ) : (
      <div style={{ maxHeight: 'calc(100vh - 130px)' }}>
         {myInboxList.length > 0 ? (
            chats.length > 0 ? (
               <MyInboxCards
                  isLoadMoreMyInbox={isLoadMoreMyInbox}
                  endReachedMyInbox={endReachedMyInbox}
                  getMyInbox={getMyInbox}
                  openChat={openChat}
                  advanceClick={advanceClick}
                  chats={chats}
               />
            ) : (
               <S.MarginAuto>
                  <Error
                     type='noMatchInbox'
                     buttonText='Start new chat'
                     buttonClick={() => advanceClick()}
                  />
               </S.MarginAuto>
            )
         ) : (
            <S.MarginAuto>
               <Error
                  type='noInbox'
                  buttonText='Start new chat'
                  buttonClick={() => advanceClick()}
               />
            </S.MarginAuto>
         )}
         {isLoadMoreMyInbox ? (
            <CardPlaceholder
               smallList
               style={{
                  marginRight: '12px',
                  marginTop: '12px',
                  marginBottom: '12px',
               }}
            />
         ) : (
            endReachedMyInbox &&
            myInboxList.length > 50 && (
               <CardWrapper err='No more records to show' />
            )
         )}
      </div>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      myInboxList,
      isSocketError,
      isMyTabLoading,
      isLoadMoreMyInbox,
      endReachedMyInbox,
   },
}) => ({
   myInboxList,
   isSocketError,
   isMyTabLoading,
   isLoadMoreMyInbox,
   endReachedMyInbox,
});

const mapDispatchToProps = { changeTab };

export default connect(mapStateToProps, mapDispatchToProps)(MyInBox);
