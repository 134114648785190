import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@datashop/button';
import Radio from '@datashop/radio';
import Icon from '@datashop/icon';
import Heading from '@datashop/heading';
import Text from '@datashop/text';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   background-color: #fff;
   z-index: 2;
`;

const InCompleteWrapper = styled(Wrapper)`
   justify-content: center;
   align-items: center;
   margin-top: 40px;
   padding: 0 24px;
`;

const HeaderWrapper = styled.div`
   display: flex;
   border-bottom: 1px solid #e5e5e5;
   padding: 16px 16px 12px;
   justify-content: space-between;
`;

const HeaderTextWrapper = styled.div`
   display: flex;
   align-items: flex-start;
`;

const HeaderText = styled.div`
   color: #151414;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
   margin-left: 16px;
`;

const ContentWrapper = styled.div`
   padding: 16px 16px 16px 36px;
`;

const TextWrapper = styled.div`
   color: #868686;
   font-weight: 600;
   line-height: 20px;
`;

const HeaderIcon = styled.i`
   font-size: 16px;
   margin-top: 4px;
`;

const ButtonWrapper = styled.div`
   margin-top: 24px;
   display: flex;
   justify-content: space-between;

   ${Button} {
      margin-right: 12px;
      :last-child {
         margin-right: 0;
      }
   }
`;

const RadioWrapper = styled.div`
   margin-top: 20px;

   > div:first-child {
      margin-bottom: 12px;
   }
`;

const options = [
   {
      label: 'Waiting Queue',
      value: 'Waiting Queue',
      id: 1,
   },
   {
      label: 'Ready for Provider',
      value: 'Ready for Provider',
      id: 2,
   },
];

const providerOption = [
   {
      label: 'In progress',
      value: 'In progress',
      id: 1,
   },
   {
      label: 'Post visit queue',
      value: 'Post visit queue',
      id: 2,
   },
];

const MedianEndCallModal = ({
   changeStatusToReady,
   canChangeStatus,
   close,
   userType,
   endCallHandler,
   leaveVisit,
   visitId,
   incompleteHandler,
   skipHandler,
   askForIncomplete,
}) => {
   const [checked, setChecked] = useState(1);
   const [isStatusChanged, setStatusChanged] = useState(false);

   useEffect(() => {
      if (
         localStorage.getItem('statusChanged') &&
         localStorage.getItem('statusChanged') === visitId
      )
         setStatusChanged(true);
   }, [visitId]);

   function changeStatus(event) {
      if (checked !== event.target.id) {
         setChecked(parseInt(event.target.id));
         changeStatusToReady();
         localStorage.setItem('statusChanged', visitId);
      }
   }

   function changeProviderStatus(event) {
      if (checked !== event.target.id) {
         setChecked(parseInt(event.target.id));
         localStorage.setItem('statusChanged', visitId);
      }
   }

   return askForIncomplete ? (
      <InCompleteWrapper>
         <Icon
            name='error_outline'
            style={{ color: '#D93737', fontSize: '40px', marginBottom: '24px' }}
         />
         <Heading as='h5' withMargin>
            Incomplete call?
         </Heading>
         <Text style={{ marginBottom: '40px', color: '#919191' }}>
            We detected an abrupt ending to your call. Would you like to mark it
            as incomplete?
         </Text>
         <Button
            appearance='primary'
            expanded
            onClick={incompleteHandler}
            style={{ marginBottom: '16px' }}>
            Mark incomplete
         </Button>
         <Button
            appearance='transparent'
            expanded
            onClick={skipHandler}
            style={{ marginBottom: '24px' }}>
            Skip
         </Button>
      </InCompleteWrapper>
   ) : (
      <Wrapper>
         <HeaderWrapper>
            <HeaderTextWrapper>
               <HeaderIcon className='material-icons'>warning</HeaderIcon>
               <HeaderText>
                  {`${userType === 'VISITOR' ? 'Leave' : 'End'}`} this visit
               </HeaderText>
            </HeaderTextWrapper>
            <HeaderIcon
               className='material-icons'
               style={{ cursor: 'pointer' }}
               onClick={() => close(false)}>
               close
            </HeaderIcon>
         </HeaderWrapper>
         <ContentWrapper>
            <TextWrapper>
               {userType === 'STAFF' && !isStatusChanged && canChangeStatus
                  ? 'Please update the status before leaving this visit'
                  : `Are you sure you want to ${
                       userType === 'VISITOR' ? 'leave' : 'end'
                    } this visit?`}
            </TextWrapper>
            {userType === 'STAFF' && !isStatusChanged && canChangeStatus && (
               <RadioWrapper>
                  {options.map(item => (
                     <Radio
                        key={item.id}
                        disabled={item.id === 1 && checked === 2}
                        value={item.value}
                        label={item.label}
                        id={item.id}
                        name='status'
                        checked={item.id === checked}
                        onChange={changeStatus}
                     />
                  ))}
               </RadioWrapper>
            )}

            {userType === 'PROVIDER' && !isStatusChanged && canChangeStatus && (
               <RadioWrapper>
                  {providerOption.map(item => (
                     <Radio
                        key={item.id}
                        disabled={item.id === 1 && checked === 2}
                        value={item.value}
                        label={item.label}
                        id={item.id}
                        name='status'
                        checked={item.id === checked}
                        onChange={changeProviderStatus}
                     />
                  ))}
               </RadioWrapper>
            )}

            <ButtonWrapper>
               {userType !== 'VISITOR' && (
                  <Button
                     dimension='small'
                     appearance='alert'
                     onClick={endCallHandler}>
                     End visit for all
                  </Button>
               )}
               <div style={{ display: 'flex' }}>
                  {userType !== 'PATIENT' && (
                     <Button
                        dimension='small'
                        appearance='secondary'
                        onClick={() => leaveVisit(checked === 2)}>
                        Leave visit
                     </Button>
                  )}
                  <Button
                     dimension='small'
                     appearance='secondary'
                     onClick={() => close(checked === 2)}>
                     Close
                  </Button>
               </div>
            </ButtonWrapper>
         </ContentWrapper>
      </Wrapper>
   );
};

export default MedianEndCallModal;
