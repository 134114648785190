import axios from 'axios';
import { reduce, replace, size } from 'lodash';
import { objectToQueryString } from '../utils';
// import { reduce, replace, size } from 'lodash';

// import { objectToQueryString } from '../utils';

const instance = axios;
const Axios = instance.create();

/**
 * Create URL from route parameters
 * @param  {string} URL API URL
 * @param  {Object} options Route parameters
 * @returns {string} URL with interpolated route parameters
 */
const interpolateURL = (URL, options) =>
   reduce(
      options,
      (returnURL, value, key) => replace(returnURL, `:${key}`, value),
      URL
   );

/**
 * Create URL from query string
 * @param  {string} URL API URL
 * @param  {Object} queryObject Query string parameters
 * @returns {string} URL with query string
 */
// const withQueryString = (URL, queryObject) => {
//    // Convert query string object to string
//    let queryString = objectToQueryString(queryObject);

//    // Prepend question mark (?)
//    if (size(queryString) > 0) {
//       queryString = `?${queryString}`;
//    }

//    return `${URL}${queryString}`;
// };

/**
 * Handle HTTP request network/other error(s)
 * @param {Object} error Error
 */
const handleError = error => {
   // Network error
   // Custom error code series 9XX
   if (!error.response) {
      return Promise.reject({
         response: {
            data: {
               error: {
                  code: 900,
                  message:
                     'Network error. Please check your internet connection.',
               },
            },
         },
      });
   }

   // Handle string in response body
   // NOTE(Krishna Kumar): This check can be removed later if error is standardised across all APIs.
   if (typeof error.response.data === 'string') {
      const {
         response: { status, statusText },
      } = error;
      return Promise.reject({
         response: {
            data: {
               error: {
                  code: status,
                  message: statusText,
               },
            },
         },
      });
   }

   return Promise.reject(error);
};

/**
 * Create URL from query string
 * @param  {string} URL API URL
 * @param  {Object} queryObject Query string parameters
 * @returns {string} URL with query string
 */
export const withQueryString = (URL, queryObject) => {
   // Convert query string object to string
   let queryString = objectToQueryString(queryObject);

   // Prepend question mark (?)
   if (size(queryString) > 0) {
      queryString = `?${queryString}`;
   }

   return `${URL}${queryString}`;
};

/**
 * HTTP service to make API calls.
 * It is a wrapper on Axios.
 */
class httpService {
   /**
    * GET method
    * @param  {string} url API URL
    * @param  {Object} [options={}] HTTP options
    * @returns {Promise}
    */
   static get(url, options = {}) {
      return Axios.get(url, {
         ...options,
      }).catch(handleError);
   }

   /**
    * POST method
    * @param  {string} url API URL
    * @param  {Object} data Request body
    * @param  {Object} [options={}] HTTP options
    * @returns {Promise}
    */
   static post(url, data, options = {}) {
      return Axios.post(url, data, {
         ...options,
      }).catch(handleError);
   }

   /**
    * PUT method
    * @param  {string} url API URL
    * @param  {Object} data Request body
    * @param  {Object} [options={}] HTTP options
    * @returns {Promise}
    */
   static put(url, data, options = {}) {
      return Axios.put(url, data, {
         ...options,
      }).catch(handleError);
   }

   /**
    * PATCH method
    * @param  {string} url API URL
    * @param  {Object} data Request body
    * @param  {Object} [options={}] HTTP options
    * @returns {Promise}
    */
   static patch(url, data, options = {}) {
      return Axios.patch(url, data, {
         ...options,
      }).catch(handleError);
   }

   /**
    * DELETE method
    * @param  {string} url API URL
    * @param  {Object} [options={}] HTTP options
    * @returns {Promise}
    */
   static delete(url, options = {}) {
      return Axios.delete(url, {
         ...options,
      }).catch(handleError);
   }
}

export default httpService;
export { interpolateURL, handleError, instance as Axios };
