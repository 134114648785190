import { TAB_RESET, TAB_SET } from './constants';

// Initial state
const initialState = {
   tab: null,
};

// Reducer
function virtualVisit(state = initialState, action) {
   switch (action.type) {
      case TAB_RESET:
         return {
            ...initialState,
         };
      case TAB_SET:
         return {
            tab: action.tab,
         };
      default:
         return state;
   }
}

export default virtualVisit;
