import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeTab } from 'store/frontDoorSocket/action';
import Tab from 'components/VirtualVisit/Tab';
import UnassignedTab from './UnassignedTab';
import MyPatientsTab from './MyPatientsTab';
import { get } from 'lodash-es';
import { Redirect } from 'react-router-dom';
import { Mixpanel } from 'helpers/mixpanelHelper';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;

const TabBodyWrapper = styled.div`
   display: flex;
   padding: 12px 0 0 12px;
   flex-direction: column;
`;

const FrontDoor = props => {
   const {
      activeTabId,
      changeTab,
      isChatOpen,
      tabList,
      isAvailableForAssistanceEnable,
      onLineInbox,
      offLineInbox,
      getUnassignedInbox,
      assignToMe,
      getMyInbox,
      retrieveInboxMessages,
      selectedInbox,
   } = props;
   const [tabs, setTabs] = useState(tabList);

   useEffect(() => {
      const tabCount = [...tabList];
      if (tabCount && tabCount[0]) {
         tabCount[0].count = isAvailableForAssistanceEnable
            ? onLineInbox.length + offLineInbox.length
            : offLineInbox.length;
         setTabs(tabCount);
      }
   }, [
      isAvailableForAssistanceEnable,
      offLineInbox.length,
      onLineInbox.length,
      tabList,
   ]);

   const handleMixPanelEvent = message => {
      Mixpanel.track(message, { category: 'VFD' });
   };

   const handleTabChange = id => {
      changeTab(id);
      handleMixPanelEvent(`${id === 1 ? 'Unassigned' : 'My'} patients chat`);
   };

   if (isChatOpen && get(selectedInbox, 'inboxId', '')) {
      return (
         <Redirect
            to={{
               pathname: `/messages/${get(selectedInbox, 'inboxId', '')}`,
            }}
         />
      );
   }

   return (
      <Wrapper>
         <div>
            <Tab
               activeTabId={activeTabId}
               setActiveTab={handleTabChange}
               list={tabs}
            />
            <TabBodyWrapper>
               {activeTabId === 1 && (
                  <UnassignedTab
                     getUnassignedInbox={getUnassignedInbox}
                     assignToMe={data => {
                        assignToMe(data);
                        handleMixPanelEvent('Assign to me chat');
                     }}
                  />
               )}
               {activeTabId === 2 && (
                  <MyPatientsTab
                     getMyInbox={getMyInbox}
                     retrieveInboxMessages={retrieveInboxMessages}
                  />
               )}
            </TabBodyWrapper>
         </div>
      </Wrapper>
   );
};

const mapStateToProps = ({
   frontDoorSocket: {
      activeTabId,
      tabList,
      isChatOpen,
      selectedInbox,
      isAvailableForAssistanceEnable,
      onLineInbox,
      offLineInbox,
   },
}) => ({
   activeTabId,
   tabList,
   isChatOpen,
   selectedInbox,
   isAvailableForAssistanceEnable,
   onLineInbox,
   offLineInbox,
});

const mapDispatchToProps = {
   changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontDoor);
