import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@krishnaxv/react-surface';

import { getArticleList } from 'store/helpArticles/articles/action';
import { getSectionList } from 'store/helpArticles/sections/action';
import { searchArticle } from 'store/helpArticles/search/action';

import HelpSection from './HelpSections';
import HelpArticles from './HelpArticles';
import ShowArticle from './ShowArticle';

import * as S from './style';

const HelpArticleContainer = ({
   getSectionList,
   getArticleList,
   searchArticle,
   sections,
   articles,
   search,
   close,
}) => {
   const [currentSectionName, setCurrentSectionName] = useState('');
   const [showSection, setShowSection] = useState(true);
   const [showArticles, setShowArticles] = useState(false);
   const [openArticle, setOpenArticle] = useState(false);
   const [showSearchResult, setShowSearchResult] = useState(false);
   const [searchText, setSearchText] = useState('');
   const [showClearButton, setShowClearButton] = useState(false);
   const [isFirstLoad, setIsFirstLoad] = useState(true);
   const [currentArticle, setCurrentArticle] = useState({});
   const [oldSearch, setOldSearch] = useState('');

   useEffect(() => {
      if (isFirstLoad) {
         setIsFirstLoad(false);
         getSectionList();
      }
   }, [isFirstLoad, getSectionList]);

   useEffect(() => {
      setShowSearchResult(!!searchText);
      setShowClearButton(!!searchText);
      if (searchText && searchText !== oldSearch && !search.isLoading) {
         setOldSearch(searchText);
         searchArticle(searchText);
      }
   }, [searchText, searchArticle, search.isLoading, oldSearch]);

   const getShowArticles = (sectionID, sectionName) => {
      setShowArticles(true);
      setShowSection(false);
      setCurrentSectionName(sectionName);
      getArticleList(sectionID);
   };

   const getOpenArticle = article => {
      setOpenArticle(true);
      setShowArticles(false);
      setShowSection(false);
      setCurrentArticle(article);
   };

   const handleResetClick = () => {
      setSearchText('');
      setShowSearchResult(false);
      setShowClearButton(false);
   };

   const goBackToArticleList = () => {
      setOpenArticle(false);
      setShowArticles(!showSearchResult);
      setShowSection(showSearchResult);
   };

   const goBackToSectionList = () => {
      setShowSection(true);
      setOpenArticle(false);
      setShowArticles(false);
   };

   const renderSearch = () => {
      return (
         <S.SearchWrapper>
            <form onSubmit={event => event.preventDefault()}>
               <S.TextFieldWrapper>
                  <S.TextField
                     data-test='search-select-article'
                     type='text'
                     placeholder='Search for articles'
                     onChange={event => {
                        setSearchText(event.target.value);
                     }}
                     value={searchText}
                     icon='search'
                     clearButton={showClearButton}
                     onClearClick={handleResetClick}
                  />
               </S.TextFieldWrapper>
            </form>
         </S.SearchWrapper>
      );
   };

   return (
      <S.ModalContainer>
         <Modal style={S.modalStyle}>
            {() => (
               <React.Fragment>
                  {showSection && (
                     <HelpSection
                        sections={sections}
                        search={search}
                        showArticles={getShowArticles}
                        renderSearch={renderSearch}
                        openArticle={getOpenArticle}
                        close={close}
                        showSearchResult={showSearchResult}
                     />
                  )}
                  {showArticles && (
                     <HelpArticles
                        articles={articles}
                        back={goBackToSectionList}
                        openArticle={getOpenArticle}
                        close={close}
                        sectionName={currentSectionName}
                     />
                  )}
                  {openArticle && (
                     <ShowArticle
                        article={currentArticle}
                        back={goBackToArticleList}
                        close={close}
                     />
                  )}
               </React.Fragment>
            )}
         </Modal>
      </S.ModalContainer>
   );
};

const mapStateToProps = ({ helpArticles: { articles, search, sections } }) => ({
   articles,
   search,
   sections,
});

const mapDispatchToProps = { getArticleList, getSectionList, searchArticle };

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(HelpArticleContainer);
