import React from 'react';
import ShowAxiosError from 'components/Main/AxiosToast';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import datashopTheme from '@datashop/theme';

let newElement = document.createElement('div');
newElement.setAttribute('id', 'errorToast');

let timeout;

const clearToast = () => {
   clearTimeout(timeout);
   const elem = document.getElementById('errorToast');
   if (elem) elem.parentNode.removeChild(elem);
};

const createToast = ({
   title = '',
   time = 7,
   appearance = 'alert',
   message = null,
   button = {},
}) => {
   if (timeout) clearToast();

   ReactDOM.render(
      <ThemeProvider theme={datashopTheme}>
         <ShowAxiosError
            close={clearToast}
            title={title}
            appearance={appearance}
            message={message}
            button={button}
         />
      </ThemeProvider>,
      document.getElementById('root').appendChild(newElement)
   );
   timeout = setTimeout(() => {
      clearToast();
   }, time * 1000);
};

export default createToast;
export { clearToast };
