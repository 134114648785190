import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Input from '@datashop/input';

const SearchWrapper = styled.div`
   padding: 0 12px 12px;

   i {
      color: #868686;
   }
`;

const SearchResult = styled.p`
   padding: 12px;
   color: #2f2f2f;
   line-height: 20px;
`;

class Search extends Component {
   timeout;
   state = {
      searchText: '',
   };

   componentDidMount() {
      this.props.getChildContext(this);
   }

   debounce = value => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
         this.props.searchAssessments(
            `${value ? `&search-term=${value}` : ''}`
         );
      }, 500);
   };

   onChange = event => {
      const {
         target: { value },
      } = event;
      this.setState(
         {
            searchText: value,
         },
         () => {
            this.debounce(this.state.searchText);
         }
      );
   };

   render() {
      const { searching, length, isLoading } = this.props;
      return (
         <Fragment>
            <SearchWrapper>
               <Input
                  value={this.state.searchText}
                  onChange={this.onChange}
                  placeholder='Search by name or number'
                  icon='search'
               />
            </SearchWrapper>
            {searching && length > 0 && (
               <SearchResult>
                  {isLoading
                     ? ''
                     : `Showing ${length > 1 ? length : ''} result${
                          length > 1 ? 's' : ''
                       } for "${this.state.searchText}"`}
               </SearchResult>
            )}
         </Fragment>
      );
   }
}

export default Search;
