import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dropdown from '@datashop/dropdown';

const SelectWrapper = styled.div`
   ${({ disabled }) =>
      disabled &&
      `
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    `};
   li {
      :hover {
         background-color: #f4f4f4;
      }
   }

   > div {
      width: 80px;
      > div:last-child {
         max-height: 108px;
      }
   }
`;

const options = [
   {
      value: '1',
      label: 'AM',
   },
   {
      value: '2',
      label: 'PM',
   },
];

function TimeDropdown({ disabled, onChange }) {
   const [selectedOption, changeSelectedOption] = useState(options[0]);

   useEffect(() => {
      onChange(selectedOption.label);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <SelectWrapper
         disabled={disabled}
         onClick={event => event.stopPropagation()}>
         <Dropdown
            placeholder=''
            options={options}
            search
            selected={[selectedOption.value]}
            onChange={(_, allOption) => {
               changeSelectedOption(allOption[0]);
               onChange(allOption[0].label);
            }}
         />
      </SelectWrapper>
   );
}

export default TimeDropdown;
