import React from 'react';
import styled from 'styled-components';
import { LabelWrapper, Wrapper } from './PoorEmoji';

const SvgWrapper = styled.svg`
   &:hover #Big-Smile {
      fill: #fff;
   }
   &:hover #eyes {
      fill: #d93737;
   }
   &:hover {
      circle {
         fill: #ffc208;
      }
   }
`;

const AmazingEmoji = ({ selected, showLabel }) => (
   <Wrapper>
      <SvgWrapper width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fill-rule='evenodd'>
            <circle
               fill={selected ? '#ffc208' : '#E5E5E5'}
               fill-rule='nonzero'
               cx='16'
               cy='16.8'
               r='15.2'
            />
            <path
               d='M6.197 19.853c.768 3.991 4.388 7.258 9.8 7.258 5.412 0 8.876-3.274 9.8-7.258.708-3.055-2.098-2.005-4.572-1.48-1.798.382-3.83.692-5.228.692-1.36 0-3.41-.31-5.223-.692-2.473-.52-5.165-1.575-4.577 1.48z'
               fill={selected ? '#d93737' : '#595959'}
               fill-rule='nonzero'
            />
            <path
               d='M6.976 3.538C9.39-.918 20.621 1.03 10.953 14.42-4.74 10.509 1.87 1.655 6.976 3.538zM24.993 3.527c4.712-1.862 12.064 6.85-3.95 10.892-9.506-13.083 1.25-15.617 3.95-10.892z'
               fill={selected ? '#d93737' : '#595959'}
               id='eyes'
            />
            <path
               d='M7.199 19.743c0 1.53 6.442 2.786 8.887 2.857 2.446.071 8.713-1.328 8.713-2.857 0-.885-2.413-.04-4.133.309-1.585.321-3.355.582-4.58.582-1.19 0-2.982-.257-4.574-.582-1.71-.35-4.313-1.19-4.313-.309z'
               fill={selected ? '#fff' : '#595959'}
               fill-rule='nonzero'
               id='Big-Smile'
            />
         </g>
      </SvgWrapper>
      {showLabel && <LabelWrapper isSelected={selected}>Amazing</LabelWrapper>}
   </Wrapper>
);

export default AmazingEmoji;
