import React, { useState } from 'react';
import Modal from '@datashop/modal';
import Button from '@datashop/button';
import { Loader } from 'packages/loader';
import { Mixpanel } from 'helpers/mixpanelHelper';
import axios from 'helpers/axios';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserInfo } from 'store/frontDoorSocket/action';
import { logout } from 'store/logout/action';
import * as S from './style';

const CardLogOut = ({ setUserInfo, logout }) => {
   const [showLogoutDialog, setShowLogoutDialog] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [redirectToSignUp, setRedirectToSignUp] = useState(false);

   const handleLogout = () => {
      setIsLoading(true);
      axios.delete('/innote-survey/user/signout').finally(() => {
         setIsLoading(false);
         localStorage.clear();
         localStorage.setItem('isOnboarded', true);
         setUserInfo();
         setRedirectToSignUp(true);
         Mixpanel.track('Logged out', {
            category: 'login',
         });
         logout();
      });
   };

   if (redirectToSignUp) {
      return (
         <Redirect
            to={{
               pathname: '/signup',
            }}
         />
      );
   }

   return (
      <>
         <S.CardWrapper shadow='light'>
            <S.TextWrapperLogout
               onClick={() => setShowLogoutDialog(!showLogoutDialog)}>
               <S.HeaderIcon className='material-icons'>input</S.HeaderIcon>
               <S.LogoutTextWrapper>
                  <S.LogoutText>Logout</S.LogoutText>
                  <S.IconWrapperLogout name='keyboard_arrow_right' />
               </S.LogoutTextWrapper>
            </S.TextWrapperLogout>
         </S.CardWrapper>
         {showLogoutDialog && (
            <Modal
               hideScroll
               style={{ width: 320 }}
               dimension='regular'
               onClose={() => setShowLogoutDialog(!showLogoutDialog)}
               showCloseButton={false}
               backdrop={false}>
               <S.DialogWrapper>
                  <S.DialogHeader>Are you sure?</S.DialogHeader>
                  <S.DialogButtonWrapper>
                     <Button
                        onClick={() => setShowLogoutDialog(!showLogoutDialog)}
                        disabled={isLoading}>
                        Cancel
                     </Button>
                     <Button
                        onClick={handleLogout}
                        appearance='alert'
                        disabled={isLoading}>
                        {isLoading ? <Loader stroke='#fff' /> : 'Logout'}
                     </Button>
                  </S.DialogButtonWrapper>
               </S.DialogWrapper>
            </Modal>
         )}
      </>
   );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
   setUserInfo,
   logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardLogOut);
