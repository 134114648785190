import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import StyledCard from '@datashop/card';
import LoadingState from './TabLoadingState';
import axios from 'helpers/axios';
import returnCamelCasedName from 'helpers/nameTransformer';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';
import ItemSchedule from './ItemSchedule';

import CardPlaceholder from 'packages/cardPlaceholder/placeholder';

const Wrapper = styled.div`
   padding: 8px 12px;
   height: ${window.innerHeight - 185}px;
   overflow: auto;
`;

const DataWrapper = styled.div``;

const Card = styled(StyledCard)`
   padding: 12px;
   background-color: #fff;
   margin-bottom: 8px;
`;

const Scheduled = ({
   toggleModal,
   socketData,
   toggleInviteModal,
   shouldRefreshScheduled,
}) => {
   const [loading, toggleLoading] = useState(true);
   const [data, setData] = useState([]);
   const [isLoadingMore, setLoadingMore] = useState(true);
   const [endReached, setEndReached] = useState(false);
   const [offset, setOffset] = useState(0);

   const targetElement = useRef(null);

   const fetchData = (shouldAppend = false) => {
      if (shouldAppend) setLoadingMore(true);
      axios
         .get(
            `/innote-survey/telehealth/video/user/schedule?status=SCHEDULED&offset=${offset}`
         )
         .then(({ data: { schedule } }) => {
            const list = shouldAppend ? [...data, ...schedule] : schedule;
            setData(list);
            if (schedule.length >= 20) {
               setOffset(offset + 20);
            } else {
               setEndReached(true);
            }
         })
         .catch(() => {
            setEndReached(true);
         })
         .finally(() => {
            toggleLoading(false);
            setLoadingMore(false);
         });
   };

   useEffect(() => {
      if (shouldRefreshScheduled) fetchData();
   }, [shouldRefreshScheduled]); //eslint-disable-line react-hooks/exhaustive-deps

   const callBack = entries => {
      entries.forEach(item => {
         item.isIntersecting && fetchData(true);
      });
   };

   useEffect(() => {
      if (!isLoadingMore && !endReached) {
         const observeElement = targetElement.current.lastElementChild;
         const options = {
            root: null,
            threshold: 1,
         };
         let observer = new IntersectionObserver(callBack, options);
         observer.observe(observeElement);
         return () => {
            observer.unobserve(observeElement);
         };
      }
   }, [isLoadingMore, endReached]); // eslint-disable-line react-hooks/exhaustive-deps

   // Component mount
   useEffect(() => {
      Mixpanel.track('Scheduled tab clicked', { category: 'virtualVisit' });
      fetchData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (socketData === 'REFRESH_SCHEDULE') fetchData();
   }, [socketData]); // eslint-disable-line react-hooks/exhaustive-deps

   const cancelSchedule = (scheduleId, date) => {
      createToast({
         message: 'Cancelling schedule',
         appearance: 'default',
      });
      const dateString = `${format(new Date(date), 'dd LLLL p')} ${
         localStorage.getItem('timezone') || ''
      }`;
      axios
         .delete(`/innote-survey/telehealth/video/user/schedule`, {
            data: { scheduleId, dateString },
         })
         .then(() => {
            Mixpanel.track('Virtual visit cancelled', {
               category: 'virtualVisit',
            });
            fetchData();
            createToast({
               message: 'Visit cancelled successfully',
               appearance: 'success',
            });
         })
         .catch(
            ({
               response: {
                  data: { error: { message = 'Something went wrong' } = {} },
               },
            }) => createToast({ message })
         );
   };

   const reSendMsg = item => {
      axios
         .post(
            `/innote-survey/telehealth/video/schedule/${item.scheduleId}/resend_schedule_sms`
         )
         .then(() => {
            fetchData();
            createToast({
               message: `Message has been sent`,
               appearance: 'default',
               time: 5,
            });
         })
         .catch(error => {
            const {
               patientInfo: { firstName, lastName },
            } = item;
            createToast({
               title: 'Message failed',
               message: `Visit message to ${returnCamelCasedName(
                  firstName,
                  lastName
               )} has failed. Try to resend`,
               appearance: 'alert',
               time: 5,
               button: {
                  text: 'Resend',
                  function: () => reSendMsg(item),
               },
            });
         });
   };

   return (
      <Wrapper>
         {loading ? (
            <LoadingState />
         ) : data.length > 0 ? (
            <Fragment>
               <DataWrapper innerRef={targetElement}>
                  {data.map((item, index) => (
                     <ItemSchedule
                        item={item}
                        key={index}
                        toggleInviteModal={toggleInviteModal}
                        cancelSchedule={cancelSchedule}
                        toggleModal={toggleModal}
                        reSendMsg={reSendMsg}
                     />
                  ))}
               </DataWrapper>
            </Fragment>
         ) : (
            <Card shadow='light'>No record to show</Card>
         )}
         <Fragment>
            {isLoadingMore ? (
               <CardPlaceholder smallList />
            ) : (
               endReached &&
               data.length > 20 && (
                  <Card shadow='light'>No more records to show</Card>
               )
            )}
         </Fragment>
      </Wrapper>
   );
};

export default Scheduled;
