import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash-es';
import Heading from '@datashop/heading';
import returnCamelCasedName from 'helpers/nameTransformer';
import formatPhoneNumber from 'helpers/formatPhone';
import { getLetterIconColor } from 'helpers/badgeColor';
import Text from '@datashop/text';
import * as S from './style';

const CardUserInfo = ({ userInfo }) => {
   return (
      !isEmpty(userInfo) && (
         <S.CardWrapper shadow='light'>
            <S.Row isCenter={false}>
               <S.Available>
                  <S.AvatarWrapper
                     text={[userInfo.name.firstName, userInfo.name.lastName]
                        .map((el, i) => (i <= 1 && el ? el[0] : ''))
                        .join('')
                        .trimRight()
                        .toUpperCase()}
                     backgroundColor={getLetterIconColor(
                        userInfo.name.firstName,
                        userInfo.name.lastName
                     )}
                  />
               </S.Available>
               <S.Column>
                  <Heading as='h3'>
                     {returnCamelCasedName(
                        userInfo.name.firstName,
                        userInfo.name.lastName
                     )}
                  </Heading>
                  {userInfo.email && (
                     <S.TextWrapper>{userInfo.email}</S.TextWrapper>
                  )}
                  {userInfo.phoneNumbers && userInfo.phoneNumbers[0] && (
                     <S.TextWrapper>
                        {formatPhoneNumber(userInfo.phoneNumbers[0])}
                     </S.TextWrapper>
                  )}
               </S.Column>
            </S.Row>
            {userInfo.practices && userInfo.practices.length > 0 && (
               <>
                  <S.Row isCenter={false} style={{ marginTop: '12px' }}>
                     <S.TextPartition fontWeight='bold'>
                        Practice
                     </S.TextPartition>
                     <S.Column>
                        {userInfo.practices.map((practice, index) => (
                           <Text
                              style={{ color: '#2f2f2f' }}
                              fontWeight='semiBold'
                              key={index}>
                              {practice.name}
                           </Text>
                        ))}
                     </S.Column>
                  </S.Row>
               </>
            )}
            {userInfo.organization && userInfo.organization.organization_name && (
               <S.Row isCenter={false} style={{ marginTop: '12px' }}>
                  <S.TextPartition fontWeight='bold'>
                     Organization
                  </S.TextPartition>
                  <Text style={{ color: '#2f2f2f' }} fontWeight='semiBold'>
                     {userInfo.organization.organization_name}
                  </Text>
               </S.Row>
            )}
         </S.CardWrapper>
      )
   );
};

const mapStateToProps = ({ frontDoorSocket: { userInfo } }) => ({
   userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CardUserInfo);
