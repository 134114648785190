// Request article search constant
const REQUEST_ARTICLE_SEARCH = 'REQUEST_ARTICLE_SEARCH';
// Article search success constant
const ARTICLE_SEARCH_SUCCESS = 'ARTICLE_SEARCH_SUCCESS';
// Article search failure constant
const ARTICLE_SEARCH_FAILURE = 'ARTICLE_SEARCH_FAILURE';

export {
   REQUEST_ARTICLE_SEARCH,
   ARTICLE_SEARCH_SUCCESS,
   ARTICLE_SEARCH_FAILURE,
};
