import { LOGOUT } from './constants';

export const logout = () => {
   return dispatch => {
      dispatch(
         (() => {
            return {
               type: LOGOUT,
            };
         })()
      );
   };
};
