import React from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';
import { Loader } from 'packages/loader';

const Wrapper = styled.div`
   position: fixed;
   bottom: 0;
   padding: 8px;
   background-color: #ffffff;
   box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.16);
   display: flex;
   width: 100%;
   flex-direction: ${({ currentStep }) => currentStep !== 3 && 'row-reverse'};
`;

const Button = styled(StyledButton)`
   height: 40px;
   width: 50%;
   :first-child {
      margin-right: 8px;
   }
`;

const Footer = ({
   currentStep,
   canMoveForward,
   nextStep,
   dobError,
   previousStep,
   showResult,
   isLoading,
   isPuiFilled,
   canFinishAssessment,
   isProvider,
   assignedTo,
}) => {
   return (
      <Wrapper currentStep={currentStep}>
         {currentStep === 3 && (
            <Button onClick={previousStep} expanded>
               Previous
            </Button>
         )}
         <Button
            onClick={nextStep}
            disabled={
               (!showResult && !isProvider && !assignedTo) ||
               !canMoveForward ||
               dobError ||
               isLoading ||
               (currentStep === 3 && !(canFinishAssessment || isPuiFilled))
            }
            appearance='primary'
            expanded={currentStep === 2 || currentStep === 3}>
            {isLoading ? (
               <Loader stroke='#fff' />
            ) : currentStep !== 3 ? (
               'Next'
            ) : (
               'Finish'
            )}
         </Button>
      </Wrapper>
   );
};

export default Footer;
