import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '@datashop/input';

const TextWrapper = styled.form`
   display: flex;
   padding: 8px 0;
   border-bottom: 1px solid rgb(232, 228, 228);
   flex: 1;

   > div {
      width: 100%;
   }
`;

const Item = styled.li`
   display: flex;
   flex-direction: column;
   cursor: default;
   background-color: #fff;

   &:last-child {
      ${TextWrapper} {
         border-bottom: none;
      }
   }
`;

const TextField = styled(Input)`
   width: 100%;
`;

const TextInputQuestion = ({
   onChange,
   question: { id, placeholder = '', inputType = '' },
   providerFlow,
   answerMap,
   response,
}) => {
   const [text, setText] = useState(response || '');

   const textChangeHandler = event => {
      const {
         target: { value },
      } = event;
      if (inputType === 'number') {
         if (
            !(
               typeof parseInt(value.slice(-1)) === 'number' &&
               !isNaN(value.slice(-1))
            )
         ) {
            return;
         }
      }
      setText(value);
      onChange(id, value);
   };

   useEffect(() => {
      if (!providerFlow) {
         const answer = answerMap[id] || '';
         setText(answer);
      }
   }, [answerMap, id, providerFlow]);

   return (
      <Item>
         <TextWrapper key={id}>
            <TextField
               autoFocus
               value={text}
               defaultValue={response}
               placeholder={placeholder}
               onChange={textChangeHandler}
            />
         </TextWrapper>
      </Item>
   );
};

export default TextInputQuestion;
