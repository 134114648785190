import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledCard, FlexBox, Label } from './commons';
import Text from '@datashop/text';
import DatePicker from '@datashop/date-picker';
import Radio from '@datashop/radio';
import Input from '@datashop/input';
import moment from 'moment';

const CardWrapper = styled(StyledCard)`
   > *:not(:last-child) {
      margin-bottom: 16px;
   }
`;

const genders = [
   { value: 'male', label: 'Male' },
   { value: 'female', label: 'Female' },
   { value: 'other', label: 'Other' },
];

const CheckinCard = ({ details = {}, onUpdate }) => {
   const [datepickerOpen, toggleDatepicker] = useState(false);

   return (
      <CardWrapper>
         <div>
            <Text fontWeight='bold' fontSize='large'>
               Personal Details
            </Text>
            <Text
               fontSize='small'
               style={{ color: '#868686', fontWeight: 600 }}>
               Please ensure that your name and DOB matches your insurance
               details.
            </Text>
         </div>
         <div>
            <Label>First Name</Label>
            <Input
               icon='person'
               value={details.firstName}
               onChange={e => onUpdate({ firstName: e.target.value })}
               placeholder='Joy'
            />
         </div>
         <div>
            <Label>Last Name</Label>
            <Input
               icon='person'
               value={details.lastName}
               onChange={e => onUpdate({ lastName: e.target.value })}
               placeholder='Lawson'
            />
         </div>
         <div>
            <Label>Date of birth</Label>
            <DatePicker
               showDatePicker={datepickerOpen}
               dateFormat='MM/DD/YYYY'
               onDateChange={(dob, dobValid) => {
                  onUpdate({ dob, dobValid });
                  toggleDatepicker(false);
               }}
               disableDate={date => moment(date).valueOf() > moment().valueOf()}
               onFocusChange={() => toggleDatepicker(!datepickerOpen)}
               icon='event'
               date={details.dob}></DatePicker>
         </div>
         <div>
            <Label>Gender</Label>
            <FlexBox style={{ height: 32 }} justifyContent='space-between'>
               {genders.map(({ value, label }) => (
                  <Radio
                     key={value}
                     checked={details.gender === value}
                     label={
                        <span onClick={() => onUpdate({ gender: value })}>
                           {label}
                        </span>
                     }
                     onChange={() => onUpdate({ gender: value })}
                  />
               ))}
            </FlexBox>
         </div>
      </CardWrapper>
   );
};

export default CheckinCard;
