import React from 'react';
import styled from 'styled-components';
import ChangeView from './ChangeView';

const PatientBox = styled.div`
   background-color: #ffffff;
   border: 1px solid #e7e5e5;
   border-radius: 8px 8px 8px 0;
   padding: 12px;
   margin-bottom: 8px;
   margin-left: 16px;
   margin-right: 12px;
   width: auto;
   max-width: 70%;
   color: #2f2f2f;
   font-size: 14px;
   line-height: 20px;
   overflow-wrap: break-word;
`;

const PatientContainer = ({ el, children, handleMarksAsRead }) => {
   return el.observe ? (
      <ChangeView el={el} handleMarksAsRead={handleMarksAsRead}>
         {children}
      </ChangeView>
   ) : (
      <PatientBox>{children}</PatientBox>
   );
};

export default PatientContainer;
