import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Badge from '@datashop/badge';

import {
   BADGE_APPEARANCES,
   BADGE_ICONS,
   CALL_STATUS,
   CALL_STATUS_LABELS,
   USER_TYPE,
} from '../../../../constants';

import StatusChangeDropdown from '../../StatusChangeDropdown';

const StatusWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const BadgeText = styled.p`
   font-weight: 700;
   text-transform: uppercase;
`;

const CallStatus = ({ userType, item, badgeDetails, onStatusChange }) => {
   const { callStatus: status } = item;
   const canUpdateStatus =
      (userType === USER_TYPE.provider && status === CALL_STATUS.inProgress) ||
      (userType === USER_TYPE.staff &&
         status === (CALL_STATUS.waiting || CALL_STATUS.onCall));

   if (canUpdateStatus) {
      return (
         <StatusChangeDropdown
            onStatusChange={onStatusChange}
            item={item}
            userType={userType}
         />
      );
   }

   return (
      <StatusWrapper>
         <Badge subtle appearance={badgeDetails[status].appearance}>
            <BadgeText>{badgeDetails[status].text}</BadgeText>
         </Badge>
      </StatusWrapper>
   );
};

CallStatus.propTypes = {
   userType: PropTypes.oneOf([...Object.values(USER_TYPE)]).isRequired,
   item: PropTypes.shape({
      callStatus: PropTypes.oneOf(Object.values(CALL_STATUS)),
   }).isRequired,
   badgeDetails: PropTypes.shape({
      appearance: PropTypes.oneOf(Object.values(BADGE_APPEARANCES)),
      icon: PropTypes.oneOf(Object.values(BADGE_ICONS)),
      text: PropTypes.oneOf(Object.values(CALL_STATUS_LABELS)),
   }).isRequired,
   onStatusChange: PropTypes.func,
};

export default CallStatus;
