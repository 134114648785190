import React, { Component } from 'react';
import styled from 'styled-components';
import Input from '@datashop/input';
import Answer from 'components/Answer/Answer';

const TextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: ${({ providerFlow }) => !providerFlow && '8px 0'};
   border-bottom: ${({ providerFlow }) =>
      !providerFlow && '1px solid rgb(232, 228, 228)'};
   flex: 1;
   cursor: pointer;
`;

const Item = styled.li`
   display: flex;
   flex-direction: column;
   cursor: default;
   background-color: #fff;

   &:last-child {
      ${TextWrapper} {
         border-bottom: none;
      }
   }
`;

const Name = styled.p`
   ${({ providerFlow }) =>
      providerFlow
         ? 'color: #2F2F2F;font-size: 12px;font-weight: 600;line-height: 16px;'
         : 'font-size: 16px;font-weight:600;letter-spacing: -0.38px;line-height: 24px;'};
`;

const CheckedIcon = styled.i`
   color: #0072e4;
   cursor: pointer;
   padding: 8px;
   font-size: ${({ providerFlow }) => (!providerFlow ? '20px' : '16px')};
`;

const UncheckedIcon = styled.i`
   color: #bfc0c0;
   cursor: pointer;
   padding: 8px;
   font-size: ${({ providerFlow }) => (!providerFlow ? '20px' : '16px')};
`;

const NameWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const ChildWrapper = styled.div`
   padding: 4px 8px;
`;

const Label = styled.p`
   color: #2f2f2f;
   font-weight: 600;
   line-height: 20px;
`;

class BooleanQuestion extends Component {
   state = {
      selectedAnswerId: null,
   };

   componentDidMount() {
      const { question } = this.props;
      // Provider flow
      if (this.props.providerFlow) {
         if (this.props.answerMap[this.props.id]) {
            this.setState({
               selectedAnswerId: this.props.answerMap[this.props.id],
            });
            if (!this.props.providerFlow) {
               // Check if the given answer id also has a dependent question
               const choiceObject = this.props.question.answer.choiceList.find(
                  item => {
                     return item.id === this.props.answerMap[this.props.id];
                  }
               );
               //  Set the dependent question
               const childQuestion = this.props.dependentSurveyList.find(
                  item => item.id === choiceObject.childId
               );
               if (childQuestion) {
                  this.setState({ childQuestion });
                  if (this.props.answerMap[childQuestion.id]) {
                     this.setState({
                        childQuestionAnswerId: this.props.answerMap[
                           childQuestion.id
                        ],
                     });
                  }
               }
            }
         }
      } else {
         if (this.props.answerMap[question.id])
            this.setParentAndChildAnswer(this.props.answerMap[question.id]);
      }
   }

   onChangeHandler = (e, selectedAnswerId, childId) => {
      const {
         onChange,
         onClickRadioHandler,
         question: { id },
      } = this.props;
      this.setState({ selectedAnswerId });
      onChange
         ? onChange(id, selectedAnswerId)
         : onClickRadioHandler(e, {
              questionId: id,
              answerId: selectedAnswerId,
           });
      //  Check if child id exists
      if (childId) {
         this.checkAndSetChild(childId);
         // Disable next option if unanswered
         if (childId && !this.props.answerMap[childId]) {
            // Check if not from provider flow
            if (!this.props.providerFlow) {
               this.props.disableNext();
            }
         }
      } else {
         this.setState({ childQuestion: null });
      }
   };

   childChangeHandler = (value, id) => {
      this.props.onChange(id, value);
   };

   checkAndSetChild = childId => {
      //   Check if child has a dependent question
      if (childId) {
         // Get the dependent question
         const childQuestion = this.props.dependentSurveyList.find(
            item => item.id === childId
         );
         // Check if that dependent question is answered
         if (this.props.answerMap[childId]) {
            // If yes get save response in state
            this.setState({
               childQuestionAnswerId: this.props.answerMap[childId],
            });
         } else {
            // Cleanup
            this.setState({
               childQuestionAnswerId: null,
            });
         }
         this.setState({ childQuestion });
      }
   };

   setParentAndChildAnswer(questionAnswerId) {
      // Check if previously annswered
      if (typeof questionAnswerId !== 'undefined') {
         // Get the child which selected previously
         const child = this.props.question.answer.choiceList.find(
            item => item.id === questionAnswerId
         );
         if (!this.props.providerFlow) {
            if (child.childId) {
               this.checkAndSetChild(child.childId);
            } else {
               // Clear previous results
               this.setState({
                  childQuestion: null,
                  childQuestionAnswerId: null,
               });
            }
         } else {
            this.checkAndSetChild(child.childId);
         }
         this.setState({ selectedAnswerId: questionAnswerId });
      } else {
         this.setState({
            selectedAnswerId: null,
            childQuestion: null,
            childQuestionAnswerId: null,
         });
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (!this.props.providerFlow) {
         if (prevProps.question.id !== this.props.question.id) {
            // Question id of already answered questions
            const questionAnswerId = this.props.answerMap[
               this.props.question.id
            ];
            this.setParentAndChildAnswer(questionAnswerId);
         }
      }
   }

   render() {
      const {
         providerFlow,
         question: {
            answer: {
               choiceList = [
                  { id: true, label: 'Yes' },
                  { id: false, label: 'No' },
               ],
            },
         },
      } = this.props;
      const answers = choiceList;
      return (
         <Item>
            {answers.map(item => (
               <TextWrapper providerFlow={providerFlow} key={item.label}>
                  <NameWrapper
                     onClick={e =>
                        this.onChangeHandler(e, item.id, item.childId)
                     }>
                     {this.state.selectedAnswerId !== null &&
                     item.id === this.state.selectedAnswerId ? (
                        <CheckedIcon
                           providerFlow={providerFlow}
                           className='material-icons'>
                           check_circle
                        </CheckedIcon>
                     ) : (
                        <UncheckedIcon
                           providerFlow={providerFlow}
                           className='material-icons'>
                           panorama_fish_eye
                        </UncheckedIcon>
                     )}
                     <Name providerFlow={providerFlow}>{item.value}</Name>
                  </NameWrapper>
                  {this.state.childQuestion && item.childId && (
                     <ChildWrapper providerFlow={providerFlow}>
                        <Label>{this.state.childQuestion.question}</Label>

                        {this.state.childQuestion.type ===
                           'SINGLECHOICE_BUTTON' && (
                           <Answer
                              id={item.childId}
                              response={this.state.childQuestionAnswerId}
                              answerQuestion={this.props.onChange}
                              providerFlow={providerFlow}
                              question={this.state.childQuestion}
                           />
                        )}
                        {this.state.childQuestion.type === 'TEXTINPUT' && (
                           <Input
                              style={{ marginTop: 8 }}
                              placeholder={this.state.childQuestion.placeholder}
                              defaultValue={this.props.answerMap[item.childId]}
                              onChange={e =>
                                 this.props.onChange(
                                    item.childId,
                                    e.target.value
                                 )
                              }
                           />
                        )}
                     </ChildWrapper>
                  )}
               </TextWrapper>
            ))}
         </Item>
      );
   }
}

export default BooleanQuestion;
