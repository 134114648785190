import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Mixpanel } from 'helpers/mixpanelHelper';

class Campaign extends Component {
   state = {
      mounted: false,
   };

   componentDidMount() {
      const params = new URLSearchParams(this.props.location.search);
      const affiliate = params.get('source');
      if (affiliate) {
         Mixpanel.people.set({
            Affiliate: 'Marketing',
         });
         Mixpanel.track('Redirected From Marketing', {
            category: 'inNote - Campaign',
         });
      }
      this.setState({ mounted: true });
   }

   render() {
      return this.state.mounted ? <Redirect to='/' /> : null;
   }
}

export default Campaign;
