import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ALLOWED_MIME_TYPES } from '../Attach';

const Wrapper = styled.span`
   display: inline-flex;
   align-items: center;
   justify-content: center;
   height: 32px;
   width: 32px;
   min-width: 32px;
   border-radius: 50%;
   background: #fde6ce;

   i {
      color: #f07d00;
      font-size: 18px;
   }
`;

const FileIcon = ({ fileType = '' }) => {
   let iconName = 'description';
   switch (fileType.split('/')[0].toLowerCase()) {
      case 'image':
         iconName = 'image';
         break;
      case 'audio':
         iconName = 'audiotrack';
         break;
      case 'video':
         iconName = 'movie';
         break;
      default:
         iconName = 'description';
   }

   return (
      <Wrapper>
         <i className='material-icons'>{iconName}</i>
      </Wrapper>
   );
};

FileIcon.propTypes = {
   fileType: PropTypes.oneOf([...ALLOWED_MIME_TYPES]).isRequired,
};

export default FileIcon;
