import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, motion } from '@krishnaxv/react-surface';
import TeleVisit from 'components/TeleVisit/TeleVisit';
import MobilePermissionModal from 'components/TeleVisit/MobilePermissionModal';
import axios from 'helpers/axios';
import { Mixpanel } from 'helpers/mixpanelHelper';
import createToast from 'helpers/toastHelper';
import PatientWaitingScreen from 'components/TeleVisit/PatientWaitingScreen';
import returnCamelCasedName from 'helpers/nameTransformer';
import { checkPermission } from 'helpers/checkPermission';
import { get } from 'lodash-es';
import { logSentry } from 'services/sentry';

const modalStyle = {
   backgroundColor: '#f4f4f4',
   height: '100vh',
   width: '100vw',
   display: 'flex',
};

const patientWaitModalStyle = {
   height: `${window.innerHeight}px`,
   width: '100vw',
   display: 'flex',
   zIndex: 9999,
};

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   background-color: #fff;
   flex: 1;
   padding: 24px 16px 16px;
   justify-content: space-between;
`;

const ModalWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

class PatientInsuranceDetails extends Component {
   child = undefined;
   state = {
      sendToTeleVisit: false,
      showPermissionModal: false,
      scheduleId: '',
      showErrorMessage: false,
      isLoading: true,
      providerName: '',
      token: '',
      message: 'Your visit has ended.',
      scheduledOn: '',
      callEnded: false,
      flowType: 'STAFF',
      recipientType: 'STAFF',
      showWaitingScreen: false,
      mobileType: '',
      audioTrack: null,
      videoTrack: null,
      browserType: '',
   };

   componentWillMount() {
      const { scheduleId } = this.props;
      this.setState({
         scheduleId,
      });
      localStorage.setItem('consentGiven', scheduleId);
   }

   createWebsocket = token => {
      const URL =
         process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_SOCKET_URL`];
      this.socket = new WebSocket(
         `${URL}?app=${
            this.props.type === 'PATIENT'
               ? 'INNOTE_ASSISTANT_PATIENT'
               : 'INNOTE_ASSISTANT_VISITOR'
         }&token=${token}`
      );

      this.socket.onopen = () => {
         if (!this.state.sendToTeleVisit) {
            if (this.props.type === 'VISITOR') {
               this.visitorJoin();
            } else {
               // HIT join call api
               axios
                  .post(
                     `/innote-survey/telehealth/video/rooms/patients/_join`,
                     {
                        scheduleId: this.state.scheduleId,
                     }
                  )
                  .then(
                     ({
                        data: {
                           flowType,
                           roomInfo,
                           visitId,
                           visitInstanceId,
                           userDetails,
                           patientDetails,
                           scheduledOn,
                        },
                     }) => {
                        // Using block scope for same variable name
                        Mixpanel.people.set({
                           Type: 'Patient',
                        });
                        // @TODO remove this call after debugging
                        logSentry({
                           data: {
                              Type: 'Patient',
                           },
                           identify: this.props.participantId,
                        });
                        Mixpanel.identify(this.props.participantId);
                        const { firstName, lastName } = userDetails;
                        this.setState({
                           visitId,
                           visitInstanceId,
                           scheduledOn,
                           recipientType: flowType,
                           isLoading: false,
                           providerName: returnCamelCasedName(
                              firstName,
                              lastName
                           ),
                        });
                        if (roomInfo !== null) {
                           const {
                              id: roomId,
                              name: roomName,
                              token,
                           } = roomInfo;
                           this.setState({
                              roomId,
                              roomName,
                              token,
                              sendToTeleVisit: true,
                           });
                        } else {
                           Mixpanel.track('Showing waiting screen', {
                              category: 'groupCall',
                              Participant: 'Patient',
                           });
                           this.setState({
                              showWaitingScreen: true,
                              recipientType: flowType,
                           });
                        }
                     }
                  )
                  .catch(err => {
                     this.setState({
                        showErrorMessage: true,
                        isLoading: false,
                        message: get(
                           err,
                           'response.data.error.message',
                           'Your visit has ended.'
                        ),
                     });
                  });
            }
         }
      };
      this.socket.onclose = () => {
         setTimeout(() => {
            this.createWebsocket(token);
         }, 1000);
      };

      this.socket.onmessage = this.handleSocketMessage;
   };

   unMuteHandler = () => {
      this.child.toggleAudio();
   };

   handleSocketMessage = ({ data }) => {
      // handle socket
      const details = JSON.parse(data);
      const {
         message: { event, eventDetails },
      } = details;
      switch (event) {
         case 'VIRTUAL_VISIT_STARTED':
            if (this.props.type === 'VISITOR') {
               this.connectVisitor();
            }
            break;
         case 'JOIN_EXAM_ROOM':
            const {
               userDetails: { firstName, lastName, role },
               visitId,
            } = eventDetails;
            this.setState({
               visitId,
               recipientType: role,
               providerName: returnCamelCasedName(firstName, lastName),
            });
            this.createRoom(visitId);

            break;
         case 'VISIT_ENDED':
            this.setState({ callEnded: true });
            break;
         case 'UNMUTE_REQUEST':
            if (navigator.userAgent.toLowerCase().indexOf('electron/') === -1) {
               createToast({
                  title: 'Request to unmute',
                  appearance: 'default',
                  message: `${returnCamelCasedName(
                     get(eventDetails, 'requester.firstName', ''),
                     get(eventDetails, 'requester.lastName', '')
                  )} has requested you to unmute your mic.`,
                  time: 30,
                  button: { text: 'Unmute', function: this.unMuteHandler },
               });
            }
            break;
         case 'MUTE':
            if (navigator.userAgent.toLowerCase().indexOf('electron/') === -1) {
               this.unMuteHandler();
               createToast({
                  message: `${returnCamelCasedName(
                     get(eventDetails, 'requester.firstName', ''),
                     get(eventDetails, 'requester.lastName', '')
                  )} has muted your audio.`,
                  time: 5,
                  appearance: 'default',
               });
            }
            break;
         default:
            console.log('No case found', details);
            break;
      }
   };

   componentDidMount = async () => {
      this.checkBrowser();
      const permission = await checkPermission();
      if (permission) {
         const { videoTrack, audioTrack } = permission;
         this.setState({ videoTrack, audioTrack });
         if (this.props.socketToken)
            this.createWebsocket(this.props.socketToken);
      } else {
         Mixpanel.track(
            `Showing permission page - ${
               this.props.type === 'VISITOR' ? 'visitor' : 'patient'
            }`,
            { category: 'browser' }
         );
         this.setState({ showPermissionModal: true, isLoading: false });
      }
   };

   visitorJoin = () => {
      const { sessionId, visitorName: name } = this.props;
      const visitorName = name || sessionId.split('_')[1].split('+').join(' ');

      Mixpanel.people.set({
         $name: `${visitorName}`,
         Type: 'Visitor',
      });
      Mixpanel.identify(sessionId);
      if (this.props.messageValidate === 'Please wait for the call to start.') {
         this.setState({
            showWaitingScreen: true,
            recipientType: this.props.type,
         });
      } else {
         this.connectVisitor();
      }
   };

   connectVisitor = () => {
      const {
         sessionId,
         visitorId,
         visitId,
         participantId,
         visitorName: name,
      } = this.props;
      const visitorName = name || sessionId.split('_')[1].split('+').join(' ');

      Mixpanel.track('Joining Virtual Visit', {
         category: 'groupCall',
         Participant: 'visitor',
      });
      axios
         .post('/innote-survey/telehealth/video/rooms/visitors/_join', {
            visitId: visitId,
            visitorId: visitorId || participantId || '',
            sessionId: participantId ? '' : sessionId.split('_')[0],
            visitorDetails: {
               name: visitorName || null,
               email: '',
               phoneNo: '',
            },
            visitorLink: '',
         })
         .then(
            ({
               data: {
                  visitInstanceId,
                  visitId,
                  roomInfo: { id: roomId, name: roomName },
                  token,
               },
            }) => {
               Mixpanel.track('Join API Visit success', {
                  category: 'groupCall',
                  Participant: 'visitor',
               });
               this.setState({
                  isLoading: false,
                  sendToTeleVisit: true,
                  visitInstanceId,
                  visitId,
                  token,
                  roomId,
                  roomName,
               });
            }
         )
         .catch(err => {
            Mixpanel.track('Join API Visit Failure', {
               category: 'groupCall',
               Participant: 'visitor',
            });
            this.setState({
               showErrorMessage: true,
               isLoading: false,
               message: get(
                  err,
                  'response.data.error.message',
                  'Your visit has ended.'
               ),
            });
         });
   };

   createRoom = visitId => {
      Mixpanel.track('Virtual Visit Initiated', {
         category: 'groupCall',
         Participant: 'Patient',
      });
      axios
         .post(`innote-survey/telehealth/video/rooms/_create`, {
            visitId,
            visitInstanceId: this.state.visitInstanceId,
         })
         .then(({ data: { roomId, roomName, token } }) => {
            Mixpanel.track('Starting Virtual Visit', {
               category: 'groupCall',
               Participant: 'Patient',
            });

            this.setState({ roomId, roomName, token, sendToTeleVisit: true });
         })
         .catch(err => {
            Mixpanel.track('Virtual Visit Api Error', {
               Participant: 'Patient',
               category: 'groupCall',
            });
            createToast({ message: 'Something went wrong!' });
            this.setState({
               showErrorMessage: true,
            });
         })
         .finally(() => {
            this.setState({
               isLoading: false,
            });
         });
   };

   checkBrowser = () => {
      this.checkMobile();
      this.setState({
         browserType:
            navigator.userAgent.indexOf('Safari') > -1 ? 'safari' : 'chrome',
      });
   };

   checkMobile = () => {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileAndroid = /Android/i.test(userAgent);
      const isMobileIOS =
         /iPad|iPhone|iPod/.test(navigator.platform) ||
         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      if (isMobileAndroid || isMobileIOS) {
         this.setState({ mobileType: isMobileAndroid ? 'android' : 'ios' });
         return true;
      }
      return false;
   };

   componentWillUnmount() {
      if (this.socket) {
         this.socket.close();
      }
   }

   render() {
      const {
         sendToTeleVisit,
         roomId,
         roomName,
         participantId = this.props.participantId,
         providerName,
         token,
         message,
         scheduleId,
         showErrorMessage,
         isLoading,
         showPermissionModal,
         callEnded,
         visitId,
         scheduledOn,
         recipientType,
         mobileType,
         videoTrack,
         audioTrack,
         browserType,
      } = this.state;

      const modalType =
         mobileType === 'ios' && browserType === 'safari' ? 'apple' : 'google';

      if (sendToTeleVisit) {
         return (
            <TeleVisit
               onRef={ref => (this.child = ref)}
               patientFlow={true}
               token={token}
               recipientType={recipientType}
               roomId={roomId}
               videoTrack={videoTrack}
               audioTrack={audioTrack}
               visitId={visitId}
               callEnded={callEnded}
               type={this.props.type}
               scheduleId={scheduleId}
               roomName={roomName}
               participantId={participantId}
               providerName={providerName}
            />
         );
      }
      return (
         <Wrapper>
            {(showPermissionModal || showErrorMessage || isLoading) && (
               <Modal motion={motion.slideInBottom} style={modalStyle}>
                  {onCloseModal => (
                     <ModalWrapper>
                        <MobilePermissionModal
                           message={message}
                           loading={isLoading}
                           showErrorMessage={showErrorMessage}
                           modalType={modalType}
                        />
                     </ModalWrapper>
                  )}
               </Modal>
            )}
            {this.state.showWaitingScreen && (
               <Modal
                  motion={motion.slideInBottom}
                  style={patientWaitModalStyle}>
                  {onCloseModal => (
                     <PatientWaitingScreen
                        date={scheduledOn}
                        name={providerName}
                     />
                  )}
               </Modal>
            )}
         </Wrapper>
      );
   }
}

export default PatientInsuranceDetails;
