import React from 'react';
import { version } from '../../../package.json';
import * as S from './style';

const CardCopyRight = () => {
   return (
      <S.CopyWriteWrapper shadow='light'>
         <S.VersionContainer>
            <S.LogoWrapper>
               <S.Logo
                  src={`${process.env.PUBLIC_URL}/img/InNote.png`}
                  alt='Datashop Care'
               />
               <S.Version>
                  <S.VersionText>{`V${version}`}</S.VersionText>
               </S.Version>
            </S.LogoWrapper>
            <S.CopyRightText>Copyright 2020 Innovaccer Inc.</S.CopyRightText>
         </S.VersionContainer>
      </S.CopyWriteWrapper>
   );
};

export default CardCopyRight;
