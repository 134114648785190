import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   background-color: black;
   flex: 1;
   width: 100vh;
`;

const DataWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
`;

const HeaderText = styled.div`
   color: #ffffff;
   font-size: 28px;
   line-height: 40px;
   margin: 56px 0 24px;
`;

const SubText = styled.div`
   display: flex;
   align-items: center;
   color: #ffffff;
   line-height: 20px;
   margin-bottom: 8px;
`;

const Icon = styled.i`
   font-size: 24px;
`;

const Image = styled.img`
   width: 605px;
`;

const VideoIcon = styled.i`
   font-size: 48px;
   color: #fff;
`;

const SubtextIcon = styled.img`
   width: 16px;
   margin: 0 6px;
`;

class PermissionModal extends Component {
   state = {
      modalType: 'google',
   };

   componentDidMount() {
      if (this.checkOs() && navigator.userAgent.indexOf('Safari') > -1) {
         this.setState({ modalType: 'apple' });
      }
   }

   checkOs = () => {
      const isIOS =
         /iPad|iPhone|iPod/.test(navigator.platform) ||
         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      return isIOS;
   };

   render() {
      const { modalType } = this.state;
      return (
         <Wrapper>
            {this.props.source === 'electron' ? (
               <DataWrapper>
                  <VideoIcon className='material-icons'>videocam_off</VideoIcon>
                  <HeaderText>Your microphone and camera is blocked</HeaderText>
                  <SubText>
                     &bull; Go to system preferences and give InNote app
                     permission to use camera and microphone and restart app.
                  </SubText>
                  <SubText>
                     &bull;After enabling camera and microphone, please close
                     the browser and click on the link again to join.
                  </SubText>
               </DataWrapper>
            ) : modalType === 'google' ? (
               <DataWrapper>
                  <Image
                     src={`${process.env.PUBLIC_URL}/img/accessAlert.png`}
                     alt='logo'
                  />
                  <HeaderText>Your microphone and camera is blocked</HeaderText>
                  <SubText>
                     &bull; Click the camera blocked icon{' '}
                     <Icon className='material-icons'>videocam_off</Icon>placed
                     in right side of your browser’s address bar.
                  </SubText>
                  <SubText>
                     &bull; After enabling camera and microphone, please close
                     the browser and click on the link again to join.
                  </SubText>
               </DataWrapper>
            ) : (
               <DataWrapper>
                  <VideoIcon className='material-icons'>videocam_off</VideoIcon>
                  <HeaderText>Your microphone and camera is blocked</HeaderText>
                  <div style={{ alignItems: 'left' }}>
                     <SubText>Follow these steps to allow access:</SubText>
                     <SubText>
                        • Open Settings,
                        <SubtextIcon
                           src={`${process.env.PUBLIC_URL}/img/settings.png`}
                           alt='Datashop Care'
                        />{' '}
                        and scroll to Safari
                        <SubtextIcon
                           src={`${process.env.PUBLIC_URL}/img/safari.png`}
                           alt='Datashop Care'
                        />
                     </SubText>
                     <SubText>
                        • Click on ‘Camera’ or ‘Microphone’ under ‘Settings for
                        Websites’ section.
                     </SubText>
                     <SubText>• Change access to ‘Allow’.</SubText>
                     <SubText>
                        • Close the browser and click on the link again to join.
                     </SubText>
                  </div>
               </DataWrapper>
            )}
         </Wrapper>
      );
   }
}

export default PermissionModal;
