import update from 'immutability-helper';
import {
   GET_ATTRIBUTED_PATIENT_DETAILS_START,
   GET_ATTRIBUTED_PATIENT_DETAILS_FAILURE,
   GET_ATTRIBUTED_PATIENT_DETAILS_SUCCESS,
} from './constants';

import { get } from 'lodash-es';

const initialState = {
   isPatientAttributionLoading: false,
   attributed: false,
};

const Chat = (state = initialState, action) => {
   switch (action.type) {
      case GET_ATTRIBUTED_PATIENT_DETAILS_START:
         return update(state, {
            $merge: {
               isPatientAttributionLoading: true,
            },
         });
      case GET_ATTRIBUTED_PATIENT_DETAILS_FAILURE:
         return update(state, {
            isPatientAttributionLoading: {
               $set: false,
            },
         });
      case GET_ATTRIBUTED_PATIENT_DETAILS_SUCCESS: {
         return update(state, {
            $merge: {
               isPatientAttributionLoading: false,
               attributed: get(action, 'data.attributed', false),
            },
         });
      }
      default:
         return state;
   }
};

export default Chat;
